const assessmentActionTypes = {
    // * Get list assessments
    GET_LIST_ASSESSMENTS_REQUEST: 'GET_LIST_ASSESSMENTS_REQUEST',
    GET_LIST_ASSESSMENTS_SUCCESS: 'GET_LIST_ASSESSMENTS_SUCCESS',
    GET_LIST_ASSESSMENTS_FAILURE: 'GET_LIST_ASSESSMENTS_FAILURE',

    // * Get list questions
    GET_LIST_QUESTIONS_REQUEST: 'GET_LIST_QUESTIONS_REQUEST',
    GET_LIST_QUESTIONS_SUCCESS: 'GET_LIST_QUESTIONS_SUCCESS',
    GET_LIST_QUESTIONS_FAILURE: 'GET_LIST_QUESTIONS_FAILURE',

    // * Get list templates
    GET_LIST_TEMPLATES_REQUEST: 'GET_LIST_TEMPLATES_REQUEST',
    GET_LIST_TEMPLATES_SUCCESS: 'GET_LIST_TEMPLATES_SUCCESS',
    GET_LIST_TEMPLATES_FAILURE: 'GET_LIST_TEMPLATES_FAILURE',

    // * Create assessment
    CREATE_ASSESSMENT_REQUEST: 'CREATE_ASSESSMENT_REQUEST',
    CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
    CREATE_ASSESSMENT_RESET: 'CREATE_ASSESSMENT_RESET',

    // * Get assessment details
    GET_ASSESSMENT_DETAILS_REQUEST: 'GET_ASSESSMENT_DETAILS_REQUEST',
    GET_ASSESSMENT_DETAILS_SUCCESS: 'GET_ASSESSMENT_DETAILS_SUCCESS',
    GET_ASSESSMENT_DETAILS_FAILURE: 'GET_ASSESSMENT_DETAILS_FAILURE',
    GET_ASSESSMENT_DETAILS_RESET: 'GET_ASSESSMENT_DETAILS_RESET',

    // * Get assessment clone details
    GET_ASSESSMENT_CLONE_DETAILS_REQUEST: 'GET_ASSESSMENT_CLONE_DETAILS_REQUEST',
    GET_ASSESSMENT_CLONE_DETAILS_SUCCESS: 'GET_ASSESSMENT_CLONE_DETAILS_SUCCESS',
    GET_ASSESSMENT_CLONE_DETAILS_FAILURE: 'GET_ASSESSMENT_CLONE_DETAILS_FAILURE',
    GET_ASSESSMENT_CLONE_DETAILS_RESET: 'GET_ASSESSMENT_CLONE_DETAILS_RESET',

    // * Edit assessment
    EDIT_ASSESSMENT_REQUEST: 'EDIT_ASSESSMENT_REQUEST',
    EDIT_ASSESSMENT_SUCCESS: 'EDIT_ASSESSMENT_SUCCESS',
    EDIT_ASSESSMENT_FAILURE: 'EDIT_ASSESSMENT_FAILURE',
    EDIT_ASSESSMENT_RESET: 'EDIT_ASSESSMENT_RESET',

    // * Get list questions by template ids
    GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_REQUEST: 'GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_REQUEST',
    GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_SUCCESS: 'GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_SUCCESS',
    GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_FAILURE: 'GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_FAILURE',

    // * Get list exam tags
    GET_LIST_EXAM_TAGS_REQUEST: 'GET_LIST_EXAM_TAGS_REQUEST',
    GET_LIST_EXAM_TAGS_SUCCESS: 'GET_LIST_EXAM_TAGS_SUCCESS',
    GET_LIST_EXAM_TAGS_FAILURE: 'GET_LIST_EXAM_TAGS_FAILURE',

    // * Get list candidates by assessment
    GET_LIST_CANDIDATES_BY_ASSESSMENT_REQUEST: 'GET_LIST_CANDIDATES_BY_ASSESSMENT_REQUEST',
    GET_LIST_CANDIDATES_BY_ASSESSMENT_SUCCESS: 'GET_LIST_CANDIDATES_BY_ASSESSMENT_SUCCESS',
    GET_LIST_CANDIDATES_BY_ASSESSMENT_FAILURE: 'GET_LIST_CANDIDATES_BY_ASSESSMENT_FAILURE',

    // * Get list questions by assessment
    GET_LIST_QUESTIONS_BY_ASSESSMENT_REQUEST: 'GET_LIST_QUESTIONS_BY_ASSESSMENT_REQUEST',
    GET_LIST_QUESTIONS_BY_ASSESSMENT_SUCCESS: 'GET_LIST_QUESTIONS_BY_ASSESSMENT_SUCCESS',
    GET_LIST_QUESTIONS_BY_ASSESSMENT_FAILURE: 'GET_LIST_QUESTIONS_BY_ASSESSMENT_FAILURE',

    // * Get assessment summary participants
    GET_ASSESSMENT_SUMMARY_PARTICIPANTS_REQUEST: 'GET_ASSESSMENT_SUMMARY_PARTICIPANTS_REQUEST',
    GET_ASSESSMENT_SUMMARY_PARTICIPANTS_SUCCESS: 'GET_ASSESSMENT_SUMMARY_PARTICIPANTS_SUCCESS',
    GET_ASSESSMENT_SUMMARY_PARTICIPANTS_FAILURE: 'GET_ASSESSMENT_SUMMARY_PARTICIPANTS_FAILURE',
    GET_ASSESSMENT_SUMMARY_PARTICIPANTS_RESET: 'GET_ASSESSMENT_SUMMARY_PARTICIPANTS_RESET',

    // * Get assessment summary statistic
    GET_ASSESSMENT_SUMMARY_STATISTIC_REQUEST: 'GET_ASSESSMENT_SUMMARY_STATISTIC_REQUEST',
    GET_ASSESSMENT_SUMMARY_STATISTIC_SUCCESS: 'GET_ASSESSMENT_SUMMARY_STATISTIC_SUCCESS',
    GET_ASSESSMENT_SUMMARY_STATISTIC_FAILURE: 'GET_ASSESSMENT_SUMMARY_STATISTIC_FAILURE',
    GET_ASSESSMENT_SUMMARY_STATISTIC_RESET: 'GET_ASSESSMENT_SUMMARY_STATISTIC_RESET',

    // * Get assessment summary question list
    GET_ASSESSMENT_SUMMARY_QUESTION_LIST_REQUEST: 'GET_ASSESSMENT_SUMMARY_QUESTION_LIST_REQUEST',
    GET_ASSESSMENT_SUMMARY_QUESTION_LIST_SUCCESS: 'GET_ASSESSMENT_SUMMARY_QUESTION_LIST_SUCCESS',
    GET_ASSESSMENT_SUMMARY_QUESTION_LIST_FAILURE: 'GET_ASSESSMENT_SUMMARY_QUESTION_LIST_FAILURE',
    GET_ASSESSMENT_SUMMARY_QUESTION_LIST_RESET: 'GET_ASSESSMENT_SUMMARY_QUESTION_LIST_RESET',

    // * Get assessment summary candidate list
    GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_REQUEST: 'GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_REQUEST',
    GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_SUCCESS: 'GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_SUCCESS',
    GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_FAILURE: 'GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_FAILURE',
    GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_RESET: 'GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_RESET',

    // * Get assessment candidate detail
    GET_ASSESSMENT_CANDIDATE_DETAIL_REQUEST: 'GET_ASSESSMENT_CANDIDATE_DETAIL_REQUEST',
    GET_ASSESSMENT_CANDIDATE_DETAIL_SUCCESS: 'GET_ASSESSMENT_CANDIDATE_DETAIL_SUCCESS',
    GET_ASSESSMENT_CANDIDATE_DETAIL_FAILURE: 'GET_ASSESSMENT_CANDIDATE_DETAIL_FAILURE',
    GET_ASSESSMENT_CANDIDATE_DETAIL_RESET: 'GET_ASSESSMENT_CANDIDATE_DETAIL_RESET',

    // * Get assessment candidate result
    GET_ASSESSMENT_CANDIDATE_RESULT_REQUEST: 'GET_ASSESSMENT_CANDIDATE_RESULT_REQUEST',
    GET_ASSESSMENT_CANDIDATE_RESULT_SUCCESS: 'GET_ASSESSMENT_CANDIDATE_RESULT_SUCCESS',
    GET_ASSESSMENT_CANDIDATE_RESULT_FAILURE: 'GET_ASSESSMENT_CANDIDATE_RESULT_FAILURE',
    GET_ASSESSMENT_CANDIDATE_RESULT_RESET: 'GET_ASSESSMENT_CANDIDATE_RESULT_RESET',

    // * Get assessment detail question  result
    GET_ASSESSMENT_DETAIL_QUESTION_RESULT_REQUEST: 'GET_ASSESSMENT_DETAIL_QUESTION_RESULT_REQUEST',
    GET_ASSESSMENT_DETAIL_QUESTION_RESULT_SUCCESS: 'GET_ASSESSMENT_DETAIL_QUESTION_RESULT_SUCCESS',
    GET_ASSESSMENT_DETAIL_QUESTION_RESULT_FAILURE: 'GET_ASSESSMENT_DETAIL_QUESTION_RESULT_FAILURE',
    GET_ASSESSMENT_DETAIL_QUESTION_RESULT_RESET: 'GET_ASSESSMENT_DETAIL_QUESTION_RESULT_RESET',

    // * Start Assessment
    START_ASSESSMENT_REQUEST: 'START_ASSESSMENT_REQUEST',
    START_ASSESSMENT_SUCCESS: 'START_ASSESSMENT_SUCCESS',
    START_ASSESSMENT_FAILURE: 'START_ASSESSMENT_FAILURE',

    // * Stop Assessment
    STOP_ASSESSMENT_REQUEST: 'STOP_ASSESSMENT_REQUEST',
    STOP_ASSESSMENT_SUCCESS: 'STOP_ASSESSMENT_SUCCESS',
    STOP_ASSESSMENT_FAILURE: 'STOP_ASSESSMENT_FAILURE',

    // * Rebuild Assessment
    REBUILD_ASSESSMENT_REQUEST: 'REBUILD_ASSESSMENT_REQUEST',
    REBUILD_ASSESSMENT_SUCCESS: 'REBUILD_ASSESSMENT_SUCCESS',
    REBUILD_ASSESSMENT_FAILURE: 'REBUILD_ASSESSMENT_FAILURE',

    // * Delete Assessments
    DELETE_ASSESSMENTS_REQUEST: 'DELETE_ASSESSMENTS_REQUEST',
    DELETE_ASSESSMENTS_SUCCESS: 'DELETE_ASSESSMENTS_SUCCESS',
    DELETE_ASSESSMENTS_FAILURE: 'DELETE_ASSESSMENTS_FAILURE',

    // * Get language supported
    GET_LANGUAGE_SUPPORTED_REQUEST: 'GET_LANGUAGE_SUPPORTED_REQUEST',
    GET_LANGUAGE_SUPPORTED_SUCCESS: 'GET_LANGUAGE_SUPPORTED_SUCCESS',
    GET_LANGUAGE_SUPPORTED_FAILURE: 'GET_LANGUAGE_SUPPORTED_FAILURE',

    // * Get assessment summary departments
    GET_ASSESSMENT_SUMMARY_DEPARTMENTS_REQUEST: 'GET_ASSESSMENT_SUMMARY_DEPARTMENTS_REQUEST',
    GET_ASSESSMENT_SUMMARY_DEPARTMENTS_SUCCESS: 'GET_ASSESSMENT_SUMMARY_DEPARTMENTS_SUCCESS',
    GET_ASSESSMENT_SUMMARY_DEPARTMENTS_FAILURE: 'GET_ASSESSMENT_SUMMARY_DEPARTMENTS_FAILURE',
    GET_ASSESSMENT_SUMMARY_DEPARTMENTS_RESET: 'GET_ASSESSMENT_SUMMARY_DEPARTMENTS_RESET'
};

export default assessmentActionTypes;
