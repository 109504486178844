import types from './actionTypes';

/**
 * TODO: login action
 */
const login = (payload) => ({
    type: types.LOGIN_REQUEST,
    payload
});

const loginSuccess = (payload) => ({
    type: types.LOGIN_SUCCESS,
    payload
});

const loginFailure = (payload) => ({
    type: types.LOGIN_FAILURE,
    payload
});

const resetLoginError = () => ({
    type: types.LOGIN_RESET
});

const register = (payload) => ({
    type: types.REGISTER_REQUEST,
    payload
});

const registerSuccess = (payload) => ({
    type: types.REGISTER_SUCCESS,
    payload
});

const registerFailure = (payload) => ({
    type: types.REGISTER_FAILURE,
    payload
});

const resetRegisterError = () => ({
    type: types.REGISTER_RESET
});

const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD_REQUEST,
    payload
});

const forgotPasswordSuccess = (payload) => ({
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload
});

const forgotPasswordFailure = (payload) => ({
    type: types.FORGOT_PASSWORD_FAILURE,
    payload
});

const resetForgotPasswordError = () => ({
    type: types.FORGOT_PASSWORD_RESET
});

const forgotPasswordResend = (payload) => ({
    type: types.FORGOT_PASSWORD_RESEND_REQUEST,
    payload
});

const forgotPasswordResendSuccess = (payload) => ({
    type: types.FORGOT_PASSWORD_RESEND_SUCCESS,
    payload
});

const forgotPasswordResendFailure = (payload) => ({
    type: types.FORGOT_PASSWORD_RESEND_FAILURE,
    payload
});

const forgotPasswordVerifyCode = (payload) => ({
    type: types.FORGOT_PASSWORD_VERIFY_CODE_REQUEST,
    payload
});

const forgotPasswordVerifyCodeSuccess = (payload) => ({
    type: types.FORGOT_PASSWORD_VERIFY_CODE_SUCCESS,
    payload
});

const forgotPasswordVerifyCodeFailure = (payload) => ({
    type: types.FORGOT_PASSWORD_VERIFY_CODE_FAILURE,
    payload
});

const resetForgotPasswordVerifyCodeError = () => ({
    type: types.FORGOT_PASSWORD_VERIFY_CODE_RESET
});

const resetPassword = (payload) => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload
});

const resetPasswordSuccess = (payload) => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload
});

const resetPasswordFailure = (payload) => ({
    type: types.RESET_PASSWORD_FAILURE,
    payload
});

const resetResetPasswordError = () => ({
    type: types.RESET_PASSWORD_RESET
});

const resetAuthState = () => ({
    type: types.RESET_AUTH_STATE
});

/**
 * TODO: Logout
 */

const logout = () => ({
    type: types.LOGOUT_REQUEST
});

const logoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS
});

const logoutFailure = (payload) => ({
    type: types.LOGOUT_FAILURE,
    payload
});

const verifyOTP = (payload) => ({
    type: types.VERIFY_OTP_REQUEST,
    payload
});

const verifyOTPSuccess = (payload) => ({
    type: types.VERIFY_OTP_SUCCESS,
    payload
});

const verifyOTPFailure = (payload) => ({
    type: types.VERIFY_OTP_FAILURE,
    payload
});

const resetVerifyOTPError = () => ({
    type: types.VERIFY_OTP_RESET
});

const resendOTP = (payload) => ({
    type: types.RESEND_OTP_REQUEST,
    payload
});

const resendOTPSuccess = (payload) => ({
    type: types.RESEND_OTP_SUCCESS,
    payload
});

const resendOTPFailure = (payload) => ({
    type: types.RESEND_OTP_FAILURE,
    payload
});

export {
    // ? Login
    login,
    loginSuccess,
    loginFailure,
    resetLoginError,

    // ? Register
    register,
    registerSuccess,
    registerFailure,
    resetRegisterError,

    // ? Forgot password
    forgotPassword,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetForgotPasswordError,

    // ? Forgot password resend
    forgotPasswordResend,
    forgotPasswordResendSuccess,
    forgotPasswordResendFailure,

    // ? Forgot password verify code
    forgotPasswordVerifyCode,
    forgotPasswordVerifyCodeSuccess,
    forgotPasswordVerifyCodeFailure,
    resetForgotPasswordVerifyCodeError,

    // ? Reset password
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetResetPasswordError,
    resetAuthState,

    // ? Logout
    logout,
    logoutSuccess,
    logoutFailure,

    // ? Verify OTP
    verifyOTP,
    verifyOTPSuccess,
    verifyOTPFailure,
    resetVerifyOTPError,

    // ? Resent OTP
    resendOTP,
    resendOTPSuccess,
    resendOTPFailure
};
