import types from './actionTypes';

/**
 * TODO: Send test email
 */

const sendTestEmail = (payload) => ({
    type: types.SEND_TEST_EMAIL_REQUEST,
    payload
});

const sendTestEmailSuccess = (payload) => ({
    type: types.SEND_TEST_EMAIL_SUCCESS,
    payload
});

const sendTestEmailFailure = (payload) => ({
    type: types.SEND_TEST_EMAIL_FAILURE,
    payload
});

const sendTestEmailReset = () => ({
    type: types.SEND_TEST_EMAIL_RESET
});

/**
 * TODO: Get phishing email templates
 */

const getPhishingEmailTemplates = (payload) => ({
    type: types.GET_PHISHING_EMAIL_TEMPLATES_REQUEST,
    payload
});

const getPhishingEmailTemplatesSuccess = (payload) => ({
    type: types.GET_PHISHING_EMAIL_TEMPLATES_SUCCESS,
    payload
});

const getPhishingEmailTemplatesFailure = (payload) => ({
    type: types.GET_PHISHING_EMAIL_TEMPLATES_FAILURE,
    payload
});

/**
 * TODO: Get list phishing campaigns
 */

const getListPhishingCampaigns = (payload, permissions) => ({
    type: types.GET_LIST_PHISHING_CAMPAIGNS_REQUEST,
    payload,
    permissions
});

const getListPhishingCampaignsSuccess = (payload) => ({
    type: types.GET_LIST_PHISHING_CAMPAIGNS_SUCCESS,
    payload
});

const getListPhishingCampaignsFailure = (payload) => ({
    type: types.GET_LIST_PHISHING_CAMPAIGNS_FAILURE,
    payload
});

/**
 * TODO: Get phishing list notifications
 */

const getPhishingListNotifications = (payload) => ({
    type: types.GET_PHISHING_LIST_NOTIFICATIONS_REQUEST,
    payload
});

const getPhishingListNotificationsSuccess = (payload) => ({
    type: types.GET_PHISHING_LIST_NOTIFICATIONS_SUCCESS,
    payload
});

const getPhishingListNotificationsFailure = (payload) => ({
    type: types.GET_PHISHING_LIST_NOTIFICATIONS_FAILURE,
    payload
});

/**
 * TODO: Get list targets
 */

const getListTargets = (payload) => ({
    type: types.GET_LIST_TARGETS_REQUEST,
    payload
});

const getListTargetsSuccess = (payload) => ({
    type: types.GET_LIST_TARGETS_SUCCESS,
    payload
});

const getListTargetsFailure = (payload) => ({
    type: types.GET_LIST_TARGETS_FAILURE,
    payload
});

/**
 * TODO: Get scam email content
 */

const getScamEmailContent = (payload) => ({
    type: types.GET_SCAM_EMAIL_CONTENT_REQUEST,
    payload
});

const getScamEmailContentSuccess = (payload) => ({
    type: types.GET_SCAM_EMAIL_CONTENT_SUCCESS,
    payload
});

const getScamEmailContentFailure = (payload) => ({
    type: types.GET_SCAM_EMAIL_CONTENT_FAILURE,
    payload
});

/**
 * TODO: Get scam web content
 */

const getScamWebContent = (payload) => ({
    type: types.GET_SCAM_WEB_CONTENT_REQUEST,
    payload
});

const getScamWebContentSuccess = (payload) => ({
    type: types.GET_SCAM_WEB_CONTENT_SUCCESS,
    payload
});

const getScamWebContentFailure = (payload) => ({
    type: types.GET_SCAM_WEB_CONTENT_FAILURE,
    payload
});

const resetScamWebContent = (payload) => ({
    type: types.RESET_SCAM_WEB_CONTENT,
    payload
});

/**
 * TODO: Get view phishing notification
 */

const getViewPhishingNotification = (payload) => ({
    type: types.GET_VIEW_PHISHING_NOTIFICATION_REQUEST,
    payload
});

const getViewPhishingNotificationSuccess = (payload) => ({
    type: types.GET_VIEW_PHISHING_NOTIFICATION_SUCCESS,
    payload
});

const getViewPhishingNotificationFailure = (payload) => ({
    type: types.GET_VIEW_PHISHING_NOTIFICATION_FAILURE,
    payload
});

/**
 * TODO: Get phishing summary
 */

const getPhishingSummary = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_REQUEST,
    payload
});

const getPhishingSummarySuccess = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_SUCCESS,
    payload
});

const getPhishingSummaryFailure = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_FAILURE,
    payload
});

/**
 * TODO: Get phishing timeline result
 */

const getPhishingTimelineResult = (payload) => ({
    type: types.GET_PHISHING_TIMELINE_RESULT_REQUEST,
    payload
});

const getPhishingTimelineResultSuccess = (payload) => ({
    type: types.GET_PHISHING_TIMELINE_RESULT_SUCCESS,
    payload
});

const getPhishingTimelineResultFailure = (payload) => ({
    type: types.GET_PHISHING_TIMELINE_RESULT_FAILURE,
    payload
});

/**
 * TODO: Get phishing result candidate list
 */

const getPhishingResultCandidateList = (payload) => ({
    type: types.GET_PHISHING_RESULT_CANDIDATE_LIST_REQUEST,
    payload
});

const getPhishingResultCandidateListSuccess = (payload) => ({
    type: types.GET_PHISHING_RESULT_CANDIDATE_LIST_SUCCESS,
    payload
});

const getPhishingResultCandidateListFailure = (payload) => ({
    type: types.GET_PHISHING_RESULT_CANDIDATE_LIST_FAILURE,
    payload
});

/**
 * TODO: stop campaign
 */

const stopCampaign = (payload) => ({
    type: types.STOP_CAMPAIGN_REQUEST,
    payload
});

const stopCampaignSuccess = (payload) => ({
    type: types.STOP_CAMPAIGN_SUCCESS,
    payload
});

const stopCampaignFailure = (payload) => ({
    type: types.STOP_CAMPAIGN_FAILURE,
    payload
});

/**
 * TODO: Delete campaigns
 */

const deleteCampaigns = (payload) => ({
    type: types.DELETE_CAMPAIGNS_REQUEST,
    payload
});

const deleteCampaignsSuccess = (payload) => ({
    type: types.DELETE_CAMPAIGNS_SUCCESS,
    payload
});

const deleteCampaignsFailure = (payload) => ({
    type: types.DELETE_CAMPAIGNS_FAILURE,
    payload
});

/**
 * TODO: Create phishing campaign
 */

const createPhishingCampaign = (payload) => ({
    type: types.CREATE_PHISHING_CAMPAIGN_REQUEST,
    payload
});

const createPhishingCampaignSuccess = (payload) => ({
    type: types.CREATE_PHISHING_CAMPAIGN_SUCCESS,
    payload
});

const createPhishingCampaignFailure = (payload) => ({
    type: types.CREATE_PHISHING_CAMPAIGN_FAILURE,
    payload
});

const createPhishingCampaignReset = () => ({
    type: types.CREATE_PHISHING_CAMPAIGN_RESET
});

/**
 * TODO: Get list bookmarks
 */

const getListBookmarks = (payload) => ({
    type: types.GET_LIST_BOOKMARKS_REQUEST,
    payload
});

const getListBookmarksSuccess = (payload) => ({
    type: types.GET_LIST_BOOKMARKS_SUCCESS,
    payload
});

const getListBookmarksFailure = (payload) => ({
    type: types.GET_LIST_BOOKMARKS_FAILURE,
    payload
});

/**
 * TODO: Import phishing site
 */

const importPhishingSite = (payload) => ({
    type: types.IMPORT_PHISHING_SITE_REQUEST,
    payload
});

const importPhishingSiteSuccess = (payload) => ({
    type: types.IMPORT_PHISHING_SITE_SUCCESS,
    payload
});

const importPhishingSiteFailure = (payload) => ({
    type: types.IMPORT_PHISHING_SITE_FAILURE,
    payload
});

const importPhishingSiteReset = () => ({
    type: types.IMPORT_PHISHING_SITE_RESET
});

/**
 * TODO: Get list sms brands
 */

const getListSmsBrands = (payload) => ({
    type: types.GET_LIST_SMS_BRANDS_REQUEST,
    payload
});

const getListSmsBrandsSuccess = (payload) => ({
    type: types.GET_LIST_SMS_BRANDS_SUCCESS,
    payload
});

const getListSmsBrandsFailure = (payload) => ({
    type: types.GET_LIST_SMS_BRANDS_FAILURE,
    payload
});

/**
 * TODO: Send test sms
 */

const sendTestSms = (payload) => ({
    type: types.SEND_TEST_SMS_REQUEST,
    payload
});

const sendTestSmsSuccess = (payload) => ({
    type: types.SEND_TEST_SMS_SUCCESS,
    payload
});

const sendTestSmsFailure = (payload) => ({
    type: types.SEND_TEST_SMS_FAILURE,
    payload
});

const sendTestSmsReset = () => ({
    type: types.SEND_TEST_SMS_RESET
});

/**
 * TODO: Get list sms templates
 */

const getListSmsTemplates = (payload) => ({
    type: types.GET_LIST_SMS_TEMPLATES_REQUEST,
    payload
});

const getListSmsTemplatesSuccess = (payload) => ({
    type: types.GET_LIST_SMS_TEMPLATES_SUCCESS,
    payload
});

const getListSmsTemplatesFailure = (payload) => ({
    type: types.GET_LIST_SMS_TEMPLATES_FAILURE,
    payload
});

/**
 * TODO: Create sms campaign
 */

const createSmsCampaign = (payload) => ({
    type: types.CREATE_SMS_CAMPAIGN_REQUEST,
    payload
});

const createSmsCampaignSuccess = (payload) => ({
    type: types.CREATE_SMS_CAMPAIGN_SUCCESS,
    payload
});

const createSmsCampaignFailure = (payload) => ({
    type: types.CREATE_SMS_CAMPAIGN_FAILURE,
    payload
});

const createSmsCampaignReset = () => ({
    type: types.CREATE_SMS_CAMPAIGN_RESET
});

/**
 * TODO: Preview site
 */

const previewSite = (payload) => ({
    type: types.PREVIEW_SITE_REQUEST,
    payload
});

const previewSiteSuccess = (payload) => ({
    type: types.PREVIEW_SITE_SUCCESS,
    payload
});

const previewSiteFailure = (payload) => ({
    type: types.PREVIEW_SITE_FAILURE,
    payload
});

const previewSiteReset = () => ({
    type: types.PREVIEW_SITE_RESET
});

/**
 * TODO: Get list category
 */

const getListCategory = (payload) => ({
    type: types.GET_LIST_CATEGORY_REQUEST,
    payload
});

const getListCategorySuccess = (payload) => ({
    type: types.GET_LIST_CATEGORY_SUCCESS,
    payload
});

const getListCategoryFailure = (payload) => ({
    type: types.GET_LIST_CATEGORY_FAILURE,
    payload
});

/**
 * TODO: Get clone phishing details
 */

const getClonePhishingDetails = (payload) => ({
    type: types.GET_CLONE_PHISHING_DETAILS_REQUEST,
    payload
});

const getClonePhishingDetailsSuccess = (payload) => ({
    type: types.GET_CLONE_PHISHING_DETAILS_SUCCESS,
    payload
});

const getClonePhishingDetailsFailure = (payload) => ({
    type: types.GET_CLONE_PHISHING_DETAILS_FAILURE,
    payload
});

/**
 * TODO: Send test ott
 */

const sendTestOtt = (payload) => ({
    type: types.SEND_TEST_OTT_REQUEST,
    payload
});

const sendTestOttSuccess = (payload) => ({
    type: types.SEND_TEST_OTT_SUCCESS,
    payload
});

const sendTestOttFailure = (payload) => ({
    type: types.SEND_TEST_OTT_FAILURE,
    payload
});

const sendTestOttReset = () => ({
    type: types.SEND_TEST_OTT_RESET
});

/**
 * TODO: Create ott campaign
 */

const createOttCampaign = (payload) => ({
    type: types.CREATE_OTT_CAMPAIGN_REQUEST,
    payload
});

const createOttCampaignSuccess = (payload) => ({
    type: types.CREATE_OTT_CAMPAIGN_SUCCESS,
    payload
});

const createOttCampaignFailure = (payload) => ({
    type: types.CREATE_OTT_CAMPAIGN_FAILURE,
    payload
});

const createOttCampaignReset = () => ({
    type: types.CREATE_OTT_CAMPAIGN_RESET
});

/**
 * TODO: Get templates ott phishing
 */

const getTemplatesOttPhishing = (payload) => ({
    type: types.GET_TEMPLATES_OTT_PHISHING_REQUEST,
    payload
});

const getTemplatesOttPhishingSuccess = (payload) => ({
    type: types.GET_TEMPLATES_OTT_PHISHING_SUCCESS,
    payload
});

const getTemplatesOttPhishingFailure = (payload) => ({
    type: types.GET_TEMPLATES_OTT_PHISHING_FAILURE,
    payload
});

/**
 * TODO: Get phishing summary departments
 */

const getPhishingSummaryDepartments = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_DEPARTMENTS_REQUEST,
    payload
});

const getPhishingSummaryDepartmentsSuccess = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_DEPARTMENTS_SUCCESS,
    payload
});

const getPhishingSummaryDepartmentsFailure = (payload) => ({
    type: types.GET_PHISHING_SUMMARY_DEPARTMENTS_FAILURE,
    payload
});

export {
    // ? Send test email
    sendTestEmail,
    sendTestEmailSuccess,
    sendTestEmailFailure,
    sendTestEmailReset,

    // ? Get phishing email templates
    getPhishingEmailTemplates,
    getPhishingEmailTemplatesSuccess,
    getPhishingEmailTemplatesFailure,

    // * Get list phishing campaigns
    getListPhishingCampaigns,
    getListPhishingCampaignsSuccess,
    getListPhishingCampaignsFailure,

    // ? Get phishing list notifications
    getPhishingListNotifications,
    getPhishingListNotificationsSuccess,
    getPhishingListNotificationsFailure,

    // * Get list targets
    getListTargets,
    getListTargetsSuccess,
    getListTargetsFailure,

    // * Get scam email content
    getScamEmailContent,
    getScamEmailContentSuccess,
    getScamEmailContentFailure,

    // * Get scam web content
    getScamWebContent,
    getScamWebContentSuccess,
    getScamWebContentFailure,
    resetScamWebContent,

    // * Get view phishing notification
    getViewPhishingNotification,
    getViewPhishingNotificationSuccess,
    getViewPhishingNotificationFailure,

    // * Get phishing summary
    getPhishingSummary,
    getPhishingSummarySuccess,
    getPhishingSummaryFailure,

    // * Get phishing timeline result
    getPhishingTimelineResult,
    getPhishingTimelineResultSuccess,
    getPhishingTimelineResultFailure,

    // * Get phishing result candidate list
    getPhishingResultCandidateList,
    getPhishingResultCandidateListSuccess,
    getPhishingResultCandidateListFailure,

    // * stop campaign
    stopCampaign,
    stopCampaignSuccess,
    stopCampaignFailure,

    // * delete campaigns
    deleteCampaigns,
    deleteCampaignsSuccess,
    deleteCampaignsFailure,

    // * Create phishing campaign
    createPhishingCampaign,
    createPhishingCampaignSuccess,
    createPhishingCampaignFailure,
    createPhishingCampaignReset,

    // * Get list bookmarks
    getListBookmarks,
    getListBookmarksSuccess,
    getListBookmarksFailure,

    // * Import phishing site
    importPhishingSite,
    importPhishingSiteSuccess,
    importPhishingSiteFailure,
    importPhishingSiteReset,

    // * Get list sms brands
    getListSmsBrands,
    getListSmsBrandsSuccess,
    getListSmsBrandsFailure,

    // * Send test sms
    sendTestSms,
    sendTestSmsSuccess,
    sendTestSmsFailure,
    sendTestSmsReset,

    // * Get list sms templates
    getListSmsTemplates,
    getListSmsTemplatesSuccess,
    getListSmsTemplatesFailure,

    // * Create sms campaign
    createSmsCampaign,
    createSmsCampaignSuccess,
    createSmsCampaignFailure,
    createSmsCampaignReset,

    // * Preview site
    previewSite,
    previewSiteSuccess,
    previewSiteFailure,
    previewSiteReset,

    // * Get list category
    getListCategory,
    getListCategorySuccess,
    getListCategoryFailure,

    // * Get clone phishing details
    getClonePhishingDetails,
    getClonePhishingDetailsSuccess,
    getClonePhishingDetailsFailure,

    // * Send test ott
    sendTestOtt,
    sendTestOttSuccess,
    sendTestOttFailure,
    sendTestOttReset,

    // * Create ott campaign
    createOttCampaign,
    createOttCampaignSuccess,
    createOttCampaignFailure,
    createOttCampaignReset,

    // * Get templates ott phishing
    getTemplatesOttPhishing,
    getTemplatesOttPhishingSuccess,
    getTemplatesOttPhishingFailure,

    // * Get phishing summary departments
    getPhishingSummaryDepartments,
    getPhishingSummaryDepartmentsSuccess,
    getPhishingSummaryDepartmentsFailure
};
