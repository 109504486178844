/**
 * TODO: Get phishing summary
 */

const getStatisticsSummaryPhishingReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsPhishingSummaryData: payload
    };
};

/**
 * TODO: Get phishing rating by months
 */

const getStatisticsRatingByMonthsPhishingReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsPhishingRatingByMonthsData: payload
    };
};

/**
 * TODO: Get phishing result
 */

const getStatisticsResultPhishingReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsPhishingResultData: payload
    };
};

/**
 * TODO: Get top phished by department
 */

const getStatisticsTopPhishedByDepartmentReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsTopPhishedByDepartmentData: payload
    };
};

/**
 * TODO: Get top clicked phishing template
 */

const getStatisticsTopClickedPhishingTemplateReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsTopClickedPhishingTemplateData: payload
    };
};

/**
 * TODO: Get phishing summary total
 */

const getStatisticsSummaryTotalPhishingReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsPhishingSummaryTotalData: payload
    };
};

/**
 * TODO: Get details phishing result
 */

const getStatisticsDetailsPhishingResultReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsDetailsPhishingResultData: payload
    };
};

/**
 * TODO: Get list targets unphished
 */

const getStatisticsListTargetsUnphishedReducerHandler = (state, payload) => {
    return {
        ...state,
        statisticsListTargetsUnphishedData: payload
    };
};

export default {
    getStatisticsSummaryPhishingReducerHandler,
    getStatisticsRatingByMonthsPhishingReducerHandler,
    getStatisticsResultPhishingReducerHandler,
    getStatisticsTopPhishedByDepartmentReducerHandler,
    getStatisticsTopClickedPhishingTemplateReducerHandler,
    getStatisticsSummaryTotalPhishingReducerHandler,
    getStatisticsDetailsPhishingResultReducerHandler,
    getStatisticsListTargetsUnphishedReducerHandler
};
