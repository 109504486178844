const userActionTypes = {
    // ? Get user profile
    GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

    // ? Update user profile
    UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
    UPDATE_USER_PROFILE_RESET: 'UPDATE_USER_PROFILE_RESET',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',

    ENABLE_2FA_REQUEST: 'ENABLE_2FA_REQUEST',
    ENABLE_2FA_SUCCESS: 'ENABLE_2FA_SUCCESS',
    ENABLE_2FA_FAILURE: 'ENABLE_2FA_FAILURE',
    ENABLE_2FA_RESET: 'ENABLE_2FA_RESET',

    DISABLE_2FA_REQUEST: 'DISABLE_2FA_REQUEST',
    DISABLE_2FA_SUCCESS: 'DISABLE_2FA_SUCCESS',
    DISABLE_2FA_FAILURE: 'DISABLE_2FA_FAILURE',
    DISABLE_2FA_RESET: 'DISABLE_2FA_RESET',

    // ? Get user token
    GET_USER_TOKEN_REQUEST: 'GET_USER_TOKEN_REQUEST',
    GET_USER_TOKEN_SUCCESS: 'GET_USER_TOKEN_SUCCESS',
    GET_USER_TOKEN_FAILURE: 'GET_USER_TOKEN_FAILURE',

    // ? Get credit summary
    GET_CREDIT_SUMMARY_REQUEST: 'GET_CREDIT_SUMMARY_REQUEST',
    GET_CREDIT_SUMMARY_SUCCESS: 'GET_CREDIT_SUMMARY_SUCCESS',
    GET_CREDIT_SUMMARY_FAILURE: 'GET_CREDIT_SUMMARY_FAILURE',

    // ? Get credit history
    GET_CREDIT_HISTORY_REQUEST: 'GET_CREDIT_HISTORY_REQUEST',
    GET_CREDIT_HISTORY_SUCCESS: 'GET_CREDIT_HISTORY_SUCCESS',
    GET_CREDIT_HISTORY_FAILURE: 'GET_CREDIT_HISTORY_FAILURE'
};

export default userActionTypes;
