import AssessmentServices from 'api/assessment';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';
import {
    // ! Get list questions
    getListQuestionsSuccess,
    getListQuestionsFailure,
    // ! Get list templates
    getListTemplatesSuccess,
    getListTemplatesFailure,
    // ! Create assessment
    createAssessmentSuccess,
    createAssessmentFailure,
    // ! Edit assessment
    editAssessmentSuccess,
    editAssessmentFailure,
    // ! Get assessment details
    getAssessmentDetailsSuccess,
    getAssessmentDetailsFailure,
    // ! Get assessment clone details
    getAssessmentCloneDetailsSuccess,
    getAssessmentCloneDetailsFailure,
    // ! Get list questions by template ids
    getListQuestionsByTemplateIdsSuccess,
    getListQuestionsByTemplateIdsFailure,
    // ! Get list exam tags
    getListExamTagsSuccess,
    getListExamTagsFailure,
    getListAssessmentsSuccess,
    getListAssessmentsFailure,
    getListCandidatesByAssessmentSuccess,
    getListCandidatesByAssessmentFailure,
    getListQuestionsByAssessmentSuccess,
    getListQuestionsByAssessmentFailure,
    getAssessmentSummaryParticipantsSuccess,
    getAssessmentSummaryParticipantsFailure,
    getAssessmentSummaryStatisticSuccess,
    getAssessmentSummaryQuestionListSuccess,
    getAssessmentSummaryQuestionListFailure,
    getAssessmentSummaryCandidateListSuccess,
    getAssessmentSummaryCandidateListFailure,
    getAssessmentCandidateDetailSuccess,
    getAssessmentCandidateDetailFailure,
    getAssessmentCandidateResultSuccess,
    getAssessmentCandidateResultFailure,
    getAssessmentDetailQuestionResultSuccess,
    getAssessmentDetailQuestionResultFailure,
    startAssessmentSuccess,
    startAssessmentFailure,
    stopAssessmentSuccess,
    stopAssessmentFailure,
    rebuildAssessmentSuccess,
    rebuildAssessmentFailure,
    deleteAssessmentsSuccess,
    deleteAssessmentsFailure,
    getLanguageSupportedSuccess,
    getLanguageSupportedFailure,

    // ! Get assessment summary departments
    getAssessmentSummaryDepartmentsSuccess,
    getAssessmentSummaryDepartmentsFailure
} from './actions';

import { transformAssessmentsList, transformAssessmentDetails } from 'transformer/assessment';

/**
 * TODO: Get list assessments request handler
 */

function* getListAssessmentsRequestHandler({ payload, permissions }) {
    try {
        const response = yield call(AssessmentServices.getListAssessment, payload);

        const transformedListAssessmentData = transformAssessmentsList(
            response?.data?.assessments,
            permissions
        );

        yield put(
            getListAssessmentsSuccess({
                ...response.data,
                assessments: transformedListAssessmentData
            })
        );
    } catch (errors) {
        yield put(getListAssessmentsFailure(errors));
    }
}

/**
 * TODO: Get list questions request handler
 */

function* getListQuestionsRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getListQuestions, payload);
        yield put(getListQuestionsSuccess(response.data));
    } catch (errors) {
        yield put(getListQuestionsFailure(errors));
    }
}

/**
 * TODO: Get list templates request handler
 */

function* getListTemplatesRequestHandler({ payload }) {
    try {
        const requestBody = {
            ...payload,
            locales: payload.locales.length > 0 ? payload.locales : ['en', 'vi']
        };
        const response = yield call(AssessmentServices.getListTemplates, requestBody);
        yield put(getListTemplatesSuccess(response.data));
    } catch (errors) {
        yield put(getListTemplatesFailure(errors));
    }
}

/**
 * TODO: Create assessment request handler
 */

function* createAssessmentRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.createAssessment, payload);
        yield put(createAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(createAssessmentFailure(errors));
    }
}

/**
 * TODO: Edit assessment request handler
 */

function* editAssessmentRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.editAssessment, payload);
        yield put(editAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(editAssessmentFailure(errors));
    }
}

/**
 * TODO: Get assessment request handler
 */

function* getAssessmentDetailsRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentDetails, payload);
        yield put(getAssessmentDetailsSuccess(transformAssessmentDetails(response.data)));
    } catch (errors) {
        yield put(getAssessmentDetailsFailure(errors));
    }
}

/**
 * TODO: Get assessment clone details request handler
 */

function* getAssessmentCloneDetailsRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentCloneDetails, payload);
        yield put(getAssessmentCloneDetailsSuccess(transformAssessmentDetails(response.data)));
    } catch (errors) {
        yield put(getAssessmentCloneDetailsFailure(errors));
    }
}

/**
 * TODO: Get list questions by template ids request handler
 */

function* getListQuestionsByTemplateIdsRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getListQuestionsByTemplateIds, payload);
        yield put(getListQuestionsByTemplateIdsSuccess(response.data));
    } catch (errors) {
        yield put(getListQuestionsByTemplateIdsFailure(errors));
    }
}

/**
 * TODO: Get list exam tags request handler
 */

function* getListExamTagsRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getListExamTags, payload);
        yield put(getListExamTagsSuccess(response.data));
    } catch (errors) {
        yield put(getListExamTagsFailure(errors));
    }
}

/**
 * TODO: Get list candidates by assessment request handler
 */

function* getListCandidatesByAssessmentRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getListCandidateByAssessment, payload);
        yield put(getListCandidatesByAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(getListCandidatesByAssessmentFailure(errors));
    }
}

/**
 * TODO: Get list questions by assessment request handler
 */

function* getListQuestionsByAssessmentRequestHandler({ payload }) {
    try {
        const response = yield call(AssessmentServices.getListQuestionByAssessment, payload);
        yield put(getListQuestionsByAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(getListQuestionsByAssessmentFailure(errors));
    }
}

/**
 * TODO: Get assessment summary participant request handler
 */

function* getAssessmentSummaryParticipants({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentSummaryParticipants, payload);
        yield put(getAssessmentSummaryParticipantsSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentSummaryParticipantsFailure(errors));
    }
}

/**
 * TODO: Get assessment summary statistic request handler
 */

function* getAssessmentSummaryStatistic({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentSummaryStatistic, payload);
        yield put(getAssessmentSummaryStatisticSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentSummaryParticipantsFailure(errors));
    }
}

/**
 * TODO: Get assessment summary question list handler
 */

function* getAssessmentSummaryQuestionList({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentSummaryQuestions, payload);
        yield put(getAssessmentSummaryQuestionListSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentSummaryQuestionListFailure(errors));
    }
}

/**
 * TODO: Get assessment summary candidate list handler
 */

function* getAssessmentSummaryCandidateList({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentSummaryCandidates, payload);
        yield put(getAssessmentSummaryCandidateListSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentSummaryCandidateListFailure(errors));
    }
}

/**
 * TODO: Get assessment candidate detail result handler
 */

function* getAssessmentCandidateDetail({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentCandidateDetail, payload);
        yield put(getAssessmentCandidateDetailSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentCandidateDetailFailure(errors));
    }
}

/**
 * TODO: Get assessment candidate result handler
 */

function* getAssessmentCandidateResult({ payload }) {
    try {
        const response = yield call(
            AssessmentServices.getAssessmentIndividualCandidateResult,
            payload
        );
        yield put(getAssessmentCandidateResultSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentCandidateResultFailure(errors));
    }
}

/**
 * TODO: Get assessment detail question result handler
 */

function* getAssessmentDetailQuestionResult({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentDetailQuestionResult, payload);
        yield put(getAssessmentDetailQuestionResultSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentDetailQuestionResultFailure(errors));
    }
}

/**
 * TODO: Start assessment
 */

function* startAssessment({ payload }) {
    try {
        const response = yield call(AssessmentServices.putStartAssessment, payload);
        yield put(startAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(startAssessmentFailure(errors));
    }
}

/**
 * TODO: Stop assessment
 */

function* stopAssessment({ payload }) {
    try {
        const response = yield call(AssessmentServices.putStopAssessment, payload);
        yield put(stopAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(stopAssessmentFailure(errors));
    }
}

/**
 * TODO: Rebuild assessment
 */

function* rebuildAssessment({ payload }) {
    try {
        const response = yield call(AssessmentServices.putRebuildAssessment, payload);
        yield put(rebuildAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(rebuildAssessmentFailure(errors));
    }
}

/**
 * TODO: Delete assessments
 */

function* deleteAssessments({ payload }) {
    try {
        const response = yield call(AssessmentServices.deleteAssessments, payload);
        yield put(deleteAssessmentsSuccess(response));
    } catch (errors) {
        yield put(deleteAssessmentsFailure(errors));
    }
}

/**
 * TODO: Get language supported
 */

function* getLanguageSupported() {
    try {
        const response = yield call(AssessmentServices.getLanguageSupported);
        yield put(getLanguageSupportedSuccess(response.data));
    } catch (errors) {
        yield put(getLanguageSupportedFailure(errors));
    }
}

/**
 * TODO: Get assessment summary departments
 */

function* getAssessmentSummaryDepartments({ payload }) {
    try {
        const response = yield call(AssessmentServices.getAssessmentSummaryDepartments, payload);
        yield put(getAssessmentSummaryDepartmentsSuccess(response.data));
    } catch (errors) {
        yield put(getAssessmentSummaryDepartmentsFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

function* watchAssessmentSaga() {
    yield takeLatest(types.GET_LIST_ASSESSMENTS_REQUEST, getListAssessmentsRequestHandler);
    yield takeLatest(types.GET_LIST_QUESTIONS_REQUEST, getListQuestionsRequestHandler);
    yield takeLatest(types.GET_LIST_TEMPLATES_REQUEST, getListTemplatesRequestHandler);
    yield takeLatest(types.CREATE_ASSESSMENT_REQUEST, createAssessmentRequestHandler);
    yield takeLatest(types.EDIT_ASSESSMENT_REQUEST, editAssessmentRequestHandler);
    yield takeLatest(types.GET_ASSESSMENT_DETAILS_REQUEST, getAssessmentDetailsRequestHandler);
    yield takeLatest(
        types.GET_ASSESSMENT_CLONE_DETAILS_REQUEST,
        getAssessmentCloneDetailsRequestHandler
    );
    yield takeLatest(
        types.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_REQUEST,
        getListQuestionsByTemplateIdsRequestHandler
    );
    yield takeLatest(types.GET_LIST_EXAM_TAGS_REQUEST, getListExamTagsRequestHandler);
    yield takeLatest(
        types.GET_LIST_CANDIDATES_BY_ASSESSMENT_REQUEST,
        getListCandidatesByAssessmentRequestHandler
    );
    yield takeLatest(
        types.GET_LIST_QUESTIONS_BY_ASSESSMENT_REQUEST,
        getListQuestionsByAssessmentRequestHandler
    );
    yield takeLatest(
        types.GET_ASSESSMENT_SUMMARY_PARTICIPANTS_REQUEST,
        getAssessmentSummaryParticipants
    );
    yield takeLatest(types.GET_ASSESSMENT_SUMMARY_STATISTIC_REQUEST, getAssessmentSummaryStatistic);
    yield takeLatest(
        types.GET_ASSESSMENT_SUMMARY_QUESTION_LIST_REQUEST,
        getAssessmentSummaryQuestionList
    );
    yield takeLatest(
        types.GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_REQUEST,
        getAssessmentSummaryCandidateList
    );
    yield takeLatest(types.GET_ASSESSMENT_CANDIDATE_DETAIL_REQUEST, getAssessmentCandidateDetail);
    yield takeLatest(types.GET_ASSESSMENT_CANDIDATE_RESULT_REQUEST, getAssessmentCandidateResult);
    yield takeLatest(
        types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_REQUEST,
        getAssessmentDetailQuestionResult
    );
    yield takeLatest(types.START_ASSESSMENT_REQUEST, startAssessment);
    yield takeLatest(types.STOP_ASSESSMENT_REQUEST, stopAssessment);
    yield takeLatest(types.REBUILD_ASSESSMENT_REQUEST, rebuildAssessment);
    yield takeLatest(types.DELETE_ASSESSMENTS_REQUEST, deleteAssessments);
    yield takeLatest(types.GET_LANGUAGE_SUPPORTED_REQUEST, getLanguageSupported);
    yield takeLatest(
        types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_REQUEST,
        getAssessmentSummaryDepartments
    );
}

export default function* assessmentSaga() {
    yield fork(watchAssessmentSaga);
}
