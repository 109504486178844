import objectUtils from 'utils/object-utils';

export const errorReducer = (state = {}, action) => {
    const { type, payload } = action;

    const matches = /(.*)_(RESET|FAILURE)/.exec(type);

    // not a *_REQUEST / *_FAILURE actions, so we ignore them
    if (!matches) return state;
    const [, requestName, requestState] = matches;

    if ((requestState === 'FAILURE' && objectUtils.isEmptyObject(payload)) || payload === 'RESET') {
        return {};
    }

    return {
        ...state,
        // Store errorMessage
        // e.g. stores errorMessage when receiving SIGN_IN_FAILURE
        //      else clear errorMessage when receiving SIGN_IN_REQUEST
        [`${requestName}`]: requestState === 'FAILURE' ? payload : {}
    };
};
