import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ? Get dashboard assessment
        case types.GET_DASHBOARD_ASSESSMENT_SUCCESS:
            return reducerHandler.getDashboardAssessmentReducerHandler(state, action.payload);
        // ? Get dashboard training
        case types.GET_DASHBOARD_TRAINING_SUCCESS:
            return reducerHandler.getDashboardTrainingReducerHandler(state, action.payload);
        // ? Get dashboard phishing
        case types.GET_DASHBOARD_PHISHING_SUCCESS:
            return reducerHandler.getDashboardPhishingHandler(state, action.payload);
        // ? Get dashboard last activity
        case types.GET_DASHBOARD_LAST_ACTIVITY_SUCCESS:
            return reducerHandler.getDashboardLastActivityHandler(state, action.payload);
        // ? Get dashboard credit participant
        case types.GET_DASHBOARD_CREDIT_PARTICIPANT_SUCCESS:
            return reducerHandler.getDashboardCreditParticipantHandler(state, action.payload);
        default:
            return state;
    }
};

export default reducer;
