import objectUtils from 'utils/object-utils';

export const loadingReducer = (state = {}, action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
    // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
    if (!matches) return state;
    const [, requestName, requestState] = matches;

    // remove flag if action dispatch success
    if (requestState === 'SUCCESS' || requestState === 'FAILURE') {
        const loadingState = { ...state };
        const omittedLoadingState = objectUtils.omit(loadingState, [requestName]);
        return omittedLoadingState;
    }

    return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving SIGN_IN_REQUEST
        //      and false when receiving  SIGN_IN_SUCCESS / SIGN_IN_FAILURE
        [requestName]: requestState === 'REQUEST'
    };
};
