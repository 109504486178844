import types from './actionTypes';

// * Get dashboard assessment
const getDashboardAssessment = (payload) => ({
    type: types.GET_DASHBOARD_ASSESSMENT_REQUEST,
    payload
});

const getDashboardAssessmentSuccess = (payload) => ({
    type: types.GET_DASHBOARD_ASSESSMENT_SUCCESS,
    payload
});

const getDashboardAssessmentFailure = (payload) => ({
    type: types.GET_DASHBOARD_ASSESSMENT_FAILURE,
    payload
});

// * Get dashboard training
const getDashboardTraining = (payload) => ({
    type: types.GET_DASHBOARD_TRAINING_REQUEST,
    payload
});

const getDashboardTrainingSuccess = (payload) => ({
    type: types.GET_DASHBOARD_TRAINING_SUCCESS,
    payload
});

const getDashboardTrainingFailure = (payload) => ({
    type: types.GET_DASHBOARD_TRAINING_FAILURE,
    payload
});

// * Get dashboard phishing
const getDashboardPhishing = (payload) => ({
    type: types.GET_DASHBOARD_PHISHING_REQUEST,
    payload
});

const getDashboardPhishingSuccess = (payload) => ({
    type: types.GET_DASHBOARD_PHISHING_SUCCESS,
    payload
});

const getDashboardPhishingFailure = (payload) => ({
    type: types.GET_DASHBOARD_PHISHING_FAILURE,
    payload
});

// * Get dashboard last activity
const getDashboardLastActivity = (payload) => ({
    type: types.GET_DASHBOARD_LAST_ACTIVITY_REQUEST,
    payload
});

const getDashboardLastActivitySuccess = (payload) => ({
    type: types.GET_DASHBOARD_LAST_ACTIVITY_SUCCESS,
    payload
});

const getDashboardLastActivityFailure = (payload) => ({
    type: types.GET_DASHBOARD_LAST_ACTIVITY_FAILURE,
    payload
});

// * Get dashboard credit & participant
const getDashboardCreditParticipant = (payload) => ({
    type: types.GET_DASHBOARD_CREDIT_PARTICIPANT_REQUEST,
    payload
});

const getDashboardCreditParticipantSuccess = (payload) => ({
    type: types.GET_DASHBOARD_CREDIT_PARTICIPANT_SUCCESS,
    payload
});

const getDashboardCreditParticipantFailure = (payload) => ({
    type: types.GET_DASHBOARD_CREDIT_PARTICIPANT_FAILURE,
    payload
});

export {
    // ? Dashboard Assessment
    getDashboardAssessment,
    getDashboardAssessmentSuccess,
    getDashboardAssessmentFailure,
    // ? Dashboard Training
    getDashboardTraining,
    getDashboardTrainingSuccess,
    getDashboardTrainingFailure,
    // ? Dashboard Phishing
    getDashboardPhishing,
    getDashboardPhishingSuccess,
    getDashboardPhishingFailure,
    // ? Dashboard Last Activity
    getDashboardLastActivity,
    getDashboardLastActivitySuccess,
    getDashboardLastActivityFailure,
    // ? Dashboard Last Activity
    getDashboardCreditParticipant,
    getDashboardCreditParticipantSuccess,
    getDashboardCreditParticipantFailure
};
