import objectUtils from 'utils/object-utils';
import dateUtils from 'utils/date-utils';

/**
 * TODO: Transform summary phishing
 */

const transformStatisticsSummaryPhishingData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;
    const { totalReady, totalRunning, totalClosed, totalCampaigns } = data;

    const totalStatus = totalReady + totalRunning + totalClosed;

    let percentReady = 0;
    let percentRunning = 0;
    let percentClosed = 0;

    if (totalStatus !== 0) {
        percentReady = (totalReady / totalStatus) * 100;
        percentRunning = (totalRunning / totalStatus) * 100;
        percentClosed = 100 - (percentReady + percentRunning);
    }

    let details = [
        {
            id: 'queued',
            value: totalReady,
            percentageCalculated: percentReady,
            color: '#E0F7FA'
        },
        {
            id: 'running',
            value: totalRunning,
            percentageCalculated: percentRunning,
            color: '#97D79A'
        },
        {
            id: 'closed',
            value: totalClosed,
            percentageCalculated: percentClosed,
            color: '#CDD5DF'
        }
    ];

    // ? Check if all percentageCalculated in details is 0
    let isAllPercentageCalculatedZero = true;

    details.forEach((item) => {
        if (item?.percentageCalculated !== 0) {
            isAllPercentageCalculatedZero = false;
        }
    });

    if (isAllPercentageCalculatedZero) {
        details.push({
            id: '',
            color: '#CDD5DF',
            value: 1,
            percentageCalculated: 100
        });
    }

    return {
        totalCampaigns,
        details
    };
};

/**
 * TODO: Transform phishing rating by months
 */

const transformStatisticsPhishingRatingByMonthsData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    const { phishingRating } = data;

    let details = [];
    let lineChartsData = [];

    phishingRating.forEach((item) => {
        const singleDetailsItem = [
            {
                id: 'sent',
                color: '#A2E1F0',
                value: item?.detail?.totalSent || 0,
                percentageCalculated: item?.details?.totalSent > 0 ? 100 - item?.detail?.rating : 0
            },
            {
                id: 'clicked',
                color: '#00758B',
                value: item?.detail?.totalClicked || 0,
                percentageCalculated: item?.detail?.rating || 0
            }
        ];

        details.push(singleDetailsItem);

        const singleLineChartsDataItem = {
            month: dateUtils.formatDateTimeLineChart(item?.month, true),
            rating: item?.detail?.rating || 0
        };

        lineChartsData.push(singleLineChartsDataItem);
    });

    return {
        lineChartsData,
        details
    };
};

/**
 * TODO: Transform phishing result data
 */

// ? Format data for phishing result
const formatDataForPhishingResult = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    let details = [
        {
            id: 'submitted',
            color: '#003848',
            realValue: data?.totalSubmitted || 0,
            value: data?.totalSubmitted || 0,
            percentageCalculated: Math.round((data?.totalSubmitted / data?.totalSent) * 100) || 0,
            order: 3
        },
        {
            id: 'clicked',
            color: '#00758B',
            realValue: data?.totalClicked || 0,
            value: data?.totalClicked - data?.totalSubmitted || 0,
            percentageCalculated:
                Math.round(((data?.totalClicked - data?.totalSubmitted) / data?.totalSent) * 100) ||
                0,
            order: 1
        },
        {
            id: 'opened',
            color: '#00ACC1',
            realValue: data?.totalOpened || 0,
            value: data?.totalOpened - data?.totalClicked || 0,
            percentageCalculated:
                Math.round(((data?.totalOpened - data?.totalClicked) / data?.totalSent) * 100) || 0,
            order: 2
        },
        {
            id: 'sent',
            color: '#A2E1F0',
            realValue: data?.totalSent || 0,
            value: data?.totalSent - data?.totalOpened || 0,
            percentageCalculated:
                Math.round(((data?.totalSent - data?.totalOpened) / data?.totalSent) * 100) || 0,
            order: 0
        }
    ];

    // ? Check if all percentageCalculated in details is 0
    let isAllPercentageCalculatedZero = true;
    details.forEach((item) => {
        if (item?.percentageCalculated !== 0) {
            isAllPercentageCalculatedZero = false;
        }
    });

    if (isAllPercentageCalculatedZero) {
        details.push({
            id: '',
            color: '#CDD5DF',
            value: 1,
            percentageCalculated: 100
        });
    }
    return {
        totalCampaigns: data?.totalCampaigns || 0,
        totalTarget: data?.totalTarget || 0,
        details
    };
};

// ? Transform phishing result data
const transformStatisticsPhishingResultData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    const { email, sms, ott } = data;

    // ? Format email data
    const emailData = formatDataForPhishingResult(email);

    // ? Format sms data
    const smsData = formatDataForPhishingResult(sms);

    // ? Format telegram data
    const telegramData = formatDataForPhishingResult(ott?.telegram);

    // ? Format whatsapp data
    const whatsappData = formatDataForPhishingResult(ott?.whatsapp);

    return {
        emailData,
        smsData,
        telegramData,
        whatsappData
    };
};

/**
 * TODO: Transform top phished by department data
 */

const transformStatisticsTopPhishedByDepartmentData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    const { departments, reportKey } = data;

    // ! Data chart
    const details = departments.map((departmentData) => {
        let departmentDataPhishing = departmentData?.phishing;
        return {
            departmentName: departmentData?.title,
            totalClicked: departmentDataPhishing?.totalClicked,
            totalSent: departmentDataPhishing?.totalSent - departmentDataPhishing?.totalClicked,
            template: departmentDataPhishing?.template
        };
    });

    // ! Colors
    const colors = ['#00758B', '#A2E1F0'];

    // ! Data keys
    const dataKeys = ['totalClicked', 'totalSent'];

    return {
        details,
        reportKey,
        colors,
        dataKeys
    };
};

/**
 * TODO: Transform top clicked phishing template data
 */

const transformStatisticsTopClickedPhishingTemplateData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    const { templates, reportKey } = data;

    // ! Data chart
    const details = templates.map((templateData) => {
        return {
            id: templateData?.id,
            templateName: templateData?.subject,
            totalClicked: templateData?.totalClicked
        };
    });

    // ! Colors
    const colors = ['#00758B'];

    // ! Data keys
    const dataKeys = ['totalClicked'];

    return {
        details,
        reportKey,
        colors,
        dataKeys
    };
};

/**
 * TODO: Transform details phishing template data
 */

const transformStatisticsDetailsPhishingTemplateData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    let scenarios = data?.scenarios || [];
    const meta = data?.meta || {};

    scenarios = scenarios.map((scenario, index) => {
        let topList = 0;

        if (meta?.sortDirection === 'asc') {
            topList = meta?.totalRecord - (meta?.currentPage - 1) * meta?.recordPerPage - index;
        } else {
            topList = (meta?.currentPage - 1) * meta?.recordPerPage + index + 1;
        }
        return {
            ...scenario,
            topList
        };
    });

    return {
        ...data,
        scenarios
    };
};

/**
 * TODO: Transform list target unphished data
 */

const transformStatisticsListTargetsUnphishedData = (data) => {
    if (objectUtils.isEmptyObject(data)) return;

    let employees = data?.employees || [];
    const meta = data?.meta || {};

    employees = employees.map((employee, index) => {
        return {
            ...employee,
            topList: index + 1 + meta.recordPerPage * (meta?.currentPage - 1)
        };
    });

    return {
        ...data,
        employees
    };
};

export {
    transformStatisticsSummaryPhishingData,
    transformStatisticsPhishingRatingByMonthsData,
    transformStatisticsPhishingResultData,
    transformStatisticsTopPhishedByDepartmentData,
    transformStatisticsTopClickedPhishingTemplateData,
    transformStatisticsDetailsPhishingTemplateData,
    transformStatisticsListTargetsUnphishedData,
    formatDataForPhishingResult
};
