const exportActionTypes = {
    EXPORT_REPORT_PDF_REQUEST: 'EXPORT_REPORT_PDF_REQUEST',
    EXPORT_REPORT_PDF_SUCCESS: 'EXPORT_REPORT_PDF_SUCCESS',
    EXPORT_REPORT_PDF_FAILURE: 'EXPORT_REPORT_PDF_FAILURE',

    // ? Export report as raw
    EXPORT_REPORT_AS_RAW_REQUEST: 'EXPORT_REPORT_AS_RAW_REQUEST',
    EXPORT_REPORT_AS_RAW_SUCCESS: 'EXPORT_REPORT_AS_RAW_SUCCESS',
    EXPORT_REPORT_AS_RAW_FAILURE: 'EXPORT_REPORT_AS_RAW_FAILURE'
};

export default exportActionTypes;
