const actionTypes = {
    // * Export survey participants
    EXPORT_SURVEY_PARTICIPANTS_PDF_REQUEST: 'EXPORT_SURVEY_PARTICIPANTS_PDF_REQUEST',
    EXPORT_SURVEY_PARTICIPANTS_PDF_SUCCESS: 'EXPORT_SURVEY_PARTICIPANTS_PDF_SUCCESS',
    EXPORT_SURVEY_PARTICIPANTS_PDF_FAILURE: 'EXPORT_SURVEY_PARTICIPANTS_PDF_FAILURE',

    EXPORT_SURVEY_PARTICIPANTS_SHEET_REQUEST: 'EXPORT_SURVEY_PARTICIPANTS_SHEET_REQUEST',
    EXPORT_SURVEY_PARTICIPANTS_SHEET_SUCCESS: 'EXPORT_SURVEY_PARTICIPANTS_SHEET_SUCCESS',
    EXPORT_SURVEY_PARTICIPANTS_SHEET_FAILURE: 'EXPORT_SURVEY_PARTICIPANTS_SHEET_FAILURE',

    // * Export survey questions
    EXPORT_SURVEY_QUESTIONS_PDF_REQUEST: 'EXPORT_SURVEY_QUESTIONS_PDF_REQUEST',
    EXPORT_SURVEY_QUESTIONS_PDF_SUCCESS: 'EXPORT_SURVEY_QUESTIONS_PDF_SUCCESS',
    EXPORT_SURVEY_QUESTIONS_PDF_FAILURE: 'EXPORT_SURVEY_QUESTIONS_PDF_FAILURE',

    EXPORT_SURVEY_QUESTIONS_SHEET_REQUEST: 'EXPORT_SURVEY_QUESTIONS_SHEET_REQUEST',
    EXPORT_SURVEY_QUESTIONS_SHEET_SUCCESS: 'EXPORT_SURVEY_QUESTIONS_SHEET_SUCCESS',
    EXPORT_SURVEY_QUESTIONS_SHEET_FAILURE: 'EXPORT_SURVEY_QUESTIONS_SHEET_FAILURE',

    // * Export survey result
    EXPORT_SURVEY_RESULT_SHEET_REQUEST: 'EXPORT_SURVEY_RESULT_SHEET_REQUEST',
    EXPORT_SURVEY_RESULT_SHEET_SUCCESS: 'EXPORT_SURVEY_RESULT_SHEET_SUCCESS',
    EXPORT_SURVEY_RESULT_SHEET_FAILURE: 'EXPORT_SURVEY_RESULT_SHEET_FAILURE'
};

export default actionTypes;
