import ExportServices from 'api/exports/participant';
import { call, takeLatest, put } from 'redux-saga/effects';
import types from './actionTypes';
import { exportParticipantSheetSuccess, exportParticipantSheetFailure } from './actions';

function* exportParticipantSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportParticipantsSheet, payload);
        yield put(exportParticipantSheetSuccess(response));
    } catch (errors) {
        yield put(exportParticipantSheetFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

export default function* watchExportParticipantSaga() {
    yield takeLatest(types.EXPORT_PARTICIPANT_SHEET_REQUEST, exportParticipantSheetRequestHandler);
}
