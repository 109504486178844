import httpClient from 'api';
import CONSTANTS from 'api/constants/statistics/summary';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get total candidates and departments
 */

const getTotalCandidatesAndDepartments = catchAsync(async () => {
    const response = await httpClient.get(CONSTANTS.TOTAL_CANDIDATES_AND_DEPARTMENTS);
    return response;
});

/**
 * TODO: Get security awareness score
 */
const getSecurityAwarenessScore = catchAsync(async () => {
    const response = await httpClient.get(CONSTANTS.SECURITY_AWARENESS_SCORE);
    return response;
});

/**
 * TODO: Get Security Awareness Rating By Department
 */

const getSecurityAwarenessRatingByDepartment = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.SECURITY_AWARENESS_RATING_BY_DEPARTMENT, {
        params
    });
    return response;
});

/**
 * TODO: Get Phishing Result
 */

const getPhishingResult = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.SUMMARY_PHISHING_RESULT, {
        params
    });
    return response;
});

/**
 * TODO: Get SA Result Of Participant
 */

const getSAResultOfParticipant = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.SECURITY_AWARENESS_RESULTS_OF_PARTICIPANT, {
        params
    });
    return response;
});
export default {
    getTotalCandidatesAndDepartments,
    getSecurityAwarenessScore,
    getSecurityAwarenessRatingByDepartment,
    getPhishingResult,
    getSAResultOfParticipant
};
