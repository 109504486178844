import objectUtils from './object-utils';
import stringUtils from './string-utils';

// ? Function to convert array to formData
const convertArrayToFormData = (array = []) => {
    const formData = new FormData();

    const cleanArray = array.map((item) => objectUtils.removeUndefinedFieldsFromObject(item));

    cleanArray.forEach((item, itemIdx) => {
        objectUtils.getKeyNames(item).map((key) => {
            if (key === 'groups') {
                item?.groups?.map((group) =>
                    formData.append(`reports[${itemIdx}][${key}][]`, group)
                );
            } else {
                formData.append(`reports[${itemIdx}][${key}]`, item[key]);
            }
        });
    });
    return formData;
};

// ? Function to get filled array
const getFilledArray = (range, mapfn) => {
    return range <= 0 ? [] : Array.from({ length: range }, mapfn);
};

// ? Return An Array Of Department Keys
const getDepartmentKeys = (departments = []) => departments?.map((department) => department?.key);

// ? Function to update index
const updateIndex = (array, indexItem, item) => {
    return array.map((chipItem, index) => {
        return indexItem === index ? item : chipItem;
    });
};

// ? Join array of strings
const joinArrayStrings = (array) => {
    return array.join('');
};

// ? Append item into array
const append = (array, item) => {
    return [...array, item];
};

// ? Merge array string from index
const mergeArrayStringFromIndex = (array, arrayToMerge, fromIndex) => {
    return array.reduce(
        (accumulator, currentValue, index) => {
            const { characters, restArrayMerged } = accumulator;
            if (index < fromIndex) {
                return {
                    restArrayMerged,
                    characters: append(characters, currentValue)
                };
            }

            const [firstCharacter, ...restArrayWithoutFirstCharacter] = restArrayMerged;
            return {
                restArrayMerged: restArrayWithoutFirstCharacter,
                characters: append(characters, firstCharacter || '')
            };
        },
        {
            restArrayMerged: arrayToMerge,
            characters: []
        }
    ).characters;
};

// ? Is empty array
const isEmptyArray = (array) => {
    return array.length === 0;
};

// ? Filter Elements Exits In Both Arrays
const existsInBothArrays = (arr1, arr2, searchKey = null) =>
    arr1.filter((element1) => arr2.map((element2) => element2[searchKey]).includes(element1));

// ? Is two arrays equal
const areTwoArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (Array.isArray(array1[i]) && Array.isArray(array2[i])) {
            if (!areTwoArraysEqual(array1[i], array2[i])) {
                return false;
            }
        } else if (typeof array1[i] === 'object' && typeof array2[i] === 'object') {
            if (!objectUtils.compareObjects(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] !== array2[i]) {
            return false;
        }
    }

    return true;
};

// ? Chunk array
const chunkArray = (array, size) => {
    if (array) {
        const chunkedArray = [];
        let index = 0;
        while (index < array.length) {
            chunkedArray.push(array.slice(index, size + index));
            index += size;
        }
        return chunkedArray;
    } else {
        return [];
    }
};

// ? Get array from chunked array with index
const getArrayFromChunkedArrayWithIndex = (array, size, index) => {
    const chunkedArray = chunkArray(array, size);
    return chunkedArray[index];
};

// ? Search by field in array
const searchByFieldContainsValueInArray = (array = [], field, value) => {
    return array.filter((item) => item[field].toLowerCase().includes(value.toLowerCase()));
};

// ? Sort array by field and direction
const sortArrayByFieldAndDirection = (array = [], field, direction) => {
    return array.sort((a, b) => {
        if (direction === 'asc') {
            return a[field] > b[field] ? 1 : -1;
        } else {
            return a[field] < b[field] ? 1 : -1;
        }
    });
};

// ? Sum of array
const sumOfArray = (array = []) => {
    return array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

const getFilterParams = (options) => {
    return options.filter((opt) => opt.isChecked).map((item) => stringUtils.htmlEscape(item.key));
};

const parameterizeArray = (arr) => {
    let params = getFilterParams(arr);
    return params.join('|');
};

// ? Revert array from chunked array
const revertArrayFromChunkedArray = (chunkedArray) => {
    return chunkedArray.flatMap((chunk) => chunk);
};

// ? Count duplicate items in array
const countDuplicates = (arr, direction = 'increase') => {
    const countMap = {};
    arr.forEach((item) => {
        countMap[item] =
            direction === 'increase' ? (countMap[item] || 0) + 1 : (countMap[item] || 0) - 1;
    });
    return countMap;
};

// ? Remove all duplicates from array with object item
const removeDuplicates = (arr, field = 'id') => {
    const seen = new Set();
    return arr.filter((item) => {
        const duplicate = seen.has(item[field]);
        seen.add(item[field]);
        return !duplicate;
    });
};

// ? Remove all items in array with object item
const removeItemsByField = (array1, array2, field) => {
    // Create a Set of values from array2 for the specified field
    const valuesToRemove = new Set(array2.map((item) => item[field]));

    // Filter array1, keeping items whose value for the field is not in valuesToRemove
    return array1.filter((item) => !valuesToRemove.has(item[field]));
};

// ? Find index with the key of an object
const findIndexByKey = (array, value) => {
    if (!array) return -1;
    return array.findIndex((item) => item === value);
};

export default {
    convertArrayToFormData,
    getDepartmentKeys,
    getFilledArray,
    updateIndex,
    joinArrayStrings,
    append,
    mergeArrayStringFromIndex,
    isEmptyArray,
    existsInBothArrays,
    areTwoArraysEqual,
    chunkArray,
    getArrayFromChunkedArrayWithIndex,
    searchByFieldContainsValueInArray,
    sumOfArray,
    sortArrayByFieldAndDirection,
    getFilterParams,
    parameterizeArray,
    revertArrayFromChunkedArray,
    countDuplicates,
    removeDuplicates,
    removeItemsByField,
    findIndexByKey
};
