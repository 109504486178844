import { store } from 'index';
import reducer from './reducer';
import saga from './saga';

const key = 'assessment';

export const useAssessmentSlice = () => {
    store.injectReducer(key, reducer);
    store.injectSaga(key, saga);
};
