import i18next from 'i18next';
import ObjectUtils from './object-utils';
import isEmpty from 'validator/lib/isEmpty';

const getErrorMessage = (errorKey) => {
    try {
        return i18next.t(`errors:${errorKey}`);
    } catch (error) {
        return 'Error fetching messages';
    }
};

// ? Format and translate validation error messages
const handleFormattingErrors = (errors) => {
    let formattedErrors = {};
    // console.log(errors);
    for (let prop in errors) {
        let key = errors[prop][0].split('validation.').pop();
        if (prop.includes('modules')) {
            prop = 'modules';
        }
        // console.log(getErrorMessage(key, 'errors'));
        formattedErrors[prop] = getErrorMessage(key, 'errors');
    }
    // console.log('formattedErrors: ', formattedErrors);
    return formattedErrors;
};

// ? Catch errors from http client request
const catchAsync =
    (fn) =>
    async (...params) => {
        return await fn(...params).catch((err) => {
            // * Validation errors type
            if (Object.hasOwn(err, 'errors') && !ObjectUtils.isEmptyObject(err.errors)) {
                const formattedErr = handleFormattingErrors(err.errors);
                if (!isEmpty(err.message)) {
                    const errMessage = getErrorMessage(err.message);
                    throw { ...formattedErr, errMessage };
                }
                throw formattedErr;
            }
            // * The rest of the error types
            else {
                if (err.message === 'limitSMSPhishing') {
                    throw { [err.message]: getErrorMessage(err.message) };
                }
                const errMessage = getErrorMessage(err.message);
                throw { errMessage };
            }
        });
    };

export { catchAsync };
