import objectUtils from 'utils/object-utils';

export const createErrorSelector = (actionTypes) => (state) => {
    const array = objectUtils
        .filterActionTypes(actionTypes)
        .map((actionType) => objectUtils.get(state, `error.${actionType}`))
        .filter((x) => !!x);

    const [error] = array;
    return error || {};
};
