const themePalette = (theme) => ({
    primary: {
        main: theme.colors?.primary600,
        800: theme.colors?.primary800,
        400: theme.colors?.primary400,
        200: theme.colors?.primary200,
        contrastText: theme.colors?.white
    },
    secondary: {
        main: theme.colors?.secondary900,
        800: theme.colors?.secondary800,
        500: theme.colors?.secondary500,
        50: theme.colors?.secondary50
    },
    common: {
        black: theme.colors?.black,
        white: theme.colors?.white
    },
    blueGrey: {
        900: theme.colors?.blueGrey900,
        600: theme.colors?.blueGrey600,
        100: theme.colors?.blueGrey100,
        50: theme.colors?.blueGrey50
    },
    grey: {
        900: theme.colors?.grey900,
        500: theme.colors?.grey500,
        300: theme.colors?.grey300,
        100: theme.colors?.grey100
    },
    background: {
        grey: theme.colors?.bgGrey50,
        blueGrey: theme.colors?.bgBlueGrey50,
        white: theme.colors?.bgWhite50,
        cyan: theme.colors?.bgCyan50,
        orange: theme.colors?.bgOrange50,
        green: theme.colors?.bgGreen50
    },
    text: {
        // ! base text
        primary: theme.colors?.baseTxt900,
        secondary: theme.colors?.baseTxt600,
        // ! nav text
        nav600: theme.colors?.navTxt600,
        nav50: theme.colors?.navTxt50,
        // ! button text
        btn900: theme.colors?.btnTxt900,
        btn600: theme.colors?.btnTxt50
    },
    table: {
        headerCell: theme.colors?.headerCell,
        headerCellHover: theme.colors?.headerCellHover,
        contentCell100: theme.colors?.contentCell100,
        contentCell50: theme.colors?.contentCell50,
        contentCellHover: theme.colors?.contentCellHover,
        contentCellBorder: theme.colors?.contentCellBorder,
        selectBg: theme.colors?.selectBg
    },
    action: {
        active: theme.colors?.active,
        txtSelection: theme.colors?.txtSelection,
        inactive: theme.colors?.inactive
    },
    info: {
        main: theme.colors?.info
    },
    warning: {
        main: theme.colors?.warning
    },
    error: {
        main: theme.colors?.error
    }
});

export default themePalette;
