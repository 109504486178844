import loadable from '@loadable/component';

const CandidateList = loadable(() => import('pages/candidate'));

const candidateRouteItems = [
    {
        value: 'candidate',
        key: 'sat.candidate.management.index',
        component: CandidateList
    }
];

export default candidateRouteItems;
