import { call, takeLatest, put, fork } from 'redux-saga/effects';

import services from 'api/org';
import types from './actionTypes';
import {
    getContractListSuccess,
    getContractListFailure,
    getContractDetailSuccess,
    getContractDetailFailure
} from './actions';

/**
 * TODO:  Contract list request handler
 */

function* getContractListRequestHandler({ payload }) {
    try {
        const response = yield call(services.getContractList, payload);
        yield put(getContractListSuccess(response.data));
    } catch (errors) {
        yield put(getContractListFailure(errors));
    }
}

/**
 * TODO:  Contract detail request handler
 */

function* getContractDetailRequestHandler({ payload }) {
    try {
        const response = yield call(services.getContractDetail, payload);
        yield put(getContractDetailSuccess(response.data));
    } catch (errors) {
        yield put(getContractDetailFailure(errors));
    }
}

function* watchOrgSaga() {
    yield takeLatest(types.GET_CONTRACT_LIST_REQUEST, getContractListRequestHandler);
    yield takeLatest(types.GET_CONTRACT_DETAIL_REQUEST, getContractDetailRequestHandler);
}

export default function* OrgSaga() {
    yield fork(watchOrgSaga);
}
