import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return reducerHandler.loginReducerHandler(state, action);
        case types.REGISTER_SUCCESS:
            return reducerHandler.registerReducerHandler(state, action);
        case types.FORGOT_PASSWORD_SUCCESS:
            return reducerHandler.forgotPasswordReducerHandler(state, action);
        case types.FORGOT_PASSWORD_VERIFY_CODE_SUCCESS:
            return reducerHandler.forgotPasswordVerifyCodeReducerHandler(state, action);
        case types.RESET_PASSWORD_SUCCESS:
            return reducerHandler.resetPasswordReducerHandler(state, action);
        case types.RESET_AUTH_STATE:
            return initState;
        case types.LOGOUT_SUCCESS:
            return reducerHandler.logoutReducerHandler(state, action);
        case types.VERIFY_OTP_SUCCESS:
            return reducerHandler.verifyOTPReducerHandler(state, action);
        case types.RESEND_OTP_SUCCESS:
            return reducerHandler.resendOTPReducerHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
