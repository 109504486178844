import loadable from '@loadable/component';

const Assessment = loadable(() => import('pages/assessment'));
const AssessmentResult = loadable(() => import('pages/assessment/AssessmentResult'));
const AssessmentResultIndividualDetail = loadable(() =>
    import('pages/assessment/AssessmentResultIndividualDetail')
);
const CreateAssessment = loadable(() => import('pages/assessment/create'));
const EditAssessment = loadable(() => import('pages/assessment/edit'));
const CloneAssessment = loadable(() => import('pages/assessment/clone'));

const assessmentRouteItems = [
    {
        value: 'assessment',
        key: 'sat.assessment.mcq.index',
        component: Assessment
    },
    {
        value: 'assessment/create',
        key: 'sat.assessment.mcq.create',
        component: CreateAssessment
    },
    {
        value: 'assessment/edit/:id',
        key: 'sat.assessment.mcq.update',
        component: EditAssessment
    },
    {
        value: 'assessment/:id/result',
        key: 'sat.assessment.mcq.view_summary',
        component: AssessmentResult
    },
    {
        value: 'assessment/:id/result/individual',
        key: 'sat.assessment.mcq.view_summary_candidate',
        component: AssessmentResultIndividualDetail
    },
    {
        value: 'assessment/clone/:id',
        key: 'sat.assessment.mcq.clone',
        component: CloneAssessment
    }
];

export default assessmentRouteItems;
