const types = {
    GET_STAFF_LIST_REQUEST: 'GET_STAFF_LIST_REQUEST',
    GET_STAFF_LIST_SUCCESS: 'GET_STAFF_LIST_SUCCESS',
    GET_STAFF_LIST_FAILURE: 'GET_STAFF_LIST_FAILURE',
    GET_STAFF_LIST_RESET: 'GET_STAFF_LIST_RESET',

    GET_STAFF_LIST_BY_ROLE_REQUEST: 'GET_STAFF_LIST_BY_ROLE_REQUEST',
    GET_STAFF_LIST_BY_ROLE_SUCCESS: 'GET_STAFF_LIST_BY_ROLE_SUCCESS',
    GET_STAFF_LIST_BY_ROLE_FAILURE: 'GET_STAFF_LIST_BY_ROLE_FAILURE',
    GET_STAFF_LIST_BY_ROLE_RESET: 'GET_STAFF_LIST_BY_ROLE_RESET',

    DELETE_STAFFS_FROM_ROLE_REQUEST: 'DELETE_STAFFS_FROM_ROLE_REQUEST',
    DELETE_STAFFS_FROM_ROLE_SUCCESS: 'DELETE_STAFFS_FROM_ROLE_SUCCESS',
    DELETE_STAFFS_FROM_ROLE_FAILURE: 'DELETE_STAFFS_FROM_ROLE_FAILURE',
    DELETE_STAFFS_FROM_ROLE_RESET: 'DELETE_STAFFS_FROM_ROLE_RESET',

    PUT_BAN_STAFF_REQUEST: 'PUT_BAN_STAFF_REQUEST',
    PUT_BAN_STAFF_SUCCESS: 'PUT_BAN_STAFF_SUCCESS',
    PUT_BAN_STAFF_FAILURE: 'PUT_BAN_STAFF_FAILURE',
    PUT_BAN_STAFF_RESET: 'PUT_BAN_STAFF_RESET',

    GET_ROLE_LIST_REQUEST: 'GET_ROLE_LIST_REQUEST',
    GET_ROLE_LIST_SUCCESS: 'GET_ROLE_LIST_SUCCESS',
    GET_ROLE_LIST_FAILURE: 'GET_ROLE_LIST_FAILURE',
    GET_ROLE_LIST_RESET: 'GET_ROLE_LIST_RESET',

    GET_PERMISSIONS_LIST_REQUEST: 'GET_PERMISSIONS_LIST_REQUEST',
    GET_PERMISSIONS_LIST_SUCCESS: 'GET_PERMISSIONS_LIST_SUCCESS',
    GET_PERMISSIONS_LIST_FAILURE: 'GET_PERMISSIONS_LIST_FAILURE',
    GET_PERMISSIONS_LIST_RESET: 'GET_PERMISSIONS_LIST_RESET',

    ADD_ROLE_REQUEST: 'ADD_ROLE_REQUEST',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    ADD_ROLE_FAILURE: 'ADD_ROLE_FAILURE',
    ADD_ROLE_RESET: 'ADD_ROLE_RESET',

    GET_ROLE_BY_ID_REQUEST: 'GET_ROLE_BY_ID_REQUEST',
    GET_ROLE_BY_ID_SUCCESS: 'GET_ROLE_BY_ID_SUCCESS',
    GET_ROLE_BY_ID_FAILURE: 'GET_ROLE_BY_ID_FAILURE',
    GET_ROLE_BY_ID_RESET: 'GET_ROLE_BY_ID_RESET',

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',
    UPDATE_ROLE_RESET: 'UPDATE_ROLE_RESET',

    DELETE_ROLES_REQUEST: 'DELETE_ROLES_REQUEST',
    DELETE_ROLES_SUCCESS: 'DELETE_ROLES_SUCCESS',
    DELETE_ROLES_FAILURE: 'DELETE_ROLES_FAILURE',
    DELETE_ROLES_RESET: 'DELETE_ROLES_RESET',

    ADD_STAFF_REQUEST: 'ADD_STAFF_REQUEST',
    ADD_STAFF_SUCCESS: 'ADD_STAFF_SUCCESS',
    ADD_STAFF_FAILURE: 'ADD_STAFF_FAILURE',
    ADD_STAFF_RESET: 'ADD_STAFF_RESET',

    GET_STAFF_BY_ID_REQUEST: 'GET_STAFF_BY_ID_REQUEST',
    GET_STAFF_BY_ID_SUCCESS: 'GET_STAFF_BY_ID_SUCCESS',
    GET_STAFF_BY_ID_FAILURE: 'GET_STAFF_BY_ID_FAILURE',
    GET_STAFF_BY_ID_RESET: 'GET_STAFF_BY_ID_RESET',

    UPDATE_STAFF_REQUEST: 'UPDATE_STAFF_REQUEST',
    UPDATE_STAFF_SUCCESS: 'UPDATE_STAFF_SUCCESS',
    UPDATE_STAFF_FAILURE: 'UPDATE_STAFF_FAILURE',
    UPDATE_STAFF_RESET: 'UPDATE_STAFF_RESET',

    RESET_STAFF_PASSWORD_REQUEST: 'RESET_STAFF_PASSWORD_REQUEST',
    RESET_STAFF_PASSWORD_SUCCESS: 'RESET_STAFF_PASSWORD_SUCCESS',
    RESET_STAFF_PASSWORD_FAILURE: 'RESET_STAFF_PASSWORD_FAILURE',
    RESET_STAFF_PASSWORD_RESET: 'RESET_STAFF_PASSWORD_RESET'
};

export default types;
