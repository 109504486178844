import {
    assessmentSummaryDataTransformer,
    totalDataTransformer
} from 'transformer/statistics/summary';
import {
    transformTablePagination,
    transformAssessmentByDepartment,
    transformNotYetAssignedData
} from 'transformer/statistics/department-general';

/**
 * TODO: Get assessment summary
 */
const getAssessmentSummary = (state, { payload }) => {
    return {
        ...state,
        assessmentSummaryData: assessmentSummaryDataTransformer(payload.assessment)
    };
};

/**
 * TODO: Get assessment total
 */
const getAssessmentTotal = (state, { payload }) => {
    return {
        ...state,
        assessmentTotalData: {
            assessments: totalDataTransformer(payload.assessments),
            candidates: payload.candidates
        }
    };
};

/**
 * TODO: Get Assessment By Department
 */

const getAssessmentByDepartment = (state, { payload = {} }) => {
    // ? Check report key is exist in templateKeys
    return {
        ...state,
        assessmentByDepartment: {
            reportKey: payload?.reportKey,
            data: transformAssessmentByDepartment(
                payload?.groups,
                'id',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

const getParticipantNotYetAssigned = (state, { payload = {} }) => {
    return {
        ...state,
        participantNotYetAssignedAssessment: {
            reportKey: payload?.reportKey,
            data: transformNotYetAssignedData(
                payload?.employees,
                'id',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

export default {
    getAssessmentSummary,
    getAssessmentTotal,
    getAssessmentByDepartment,
    getParticipantNotYetAssigned
};
