import actionTypes from './actionTypes';

// ? Export report PDF
const exportReportPDFRequest = (payload) => ({
    type: actionTypes.EXPORT_REPORT_PDF_REQUEST,
    payload
});

const exportReportPDFSuccess = (payload) => ({
    type: actionTypes.EXPORT_REPORT_PDF_SUCCESS,
    payload
});

const exportReportPDFFailure = (payload) => ({
    type: actionTypes.EXPORT_REPORT_PDF_FAILURE,
    payload
});

// ? Export report as raw
const exportReportAsRawRequest = () => ({
    type: actionTypes.EXPORT_REPORT_AS_RAW_REQUEST
});

const exportReportAsRawSuccess = () => ({
    type: actionTypes.EXPORT_REPORT_AS_RAW_SUCCESS
});

const exportReportAsRawFailure = (payload) => ({
    type: actionTypes.EXPORT_REPORT_AS_RAW_FAILURE,
    payload
});

export default {
    exportReportPDFRequest,
    exportReportPDFSuccess,
    exportReportPDFFailure,
    exportReportAsRawRequest,
    exportReportAsRawSuccess,
    exportReportAsRawFailure
};
