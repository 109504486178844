import actionTypes from './actionTypes';

// ? Get training result
const getTrainingSummary = (payload) => ({
    type: actionTypes.GET_TRAINING_REPORT_SUMMARY_REQUEST,
    payload
});

const getTrainingSummarySuccess = (payload) => ({
    type: actionTypes.GET_TRAINING_REPORT_SUMMARY_SUCCESS,
    payload
});

const getTrainingSummaryFailure = (errors) => ({
    type: actionTypes.GET_TRAINING_REPORT_SUMMARY_FAILURE,
    payload: { errors }
});

// ? Get training total
const getTrainingTotal = (payload) => ({
    type: actionTypes.GET_TRAINING_REPORT_TOTAL_REQUEST,
    payload
});

const getTrainingTotalSuccess = (payload) => ({
    type: actionTypes.GET_TRAINING_REPORT_TOTAL_SUCCESS,
    payload
});

const getTrainingTotalFailure = (errors) => ({
    type: actionTypes.GET_TRAINING_REPORT_TOTAL_FAILURE,
    payload: { errors }
});

// ? Request
const getTrainingByDepartmentRequest = (data) => ({
    type: actionTypes.TRAINING_BY_DEPARTMENT_REQUEST,
    payload: data
});

// ? Success
const getTrainingByDepartmentSuccess = (data) => ({
    type: actionTypes.TRAINING_BY_DEPARTMENT_SUCCESS,
    payload: data
});

// ? Failure
const getTrainingByDepartmentFailure = (errors) => ({
    type: actionTypes.TRAINING_BY_DEPARTMENT_FAILURE,
    payload: errors
});

// ? Request
const getParticipantNotYetAssignedToTrainingRequest = (data) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_TRAINING_REQUEST,
    payload: data
});

// ? Success
const getParticipantNotYetAssignedToTrainingSuccess = (data) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_TRAINING_SUCCESS,
    payload: data
});

// ? Failure
const getParticipantNotYetAssignedToTrainingFailure = (errors) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_TRAINING_FAILURE,
    payload: errors
});

export default {
    getTrainingSummary,
    getTrainingSummarySuccess,
    getTrainingSummaryFailure,
    getTrainingTotal,
    getTrainingTotalSuccess,
    getTrainingTotalFailure,
    getTrainingByDepartmentRequest,
    getTrainingByDepartmentSuccess,
    getTrainingByDepartmentFailure,
    getParticipantNotYetAssignedToTrainingRequest,
    getParticipantNotYetAssignedToTrainingSuccess,
    getParticipantNotYetAssignedToTrainingFailure
};
