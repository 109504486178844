import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import EXPORT_CONSTANTS from 'api/constants/exports';
import dateUtils from 'utils/date-utils';
import objectUtils from 'utils/object-utils';

const createRequestURL = (path, code, endpoint) =>
    `${path}/${endpoint}`.replace(':phishingCode', code);

/**
 * TODO: Export phishing targets
 */

const exportPhishingTargetsPDF = catchAsync(async ({ params, phishingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.PHISHING_PDF_EXPORT,
        phishingCode,
        'popups/targets'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportPhishingTargetsSheet = catchAsync(async ({ params, phishingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.PHISHING_SHEET_EXPORT,
        phishingCode,
        'popups/targets'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export phishing result
 */

const exportPhishingResultPDF = catchAsync(async ({ params, phishingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.PHISHING_PDF_EXPORT,
        phishingCode,
        'summary/results'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportPhishingResultSheet = catchAsync(async ({ params, phishingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.PHISHING_SHEET_EXPORT,
        phishingCode,
        'summary/results'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const PhishingServices = {
    exportPhishingTargetsPDF,
    exportPhishingTargetsSheet,
    exportPhishingResultPDF,
    exportPhishingResultSheet
};

export default PhishingServices;
