const initialState = {
    assessmentSummaryData: {},
    assessmentTotalData: {},
    assessmentByDepartment: {
        data: [],
        pagination: {},
        reportKey: ''
    },
    participantNotYetAssignedAssessment: {
        data: [],
        pagination: {}
    }
};

export default initialState;
