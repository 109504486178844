const ASSESSMENT_CONSTANTS = {
    GET_LIST_ASSESSMENT: '/assessments',
    GET_LIST_QUESTION: 'questions/exam',
    GET_LIST_TEMPLATES: 'bundles',
    CREATE_ASSESSMENT: 'assessments/create',
    EDIT_ASSESSMENT: 'assessments/edit',
    GET_ASSESSMENT_DETAILS: '/assessments',
    GET_ASSESSMENT_CLONE_DETAILS: '/assessments',
    GET_LIST_QUESTIONS_BY_TEMPLATE_IDS: 'bundles/questions',
    GET_LIST_EXAM_TAGS: 'tags/exam',
    START_ASSESSMENT: '/assessments/start',
    STOP_ASSESSMENT: '/assessments/stop',
    REBUILD_ASSESSMENT: '/assessments/rebuild',
    DELETE_ASSESSMENTS: '/assessments/delete',
    GET_LANGUAGE_SUPPORTED: '/support/languages',
    GET_SUMMARY_DEPARTMENTS: '/summary/departments'
};

export default ASSESSMENT_CONSTANTS;
