const actionTypes = {
    // ? Get result
    GET_TRAINING_REPORT_SUMMARY_REQUEST: 'GET_TRAINING_REPORT_SUMMARY_REQUEST',
    GET_TRAINING_REPORT_SUMMARY_SUCCESS: 'GET_TRAINING_REPORT_SUMMARY_SUCCESS',
    GET_TRAINING_REPORT_SUMMARY_FAILURE: 'GET_TRAINING_REPORT_SUMMARY_FAILURE',

    // ? Get Number Of Trainings
    GET_TRAINING_REPORT_TOTAL_REQUEST: 'GET_TRAINING_REPORT_TOTAL_REQUEST',
    GET_TRAINING_REPORT_TOTAL_SUCCESS: 'GET_TRAINING_REPORT_TOTAL_SUCCESS',
    GET_TRAINING_REPORT_TOTAL_FAILURE: 'GET_TRAINING_REPORT_TOTAL_FAILURE',

    // ? Get Result By Department
    TRAINING_BY_DEPARTMENT_REQUEST: 'TRAINING_BY_DEPARTMENT_REQUEST',
    TRAINING_BY_DEPARTMENT_SUCCESS: 'TRAINING_BY_DEPARTMENT_SUCCESS',
    TRAINING_BY_DEPARTMENT_FAILURE: 'TRAINING_BY_DEPARTMENT_FAILURE',

    // ? Not yet assigned
    NOT_YET_ASSIGNED_TO_TRAINING_REQUEST: 'NOT_YET_ASSIGNED_TO_TRAINING_REQUEST',
    NOT_YET_ASSIGNED_TO_TRAINING_SUCCESS: 'NOT_YET_ASSIGNED_TO_TRAINING_SUCCESS',
    NOT_YET_ASSIGNED_TO_TRAINING_FAILURE: 'NOT_YET_ASSIGNED_TO_TRAINING_FAILURE'
};

export default actionTypes;
