const initialState = {
    user: null,
    isRegisterSuccess: false,
    isForgotPasswordMailSent: false,
    isForgotPasswordCodeVerified: false,
    isResetPasswordSuccess: false,
    isLogoutSuccess: false,
    isLoginSuccess: false,
    accessToken: null,
    isResendOTPSuccess: false
};

export default initialState;
