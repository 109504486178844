import ExportServices from 'api/exports/assessment';
import { call, takeLatest, put } from 'redux-saga/effects';
import types from './actionTypes';
import {
    exportAssessmentParticipantsPDFSuccess,
    exportAssessmentParticipantsPDFFailure,
    exportAssessmentParticipantsSheetSuccess,
    exportAssessmentParticipantsSheetFailure,
    exportAssessmentInprogressPDFSuccess,
    exportAssessmentInprogressPDFFailure,
    exportAssessmentInprogressSheetSuccess,
    exportAssessmentInprogressSheetFailure,
    exportAssessmentResultPDFSuccess,
    exportAssessmentResultPDFFailure,
    exportAssessmentResultSheetSuccess,
    exportAssessmentResultSheetFailure,
    exportAssessmentQuestionsPDFSuccess,
    exportAssessmentQuestionsPDFFailure,
    exportAssessmentQuestionsSheetSuccess,
    exportAssessmentQuestionsSheetFailure,
    exportAssessmentIndividualPDFSuccess,
    exportAssessmentIndividualPDFFailure,
    exportAssessmentIndividualSheetSuccess,
    exportAssessmentIndividualSheetFailure
} from './actions';

/**
 * TODO: Export assessment participants
 */

function* exportAssessmentParticipantsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentParticipantsPDF, payload);
        yield put(exportAssessmentParticipantsPDFSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentParticipantsPDFFailure(errors));
    }
}

function* exportAssessmentParticipantsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentParticipantsSheet, payload);
        yield put(exportAssessmentParticipantsSheetSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentParticipantsSheetFailure(errors));
    }
}

/**
 * TODO: Export assessment questions
 */

function* exportAssessmentQuestionsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentQuestionsPDF, payload);
        yield put(exportAssessmentQuestionsPDFSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentQuestionsPDFFailure(errors));
    }
}

function* exportAssessmentQuestionsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentQuestionsSheet, payload);
        yield put(exportAssessmentQuestionsSheetSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentQuestionsSheetFailure(errors));
    }
}

/**
 * TODO: Export assessment in-progress
 */

function* exportAssessmentInprogressPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentInprogressPDF, payload);
        yield put(exportAssessmentInprogressPDFSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentInprogressPDFFailure(errors));
    }
}

function* exportAssessmentInprogressSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentInprogressSheet, payload);
        yield put(exportAssessmentInprogressSheetSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentInprogressSheetFailure(errors));
    }
}

/**
 * TODO: Export assessment result
 */

function* exportAssessmentResultPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentResultPDF, payload);
        yield put(exportAssessmentResultPDFSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentResultPDFFailure(errors));
    }
}

function* exportAssessmentResultSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentResultSheet, payload);
        yield put(exportAssessmentResultSheetSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentResultSheetFailure(errors));
    }
}

/**
 * TODO: Export assessment individual result
 */

function* exportAssessmentIndividualPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentIndividualPDF, payload);
        yield put(exportAssessmentIndividualPDFSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentIndividualPDFFailure(errors));
    }
}

function* exportAssessmentIndividualSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportAssessmentIndividualSheet, payload);
        yield put(exportAssessmentIndividualSheetSuccess(response));
    } catch (errors) {
        yield put(exportAssessmentIndividualSheetFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

export default function* watchExportAssessmentSaga() {
    yield takeLatest(
        types.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_REQUEST,
        exportAssessmentParticipantsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_REQUEST,
        exportAssessmentParticipantsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_QUESTIONS_PDF_REQUEST,
        exportAssessmentQuestionsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_QUESTIONS_SHEET_REQUEST,
        exportAssessmentQuestionsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_INPROGRESS_PDF_REQUEST,
        exportAssessmentInprogressPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_INPROGRESS_SHEET_REQUEST,
        exportAssessmentInprogressSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_RESULT_PDF_REQUEST,
        exportAssessmentResultPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_RESULT_SHEET_REQUEST,
        exportAssessmentResultSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_REQUEST,
        exportAssessmentIndividualPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_REQUEST,
        exportAssessmentIndividualSheetRequestHandler
    );
}
