import loadable from '@loadable/component';

const TrainingPage = loadable(() => import('pages/training'));
const TrainingResult = loadable(() => import('pages/training/TrainingResult'));
const TrainingResultIndividualDetail = loadable(() =>
    import('pages/training/TrainingResultIndividualDetail')
);
const CreateTrainingPage = loadable(() => import('pages/training/create'));
const EditTraining = loadable(() => import('pages/training/edit'));
const CloneTraining = loadable(() => import('pages/training/clone'));

const trainingRouteItems = [
    {
        value: 'training',
        key: 'sat.training.theory.index',
        component: TrainingPage
    },
    {
        value: 'training/create',
        key: 'sat.training.theory.create',
        component: CreateTrainingPage
    },
    {
        value: 'training/:id/result',
        key: ['sat.training.theory.view_summary', 'sat.training.theory.view_statistics'],
        component: TrainingResult
    },
    {
        value: 'training/:id/result/individual',
        key: 'sat.training.theory.view_statistics',
        component: TrainingResultIndividualDetail
    },
    {
        value: 'training/edit/:id',
        key: 'sat.training.theory.update',
        component: EditTraining
    },
    {
        value: 'training/clone/:id',
        key: 'sat.training.theory.clone',
        component: CloneTraining
    }
];

export default trainingRouteItems;
