import ExportServices from 'api/exports/survey';
import { call, takeLatest, put } from 'redux-saga/effects';
import types from './actionTypes';
import {
    exportSurveyParticipantsPDFSuccess,
    exportSurveyParticipantsPDFFailure,
    exportSurveyParticipantsSheetSuccess,
    exportSurveyParticipantsSheetFailure,
    exportSurveyResultSheetSuccess,
    exportSurveyResultSheetFailure,
    exportSurveyQuestionsPDFSuccess,
    exportSurveyQuestionsPDFFailure,
    exportSurveyQuestionsSheetSuccess,
    exportSurveyQuestionsSheetFailure
} from './actions';

/**
 * TODO: Export survey participants
 */

function* exportSurveyParticipantsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportSurveyParticipantsPDF, payload);
        yield put(exportSurveyParticipantsPDFSuccess(response));
    } catch (errors) {
        yield put(exportSurveyParticipantsPDFFailure(errors));
    }
}

function* exportSurveyParticipantsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportSurveyParticipantsSheet, payload);
        yield put(exportSurveyParticipantsSheetSuccess(response));
    } catch (errors) {
        yield put(exportSurveyParticipantsSheetFailure(errors));
    }
}

/**
 * TODO: Export survey questions
 */

function* exportSurveyQuestionsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportSurveyQuestionsPDF, payload);
        yield put(exportSurveyQuestionsPDFSuccess(response));
    } catch (errors) {
        yield put(exportSurveyQuestionsPDFFailure(errors));
    }
}

function* exportSurveyQuestionsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportSurveyQuestionsSheet, payload);
        yield put(exportSurveyQuestionsSheetSuccess(response));
    } catch (errors) {
        yield put(exportSurveyQuestionsSheetFailure(errors));
    }
}

/**
 * TODO: Download survey result
 */

function* exportSurveyResultSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportSurveyResultSheet, payload);
        yield put(exportSurveyResultSheetSuccess(response));
    } catch (errors) {
        yield put(exportSurveyResultSheetFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

export default function* watchExportSurveySaga() {
    yield takeLatest(
        types.EXPORT_SURVEY_PARTICIPANTS_PDF_REQUEST,
        exportSurveyParticipantsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_SURVEY_PARTICIPANTS_SHEET_REQUEST,
        exportSurveyParticipantsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_SURVEY_QUESTIONS_PDF_REQUEST,
        exportSurveyQuestionsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_SURVEY_QUESTIONS_SHEET_REQUEST,
        exportSurveyQuestionsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_SURVEY_RESULT_SHEET_REQUEST,
        exportSurveyResultSheetRequestHandler
    );
}
