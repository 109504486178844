import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// ~ Services
import { toggleBreadcrumbs } from 'services/common/action';

// ~ Components
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BaseButton from 'components/Input/Button';

// ~ Assets
import NotFoundImage from 'assets/img/404.svg';
import { GrLinkPrevious } from 'react-icons/gr';

const NotFound = () => {
    /**
     * TODO: Services
     */
    const dispatch = useDispatch();
    const [t] = useTranslation('common');
    const location = useLocation();

    /**
     * TODO: Handlers
     */

    const handleOnPreviousPage = () => {
        window.history.back();
    };

    const isAuthPage = location.pathname.includes('/auth');

    useEffect(() => {
        dispatch(toggleBreadcrumbs(false));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(toggleBreadcrumbs(true));
        };
    }, []);

    return (
        <Stack
            width='100%'
            height='100%'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
        >
            <Stack
                width='100%'
                height='100%'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                gap={5}
            >
                <Box textAlign='center'>
                    {!isAuthPage && (
                        <Typography variant='display1' fontSize={32} fontWeight={900}>
                            Oops!
                        </Typography>
                    )}

                    <Typography color='#121926' variant='body2' marginTop={1.5}>
                        {t('common.notFound')}
                    </Typography>
                </Box>
                <Box width='50%' maxWidth='570px'>
                    <img src={NotFoundImage} />
                </Box>

                {!isAuthPage && (
                    <BaseButton
                        variant='outlined'
                        styles={{
                            py: '16px',
                            px: '24px',
                            width: 181,
                            height: 48
                        }}
                        onClick={handleOnPreviousPage}
                    >
                        <GrLinkPrevious color='#4B5565' fontSize={18} />
                        <Typography variant='body2' fontWeight={700} ml={1}>
                            Previous page
                        </Typography>
                    </BaseButton>
                )}
            </Stack>
        </Stack>
    );
};

export default NotFound;
