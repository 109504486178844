import loadable from '@loadable/component';

const Dashboard = loadable(() => import('pages/user/dashboard'));

const dashboardRouteItems = [
    {
        value: 'dashboard',
        key: 'sat.manager.dashboard.index',
        component: Dashboard
    }
];

export default dashboardRouteItems;
