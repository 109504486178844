import React, { useState, createContext, useContext } from 'react';

import PropTypes from 'prop-types';

export const initialState = {
    modal: {
        title: '',
        isOpen: false
    },
    isExporting: false,
    isSidebarOpen: false,
    isRenderSummaryExport: false
};

const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }) => {
    const [modal, setModal] = useState(initialState.modal);
    const [isExporting, setIsExporting] = useState(initialState.isExporting);
    const [isSidebarOpen, setIsSidebarOpen] = useState(initialState.isSidebarOpen);
    const [isRenderSummaryExport, setIsRenderSummaryExport] = useState(
        initialState.isRenderSummaryExport
    );

    return (
        <AppContext.Provider
            value={{
                modal,
                setModal,
                isExporting,
                setIsExporting,
                isSidebarOpen,
                setIsSidebarOpen,
                isRenderSummaryExport,
                setIsRenderSummaryExport
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.node
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;
