import types from './actionTypes';

/**
 * TODO:  Contract List action
 */
const getContractList = (payload) => ({
    type: types.GET_CONTRACT_LIST_REQUEST,
    payload
});

const getContractListSuccess = (payload) => ({
    type: types.GET_CONTRACT_LIST_SUCCESS,
    payload
});

const getContractListFailure = (payload) => ({
    type: types.GET_CONTRACT_LIST_FAILURE,
    payload
});

const resetGetContractListError = () => ({
    type: types.GET_CONTRACT_LIST_RESET
});

/**
 * TODO:  Contract Detail action
 */
const getContractDetail = (payload) => ({
    type: types.GET_CONTRACT_DETAIL_REQUEST,
    payload
});

const getContractDetailSuccess = (payload) => ({
    type: types.GET_CONTRACT_DETAIL_SUCCESS,
    payload
});

const getContractDetailFailure = (payload) => ({
    type: types.GET_CONTRACT_DETAIL_FAILURE,
    payload
});

const resetGetContractDetailError = () => ({
    type: types.GET_CONTRACT_DETAIL_RESET
});

export {
    // ? Contract List
    getContractList,
    getContractListSuccess,
    getContractListFailure,
    resetGetContractListError,

    // ? Contract Detail
    getContractDetail,
    getContractDetailSuccess,
    getContractDetailFailure,
    resetGetContractDetailError
};
