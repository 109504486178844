import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import ORG_CONSTANTS from 'api/constants/org';

/**
 * TODO: Contract List
 * @param {Object} data
 * @param {String} data.page
 * @param {String} data.pageSize
 * @returns {Promise}
 */

const getContractList = catchAsync(async (params) => {
    const response = await httpClient.get(ORG_CONSTANTS.GET_CONTRACT_LIST, { params });
    return response;
});

/**
 * TODO: Contract Detail
 * @param {String} contractId
 * @returns {Promise}
 */

const getContractDetail = catchAsync(async ({ contractId }) => {
    const response = await httpClient.get(`${ORG_CONSTANTS.GET_CONTRACT_DETAIL}/${contractId}`);
    return response;
});

const OrganizationServices = {
    getContractList,
    getContractDetail
};

export default OrganizationServices;
