import i18next from 'i18next';
import {
    transformTargetListWithID,
    transformSummaryData,
    transformTimelineResultData,
    transformResultCandidateListWithID
} from 'transformer/simulation';
import stringUtils from 'utils/string-utils';
import { transformDeleteSuccessMessage } from 'transformer/assessment';

/**
 * TODO: Send test email
 */

const sendTestEmailReducerHandler = (state) => {
    return {
        ...state,
        isSendTestEmailSuccess: true
    };
};

const sendTestEmailFailureReducerHandler = (state, payload) => {
    return {
        ...state,
        isSendTestEmailSuccess: false,
        sendTestEmailError: payload
    };
};

const sendTestEmailResetReducerHandler = (state) => {
    return {
        ...state,
        isSendTestEmailSuccess: false,
        sendTestEmailError: {}
    };
};

/**
 * TODO: Get phishing email templates
 */

const getPhishingEmailTemplatesReducerHandler = (state, payload) => {
    return {
        ...state,
        phishingEmailTemplatesData: payload
    };
};

/**
 * TODO: Transform Phishing Campaigns List data
 */

const getListPhishingCampaigns = (state, payload) => {
    return {
        ...state,
        stopCampaignSuccessMessage: '',
        deleteCampaignsSuccessMessage: '',
        listPhishingCampaigns: {
            data: payload.campaigns,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Get phishing list notifications
 */

const getPhishingListNotificationsReducerHandler = (state, payload) => {
    return {
        ...state,
        phishingListNotificationsData: payload
    };
};

/**
 * TODO: Transform Targets List
 */

const getListTargets = (state, payload) => {
    return {
        ...state,
        listTargets: {
            data: transformTargetListWithID(
                payload.targets,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform scam email content
 */

const getScamEmailContent = (state, payload) => {
    return {
        ...state,
        scamEmailContent: payload
    };
};

/**
 * TODO: Transform scam web content
 */

const getScamWebContent = (state, payload) => {
    return {
        ...state,
        scamWebContent: payload
    };
};
const resetScamWebContent = (state) => {
    return {
        ...state,
        scamWebContent: {}
    };
};

/**
 * TODO: Transform view phishing notification
 */

const getViewPhishingNotification = (state, payload) => {
    return {
        ...state,
        viewPhishingNotification: payload
    };
};

/**
 * TODO: Transform summary data
 */

const getPhishingSummary = (state, payload) => {
    return {
        ...state,
        phishingSummary: transformSummaryData(payload)
    };
};
/**
 * TODO: Transform timeline result data
 */

const getPhishingTimelineResult = (state, payload) => {
    return {
        ...state,
        phishingTimelineResult: transformTimelineResultData(payload)
    };
};

/**
 * TODO: Transform result candidate list data
 */

const getPhishingResultCandidateList = (state, payload) => {
    return {
        ...state,
        phishingResultCandidateList: {
            data: transformResultCandidateListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform stop campaign message
 */

const stopCampaign = (state) => {
    return {
        ...state,
        stopCampaignSuccessMessage: i18next.t('success:phishing.stoppedSuccess')
    };
};

/**
 * TODO: Transform delete campaigns message
 */

const deleteCampaigns = (state, payload) => {
    const counter = transformDeleteSuccessMessage(payload.message);
    return {
        ...state,
        deleteCampaignsSuccessMessage: i18next.t('success:phishing.deleteSuccess', {
            key: +counter,
            label: stringUtils.pluralNounCheck(+counter, i18next.t('simulation:campaignLabel'), 's')
        })
    };
};

/**
 * TODO: Create phishing campagin reducer handler
 */

const createPhishingCampaignReducerHandler = (state) => {
    return {
        ...state,
        isCreatePhishingCampaignSuccess: true
    };
};

const createPhishingCampaignResetReducerHandler = (state) => {
    return {
        ...state,
        isCreatePhishingCampaignSuccess: false
    };
};

/**
 * TODO: Get list bookmarks
 */

const getListBookmarks = (state, payload) => {
    return {
        ...state,
        listBookmarksData: payload
    };
};

/**
 * TODO: Import phishing site
 */

const importPhishingSiteReducerHandler = (state, payload) => {
    return {
        ...state,
        isImportPhishingSiteSuccess: true,
        importPhishingSiteData: payload
    };
};

const importPhishingSiteFailureReducerHandler = (state) => {
    return {
        ...state,
        isImportPhishingSiteSuccess: false,
        importPhishingSiteData: {}
    };
};

const importPhishingSiteResetReducerHandler = (state) => {
    return {
        ...state,
        isImportPhishingSiteSuccess: false,
        importPhishingSiteData: {}
    };
};

/**
 * TODO: Get list sms brands
 */

const getListSmsBrandsReducerHandler = (state, payload) => {
    return {
        ...state,
        listSmsBrandsData: payload
    };
};

/**
 * TODO: Send test sms
 */

const sendTestSmsReducerHandler = (state) => {
    return {
        ...state,
        isSendTestSmsSuccess: true
    };
};

const sendTestSmsResetReducerHandler = (state) => {
    return {
        ...state,
        isSendTestSmsSuccess: false
    };
};

const sendTestSmsFailureReducerHandler = (state) => {
    return {
        ...state,
        isSendTestSmsSuccess: false
    };
};

/**
 * TODO: Get list sms templates
 */

const getListSmsTemplatesReducerHandler = (state, payload) => {
    return {
        ...state,
        listSmsTemplatesData: payload || {}
    };
};

const getListSmsTemplatesFailureReducerHandler = (state) => {
    return {
        ...state,
        listSmsTemplatesData: []
    };
};

/**
 * TODO: Create sms phishing campaign
 */

const createSmsPhishingCampaignReducerHandler = (state) => {
    return {
        ...state,
        isCreateSmsPhishingCampaignSuccess: true
    };
};

const createSmsPhishingCampaignResetReducerHandler = (state) => {
    return {
        ...state,
        isCreateSmsPhishingCampaignSuccess: false
    };
};

/**
 * TODO: Preview site
 */

const previewSiteReducerHandler = (state, payload) => {
    return {
        ...state,
        previewSiteData: payload
    };
};

const previewSiteResetReducerHandler = (state) => {
    return {
        ...state,
        previewSiteData: {}
    };
};

/**
 * TODO: Get list category
 */

const getListCategoryReducerHandler = (state, payload) => {
    return {
        ...state,
        listCategoryData: payload
    };
};

const getListCategoryFailureReducerHandler = (state) => {
    return {
        ...state,
        listCategoryData: {}
    };
};

/**
 * TODO: Get clone phishing details
 */

const getClonePhishingDetailsReducerHandler = (state, payload) => {
    return {
        ...state,
        clonePhishingDetailsData: payload
    };
};

const getClonePhishingDetailsFailureReducerHandler = (state) => {
    return {
        ...state,
        clonePhishingDetailsData: {}
    };
};

/**
 * TODO: Send test ott
 */

const sendTestOttReducerHandler = (state) => {
    return {
        ...state,
        isSendTestOttSuccess: true
    };
};

const sendTestOttResetReducerHandler = (state) => {
    return {
        ...state,
        isSendTestOttSuccess: false
    };
};

const sendTestOttFailureReducerHandler = (state) => {
    return {
        ...state,
        isSendTestOttSuccess: false
    };
};

/**
 * TODO: Create ott campaign
 */

const createOttCampaignReducerHandler = (state) => {
    return {
        ...state,
        isCreateOttPhishingCampaignSuccess: true
    };
};

const createOttCampaignResetReducerHandler = (state) => {
    return {
        ...state,
        isCreateOttPhishingCampaignSuccess: false
    };
};

/**
 * TODO: Get templates ott phishing
 */

const getTemplatesOttPhishingReducerHandler = (state, payload) => {
    return {
        ...state,
        templatesOttPhishingData: payload
    };
};

const getTemplatesOttPhishingFailureReducerHandler = (state) => {
    return {
        ...state,
        templatesOttPhishingData: {}
    };
};

/**
 * TODO: Get phishing summary departments
 */

const getPhishingSummaryDepartments = (state, payload) => {
    return {
        ...state,
        summaryDepartments: {
            data: payload.departments,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

export default {
    // ? Send test email
    sendTestEmailReducerHandler,
    sendTestEmailFailureReducerHandler,
    sendTestEmailResetReducerHandler,

    // ? Get phishing email templates
    getPhishingEmailTemplatesReducerHandler,

    // ? Get phishing list notifications
    getPhishingListNotificationsReducerHandler,

    // ? List of Phishing
    getListPhishingCampaigns,

    // ? List of Targets
    getListTargets,

    // ? Scam email content
    getScamEmailContent,

    // ? Scam web content
    getScamWebContent,
    resetScamWebContent,

    // ? View phishing notification
    getViewPhishingNotification,

    // ? Summary data
    getPhishingSummary,

    // ? Timeline result data
    getPhishingTimelineResult,

    // ? Result candidate list data
    getPhishingResultCandidateList,

    // ? Stop campaign
    stopCampaign,

    // ? Delete campaigns
    deleteCampaigns,

    // ? Create phishing campaign
    createPhishingCampaignReducerHandler,
    createPhishingCampaignResetReducerHandler,

    // ? Get list bookmarks
    getListBookmarks,

    // ? Import phishing site
    importPhishingSiteReducerHandler,
    importPhishingSiteFailureReducerHandler,
    importPhishingSiteResetReducerHandler,

    // ? Get list sms brands
    getListSmsBrandsReducerHandler,

    // ? Send test sms
    sendTestSmsReducerHandler,
    sendTestSmsResetReducerHandler,
    sendTestSmsFailureReducerHandler,

    // ? Get list sms templates
    getListSmsTemplatesReducerHandler,
    getListSmsTemplatesFailureReducerHandler,

    // ? Create sms phishing campaign
    createSmsPhishingCampaignReducerHandler,
    createSmsPhishingCampaignResetReducerHandler,

    // ? Preview site
    previewSiteReducerHandler,
    previewSiteResetReducerHandler,

    // ? Get list category
    getListCategoryReducerHandler,
    getListCategoryFailureReducerHandler,

    // ? Get clone phishing details
    getClonePhishingDetailsReducerHandler,
    getClonePhishingDetailsFailureReducerHandler,

    // ? Send test ott
    sendTestOttReducerHandler,
    sendTestOttResetReducerHandler,
    sendTestOttFailureReducerHandler,

    // ? Create ott campaign
    createOttCampaignReducerHandler,
    createOttCampaignResetReducerHandler,

    // ? Get templates ott phishing
    getTemplatesOttPhishingReducerHandler,
    getTemplatesOttPhishingFailureReducerHandler,

    // ? Get phishing summary departments
    getPhishingSummaryDepartments
};
