import ExportServices from 'api/exports/training';
import { call, takeLatest, put } from 'redux-saga/effects';
import types from './actionTypes';
import {
    exportTrainingParticipantsPDFSuccess,
    exportTrainingParticipantsPDFFailure,
    exportTrainingParticipantsSheetSuccess,
    exportTrainingParticipantsSheetFailure,
    exportTrainingInprogressPDFSuccess,
    exportTrainingInprogressPDFFailure,
    exportTrainingInprogressSheetSuccess,
    exportTrainingInprogressSheetFailure,
    exportTrainingResultPDFSuccess,
    exportTrainingResultPDFFailure,
    exportTrainingResultSheetSuccess,
    exportTrainingResultSheetFailure,
    exportTrainingIndividualPDFSuccess,
    exportTrainingIndividualPDFFailure,
    exportTrainingIndividualSheetSuccess,
    exportTrainingIndividualSheetFailure
} from './actions';

/**
 * TODO: Export training participants
 */

function* exportTrainingParticipantsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingParticipantsPDF, payload);
        yield put(exportTrainingParticipantsPDFSuccess(response));
    } catch (errors) {
        yield put(exportTrainingParticipantsPDFFailure(errors));
    }
}

function* exportTrainingParticipantsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingParticipantsSheet, payload);
        yield put(exportTrainingParticipantsSheetSuccess(response));
    } catch (errors) {
        yield put(exportTrainingParticipantsSheetFailure(errors));
    }
}

/**
 * TODO: Export training in-progress
 */

function* exportTrainingInprogressPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingInprogressPDF, payload);
        yield put(exportTrainingInprogressPDFSuccess(response));
    } catch (errors) {
        yield put(exportTrainingInprogressPDFFailure(errors));
    }
}

function* exportTrainingInprogressSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingInprogressSheet, payload);
        yield put(exportTrainingInprogressSheetSuccess(response));
    } catch (errors) {
        yield put(exportTrainingInprogressSheetFailure(errors));
    }
}

/**
 * TODO: Export training result
 */

function* exportTrainingResultPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingResultPDF, payload);
        yield put(exportTrainingResultPDFSuccess(response));
    } catch (errors) {
        yield put(exportTrainingResultPDFFailure(errors));
    }
}

function* exportTrainingResultSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingResultSheet, payload);
        yield put(exportTrainingResultSheetSuccess(response));
    } catch (errors) {
        yield put(exportTrainingResultSheetFailure(errors));
    }
}

/**
 * TODO: Export training individual result
 */

function* exportTrainingIndividualPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingIndividualPDF, payload);
        yield put(exportTrainingIndividualPDFSuccess(response));
    } catch (errors) {
        yield put(exportTrainingIndividualPDFFailure(errors));
    }
}

function* exportTrainingIndividualSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportTrainingIndividualSheet, payload);
        yield put(exportTrainingIndividualSheetSuccess(response));
    } catch (errors) {
        yield put(exportTrainingIndividualSheetFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

export default function* watchExportTrainingSaga() {
    yield takeLatest(
        types.EXPORT_TRAINING_PARTICIPANTS_PDF_REQUEST,
        exportTrainingParticipantsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_PARTICIPANTS_SHEET_REQUEST,
        exportTrainingParticipantsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_INPROGRESS_PDF_REQUEST,
        exportTrainingInprogressPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_INPROGRESS_SHEET_REQUEST,
        exportTrainingInprogressSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_RESULT_PDF_REQUEST,
        exportTrainingResultPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_RESULT_SHEET_REQUEST,
        exportTrainingResultSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_INDIVIDUAL_PDF_REQUEST,
        exportTrainingIndividualPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_TRAINING_INDIVIDUAL_SHEET_REQUEST,
        exportTrainingIndividualSheetRequestHandler
    );
}
