import initState from './initialState';
import actionTypes from './actionTypes';
import reducerHandler from './reducerHandler';

const summaryReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_SUCCESS:
            return reducerHandler.getTotalCandidatesAndDepartments(state, action);
        case actionTypes.GET_SECURITY_AWARENESS_SCORE_SUCCESS:
            return reducerHandler.getSecurityAwarenessScore(state, action);
        case actionTypes.GET_PHISHING_RESULT_SUCCESS:
            return reducerHandler.getSummaryPhishingResult(state, action);
        case actionTypes.SECURITY_AWARENESS_RATING_BY_DEPARTMENT_SUCCESS:
            return reducerHandler.getSARByDepartment(state, action);
        case actionTypes.DETAILED_SECURITY_AWARENESS_RESULTS_SUCCESS:
            return reducerHandler.getSAROfParticipant(state, action);
        default:
            return state;
    }
};

export default summaryReducer;
