import httpClient from 'api';
import SIMULATION_CONSTANTS from 'api/constants/simulation';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Send Test Email
 */

const sendTestEmail = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.SEND_TEST_EMAIL, data);
    return response;
});

/**
 * TODO: Get Phishing Email Templates
 */

const getPhishingEmailTemplates = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_PHISHING_EMAIL_TEMPLATES, {
        params
    });
    return response;
});

/**
 * TODO: Get list phishing campaign
 */

const getListPhishingCampaign = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN, {
        params
    });
    return response;
});

/**
 * TODO: Get Phishing List Notifications
 */

const getPhishingListNotifications = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_PHISHING_LIST_NOTIFICATIONS, {
        params
    });
    return response;
});

/**
 * TODO: Get target list
 */

const getListTarget = catchAsync(async ({ campaignCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/targets`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get scam email content
 */

const getScamEmailContent = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/scam/email`
    );
    return response;
});

/**
 * TODO: Get scam web Content
 */

const getScamWebContent = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/scam/web`
    );
    return response;
});

/**
 * TODO: Get view phishing notifications
 */

const getViewPhishingNotification = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/notification`
    );
    return response;
});

/**
 * TODO: Get phishing results candidate
 */

const getPhishingResultCandidate = catchAsync(async ({ campaignCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/results`,
        { params: rest }
    );
    return response;
});

/**
 * TODO: Get phishing campaign summary
 */

const getPhishingCampaignSummary = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/summary`
    );
    return response;
});

/**
 * TODO: Get phishing timeline result
 */

const getPhishingTimelineResult = catchAsync(async ({ campaignCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/timelines`,
        { params: rest }
    );
    return response;
});

/**
 * TODO: Stop Campaign
 */

const putStopCampaign = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.put(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${campaignCode}/stop`
    );
    return response;
});

/**
 * TODO: Delete Campaigns
 */

const deleteCampaigns = catchAsync(async (params) => {
    const response = await httpClient.delete(`${SIMULATION_CONSTANTS.DELETE_CAMPAIGNS}`, {
        params
    });
    return response;
});

/**
 * TODO: Create phishing campaign
 */

const createPhishingCampaign = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.CREATE_PHISHING_CAMPAIGN, data);

    return response;
});

/**
 * TODO: Get list bookmarks
 */

const getListBookmarks = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_LIST_BOOKMARKS, {
        params
    });
    return response;
});

/**
 * TODO: Import phishing site
 */

const importPhishingSite = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.IMPORT_PHISHING_SITE, data);
    return response;
});

/**
 * TODO: Get list sms brands
 */

const getListSmsBrands = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_LIST_SMS_BRANDS, {
        params
    });
    return response;
});

/**
 * TODO: Send test sms
 */

const sendTestSms = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.SEND_TEST_SMS, data);
    return response;
});

/**
 * TODO: Get list sms templates
 */

const getListSmsTemplates = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_LIST_SMS_TEMPLATES, {
        params
    });
    return response;
});

/**
 * TODO: Create sms campaign
 */

const createSmsCampaign = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.CREATE_SMS_CAMPAIGN, data);
    return response;
});

/**
 * TODO: Preview site
 */

const previewSite = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.PREVIEW_SITE, data);
    return response;
});

/**
 * TODO: Get list category
 */

const getListCategory = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_LIST_CATEGORY, {
        params
    });
    return response;
});

/**
 * TODO: Get clone phishing details
 */

const getClonePhishingDetails = catchAsync(async ({ campaignCode = '' }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.GET_CLONE_PHISHING_DETAILS}/${campaignCode}/clone`
    );

    return response;
});

/**
 * TODO: Send test ott
 */

const sendTestOtt = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.SEND_TEST_OTT, data);
    return response;
});

/**
 * TODO: Create ott campagin
 */

const createOttCampagin = catchAsync(async (data) => {
    const response = await httpClient.post(SIMULATION_CONSTANTS.CREATE_OTT_CAMPAIGN, data);

    return response;
});

/**
 * TODO: Get templates ott phishing
 */

const getTemplatesOttPhishing = catchAsync(async (params) => {
    const response = await httpClient.get(SIMULATION_CONSTANTS.GET_TEMPLATES_OTT_PHISHING, {
        params
    });
    return response;
});

/**
 * TODO: Get phishing summary departments
 */

const getPhishingSummaryDepartments = catchAsync(async ({ phishingCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${SIMULATION_CONSTANTS.LIST_PHISHING_CAMPAIGN}/${phishingCode}/${SIMULATION_CONSTANTS.GET_PHISHING_SUMMARY_DEPARTMENTS}`,
        { params: rest }
    );

    return response;
});

const SimulationServices = {
    sendTestEmail,
    getPhishingEmailTemplates,
    getPhishingListNotifications,
    getListPhishingCampaign,
    getListTarget,
    putStopCampaign,
    deleteCampaigns,
    getScamEmailContent,
    getScamWebContent,
    getViewPhishingNotification,
    getPhishingResultCandidate,
    getPhishingTimelineResult,
    getPhishingCampaignSummary,
    createPhishingCampaign,
    getListBookmarks,
    importPhishingSite,
    getListSmsBrands,
    sendTestSms,
    getListSmsTemplates,
    createSmsCampaign,
    previewSite,
    getListCategory,
    getClonePhishingDetails,
    sendTestOtt,
    createOttCampagin,
    getTemplatesOttPhishing,
    getPhishingSummaryDepartments
};

export default SimulationServices;
