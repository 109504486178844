import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';
import DashboardServices from 'api/dashboard';
import {
    getDashboardAssessmentSuccess,
    getDashboardAssessmentFailure,
    getDashboardTrainingSuccess,
    getDashboardTrainingFailure,
    getDashboardPhishingSuccess,
    getDashboardPhishingFailure,
    getDashboardLastActivitySuccess,
    getDashboardLastActivityFailure,
    getDashboardCreditParticipantSuccess,
    getDashboardCreditParticipantFailure
} from './action';

/**
 * TODO: Get dashboard assessment
 */

function* getDashboardAssessmentRequestHandler({ payload }) {
    try {
        const response = yield call(DashboardServices.getDashboardAssessment, payload);
        yield put(getDashboardAssessmentSuccess(response.data));
    } catch (errors) {
        yield put(getDashboardAssessmentFailure(errors));
    }
}

/**
 * TODO: Get dashboard training
 */

function* getDashboardTrainingRequestHandler({ payload }) {
    try {
        const response = yield call(DashboardServices.getDashboardTraining, payload);
        yield put(getDashboardTrainingSuccess(response.data));
    } catch (errors) {
        yield put(getDashboardTrainingFailure(errors));
    }
}

/**
 * TODO: Get dashboard phishing
 */

function* getDashboardPhishingRequestHandler({ payload }) {
    try {
        const response = yield call(DashboardServices.getDashboardPhishing, payload);
        yield put(getDashboardPhishingSuccess(response.data));
    } catch (errors) {
        yield put(getDashboardPhishingFailure(errors));
    }
}

/**
 * TODO: Get dashboard last activity
 */

function* getDashboardLastActivityRequestHandler({ payload }) {
    try {
        const response = yield call(DashboardServices.getDashboardLastActivity, payload);
        yield put(getDashboardLastActivitySuccess(response.data));
    } catch (errors) {
        yield put(getDashboardLastActivityFailure(errors));
    }
}

/**
 * TODO: Get dashboard credit participant
 */

function* getDashboardCreditParticipantRequestHandler({ payload }) {
    try {
        const response = yield call(DashboardServices.getDashboardCreditAndParticipant, payload);
        yield put(getDashboardCreditParticipantSuccess(response.data));
    } catch (errors) {
        yield put(getDashboardCreditParticipantFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

function* watchDashboardSaga() {
    yield takeLatest(types.GET_DASHBOARD_ASSESSMENT_REQUEST, getDashboardAssessmentRequestHandler);
    yield takeLatest(types.GET_DASHBOARD_TRAINING_REQUEST, getDashboardTrainingRequestHandler);
    yield takeLatest(types.GET_DASHBOARD_PHISHING_REQUEST, getDashboardPhishingRequestHandler);
    yield takeLatest(
        types.GET_DASHBOARD_LAST_ACTIVITY_REQUEST,
        getDashboardLastActivityRequestHandler
    );
    yield takeLatest(
        types.GET_DASHBOARD_CREDIT_PARTICIPANT_REQUEST,
        getDashboardCreditParticipantRequestHandler
    );
}

export default function* dashboardSaga() {
    yield fork(watchDashboardSaga);
}
