const actionTypes = {
    // * Export assessment participants
    EXPORT_ASSESSMENT_PARTICIPANTS_PDF_REQUEST: 'EXPORT_ASSESSMENT_PARTICIPANTS_PDF_REQUEST',
    EXPORT_ASSESSMENT_PARTICIPANTS_PDF_SUCCESS: 'EXPORT_ASSESSMENT_PARTICIPANTS_PDF_SUCCESS',
    EXPORT_ASSESSMENT_PARTICIPANTS_PDF_FAILURE: 'EXPORT_ASSESSMENT_PARTICIPANTS_PDF_FAILURE',

    EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_REQUEST: 'EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_REQUEST',
    EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_SUCCESS: 'EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_SUCCESS',
    EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_FAILURE: 'EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_FAILURE',

    // * Export assessment questions
    EXPORT_ASSESSMENT_QUESTIONS_PDF_REQUEST: 'EXPORT_ASSESSMENT_QUESTIONS_PDF_REQUEST',
    EXPORT_ASSESSMENT_QUESTIONS_PDF_SUCCESS: 'EXPORT_ASSESSMENT_QUESTIONS_PDF_SUCCESS',
    EXPORT_ASSESSMENT_QUESTIONS_PDF_FAILURE: 'EXPORT_ASSESSMENT_QUESTIONS_PDF_FAILURE',

    EXPORT_ASSESSMENT_QUESTIONS_SHEET_REQUEST: 'EXPORT_ASSESSMENT_QUESTIONS_SHEET_REQUEST',
    EXPORT_ASSESSMENT_QUESTIONS_SHEET_SUCCESS: 'EXPORT_ASSESSMENT_QUESTIONS_SHEET_SUCCESS',
    EXPORT_ASSESSMENT_QUESTIONS_SHEET_FAILURE: 'EXPORT_ASSESSMENT_QUESTIONS_SHEET_FAILURE',

    // * Export assessment in-progress
    EXPORT_ASSESSMENT_INPROGRESS_PDF_REQUEST: 'EXPORT_ASSESSMENT_INPROGRESS_PDF_REQUEST',
    EXPORT_ASSESSMENT_INPROGRESS_PDF_SUCCESS: 'EXPORT_ASSESSMENT_INPROGRESS_PDF_SUCCESS',
    EXPORT_ASSESSMENT_INPROGRESS_PDF_FAILURE: 'EXPORT_ASSESSMENT_INPROGRESS_PDF_FAILURE',

    EXPORT_ASSESSMENT_INPROGRESS_SHEET_REQUEST: 'EXPORT_ASSESSMENT_INPROGRESS_SHEET_REQUEST',
    EXPORT_ASSESSMENT_INPROGRESS_SHEET_SUCCESS: 'EXPORT_ASSESSMENT_INPROGRESS_SHEET_SUCCESS',
    EXPORT_ASSESSMENT_INPROGRESS_SHEET_FAILURE: 'EXPORT_ASSESSMENT_INPROGRESS_SHEET_FAILURE',

    // * Export assessment result
    EXPORT_ASSESSMENT_RESULT_PDF_REQUEST: 'EXPORT_ASSESSMENT_RESULT_PDF_REQUEST',
    EXPORT_ASSESSMENT_RESULT_PDF_SUCCESS: 'EXPORT_ASSESSMENT_RESULT_PDF_SUCCESS',
    EXPORT_ASSESSMENT_RESULT_PDF_FAILURE: 'EXPORT_ASSESSMENT_RESULT_PDF_FAILURE',

    EXPORT_ASSESSMENT_RESULT_SHEET_REQUEST: 'EXPORT_ASSESSMENT_RESULT_SHEET_REQUEST',
    EXPORT_ASSESSMENT_RESULT_SHEET_SUCCESS: 'EXPORT_ASSESSMENT_RESULT_SHEET_SUCCESS',
    EXPORT_ASSESSMENT_RESULT_SHEET_FAILURE: 'EXPORT_ASSESSMENT_RESULT_SHEET_FAILURE',

    // * Export assessment individual result
    EXPORT_ASSESSMENT_INDIVIDUAL_PDF_REQUEST: 'EXPORT_ASSESSMENT_INDIVIDUAL_PDF_REQUEST',
    EXPORT_ASSESSMENT_INDIVIDUAL_PDF_SUCCESS: 'EXPORT_ASSESSMENT_INDIVIDUAL_PDF_SUCCESS',
    EXPORT_ASSESSMENT_INDIVIDUAL_PDF_FAILURE: 'EXPORT_ASSESSMENT_INDIVIDUAL_PDF_FAILURE',

    EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_REQUEST: 'EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_REQUEST',
    EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_SUCCESS: 'EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_SUCCESS',
    EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_FAILURE: 'EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_FAILURE'
};

export default actionTypes;
