import types from './actionTypes';
import initState from './reducer';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ~ Get phishing summary
        case types.GET_STATISTICS_PHISHING_SUMMARY_SUCCESS:
            return reducerHandler.getStatisticsSummaryPhishingReducerHandler(state, action.payload);

        // ~ Get phishing rating by months
        case types.GET_STATISTICS_PHISHING_RATING_BY_MONTHS_SUCCESS:
            return reducerHandler.getStatisticsRatingByMonthsPhishingReducerHandler(
                state,
                action.payload
            );

        // ~ Get phishing result
        case types.GET_STATISTICS_PHISHING_RESULT_SUCCESS:
            return reducerHandler.getStatisticsResultPhishingReducerHandler(state, action.payload);

        // ~ Get top phished by department
        case types.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_SUCCESS:
            return reducerHandler.getStatisticsTopPhishedByDepartmentReducerHandler(
                state,
                action.payload
            );

        // ~ Get top clicked phishing template
        case types.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_SUCCESS:
            return reducerHandler.getStatisticsTopClickedPhishingTemplateReducerHandler(
                state,
                action.payload
            );

        // ~ Get phishing summary total
        case types.GET_STATISTICS_PHISHING_SUMMARY_TOTAL_SUCCESS:
            return reducerHandler.getStatisticsSummaryTotalPhishingReducerHandler(
                state,
                action.payload
            );

        // ~ Get details phishing result
        case types.GET_STATISTICS_DETAILS_PHISHING_RESULT_SUCCESS:
            return reducerHandler.getStatisticsDetailsPhishingResultReducerHandler(
                state,
                action.payload
            );

        // ~ Get statistics list targets unphished
        case types.GET_STATISTICS_LIST_TARGETS_UNPHISHED_SUCCESS:
            return reducerHandler.getStatisticsListTargetsUnphishedReducerHandler(
                state,
                action.payload
            );

        default:
            return state;
    }
};

export default reducer;
