import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ? Get list of departments
        case types.GET_DEPARTMENT_LIST_SUCCESS:
            return reducerHandler.getDepartmentListReducerHandler(state, action.payload);

        // ? Get list of departments for sms phishing
        case types.GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_SUCCESS:
            return reducerHandler.getDepartmentListForSmsPhishingReducerHandler(
                state,
                action.payload
            );

        // ? Toggle breadcrumbs
        case types.TOGGLE_BREADCRUMBS:
            return reducerHandler.toggleBreadcrumbsReducerHandler(state, action.payload);

        default:
            return state;
    }
};

export default reducer;
