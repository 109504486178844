const authActionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGIN_RESET: 'LOGIN_RESET',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    REGISTER_RESET: 'REGISTER_RESET',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_RESET: 'FORGOT_PASSWORD_RESET',

    FORGOT_PASSWORD_RESEND_REQUEST: 'FORGOT_PASSWORD_RESEND_REQUEST',
    FORGOT_PASSWORD_RESEND_SUCCESS: 'FORGOT_PASSWORD_RESEND_SUCCESS',
    FORGOT_PASSWORD_RESEND_FAILURE: 'FORGOT_PASSWORD_RESEND_FAILURE',
    FORGOT_PASSWORD_RESEND_RESET: 'FORGOT_PASSWORD_RESEND_RESET',

    FORGOT_PASSWORD_VERIFY_CODE_REQUEST: 'FORGOT_PASSWORD_VERIFY_CODE_REQUEST',
    FORGOT_PASSWORD_VERIFY_CODE_SUCCESS: 'FORGOT_PASSWORD_VERIFY_CODE_SUCCESS',
    FORGOT_PASSWORD_VERIFY_CODE_FAILURE: 'FORGOT_PASSWORD_VERIFY_CODE_FAILURE',
    FORGOT_PASSWORD_VERIFY_CODE_RESET: 'FORGOT_PASSWORD_VERIFY_CODE_RESET',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_RESET: 'RESET_PASSWORD_RESET',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    RESET_AUTH_STATE: 'RESET_AUTH_STATE',

    VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',
    VERIFY_OTP_RESET: 'VERIFY_OTP_RESET',

    RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',
    RESEND_OTP_RESET: 'RESEND_OTP_RESET'
};

export default authActionTypes;
