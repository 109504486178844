import { store } from 'index';
import reducer from './reducer';
import saga from './saga';

const key = 'simulation';

export const useSimulationSlice = () => {
    store.injectReducer(key, reducer);
    store.injectSaga(key, saga);
};
