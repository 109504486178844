const initialState = {
    listCourseData: {
        courses: []
    },
    isCreateTrainingSuccess: false,
    listTrainings: {
        data: [],
        pagination: {}
    },
    listTrainingsExpandRow: {},
    listCandidatesByTraining: {
        data: [],
        pagination: {}
    },
    trainingSummary: {},
    resultCourseSummary: {},
    summaryCandidateList: {
        data: [],
        pagination: {}
    },
    summaryDepartments: {
        data: [],
        pagination: {}
    },
    candidateTrainingDetailStats: {
        accessInfo: {},
        percentComplete: 0,
        modules: []
    },
    startTrainingSuccessMessage: '',
    stopTrainingSuccessMessage: '',
    rebuildTrainingSuccessMessage: '',
    deleteTrainingsSuccessMessage: '',
    trainingDetails: {},
    trainingCloneDetails: {},
    isUpdateTrainingSuccess: false
};

export default initialState;
