import SurveyServices from 'api/survey';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';

import {
    // ? Get list survey
    getListSurveySuccess,
    getListSurveyFailure,

    // * Start Survey
    startSurveySuccess,
    startSurveyFailure,
    // * Stop Survey
    stopSurveySuccess,
    stopSurveyFailure,
    // * Rebuild Survey
    rebuildSurveySuccess,
    rebuildSurveyFailure,

    // * List survey templates
    getListSurveyTemplatesSuccess,
    getListSurveyTemplatesFailure,
    deleteSurveysSuccess,
    deleteSurveysFailure,
    getListCandidatesBySurveySuccess,
    getListCandidatesBySurveyFailure,
    getListQuestionsBySurveySuccess,
    getListQuestionsBySurveyFailure,
    createSurveySuccess,
    createSurveyFailure,

    // * Get survey details
    getSurveyDetailsSuccess,
    getSurveyDetailsFailure,

    // * Edit survey
    editSurveySuccess,
    editSurveyFailure
} from './actions';

import { transformSurveyList, transformSurveyDetails } from 'transformer/survey';

/**
 * TODO: Get list survey
 */

function* getListSurveyRequestSaga({ payload, permissions }) {
    try {
        const response = yield call(SurveyServices.getListSurvey, payload);
        const transformedList = transformSurveyList(response?.data?.surveys, permissions);
        yield put(getListSurveySuccess({ ...response.data, surveys: transformedList }));
    } catch (error) {
        yield put(getListSurveyFailure(error));
    }
}

/**
 * TODO: Start survey
 */

function* startSurvey({ payload }) {
    try {
        const response = yield call(SurveyServices.putStartSurvey, payload);
        yield put(startSurveySuccess(response.data));
    } catch (errors) {
        yield put(startSurveyFailure(errors));
    }
}

/**
 * TODO: Stop survey
 */

function* stopSurvey({ payload }) {
    try {
        const response = yield call(SurveyServices.putStopSurvey, payload);
        yield put(stopSurveySuccess(response.data));
    } catch (errors) {
        yield put(stopSurveyFailure(errors));
    }
}
/**
 * TODO: Rebuild survey
 */

function* rebuildSurvey({ payload }) {
    try {
        const response = yield call(SurveyServices.putRebuildSurvey, payload);
        yield put(rebuildSurveySuccess(response.data));
    } catch (errors) {
        yield put(rebuildSurveyFailure(errors));
    }
}

/**
 * TODO: List survey templates
 */

function* getListSurveyTemplatesRequestSaga({ payload }) {
    try {
        const response = yield call(SurveyServices.getListSurveyTemplates, payload);
        yield put(getListSurveyTemplatesSuccess(response.data));
    } catch (error) {
        yield put(getListSurveyTemplatesFailure(error));
    }
}

/**
 * TODO: Delete surveys
 */

function* deleteSurveys({ payload }) {
    try {
        const response = yield call(SurveyServices.deleteSurveys, payload);
        yield put(deleteSurveysSuccess(response));
    } catch (errors) {
        yield put(deleteSurveysFailure(errors));
    }
}

/**
 * TODO: Get list candidates by survey request handler
 */

function* getListCandidatesBySurveyRequestHandler({ payload }) {
    try {
        const response = yield call(SurveyServices.getListCandidateBySurvey, payload);
        yield put(getListCandidatesBySurveySuccess(response.data));
    } catch (errors) {
        yield put(getListCandidatesBySurveyFailure(errors));
    }
}

/**
 * TODO: Get list questions by survey request handler
 */

function* getListQuestionsBySurveyRequestHandler({ payload }) {
    try {
        const response = yield call(SurveyServices.getListQuestionBySurvey, payload);
        yield put(getListQuestionsBySurveySuccess(response.data));
    } catch (errors) {
        yield put(getListQuestionsBySurveyFailure(errors));
    }
}

/**
 * TODO: Create survey request handler
 */

function* createSurveyRequestHandler({ payload }) {
    try {
        const response = yield call(SurveyServices.createSurvey, payload);
        yield put(createSurveySuccess(response.data));
    } catch (errors) {
        yield put(createSurveyFailure(errors));
    }
}

/**
 * TODO: Get survey details request handler
 */

function* getSurveyDetailsRequestHandler({ payload }) {
    try {
        const response = yield call(SurveyServices.getSurveyDetails, payload);
        yield put(getSurveyDetailsSuccess(transformSurveyDetails(response.data)));
    } catch (errors) {
        yield put(getSurveyDetailsFailure(errors));
    }
}

/**
 * TODO: Edit survey request handler
 */

function* editSurveyRequestHandler({ payload }) {
    try {
        const response = yield call(SurveyServices.editSurvey, payload);
        yield put(editSurveySuccess(response.data));
    } catch (errors) {
        yield put(editSurveyFailure(errors));
    }
}

/**
 * TODO: Watchers
 */

function* watchGetListSurveyRequest() {
    yield takeLatest(types.GET_LIST_SURVEY_REQUEST, getListSurveyRequestSaga);
    yield takeLatest(types.START_SURVEY_REQUEST, startSurvey);
    yield takeLatest(types.STOP_SURVEY_REQUEST, stopSurvey);
    yield takeLatest(types.REBUILD_SURVEY_REQUEST, rebuildSurvey);
    yield takeLatest(types.GET_LIST_SURVEY_TEMPLATES_REQUEST, getListSurveyTemplatesRequestSaga);
    yield takeLatest(types.DELETE_SURVEYS_REQUEST, deleteSurveys);
    yield takeLatest(
        types.GET_LIST_CANDIDATES_BY_SURVEY_REQUEST,
        getListCandidatesBySurveyRequestHandler
    );
    yield takeLatest(
        types.GET_LIST_QUESTIONS_BY_SURVEY_REQUEST,
        getListQuestionsBySurveyRequestHandler
    );

    yield takeLatest(types.CREATE_SURVEY_REQUEST, createSurveyRequestHandler);
    yield takeLatest(types.GET_SURVEY_DETAILS_REQUEST, getSurveyDetailsRequestHandler);
    yield takeLatest(types.EDIT_SURVEY_REQUEST, editSurveyRequestHandler);
}

export default function* surveySaga() {
    yield fork(watchGetListSurveyRequest);
}
