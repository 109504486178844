import i18next from 'i18next';

import {
    transformCandidateResultData,
    transformDeleteSuccessMessage,
    transformQuestionsListWithID,
    transformSummaryParticipants,
    transformSummaryStatistic
} from 'transformer/assessment';
import { transformCandidatesListWithID } from 'transformer/candidate';
import stringUtils from 'utils/string-utils';

/**
 * TODO: Transform Assessments List data
 */

const getListAssessments = (state, payload) => {
    return {
        ...state,
        startAssessmentSuccessMessage: '',
        stopAssessmentSuccessMessage: '',
        rebuildAssessmentSuccessMessage: '',
        deleteAssessmentsSuccessMessage: '',
        listAssessments: {
            data: payload.assessments,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Get list questions reducer handler
 */

const getListQuestionsReducerHandler = (state, payload) => {
    return { ...state, listQuestionsData: payload };
};

/**
 * TODO: Get list templates reducer handler
 */

const getListTemplatesReducerHandler = (state, payload) => {
    return { ...state, listTemplatesData: payload };
};

/**
 * TODO: Create assessment reducer handler
 */

const createAssessmentReducerHandler = (state) => {
    return { ...state, isCreateAssessmentSuccess: true };
};

const createAssessmentResetReducerHandler = (state) => {
    return { ...state, isCreateAssessmentSuccess: false };
};

/**
 * TODO: Edit assessment reducer handler
 */

const editAssessmentReducerHandler = (state) => {
    return { ...state, isEditAssessmentSuccess: true };
};

const editAssessmentResetReducerHandler = (state) => {
    return { ...state, isEditAssessmentSuccess: false, assessmentDetailsData: {} };
};

/**
 * TODO: Get assessment details
 */

const getAssessmentDetailsReducerHandler = (state, payload) => {
    return { ...state, assessmentDetailsData: payload };
};

const getAssessmentDetailsResetReducerHandler = (state) => {
    return { ...state, assessmentDetailsData: {} };
};

/**
 * TODO: Get assessment clone details
 */

const getAssessmentCloneDetailsReducerHandler = (state, payload) => {
    return { ...state, assessmentCloneDetailsData: payload };
};

const getAssessmentCloneDetailsResetReducerHandler = (state) => {
    return { ...state, assessmentCloneDetailsData: {} };
};

/**
 * TODO: Get list questions by template ids reducer handler
 */

const getListQuestionsByTemplateIdsReducerHandler = (state, payload) => {
    return { ...state, listQuestionsByTemplateIdsData: payload };
};

/**
 * TODO: Get list exam tags reducer handler
 */

const getListExamTagsReducerHandler = (state, payload) => {
    return { ...state, listExamTagsData: payload };
};

/**
 * TODO: Transform Candidates List By Assessment data
 */

const getListCandidatesByAssessment = (state, payload) => {
    return {
        ...state,
        listCandidatesByAssessment: {
            data: transformCandidatesListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform Question List By Assessment data
 */

const getListQuestionsByAssessment = (state, payload) => {
    return {
        ...state,
        listQuestionsByAssessment: {
            data: transformQuestionsListWithID(
                payload.questions,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform summary participants
 */

const getAssessmentSummaryParticipants = (state, payload) => {
    return {
        ...state,
        summaryParticipants: transformSummaryParticipants(payload.assessment)
    };
};

/**
 * TODO: Transform summary statistic
 */

const getAssessmentSummaryStatistic = (state, payload) => {
    return {
        ...state,
        summaryStatistic: transformSummaryStatistic(payload.assessment)
    };
};

/**
 * TODO: Transform summary question list
 */

const getAssessmentSummaryQuestionList = (state, payload) => {
    return {
        ...state,
        listSummaryQuestion: {
            data: transformQuestionsListWithID(
                payload.questions,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform summary candidate list
 */

const getAssessmentSummaryCandidateList = (state, payload) => {
    return {
        ...state,
        listSummaryCandidates: {
            data: transformCandidatesListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage,
                payload?.meta.totalRecord,
                payload?.meta?.sortDirection
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform candidate detail
 */

const getAssessmentCandidateDetail = (state, payload) => {
    return {
        ...state,
        candidateDetail: {
            data: payload.result,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform candidate result
 */

const getAssessmentCandidateResult = (state, payload) => {
    return {
        ...state,
        candidateResult: transformCandidateResultData(payload.results)
    };
};

/**
 * TODO: Transform detail question result
 */

const getDetailQuestionResult = (state, payload) => {
    return {
        ...state,
        detailQuestionResult: payload.results.answers
    };
};

const getDetailQuestionResultReset = (state) => {
    return {
        ...state,
        detailQuestionResult: []
    };
};

/**
 * TODO: start assessment
 */

const startAssessment = (state) => {
    return {
        ...state,
        startAssessmentSuccessMessage: i18next.t('success:assessment.startedSuccess')
    };
};

/**
 * TODO: stop assessment
 */

const stopAssessment = (state) => {
    return {
        ...state,
        stopAssessmentSuccessMessage: i18next.t('success:assessment.stoppedSuccess')
    };
};

/**
 * TODO: rebuild assessment
 */

const rebuildAssessment = (state) => {
    return {
        ...state,
        rebuildAssessmentSuccessMessage: i18next.t('success:assessment.rebuildSuccess')
    };
};

/**
 * TODO: delete assessments
 */

const deleteAssessments = (state, payload) => {
    const counter = transformDeleteSuccessMessage(payload.message);
    return {
        ...state,
        deleteAssessmentsSuccessMessage: i18next.t('success:assessment.deleteSuccess', {
            key: +counter,
            label: stringUtils.pluralNounCheck(
                +counter,
                i18next.t('assessment:assessmentLabel'),
                's'
            )
        })
    };
};

/**
 * TODO: Get language supported
 */

const getLanguageSupported = (state, payload) => {
    return {
        ...state,
        languageSupported: payload?.languages || []
    };
};

/**
 * TODO: Get assessment summary departments
 */

const getAssessmentSummaryDepartments = (state, payload) => {
    return {
        ...state,
        listSummaryDepartments: {
            data: payload.departments,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

const getAssessmentSummaryDepartmentsReset = (state) => {
    return {
        ...state,
        listSummaryDepartments: {
            data: [],
            pagination: {}
        }
    };
};

export default {
    getListAssessments,
    getListQuestionsReducerHandler,
    getListTemplatesReducerHandler,
    createAssessmentReducerHandler,
    createAssessmentResetReducerHandler,
    editAssessmentReducerHandler,
    editAssessmentResetReducerHandler,
    getAssessmentDetailsReducerHandler,
    getAssessmentDetailsResetReducerHandler,
    getAssessmentCloneDetailsReducerHandler,
    getAssessmentCloneDetailsResetReducerHandler,
    getListQuestionsByTemplateIdsReducerHandler,
    getListExamTagsReducerHandler,
    getListCandidatesByAssessment,
    getListQuestionsByAssessment,
    getAssessmentSummaryParticipants,
    getAssessmentSummaryStatistic,
    getAssessmentSummaryQuestionList,
    getAssessmentSummaryCandidateList,
    getAssessmentCandidateDetail,
    getAssessmentCandidateResult,
    getDetailQuestionResult,
    getDetailQuestionResultReset,
    startAssessment,
    stopAssessment,
    rebuildAssessment,
    deleteAssessments,
    getLanguageSupported,
    getAssessmentSummaryDepartments,
    getAssessmentSummaryDepartmentsReset
};
