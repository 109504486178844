import actionTypes from './actionTypes';

// ? Get total candidates and departments
const getTotalCandidatesAndDepartments = () => ({
    type: actionTypes.GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_REQUEST
});

const getTotalCandidatesAndDepartmentsSuccess = (payload) => ({
    type: actionTypes.GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_SUCCESS,
    payload
});

const getTotalCandidatesAndDepartmentsFailure = (errors) => ({
    type: actionTypes.GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_FAILURE,
    payload: errors
});

// ? Get security awareness score
const getSecurityAwarenessScore = () => ({
    type: actionTypes.GET_SECURITY_AWARENESS_SCORE_REQUEST
});

const getSecurityAwarenessScoreSuccess = (payload) => ({
    type: actionTypes.GET_SECURITY_AWARENESS_SCORE_SUCCESS,
    payload
});

const getSecurityAwarenessScoreFailure = (errors) => ({
    type: actionTypes.GET_SECURITY_AWARENESS_SCORE_FAILURE,
    payload: errors
});

// ? Get phishing result
const getPhishingResult = () => ({
    type: actionTypes.GET_PHISHING_RESULT_REQUEST
});

const getPhishingResultSuccess = (payload) => ({
    type: actionTypes.GET_PHISHING_RESULT_SUCCESS,
    payload
});

const getPhishingResultFailure = (errors) => ({
    type: actionTypes.GET_PHISHING_RESULT_FAILURE,
    payload: errors
});

// ** Security Awareness Rating By Department **  //
const getSecurityAwarenessRatingByDepartmentRequest = (data) => ({
    type: actionTypes.SECURITY_AWARENESS_RATING_BY_DEPARTMENT_REQUEST,
    payload: data
});

const getSecurityAwarenessRatingByDepartmentSuccess = (data) => ({
    type: actionTypes.SECURITY_AWARENESS_RATING_BY_DEPARTMENT_SUCCESS,
    payload: data
});

const getSecurityAwarenessRatingByDepartmentFailure = (errors) => ({
    type: actionTypes.SECURITY_AWARENESS_RATING_BY_DEPARTMENT_FAILURE,
    payload: errors
});

// **  Security Awareness Results Of Each Participant **  //
const getSecurityAwarenessResultsOfParticipantRequest = (data) => ({
    type: actionTypes.DETAILED_SECURITY_AWARENESS_RESULTS_REQUEST,
    payload: data
});

const getSecurityAwarenessResultsOfParticipantSuccess = (data) => ({
    type: actionTypes.DETAILED_SECURITY_AWARENESS_RESULTS_SUCCESS,
    payload: data
});

const getSecurityAwarenessResultsOfParticipantFailure = (errors) => ({
    type: actionTypes.DETAILED_SECURITY_AWARENESS_RESULTS_FAILURE,
    payload: errors
});

export default {
    getTotalCandidatesAndDepartments,
    getTotalCandidatesAndDepartmentsSuccess,
    getTotalCandidatesAndDepartmentsFailure,
    getSecurityAwarenessScore,
    getSecurityAwarenessScoreSuccess,
    getSecurityAwarenessScoreFailure,
    getSecurityAwarenessResultsOfParticipantRequest,
    getSecurityAwarenessResultsOfParticipantSuccess,
    getSecurityAwarenessResultsOfParticipantFailure,
    getSecurityAwarenessRatingByDepartmentRequest,
    getSecurityAwarenessRatingByDepartmentSuccess,
    getSecurityAwarenessRatingByDepartmentFailure,
    getPhishingResult,
    getPhishingResultSuccess,
    getPhishingResultFailure
};
