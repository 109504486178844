import loadable from '@loadable/component';

const SummaryStatistics = loadable(() => import('pages/statistics/summary'));
const AssessmentStatistics = loadable(() => import('pages/statistics/assessment'));
const TrainingStatistics = loadable(() => import('pages/statistics/training'));
const PhishingStatistics = loadable(() => import('pages/statistics/phishing'));

const statisticsRouteItems = [
    {
        value: 'statistics/summary',
        key: 'sat.manager.report.view',
        component: SummaryStatistics
    },
    {
        value: 'statistics/assessment',
        key: 'sat.manager.report.view',
        component: AssessmentStatistics
    },
    {
        value: 'statistics/training',
        key: 'sat.manager.report.view',
        component: TrainingStatistics
    },
    {
        value: 'statistics/simulation',
        key: 'sat.manager.report.view',
        component: PhishingStatistics
    }
];

export default statisticsRouteItems;
