import types from './actionTypes';

/**
 * TODO: Get list survey
 */

const getListSurveyRequest = (params, permissions) => ({
    type: types.GET_LIST_SURVEY_REQUEST,
    payload: params,
    permissions
});

const getListSurveySuccess = (data) => ({
    type: types.GET_LIST_SURVEY_SUCCESS,
    payload: data
});

const getListSurveyFailure = (error) => ({
    type: types.GET_LIST_SURVEY_FAILURE,
    payload: error
});

/**
 * TODO: Start survey
 */

const startSurvey = (payload) => ({
    type: types.START_SURVEY_REQUEST,
    payload
});

const startSurveySuccess = (payload) => ({
    type: types.START_SURVEY_SUCCESS,
    payload
});

const startSurveyFailure = (payload) => ({
    type: types.START_SURVEY_FAILURE,
    payload
});

/**
 * TODO: stop survey
 */

const stopSurvey = (payload) => ({
    type: types.STOP_SURVEY_REQUEST,
    payload
});

const stopSurveySuccess = (payload) => ({
    type: types.STOP_SURVEY_SUCCESS,
    payload
});

const stopSurveyFailure = (payload) => ({
    type: types.STOP_SURVEY_FAILURE,
    payload
});

/**
 * TODO: Rebuild survey
 */

const rebuildSurvey = (payload) => ({
    type: types.REBUILD_SURVEY_REQUEST,
    payload
});

const rebuildSurveySuccess = (payload) => ({
    type: types.REBUILD_SURVEY_SUCCESS,
    payload
});

const rebuildSurveyFailure = (payload) => ({
    type: types.REBUILD_SURVEY_FAILURE,
    payload
});

/**
 * TODO: List survey templates
 */

const getListSurveyTemplatesRequest = (params) => ({
    type: types.GET_LIST_SURVEY_TEMPLATES_REQUEST,
    payload: params
});

const getListSurveyTemplatesSuccess = (data) => ({
    type: types.GET_LIST_SURVEY_TEMPLATES_SUCCESS,
    payload: data
});

const getListSurveyTemplatesFailure = (error) => ({
    type: types.GET_LIST_SURVEY_TEMPLATES_FAILURE,
    payload: error
});

/**
 * TODO: Delete surveys
 */

const deleteSurveys = (payload) => ({
    type: types.DELETE_SURVEYS_REQUEST,
    payload
});

const deleteSurveysSuccess = (payload) => ({
    type: types.DELETE_SURVEYS_SUCCESS,
    payload
});

const deleteSurveysFailure = (payload) => ({
    type: types.DELETE_SURVEYS_FAILURE,
    payload
});

/**
 * TODO: Get list candidates by survey
 */

const getListCandidatesBySurvey = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_SURVEY_REQUEST,
    payload
});

const getListCandidatesBySurveySuccess = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_SURVEY_SUCCESS,
    payload
});

const getListCandidatesBySurveyFailure = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_SURVEY_FAILURE,
    payload
});

/**
 * TODO: Get list questions by survey
 */

const getListQuestionsBySurvey = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_SURVEY_REQUEST,
    payload
});

const getListQuestionsBySurveySuccess = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_SURVEY_SUCCESS,
    payload
});

const getListQuestionsBySurveyFailure = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_SURVEY_FAILURE,
    payload
});

/**
 * TODO: Create survey
 */

const createSurvey = (payload) => ({
    type: types.CREATE_SURVEY_REQUEST,
    payload
});

const createSurveySuccess = (payload) => ({
    type: types.CREATE_SURVEY_SUCCESS,
    payload
});

const createSurveyFailure = (payload) => ({
    type: types.CREATE_SURVEY_FAILURE,
    payload
});

const createSurveyReset = () => ({
    type: types.CREATE_SURVEY_RESET
});

/**
 * TODO: Get survey details
 */

const getSurveyDetails = (payload) => ({
    type: types.GET_SURVEY_DETAILS_REQUEST,
    payload
});

const getSurveyDetailsSuccess = (payload) => ({
    type: types.GET_SURVEY_DETAILS_SUCCESS,
    payload
});

const getSurveyDetailsFailure = (payload) => ({
    type: types.GET_SURVEY_DETAILS_FAILURE,
    payload
});

const getSurveyDetailsReset = () => ({
    type: types.GET_SURVEY_DETAILS_RESET
});

/**
 * TODO: Edit survey
 */

const editSurvey = (payload) => ({
    type: types.EDIT_SURVEY_REQUEST,
    payload
});

const editSurveySuccess = (payload) => ({
    type: types.EDIT_SURVEY_SUCCESS,
    payload
});

const editSurveyFailure = (payload) => ({
    type: types.EDIT_SURVEY_FAILURE,
    payload
});

const editSurveyReset = () => ({
    type: types.EDIT_SURVEY_RESET
});

export {
    // * Get list survey
    getListSurveyRequest,
    getListSurveySuccess,
    getListSurveyFailure,

    // * Start survey
    startSurvey,
    startSurveySuccess,
    startSurveyFailure,
    // * Stop survey
    stopSurvey,
    stopSurveySuccess,
    stopSurveyFailure,

    // * Rebuild survey
    rebuildSurvey,
    rebuildSurveySuccess,
    rebuildSurveyFailure,

    // * List survey templates
    getListSurveyTemplatesRequest,
    getListSurveyTemplatesSuccess,
    getListSurveyTemplatesFailure,

    // * Delete survey
    deleteSurveys,
    deleteSurveysSuccess,
    deleteSurveysFailure,
    getListCandidatesBySurvey,
    getListCandidatesBySurveySuccess,
    getListCandidatesBySurveyFailure,
    getListQuestionsBySurvey,
    getListQuestionsBySurveySuccess,
    getListQuestionsBySurveyFailure,

    // * Create survey
    createSurvey,
    createSurveySuccess,
    createSurveyFailure,
    createSurveyReset,

    // * Get survey details
    getSurveyDetails,
    getSurveyDetailsSuccess,
    getSurveyDetailsFailure,
    getSurveyDetailsReset,

    // * Edit survey
    editSurvey,
    editSurveySuccess,
    editSurveyFailure,
    editSurveyReset
};
