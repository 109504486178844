const initState = {
    listSurvey: {
        data: [],
        pagination: {}
    },
    startSurveySuccessMessage: '',
    stopSurveySuccessMessage: '',
    rebuildSurveySuccessMessage: '',
    deleteSurveysSuccessMessage: '',
    listSurveyTemplates: {
        data: [],
        pagination: {}
    },
    listCandidatesBySurvey: {
        data: [],
        pagination: {}
    },
    listQuestionsBySurvey: {
        data: [],
        pagination: {}
    },
    isCreateSurveySuccess: false,
    surveyDetailsData: {},
    isEditSurveySuccess: false
};

export default initState;
