import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';
import i18next from 'i18next';

// ~ Utils
import stringUtils from './string-utils';
import langUtils from './lang-utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('en');

export { dayjs };

const addZero = (m) => {
    if (m < 10) {
        m = '0' + m;
    }
    return m;
};

const formatDatePicker = (update, isShowHour = false) => {
    if (!update) return '';
    const date = new Date(update);

    const locale = langUtils.getCurrentLanguage();

    let formattedDate;
    const day = String(date.getDate()).padStart(2, '0');

    if (locale === 'en') {
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        formattedDate = ` ${day} ${month} ${year}`;
    }

    if (locale === 'vi') {
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        formattedDate = ` ${day}/${month}/${year}`;
    }

    if (isShowHour) {
        const hoursAndMinutes = `${date.getHours()}:${addZero(date.getMinutes())}`;
        return `${hoursAndMinutes} ${formattedDate}`;
    }

    return formattedDate;
};

const formatFilterDate = (date) => {
    if (date) {
        const temp = new Date(date);
        // Modify the UMT + 7 hours
        temp.setHours(temp.getHours() + 7);

        return temp.toISOString().split('T')[0];
    }
};

const getThisDayLastYear = () => {
    return new Date().setFullYear(new Date().getFullYear() - 1);
};

const getThisDaySixMonthsAgo = () => {
    return new Date().setMonth(new Date().getMonth() - 6);
};

const getThisDayThreeMonthsAgo = () => {
    return new Date().setMonth(new Date().getMonth() - 3);
};

const today = new Date();

const getDate30DaysAgo = () => {
    // const thirtyDaysAgo = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
    const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
    return thirtyDaysAgo;
};

const formatDateTime = (date) => {
    if (!date) return '';
    const temp = new Date(date);

    const day = String(temp.getDate()).padStart(2, '0');
    const month = temp.toLocaleString('default', { month: 'short' });
    const year = temp.getFullYear();
    const hoursAndMinutes = `${temp.getHours()}:${addZero(temp.getMinutes())}`;

    return `${hoursAndMinutes} ${day} ${month} ${year}`;
};

const formatDateTimeParam = (date, isHideHour = false) => {
    const value = date?.selectValue ? date.selectValue : date;
    const formattedDate = isHideHour
        ? dayjs(value).format('YYYY-MM-DD')
        : dayjs(value).format('YYYY/MM/DD HH:mm');
    return formattedDate;
};

/**
 * TODO: Get current timezone
 */

const convertTimezoneIdentifier = (identifier) => {
    const timezoneMapping = {
        'Asia/Saigon': 'Asia/Ho_Chi_Minh'
        // Add more mappings as needed
    };

    return timezoneMapping[identifier] || identifier;
};

const getCurrentTimezone = () => {
    const timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const convertedIdentifier = convertTimezoneIdentifier(timezoneIdentifier);
    return convertedIdentifier;
};

const getCurrentTime = () => {
    const now = new Date();
    return new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
    );
};

const formatExportFilterDate = (date) => {
    if (date) {
        const temp = new Date(date);
        // Modify the UMT + 7 hours
        temp.setHours(temp.getHours() + 7);

        return temp.toISOString().split('T')[0];
    }
};

const formatTableDateTime = (dateString, locale = i18next.language) => {
    if (dateString == null || dateString == '') return 'N/A';
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        // Invalid date, return the original input
        return dateString;
    }
    const timeZone = getCurrentTimezone();

    if (locale === 'vi') {
        return dayjs(date).tz(timeZone).format('DD/MM/YYYY HH:mm');
    }

    return dayjs(date).tz(timeZone).format('MMM D, YYYY hh:mmA');
};

const formatDate = (date = '', locale = 'en') => {
    if (!date) return '';

    if (locale === 'vi') {
        const timeZone = getCurrentTimezone();
        return dayjs(date).tz(timeZone).format('DD/MM/YYYY');
    }

    const dateObj = Date.parse(date);
    const timeZone = getCurrentTimezone();
    return dayjs(dateObj).tz(timeZone).format('MMM D, YYYY');
};

const formatChartAxisDateTime = (date) => {
    if (!date) return '';
    const dateObj = Date.parse(date);
    const timeZone = getCurrentTimezone();
    return dayjs(dateObj).tz(timeZone).format('MMM DD');
};

/**
 * TODO: Format chart tooltip date time
 */

const formatChartTooltipDateTime = (date, locale = 'en') => {
    if (!date) return '';
    const timeZone = getCurrentTimezone();

    if (locale === 'vi') {
        // ? Convert date to day of week
        const dayOfWeek = dayjs(date).tz(timeZone).format('dddd');
        const dayFormatted = formatDay(dayOfWeek, locale);

        return `${dayFormatted}, ${dayjs(date).tz(timeZone).format('DD/MM/YYYY')}`;
    }

    return dayjs(date).tz(timeZone).format('dddd, MMMM D, YYYY');
};

// ? Convert number to hours and minutes
const convertNumberToHoursAndMinutes = (number) => {
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    // ! With full text hours and minutes
    const hoursText = stringUtils.pluralNounCheck(hours, i18next.t('common:time.hour'), 's');
    const minutesText = stringUtils.pluralNounCheck(minutes, i18next.t('common:time.minute'), 's');

    return `${hours} ${hoursText} ${minutes} ${minutesText}`;
};

const getMonthsBetweenTwoDates = (startDate, endDate, locale = 'en') => {
    let start = startDate.split('-');
    let end = endDate.split('-');
    let startYear = parseInt(start[0]);
    let endYear = parseInt(end[0]);
    let dates = [];

    for (let i = startYear; i <= endYear; i++) {
        let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            let month = j + 1;
            let displayMonth = month < 10 ? '0' + month : month;
            const monthDate = [i, displayMonth, '01'].join('/');
            let formattedMonthDate = '';

            formattedMonthDate = formatMonth(month, locale);

            dates.push({
                label:
                    locale === 'vi' ? `${formattedMonthDate}/${i}` : `${formattedMonthDate} ${i}`,
                key: monthDate
            });
        }
    }
    return dates;
};

const isMoreThanMinutes = (date, minutes = 60) => {
    const today = new Date();
    const calculateDate = new Date(date);
    const diffMs = calculateDate - today;
    const thirtyMins = minutes * 60 * 1000;
    return diffMs > thirtyMins;
};

const isAfterCurrentTime = (date) => {
    const today = new Date();
    const calculateDate = new Date(date);
    const diffMs = calculateDate - today;
    return diffMs > 0;
};

/**
 * TODO: Format date time for line chart
 */

const formatDateTimeLineChart = (dateString, isShowYear = false) => {
    const date = new Date(dateString);
    const locale = i18next.language;

    if (isNaN(date.getTime())) {
        // Invalid date, return the original input
        return dateString;
    }

    let options = { month: 'short' };

    if (isShowYear) {
        options.year = 'numeric';
    } else {
        options.day = 'numeric';
    }

    const formattedDate = date.toLocaleDateString(locale === 'vi' ? 'vi-VN' : 'en-US', options);

    if (locale === 'vi') {
        const parts = formattedDate.split(' ');
        if (parts.length === 4) {
            return `${parts[0]}/${parts[2]}`;
        }
    }

    return formattedDate;
};

/**
 * TODO: Format day
 */

const formatDay = (dayString, locale) => {
    const daysOfWeek = {
        en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        vi: ['Chủ Nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7']
        // Add more locales as needed
    };

    if (!daysOfWeek[locale]) {
        // Unsupported locale, return the original input
        return dayString;
    }

    const index = daysOfWeek.en.indexOf(dayString);
    if (index !== -1) {
        return daysOfWeek[locale][index];
    }

    // Day not found in English names, return the original input
    return dayString;
};

/**
 * TODO: Format month
 */

const formatMonth = (monthNumber, locale) => {
    const months = {
        en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        vi: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    };

    if (!months[locale] || monthNumber < 1 || monthNumber > 12) {
        // Unsupported locale or invalid month number, return an empty string
        return '';
    }

    const index = monthNumber - 1;
    const monthName = months[locale][index];

    return monthName;
};

const formatDateRangeParam = (value) => dayjs(value).format('YYYY/MM/DD');

export default {
    formatDatePicker,
    formatFilterDate,
    getThisDayLastYear,
    getThisDaySixMonthsAgo,
    getThisDayThreeMonthsAgo,
    getDate30DaysAgo,
    formatDateTime,
    getCurrentTimezone,
    formatDateTimeParam,
    addZero,
    formatTableDateTime,
    convertNumberToHoursAndMinutes,
    formatChartTooltipDateTime,
    formatChartAxisDateTime,
    getMonthsBetweenTwoDates,
    formatDate,
    isMoreThanMinutes,
    formatDateTimeLineChart,
    formatDay,
    formatMonth,
    isAfterCurrentTime,
    formatExportFilterDate,
    today,
    getCurrentTime,
    formatDateRangeParam
};
