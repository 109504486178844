import initState from './initialState';
import actionTypes from './actionTypes';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAINING_REPORT_SUMMARY_SUCCESS:
            return reducerHandler.getTrainingSummary(state, action);
        case actionTypes.GET_TRAINING_REPORT_TOTAL_SUCCESS:
            return reducerHandler.getTrainingTotal(state, action);
        case actionTypes.TRAINING_BY_DEPARTMENT_SUCCESS:
            return reducerHandler.getTrainingByDepartment(state, action);
        case actionTypes.NOT_YET_ASSIGNED_TO_TRAINING_SUCCESS:
            return reducerHandler.getParticipantNotYetAssigned(state, action);
        default:
            return state;
    }
};

export default reducer;
