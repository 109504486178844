import { createTheme } from '@mui/material';

// * Assets
import colors from 'styles/abstracts/_color-variables.scss';

// * Palette Customization
import themePalette from './palette';
import themeTypography from './typography.js';

const themes = (customization = {}) => {
    const themeOption = {
        colors,
        customization: {
            ...customization,
            fontFamily: `'Lato', sans-serif`
        }
    };

    const themeOptions = {
        palette: themePalette(themeOption),
        typography: themeTypography(themeOption)
    };
    const theme = createTheme(themeOptions);

    return theme;
};

export default themes;
