const actionTypes = {
    // ? Get total candidates and departments
    GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_REQUEST: 'GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_REQUEST',
    GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_SUCCESS: 'GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_SUCCESS',
    GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_FAILURE: 'GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_FAILURE',

    // ? Get security awareness score
    GET_SECURITY_AWARENESS_SCORE_REQUEST: 'GET_SECURITY_AWARENESS_SCORE_REQUEST',
    GET_SECURITY_AWARENESS_SCORE_SUCCESS: 'GET_SECURITY_AWARENESS_SCORE_SUCCESS',
    GET_SECURITY_AWARENESS_SCORE_FAILURE: 'GET_SECURITY_AWARENESS_SCORE_FAILURE',

    // ? Get summary phishing result
    GET_PHISHING_RESULT_REQUEST: 'GET_PHISHING_RESULT_REQUEST',
    GET_PHISHING_RESULT_SUCCESS: 'GET_PHISHING_RESULT_SUCCESS',
    GET_PHISHING_RESULT_FAILURE: 'GET_PHISHING_RESULT_FAILURE',

    // ? Get Security Awareness Rating
    SECURITY_AWARENESS_RATING_BY_DEPARTMENT_REQUEST:
        'SECURITY_AWARENESS_RATING_BY_DEPARTMENT_REQUEST',
    SECURITY_AWARENESS_RATING_BY_DEPARTMENT_SUCCESS:
        'SECURITY_AWARENESS_RATING_BY_DEPARTMENT_SUCCESS',
    SECURITY_AWARENESS_RATING_BY_DEPARTMENT_FAILURE:
        'SECURITY_AWARENESS_RATING_BY_DEPARTMENT_FAILURE',

    // ? Get Detail SA Results
    DETAILED_SECURITY_AWARENESS_RESULTS_REQUEST: ' DETAILED_SECURITY_AWARENESS_RESULTS_REQUEST',
    DETAILED_SECURITY_AWARENESS_RESULTS_SUCCESS: ' DETAILED_SECURITY_AWARENESS_RESULTS_SUCCESS',
    DETAILED_SECURITY_AWARENESS_RESULTS_FAILURE: ' DETAILED_SECURITY_AWARENESS_RESULTS_FAILURE'
};

export default actionTypes;
