import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const MainLayout = loadable(() => import('layouts/MainLayout'));
const LoadingLayout = loadable(() => import('layouts/main/Loading/LoadingLayout'));
const NotFound = loadable(() => import('pages/404'));

import routeItems from 'shared/route-items';

// ~ Services
import { userPermissionsData, loadingUserProfileSelector } from 'services/user/selectors';

const ProtectedRoutes = () => {
    // ? Selectors
    const userPermissions = useSelector(userPermissionsData);
    const loadingUserProfile = useSelector(loadingUserProfileSelector);

    // ? Render route items
    const renderRouteItems = () => {
        return routeItems.map((routeItem) => {
            const { value, key } = routeItem;
            const Component = routeItem.component;

            // ! Only one permission on url
            if (typeof key === 'string') {
                // ? Permission to access SAT
                if (userPermissions[key] || key === 'sat.user') {
                    return <Route key={key} path={value} element={<Component />} />;
                } else {
                    return null;
                }
            }

            // ! Has multiple permissions on url
            if (Array.isArray(key)) {
                const hasPermission = key.some((permission) => userPermissions[permission]);

                if (hasPermission) {
                    return <Route key={key} path={value} element={<Component />} />;
                }

                return null;
            }
        });
    };

    return (
        <Routes>
            <Route path='/*' element={<MainLayout />}>
                {renderRouteItems()}
                {/* 404 */}
                <Route path='*' element={loadingUserProfile ? <LoadingLayout /> : <NotFound />} />
            </Route>
        </Routes>
    );
};

ProtectedRoutes.propTypes = {
    children: PropTypes.node
};

export default ProtectedRoutes;
