import PhishingStatisticsServices from 'api/statistics/phishing';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';
import {
    // ? Get statistics phishing summary
    getStatisticsPhishingSummarySuccess,
    getStatisticsPhishingSummaryFailure,

    // ? Get statistics phishing rating by months
    getStatisticsPhishingRatingByMonthsSuccess,
    getStatisticsPhishingRatingByMonthsFailure,

    // ? Get statistics phishing result
    getStatisticsPhishingResultSuccess,
    getStatisticsPhishingResultFailure,

    // ? Get statistics top phished by department
    getStatisticsTopPhishedByDepartmentSuccess,
    getStatisticsTopPhishedByDepartmentFailure,

    // ? Get statistics top clicked phishing template
    getStatisticsTopClickedPhishingTemplateSuccess,
    getStatisticsTopClickedPhishingTemplateFailure,

    // ? Get statistics phishing summary total
    getStatisticsPhishingSummaryTotalSuccess,
    getStatisticsPhishingSummaryTotalFailure,

    // ? Get statistics details phishing result
    getStatisticsDetailsPhishingResultSuccess,
    getStatisticsDetailsPhishingResultFailure,

    // ? Get statistics list targets unphished
    getStatisticsListTargetsUnphishedSuccess,
    getStatisticsListTargetsUnphishedFailure
} from './actions';

// ~ Transform data
import {
    transformStatisticsSummaryPhishingData,
    transformStatisticsPhishingRatingByMonthsData,
    transformStatisticsPhishingResultData,
    transformStatisticsTopPhishedByDepartmentData,
    transformStatisticsTopClickedPhishingTemplateData,
    transformStatisticsDetailsPhishingTemplateData,
    transformStatisticsListTargetsUnphishedData
} from 'transformer/statistics/phishing';

/**
 * TODO: Get phishing summary
 */

function* getStatisticsPhishingSummaryRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsPhishingSummary,
            payload
        );

        const dataTransformed = transformStatisticsSummaryPhishingData(response.data);
        yield put(getStatisticsPhishingSummarySuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsPhishingSummaryFailure(errors));
    }
}

/**
 * TODO: Get phishing rating by months
 */

function* getStatisticsPhishingRatingByMonthsRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsPhishingRatingByMonths,
            payload
        );

        const dataTransformed = transformStatisticsPhishingRatingByMonthsData(response.data);

        yield put(getStatisticsPhishingRatingByMonthsSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsPhishingRatingByMonthsFailure(errors));
    }
}

/**
 * TODO: Get phishing result
 */

function* getStatisticsPhishingResultRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsPhishingResult,
            payload
        );

        const dataTransformed = transformStatisticsPhishingResultData(response.data);

        yield put(getStatisticsPhishingResultSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsPhishingResultFailure(errors));
    }
}

/**
 * TODO: Get top phished by department
 */

function* getStatisticsTopPhishedByDepartmentRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsTopPhishedByDepartment,
            payload
        );

        const dataTransformed = transformStatisticsTopPhishedByDepartmentData(response.data);

        yield put(getStatisticsTopPhishedByDepartmentSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsTopPhishedByDepartmentFailure(errors));
    }
}

/**
 * TODO: Get top clicked phishing template
 */

function* getStatisticsTopClickedPhishingTemplateRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsTopClickedPhishingTemplate,
            payload
        );

        const dataTransformed = transformStatisticsTopClickedPhishingTemplateData(response.data);

        yield put(getStatisticsTopClickedPhishingTemplateSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsTopClickedPhishingTemplateFailure(errors));
    }
}

/**
 * TODO: Get phishing summary total
 */

function* getStatisticsPhishingSummaryTotalRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsPhishingSummaryTotal,
            payload
        );

        yield put(getStatisticsPhishingSummaryTotalSuccess(response.data));
    } catch (errors) {
        yield put(getStatisticsPhishingSummaryTotalFailure(errors));
    }
}

/**
 * TODO: Get details phishing result
 */

function* getStatisticsDetailsPhishingResultRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsDetailsPhishingResult,
            payload
        );

        const dataTransformed = transformStatisticsDetailsPhishingTemplateData(response.data);

        yield put(getStatisticsDetailsPhishingResultSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsDetailsPhishingResultFailure(errors));
    }
}

/**
 * TODO: Get list targets unphished
 */

function* getStatisticsListTargetsUnphishedRequestHandler({ payload }) {
    try {
        const response = yield call(
            PhishingStatisticsServices.getStatisticsListTargetsUnphished,
            payload
        );

        const dataTransformed = transformStatisticsListTargetsUnphishedData(response.data);

        yield put(getStatisticsListTargetsUnphishedSuccess(dataTransformed));
    } catch (errors) {
        yield put(getStatisticsListTargetsUnphishedFailure(errors));
    }
}

/**
 * TODO: Watchers
 */

function* statisticsPhishingWatcher() {
    yield takeLatest(
        types.GET_STATISTICS_PHISHING_SUMMARY_REQUEST,
        getStatisticsPhishingSummaryRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_PHISHING_RATING_BY_MONTHS_REQUEST,
        getStatisticsPhishingRatingByMonthsRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_PHISHING_RESULT_REQUEST,
        getStatisticsPhishingResultRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_REQUEST,
        getStatisticsTopPhishedByDepartmentRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_REQUEST,
        getStatisticsTopClickedPhishingTemplateRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_PHISHING_SUMMARY_TOTAL_REQUEST,
        getStatisticsPhishingSummaryTotalRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_DETAILS_PHISHING_RESULT_REQUEST,
        getStatisticsDetailsPhishingResultRequestHandler
    );

    yield takeLatest(
        types.GET_STATISTICS_LIST_TARGETS_UNPHISHED_REQUEST,
        getStatisticsListTargetsUnphishedRequestHandler
    );
}

export default function* rootSaga() {
    yield fork(statisticsPhishingWatcher);
}
