const initialState = {
    staffs: {
        data: [],
        pagination: {}
    },
    roles: {
        data: [],
        pagination: {}
    },

    staffsByRole: {
        data: [],
        pagination: {}
    },

    bannedStaffSuccessMessage: '',
    deletedRolesSuccessMessage: '',
    deletedStaffsFromRoleSuccessMessage: '',
    staffData: {},

    permissions: {},
    isAddRoleSuccess: false,
    isUpdateRoleSuccess: false,
    isAddStaffSuccess: false,
    isUpdateStaffSuccess: false,
    isResetStaffPasswordSuccess: false,
    roleData: {}
};

export default initialState;
