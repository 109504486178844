import numberUtils from 'utils/number-utils';

import { CANDIDATE_CUSTOMIZATION_METHODS } from 'common/constants';

const transformCandidatesList = (list = []) => {
    if (!list || list.length === 0) {
        return [];
    }
    return list.map((item) => {
        return {
            ...item,
            fullName: item.fullName,
            email: item.email,
            department: item?.department?.toLowerCase() || ''
        };
    });
};

const transformCandidatesListWithID = (list, idKey = 'id', currentPage = 1, pageSize = 10) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item, key) => {
        let id = key + pageSize * (currentPage - 1) + 1;

        let formattedID = numberUtils.formatIDWithTwoDigits(id);

        return {
            ...item,
            [idKey]: formattedID,
            fullName: item?.fullName || '',
            email: item?.email || '',
            department: item?.department || ''
        };
    });
};

const transformInvalidCandidatesErrors = (error = {}, data = []) => {
    const errorKeys = Object.keys(error);

    let invalidCandidates = [];
    errorKeys.forEach((key) => {
        if (key.includes('.')) {
            const index = key.split('.')[1];

            invalidCandidates.push(data[index]);
        }
    });

    return invalidCandidates;
};

// ? Transform candidate data in overview step
const transformCandidateDataInOverviewStep = (
    candidates = [],
    unselectedCandidates = [],
    departments = [],
    method = CANDIDATE_CUSTOMIZATION_METHODS.MANUAL
) => {
    let totalCandidates = 0;
    let totalCandidatesNotPaidYet = 0;
    let totalCandidatesPaid = 0;

    if (method === CANDIDATE_CUSTOMIZATION_METHODS.MANUAL) {
        totalCandidates =
            departments.reduce((total, department) => total + department.tempTotalCandidate, 0) -
                unselectedCandidates?.length || 0;

        // ? Get total candidates paid
        const totalCandidatesPaidUnselected =
            unselectedCandidates?.filter((candidate) => {
                return candidate?.isPaid;
            })?.length || 0;

        totalCandidatesPaid =
            departments.reduce((total, department) => {
                return total + department.totalCandidatePaid;
            }, 0) - totalCandidatesPaidUnselected;

        // ? Get total candidates not paid yet
        totalCandidatesNotPaidYet = totalCandidates - totalCandidatesPaid;
    } else if (method === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        totalCandidates = candidates?.length - unselectedCandidates?.length || 0;
        const selectedCandidates = candidates.filter(
            (candidate) =>
                !unselectedCandidates.some((unselected) => unselected.email === candidate.email)
        );

        totalCandidatesPaid =
            selectedCandidates?.filter((candidate) => candidate?.isPaid)?.length || 0;
        totalCandidatesNotPaidYet = totalCandidates - totalCandidatesPaid;
    }

    return {
        totalCandidates,
        totalCandidatesNotPaidYet,
        totalCandidatesPaid,
        transformCandidateDataInOverviewStep
    };
};

// ? Transform candidate data before send to server
const transformCandidateDataBeforeSendToServer = (
    candidates = [],
    unselectedCandidates = [],
    departments = [],
    method = CANDIDATE_CUSTOMIZATION_METHODS.MANUAL
) => {
    // ! Deparmtent keys
    const departmentKeys = departments.map((department) => department.departmentKey);

    // ! Candidates transformer
    let candidateEmails = null;

    if (method === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        const selectedCandidates = candidates.filter(
            (candidate) =>
                !unselectedCandidates.some((unselected) => unselected.email === candidate.email)
        );
        candidateEmails = selectedCandidates.map((candidate) => candidate.email);
    }

    // ! Unselected candidates transformer
    let unselectedCandidateEmails = [];

    if (unselectedCandidates.length > 0) {
        unselectedCandidateEmails = unselectedCandidates.map((candidate) => {
            return candidate?.email || '';
        });
    }

    return {
        departmentKeys,
        candidates: candidateEmails,
        unselectedCandidates: unselectedCandidateEmails
    };
};

export {
    transformCandidatesList,
    transformCandidatesListWithID,
    transformInvalidCandidatesErrors,
    transformCandidateDataInOverviewStep,
    transformCandidateDataBeforeSendToServer
};
