import i18next from 'i18next';

import {
    transformDeleteSuccessMessage,
    transformQuestionsListWithID
} from 'transformer/assessment';
import { transformCandidatesListWithID } from 'transformer/candidate';

import stringUtils from 'utils/string-utils';

/**
 * TODO: Get list survey
 */

const getListSurveyReducerHandler = (state, payload) => {
    return {
        ...state,
        startSurveySuccessMessage: '',
        stopSurveySuccessMessage: '',
        rebuildSurveySuccessMessage: '',
        deleteSurveysSuccessMessage: '',
        listSurvey: {
            data: payload.surveys,
            pagination: payload.meta
        }
    };
};

/**
 * TODO: start survey
 */

const startSurvey = (state) => {
    return {
        ...state,
        startSurveySuccessMessage: i18next.t('success:survey.startedSuccess')
    };
};

/**
 * TODO: stop survey
 */

const stopSurvey = (state) => {
    return {
        ...state,
        stopSurveySuccessMessage: i18next.t('success:survey.stoppedSuccess')
    };
};

/**
 * TODO: rebuild survey
 */

const rebuildSurvey = (state) => {
    return {
        ...state,
        rebuildSurveySuccessMessage: i18next.t('success:survey.rebuildSuccess')
    };
};

/**
 * TODO: delete surveys
 */

const deleteSurveys = (state, payload) => {
    const counter = transformDeleteSuccessMessage(payload.message);
    return {
        ...state,
        deleteSurveysSuccessMessage: i18next.t('success:survey.deleteSuccess', {
            key: +counter,
            label: stringUtils.pluralNounCheck(+counter, i18next.t('survey:surveyLabel'), 's')
        })
    };
};

/**
 * TODO: List survey templates
 */

const getListSurveyTemplatesReducerHandler = (state, payload) => {
    return {
        ...state,
        listSurveyTemplates: {
            data: payload.templates,
            pagination: payload.meta
        }
    };
};

/**
 * TODO: Transform Candidates List By Survey data
 */

const getListCandidatesBySurvey = (state, payload) => {
    return {
        ...state,
        listCandidatesBySurvey: {
            data: transformCandidatesListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform Question List By Survey data
 */

const getListQuestionsBySurvey = (state, payload) => {
    return {
        ...state,
        listQuestionsBySurvey: {
            data: transformQuestionsListWithID(
                payload.questions,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Create survey
 */

const createSurvey = (state) => {
    return {
        ...state,
        isCreateSurveySuccess: true
    };
};

const createSurveyReset = (state) => {
    return {
        ...state,
        isCreateSurveySuccess: false
    };
};

/**
 * TODO: Get survey details
 */

const getSurveyDetailsReducerHandler = (state, payload) => {
    return {
        ...state,
        surveyDetailsData: payload
    };
};

const getSurveyDetailsResetReducerHandler = (state) => {
    return {
        ...state,
        surveyDetailsData: {}
    };
};

/**
 * TODO: Edit survey
 */

const editSurvey = (state) => {
    return {
        ...state,
        isEditSurveySuccess: true
    };
};

const editSurveyReset = (state) => {
    return {
        ...state,
        isEditSurveySuccess: false
    };
};

export default {
    getListSurveyReducerHandler,
    startSurvey,
    stopSurvey,
    deleteSurveys,
    rebuildSurvey,
    getListSurveyTemplatesReducerHandler,
    getListCandidatesBySurvey,
    getListQuestionsBySurvey,
    createSurvey,
    createSurveyReset,
    getSurveyDetailsReducerHandler,
    getSurveyDetailsResetReducerHandler,
    editSurvey,
    editSurveyReset
};
