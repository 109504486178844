const types = {
    GET_CONTRACT_LIST_REQUEST: 'GET_CONTRACT_LIST_REQUEST',
    GET_CONTRACT_LIST_SUCCESS: 'GET_CONTRACT_LIST_SUCCESS',
    GET_CONTRACT_LIST_FAILURE: 'GET_CONTRACT_LIST_FAILURE',
    GET_CONTRACT_LIST_RESET: 'GET_CONTRACT_LIST_RESET',

    GET_CONTRACT_DETAIL_REQUEST: 'GET_CONTRACT_DETAIL_REQUEST',
    GET_CONTRACT_DETAIL_SUCCESS: 'GET_CONTRACT_DETAIL_SUCCESS',
    GET_CONTRACT_DETAIL_FAILURE: 'GET_CONTRACT_DETAIL_FAILURE',
    GET_CONTRACT_DETAIL_RESET: 'GET_CONTRACT_DETAIL_RESET'
};

export default types;
