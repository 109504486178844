import SimulationServices from 'api/simulation';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';

import {
    // ? Send test email
    sendTestEmailSuccess,
    sendTestEmailFailure,

    // ? Get phishing email templates
    getPhishingEmailTemplatesSuccess,
    getPhishingEmailTemplatesFailure,

    // ? List of phishing
    getListPhishingCampaignsSuccess,
    getListPhishingCampaignsFailure,

    // ? Get phishing list notifications
    getPhishingListNotificationsSuccess,
    getPhishingListNotificationsFailure,

    // ? Get phishing list targets
    getListTargetsSuccess,
    getListTargetsFailure,

    // ? Stop campaign
    stopCampaignSuccess,
    stopCampaignFailure,

    // ? Delete campaigns
    deleteCampaignsSuccess,
    deleteCampaignsFailure,

    // ? Get scam email content
    getScamEmailContentSuccess,
    getScamEmailContentFailure,

    // ? Get scam web content
    getScamWebContentSuccess,

    // ? Get view phishing notification
    getViewPhishingNotificationSuccess,
    getViewPhishingNotificationFailure,

    // ? Get phishing summary
    getPhishingSummarySuccess,
    getPhishingSummaryFailure,

    // ? Get phishing timeline
    getPhishingTimelineResultSuccess,
    getPhishingTimelineResultFailure,

    // ? Get phishing result candidate list
    getPhishingResultCandidateListSuccess,
    getPhishingResultCandidateListFailure,

    // ? Create phishing campaign
    createPhishingCampaignSuccess,
    createPhishingCampaignFailure,

    // ? Get list bookmarks
    getListBookmarksSuccess,
    getListBookmarksFailure,

    // ? Import phishing site
    importPhishingSiteSuccess,
    importPhishingSiteFailure,

    // ? Get list sms brands
    getListSmsBrandsSuccess,
    getListSmsBrandsFailure,

    // ? Send test sms
    sendTestSmsSuccess,
    sendTestSmsFailure,

    // ? Get list sms templates
    getListSmsTemplatesSuccess,
    getListSmsTemplatesFailure,

    // ? Create sms phishing campaign
    createSmsCampaignSuccess,
    createSmsCampaignFailure,

    // ? Preview site
    previewSiteSuccess,
    previewSiteFailure,

    // ? Get list category
    getListCategorySuccess,
    getListCategoryFailure,

    // ? Get clone phishing details
    getClonePhishingDetailsSuccess,
    getClonePhishingDetailsFailure,

    // ? Send test ott
    sendTestOttSuccess,
    sendTestOttFailure,

    // ? Create ott campaign
    createOttCampaignSuccess,
    createOttCampaignFailure,

    // ? Get list ott templates
    getTemplatesOttPhishingSuccess,
    getTemplatesOttPhishingFailure,
    getPhishingSummaryDepartmentsSuccess,
    getPhishingSummaryDepartmentsFailure
} from './actions';
import {
    transformPhishingEmailTemplateList,
    transformPhishingSmsTemplateList,
    transformLandingPageData,
    transformPhishingCampaignsList,
    transformClonePhishingData
} from 'transformer/simulation';

/**
 * TODO: Send test email request handler
 */

function* sendTestEmailRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.sendTestEmail, payload);
        yield put(sendTestEmailSuccess(response.data));
    } catch (errors) {
        yield put(sendTestEmailFailure(errors));
    }
}

/**
 * TODO: Get phishing email templates request handler
 */

function* getPhishingEmailTemplatesRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingEmailTemplates, payload);

        const phishingEmailTemplates = {
            ...response.data,
            emails: transformPhishingEmailTemplateList(response.data?.emails)
        };
        yield put(getPhishingEmailTemplatesSuccess(phishingEmailTemplates));
    } catch (errors) {
        yield put(getPhishingEmailTemplatesFailure(errors));
    }
}

/**
 * TODO: Get list phishing campaigns request handler
 */

function* getListPhishingCampaignsRequestHandler({ payload, permissions }) {
    try {
        const response = yield call(SimulationServices.getListPhishingCampaign, payload);
        const transformedListPhishingData = transformPhishingCampaignsList(
            response?.data?.campaigns,
            permissions
        );
        yield put(
            getListPhishingCampaignsSuccess({
                ...response.data,
                campaigns: transformedListPhishingData
            })
        );
    } catch (errors) {
        yield put(getListPhishingCampaignsFailure(errors));
    }
}

/**
 * TODO: Get phishing list notifications request handler
 */

function* getPhishingListNotificationsRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingListNotifications, payload);
        yield put(getPhishingListNotificationsSuccess(response.data));
    } catch (errors) {
        yield put(getPhishingListNotificationsFailure(errors));
    }
}

/**
 * TODO: Get target list request handler
 */

function* getTargetListRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.getListTarget, payload);
        yield put(getListTargetsSuccess(response.data));
    } catch (errors) {
        yield put(getListTargetsFailure(errors));
    }
}

/**
 * TODO: Get scam email content
 */

function* getScamEmailContentRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.getScamEmailContent, payload);
        yield put(getScamEmailContentSuccess(response.data));
    } catch (errors) {
        yield put(getScamEmailContentFailure(errors));
    }
}

/**
 * TODO: Get scam web content
 */

function* getScamWebContentRequestHandler({ payload }) {
    try {
        const response = yield call(SimulationServices.getScamWebContent, payload);
        yield put(getScamWebContentSuccess(response.data));
    } catch (errors) {
        yield put(getScamEmailContentFailure(errors));
    }
}

/**
 * TODO: Get view phishing notification
 */

function* getViewPhishingNotification({ payload }) {
    try {
        const response = yield call(SimulationServices.getViewPhishingNotification, payload);
        yield put(getViewPhishingNotificationSuccess(response.data));
    } catch (errors) {
        yield put(getViewPhishingNotificationFailure(errors));
    }
}

/**
 * TODO: Get phishing summary
 */

function* getPhishingSummary({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingCampaignSummary, payload);
        yield put(getPhishingSummarySuccess(response.data));
    } catch (errors) {
        yield put(getPhishingSummaryFailure(errors));
    }
}

/**
 * TODO: Get phishing timeline result
 */

function* getPhishingTimelineResult({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingTimelineResult, payload);
        yield put(getPhishingTimelineResultSuccess(response.data));
    } catch (errors) {
        yield put(getPhishingTimelineResultFailure(errors));
    }
}

/**
 * TODO: Get result candidate list
 */

function* getPhishingResultCandidateList({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingResultCandidate, payload);
        yield put(getPhishingResultCandidateListSuccess(response.data));
    } catch (errors) {
        yield put(getPhishingResultCandidateListFailure(errors));
    }
}

/**
 * TODO: Stop campaign
 */

function* stopCampaign({ payload }) {
    try {
        const response = yield call(SimulationServices.putStopCampaign, payload);
        yield put(stopCampaignSuccess(response.data));
    } catch (errors) {
        yield put(stopCampaignFailure(errors));
    }
}

/**
 * TODO: Delete campaigns
 */

function* deleteCampaigns({ payload }) {
    try {
        const response = yield call(SimulationServices.deleteCampaigns, payload);
        yield put(deleteCampaignsSuccess(response));
    } catch (errors) {
        yield put(deleteCampaignsFailure(errors));
    }
}

/**
 * TODO: Create phishing campaign
 */

function* createPhishingCampaign({ payload }) {
    try {
        const response = yield call(SimulationServices.createPhishingCampaign, payload);
        yield put(createPhishingCampaignSuccess(response.data));
    } catch (errors) {
        yield put(createPhishingCampaignFailure(errors));
    }
}

/**
 * TODO: Get list bookmarks
 */

function* getListBookmarks({ payload }) {
    try {
        const response = yield call(SimulationServices.getListBookmarks, payload);
        yield put(getListBookmarksSuccess(response.data));
    } catch (errors) {
        yield put(getListBookmarksFailure(errors));
    }
}

/**
 * TODO: Import phishing site
 */

function* importPhishingSite({ payload }) {
    try {
        const response = yield call(SimulationServices.importPhishingSite, payload);
        yield put(importPhishingSiteSuccess(transformLandingPageData(response.data)));
    } catch (errors) {
        yield put(importPhishingSiteFailure(errors));
    }
}

/**
 * TODO: Get list sms brands
 */

function* getListSmsBrands({ payload }) {
    try {
        const response = yield call(SimulationServices.getListSmsBrands, payload);
        yield put(getListSmsBrandsSuccess(response.data));
    } catch (errors) {
        yield put(getListSmsBrandsFailure(errors));
    }
}

/**
 * TODO: Send test sms
 */

function* sendTestSms({ payload }) {
    try {
        const response = yield call(SimulationServices.sendTestSms, payload);

        yield put(sendTestSmsSuccess(response.data));
    } catch (errors) {
        yield put(sendTestSmsFailure(errors));
    }
}

/**
 * TODO: Get list sms templates
 */

function* getListSmsTemplates({ payload }) {
    try {
        const response = yield call(SimulationServices.getListSmsTemplates, payload);
        yield put(
            getListSmsTemplatesSuccess({
                ...response.data,
                emails: transformPhishingSmsTemplateList(response?.data?.emails)
            })
        );
    } catch (errors) {
        yield put(getListSmsTemplatesFailure(errors));
    }
}

/**
 * TODO: Create sms phishing campaign
 */

function* createSmsPhishingCampaign({ payload }) {
    try {
        const response = yield call(SimulationServices.createSmsCampaign, payload);
        yield put(createSmsCampaignSuccess(response.data));
    } catch (errors) {
        yield put(createSmsCampaignFailure(errors));
    }
}

/**
 * TODO: Preview site
 */

function* previewSite({ payload }) {
    try {
        const response = yield call(SimulationServices.previewSite, payload);
        yield put(previewSiteSuccess(response.data));
    } catch (errors) {
        yield put(previewSiteFailure(errors));
    }
}

/**
 * TODO: Get list category
 */

function* getListCategory({ payload }) {
    try {
        const response = yield call(SimulationServices.getListCategory, payload);
        yield put(getListCategorySuccess(response.data));
    } catch (errors) {
        yield put(getListCategoryFailure(errors));
    }
}

/**
 * TODO: Get clone phishing details
 */

function* getClonePhishingDetails({ payload }) {
    try {
        const response = yield call(SimulationServices.getClonePhishingDetails, payload);

        yield put(getClonePhishingDetailsSuccess(transformClonePhishingData(response.data)));
    } catch (errors) {
        yield put(getClonePhishingDetailsFailure(errors));
    }
}

/**
 * TODO: Send test ott
 */

function* sendTestOtt({ payload }) {
    try {
        const response = yield call(SimulationServices.sendTestOtt, payload);
        yield put(sendTestOttSuccess(response.data));
    } catch (errors) {
        yield put(sendTestOttFailure(errors));
    }
}

/**
 * TODO: Create ott campaign
 */

function* createOttCampaign({ payload }) {
    try {
        const response = yield call(SimulationServices.createOttCampagin, payload);
        yield put(createOttCampaignSuccess(response.data));
    } catch (errors) {
        yield put(createOttCampaignFailure(errors));
    }
}

/**
 * TODO: Get templates ott phishing
 */

function* getTemplatesOttPhishing({ payload }) {
    try {
        const response = yield call(SimulationServices.getTemplatesOttPhishing, payload);
        yield put(getTemplatesOttPhishingSuccess(response.data));
    } catch (errors) {
        yield put(getTemplatesOttPhishingFailure(errors));
    }
}

/**
 * TODO: Get phishing summary departments
 */

function* getPhishingSummaryDepartments({ payload }) {
    try {
        const response = yield call(SimulationServices.getPhishingSummaryDepartments, payload);
        yield put(getPhishingSummaryDepartmentsSuccess(response.data));
    } catch (errors) {
        yield put(getPhishingSummaryDepartmentsFailure(errors));
    }
}

/**
 * TODO: Watchers
 */

function* watchSimulationSaga() {
    yield takeLatest(types.SEND_TEST_EMAIL_REQUEST, sendTestEmailRequestHandler);
    yield takeLatest(
        types.GET_PHISHING_EMAIL_TEMPLATES_REQUEST,
        getPhishingEmailTemplatesRequestHandler
    );
    yield takeLatest(
        types.GET_LIST_PHISHING_CAMPAIGNS_REQUEST,
        getListPhishingCampaignsRequestHandler
    );
    yield takeLatest(
        types.GET_PHISHING_LIST_NOTIFICATIONS_REQUEST,
        getPhishingListNotificationsRequestHandler
    );
    yield takeLatest(types.GET_LIST_TARGETS_REQUEST, getTargetListRequestHandler);
    yield takeLatest(types.GET_SCAM_EMAIL_CONTENT_REQUEST, getScamEmailContentRequestHandler);
    yield takeLatest(types.GET_SCAM_WEB_CONTENT_REQUEST, getScamWebContentRequestHandler);
    yield takeLatest(types.GET_VIEW_PHISHING_NOTIFICATION_REQUEST, getViewPhishingNotification);
    yield takeLatest(types.GET_PHISHING_SUMMARY_REQUEST, getPhishingSummary);
    yield takeLatest(types.GET_PHISHING_TIMELINE_RESULT_REQUEST, getPhishingTimelineResult);
    yield takeLatest(
        types.GET_PHISHING_RESULT_CANDIDATE_LIST_REQUEST,
        getPhishingResultCandidateList
    );
    yield takeLatest(types.STOP_CAMPAIGN_REQUEST, stopCampaign);
    yield takeLatest(types.DELETE_CAMPAIGNS_REQUEST, deleteCampaigns);
    yield takeLatest(types.CREATE_PHISHING_CAMPAIGN_REQUEST, createPhishingCampaign);
    yield takeLatest(types.GET_LIST_BOOKMARKS_REQUEST, getListBookmarks);
    yield takeLatest(types.IMPORT_PHISHING_SITE_REQUEST, importPhishingSite);
    yield takeLatest(types.GET_LIST_SMS_BRANDS_REQUEST, getListSmsBrands);
    yield takeLatest(types.SEND_TEST_SMS_REQUEST, sendTestSms);
    yield takeLatest(types.GET_LIST_SMS_TEMPLATES_REQUEST, getListSmsTemplates);
    yield takeLatest(types.CREATE_SMS_CAMPAIGN_REQUEST, createSmsPhishingCampaign);
    yield takeLatest(types.PREVIEW_SITE_REQUEST, previewSite);
    yield takeLatest(types.GET_LIST_CATEGORY_REQUEST, getListCategory);
    yield takeLatest(types.GET_CLONE_PHISHING_DETAILS_REQUEST, getClonePhishingDetails);
    yield takeLatest(types.SEND_TEST_OTT_REQUEST, sendTestOtt);
    yield takeLatest(types.CREATE_OTT_CAMPAIGN_REQUEST, createOttCampaign);
    yield takeLatest(types.GET_TEMPLATES_OTT_PHISHING_REQUEST, getTemplatesOttPhishing);
    yield takeLatest(types.GET_PHISHING_SUMMARY_DEPARTMENTS_REQUEST, getPhishingSummaryDepartments);
}

export default function* rootSaga() {
    yield fork(watchSimulationSaga);
}
