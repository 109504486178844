import { checkItemStatus } from 'transformer/common';
import dateUtils from 'utils/date-utils';
import numberUtils from 'utils/number-utils';
import {
    deleteStatuses,
    startStatuses,
    stopStatuses,
    cloneStatuses,
    rebuildStatus
} from 'common/constants/assessment';

import { CANDIDATE_CUSTOMIZATION_METHODS } from 'common/constants';

const transformAssessmentsList = (list = [], permissions = {}) => {
    if (list.length === 0) {
        return;
    }

    return list.map((item) => {
        let status = checkItemStatus(item.status);
        return {
            ...item,
            title: item.details.title,
            credit: numberUtils.formatNumberWithCommas(item.credit),
            startDate: item.startDate,
            endDate: item.endDate,
            status: status,
            isDelete: permissions?.['sat.assessment.mcq.delete']
                ? deleteStatuses.includes(status)
                : false,
            isStart: permissions?.['sat.assessment.mcq.start']
                ? startStatuses.includes(status)
                : false,
            isStop: permissions?.['sat.assessment.mcq.stop']
                ? stopStatuses.includes(status)
                : false,
            isRebuild:
                permissions?.['sat.assessment.mcq.rebuild'] &&
                dateUtils.isAfterCurrentTime(item.startDate)
                    ? rebuildStatus.includes(status)
                    : false,
            isEdit: permissions?.['sat.assessment.mcq.update']
                ? status == 'draft' ||
                  (status == 'ready' && dateUtils.isMoreThanMinutes(item.startDate))
                : false,
            isClone: permissions?.['sat.assessment.mcq.clone']
                ? cloneStatuses.includes(status)
                : false
        };
    });
};

const transformQuestionsListWithID = (list, idKey = 'id', currentPage = 1, pageSize = 10) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item, key) => {
        let id = key + pageSize * (currentPage - 1) + 1;

        let formattedID = numberUtils.formatIDWithTwoDigits(id);

        return {
            ...item,
            [idKey]: formattedID,
            answered: {
                solved: item.totalSolved || 0,
                assigned: item.totalAssigned || 0
            },
            description: item.title,
            author: item.author,
            tags: item.tags
        };
    });
};

/**
 * TODO: Function to transform assessment data to show in overview page
 */

const transformAssessmentOverviewData = (data) => {
    const {
        departments,
        questions,
        unselectedQuestions,
        challengeMode,
        assessmentTitle,
        startDate,
        endDate,
        tagsValue: tags,
        generalInformation,
        candidates,
        methodSelection,
        unselectedCandidates,
        templates
    } = data;

    // ? Required questions
    let requiredQuestions = 0;

    // ? Get total candidates
    let totalCandidates = 0;
    let totalCandidatesNotPaidYet = 0;
    let totalCandidatesPaid = 0;

    // ? Get total candidates

    if (methodSelection === CANDIDATE_CUSTOMIZATION_METHODS.MANUAL) {
        totalCandidates =
            departments.reduce((total, department) => total + department.tempTotalCandidate, 0) -
                unselectedCandidates?.length || 0;

        // ? Get total candidates paid
        const totalCandidatesPaidUnselected =
            unselectedCandidates?.filter((candidate) => {
                return candidate?.isPaid;
            })?.length || 0;

        totalCandidatesPaid =
            departments.reduce((total, department) => {
                return total + department.totalCandidatePaid;
            }, 0) - totalCandidatesPaidUnselected;

        // ? Get total candidates not paid yet
        totalCandidatesNotPaidYet = totalCandidates - totalCandidatesPaid;
    } else if (methodSelection === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        totalCandidates = candidates?.length - unselectedCandidates?.length || 0;
        const selectedCandidates = candidates.filter(
            (candidate) =>
                !unselectedCandidates.some((unselected) => unselected.email === candidate.email)
        );

        totalCandidatesPaid =
            selectedCandidates?.filter((candidate) => candidate?.isPaid)?.length || 0;
        totalCandidatesNotPaidYet = totalCandidates - totalCandidatesPaid;
    }

    // ? Get total questions
    let totalQuestions = 0;
    if (challengeMode === 'manual') {
        totalQuestions = questions?.length || 0;
    } else if (challengeMode === 'templates') {
        if (questions?.length === 0) {
            // ! Get total questions from templates
            totalQuestions = templates.reduce((total, template) => {
                return total + template?.questions?.total || 0;
            }, 0);
        } else {
            totalQuestions =
                questions?.length - (unselectedQuestions ? unselectedQuestions?.length : 0);
        }
    } else if (challengeMode === 'random') {
        // ? Sum all value of each element in tags
        totalQuestions = tags.reduce((total, tag) => total + tag.value, 0);
        requiredQuestions = questions.length;
    }

    // ? Get start date
    const startDateFormatted = dateUtils.formatTableDateTime(startDate);

    // ? Get end date
    const endDateFormatted = dateUtils.formatTableDateTime(endDate);

    // ? Get duration
    const duration = generalInformation?.duration;

    // ? Total number of attempts
    const numberOfRetakeAttempts = generalInformation?.numberOfRetakeAttempts;

    return {
        totalCandidates,
        totalCandidatesNotPaidYet,
        totalCandidatesPaid,
        totalQuestions,
        templateType: challengeMode,
        assessmentTitle,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        duration,
        requiredQuestions,
        numberOfRetakeAttempts
    };
};

/**
 * TODO: Function to transform assessment data before send to server
 */

const transformAssessmentData = (data) => {
    const {
        departments,
        assessmentTitle,
        generalInformation,
        challengeMode: templateType,
        unselectedQuestions,
        questions,
        startDate,
        endDate,
        isShuffle,
        templates = [],
        tagsValue: tags,
        unselectedCandidates,
        candidates = [],
        methodSelection
    } = data;

    // ? Get all departmentKeys from departments
    const departmentKeys = departments.map((department) => department.departmentKey);

    let templateCodesTransformer = [];
    let candidatesTransformer = [];

    if (templates.length > 0) {
        templateCodesTransformer = templates.map((template) => {
            return template?.slug;
        });
    }

    if (unselectedCandidates.length > 0) {
        candidatesTransformer = unselectedCandidates.map((candidate) => {
            return candidate?.email || '';
        });
    }

    const nowDateTime = new Date(Date.now());
    const tempLaunchDate =
        startDate < nowDateTime.toISOString() ? nowDateTime.toISOString() : startDate;

    let selectedQuestionSlugs = [];

    if (templateType === 'random') {
        // ? Get all question slugs
        selectedQuestionSlugs = questions;
    } else {
        // ! Questions formatter
        const unselectedQuestionSlugs = unselectedQuestions?.map((question) => question.slug) || [];
        const selectedQuestions = questions.filter(
            (question) => !unselectedQuestionSlugs.includes(question.slug)
        );

        selectedQuestionSlugs = selectedQuestions.map((question) => question.slug);
    }

    let candidateEmails = [];

    if (methodSelection === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        const selectedCandidates = candidates.filter(
            (candidate) =>
                !unselectedCandidates.some((unselected) => unselected.email === candidate.email)
        );
        candidateEmails = selectedCandidates.map((candidate) => candidate.email);
    }

    return {
        departments: departmentKeys,
        title: assessmentTitle,
        duration: +generalInformation?.duration,
        numberOfRetakeAttempts: +generalInformation?.numberOfRetakeAttempts,
        templateType: templateType === 'random' ? 'random_question' : templateType,
        candidateExcludes: candidatesTransformer,
        templateCodes: templateType === 'templates' ? templateCodesTransformer : [],
        isShuffle,
        questions: selectedQuestionSlugs,
        locales: generalInformation?.locales || [],
        tags: templateType === 'random' ? tags : [],
        timezone: dateUtils.getCurrentTimezone(),
        startDate: dateUtils.formatDateTimeParam(tempLaunchDate),
        endDate: dateUtils.formatDateTimeParam(endDate),
        auto: dateUtils.formatDateTimeParam(startDate),
        candidates:
            methodSelection === CANDIDATE_CUSTOMIZATION_METHODS.MANUAL ? null : candidateEmails
    };
};

/**
 * TODO: Transform assessment summary participants data
 */

const transformSummaryParticipants = (data) => {
    let details = [];
    if (data?.status === 'running') {
        details = [
            {
                id: 'assessmentSubmitted',
                value: data.details?.candidatesSubmitted,
                percentageCalculated: data.details?.candidatesSubmittedPercent,
                color: '#97D79A'
            },
            {
                id: 'in-progress',
                value: data.details?.candidatesInProgress,
                percentageCalculated: data.details?.candidatesInProgressPercent,
                color: '#FFD650'
            },
            {
                id: 'unenrolled',
                value: data.details?.candidatesUnenrolled,
                percentageCalculated: data.details?.candidatesUnenrolledPercent,
                color: '#A2E1F0'
            }
        ];
    } else {
        details = [
            {
                id: 'completed',
                value: data.details?.candidatesCompleted,
                percentageCalculated: data.details?.candidatesCompletedPercent,
                color: '#97D79A'
            },
            {
                id: 'incomplete',
                value: data.details?.candidatesIncompleted,
                percentageCalculated: data.details?.candidatesIncompletedPercent,
                color: '#A2E1F0'
            }
        ];
    }

    return {
        candidatesAssigned: data.candidatesAssigned,
        details,
        assessmentDetail: {
            title: data.title,
            startDate: data.startDate,
            endDate: data.endDate,
            passingScore: data.passingScore,
            status: data.status,
            numberOfAttempts: data.numberOfAttempts
        }
    };
};

/**
 * TODO: Transform assessment summary statistic data
 */

const transformSummaryStatistic = (data) => {
    return {
        candidatesCompleted: data.totalCompleted,
        details: [
            {
                id: 'passed',
                value: data.details?.candidatesPassed,
                percentageCalculated: data.details?.candidatesPassedPercent,
                color: '#97D79A'
            },
            {
                id: 'failedAssessment',
                value: data.details?.candidatesFailed,
                percentageCalculated: data.details?.candidatesFailedPercent,
                color: '#FF7D5C'
            },
            {
                id: 'unenrolled',
                value: 0,
                percentageCalculated: 0,
                color: '#CDD5DF'
            }
        ]
    };
};

/**
 * TODO: Transform assessment candidate result
 */

const transformCandidateResultData = (data) => {
    return {
        totalQuestions: numberUtils.formatNumberWithCommas(data.details.totalQuestions),
        score: data.score,
        status: data.status,
        details: [
            {
                id: 'assessment.totalCorrects',
                value: data.details?.totalCorrects,
                percent: data.details?.percentCorrects
            },
            {
                id: 'assessment.totalIncorrects',
                value: data.details?.totalIncorrects,
                percent: data.details?.percentIncorrects
            }
        ]
    };
};

const transformDeleteSuccessMessage = (message) => {
    return message?.split('.')[0];
};

/**
 * TODO: Transform assessment details
 */

const transformAssessmentDetails = (data) => {
    // ! Format candidates
    let candidateExcludes = data?.candidateExcludes || [];

    // ! Format departments
    let departments = data?.departments || [];
    departments = departments.map((department) => {
        return {
            ...department,
            tempTotalCandidate:
                department?.totalCandidateExcludes + department?.totalCandidateAssigned,
            totalCandidate: department?.totalCandidateAssigned,
            totalCandidateNotPaidYet: 0,
            totalCandidatePaid: department?.totalCandidatePaid,
            departmentKey: department?.departmentKey?.toLowerCase() || '',
            departmentName: department?.departmentName?.toLowerCase() || ''
        };
    });

    //! Format tags
    let tags = data?.random || [];

    tags = tags.map((tag) => {
        return {
            ...tag,
            totalQuestions: tag?.value || 0
        };
    });

    // ! Format questions
    let questions = [];

    if (data?.type === 'random_question') {
        questions = data?.questions?.map((question) => question?.slug || '') || [];
    } else {
        questions = data?.questions || [];
    }
    return {
        scheduleStep: {
            startDate: data?.startDate,
            endDate: data?.endDate,
            assessmentTitle: data?.title
        },
        candidateStep: {
            departments,
            candidates: data?.candidates,
            unselectedCandidates: candidateExcludes
        },
        challengeStep: {
            challengeMode: data?.type === 'random_question' ? 'random' : data?.type,
            generalInformation: {
                duration: `${data?.duration}`,
                title: data?.title,
                locales: data?.locales,
                numberOfRetakeAttempts: data?.numberOfRetakeAttempts
            },
            questions,
            tags,
            tagsValue: tags,
            templates: data?.templates
        },
        overviewStep: {
            isShuffle: data?.isShuffle
        },
        status: data?.status
    };
};

export {
    transformAssessmentOverviewData,
    transformAssessmentData,
    transformAssessmentsList,
    transformQuestionsListWithID,
    transformSummaryParticipants,
    transformSummaryStatistic,
    transformCandidateResultData,
    transformDeleteSuccessMessage,
    transformAssessmentDetails
};
