import httpClient from 'api';
import CONSTANTS from 'api/constants/statistics/training';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO:: Get training total
 */
const getTrainingTotal = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.TRAINING_TOTAL, { params });
    return response;
});

/**
 * TODO:: Get training summary
 */
const getTrainingSummary = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.TRAINING_SUMMARY, { params });
    return response;
});
/**
 * TODO: Training By Department
 */

const getTrainingByDepartment = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.TRAINING_BY_DEPARTMENT, { params });
    return response;
});

/**
 * TODO: Not yet assign to training
 */

const getParticipantNotYetAssignToTraining = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.NOT_YET_ASSIGN_TO_TRAINING, { params });
    return response;
});

export default {
    getTrainingSummary,
    getTrainingTotal,
    getTrainingByDepartment,
    getParticipantNotYetAssignToTraining
};
