import TrainingServices from 'api/training';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';

import {
    getListCourseSuccess,
    getListCourseFailure,
    createTrainingSuccess,
    createTrainingFailure,
    getListTrainingsSuccess,
    getListTrainingsFailure,
    getListTrainingsExpandRowSuccess,
    getListTrainingsExpandRowFailure,
    getListCandidatesByTrainingSuccess,
    getListCandidatesByTrainingFailure,
    getTrainingSummarySuccess,
    getTrainingSummaryFailure,
    getTrainingResultCourseSummarySuccess,
    getTrainingResultCourseSummaryFailure,
    getTrainingSummaryCandidateListSuccess,
    getTrainingSummaryCandidateListFailure,
    getCandidateTrainingDetailStatsSuccess,
    getCandidateTrainingDetailStatsFailure,
    startTrainingSuccess,
    startTrainingFailure,
    stopTrainingSuccess,
    stopTrainingFailure,
    rebuildTrainingSuccess,
    rebuildTrainingFailure,
    deleteTrainingsFailure,
    deleteTrainingsSuccess,
    getTrainingDetailsSuccess,
    getTrainingDetailsFailure,
    updateTrainingSuccess,
    updateTrainingFailure,
    getTrainingCloneDetailsSuccess,
    getTrainingCloneDetailsFailure,
    getTrainingSummaryDepartmentsSuccess,
    getTrainingSummaryDepartmentsFailure
} from './actions';

import { transformTrainingList, transformTrainingDetails } from 'transformer/training';

/**
 * TODO: Get list course request handler
 */

function* getListCourseRequestHandler({ payload }) {
    try {
        const response = yield call(TrainingServices.getListCourse, payload);
        yield put(getListCourseSuccess(response.data));
    } catch (errors) {
        yield put(getListCourseFailure(errors));
    }
}

/**
 * TODO: Create training request handler
 */

function* createTrainingRequestHandler({ payload }) {
    try {
        const response = yield call(TrainingServices.createTraining, payload);
        yield put(createTrainingSuccess(response.data));
    } catch (errors) {
        yield put(createTrainingFailure(errors));
    }
}

/**
 * TODO: Get list training request handler
 */

function* getListTrainingRequestHandler({ payload, permissions }) {
    try {
        const response = yield call(TrainingServices.getListTraining, payload);
        const transformedListTrainingData = transformTrainingList(
            response?.data?.trainings,
            permissions
        );
        yield put(
            getListTrainingsSuccess({
                ...response.data,
                trainings: transformedListTrainingData
            })
        );
    } catch (errors) {
        yield put(getListTrainingsFailure(errors));
    }
}

/**
 * TODO: Get list training expand row request handler
 */

function* getListTrainingExpandRowRequestHandler({ payload }) {
    try {
        const response = yield call(TrainingServices.getListTrainingExpandRow, payload);
        yield put(getListTrainingsExpandRowSuccess(response.data));
    } catch (errors) {
        yield put(getListTrainingsExpandRowFailure(errors));
    }
}

/**
 * TODO: Get list candidates by training request handler
 */

function* getListCandidatesByTrainingRequestHandler({ payload }) {
    try {
        const response = yield call(TrainingServices.getListCandidateByTraining, payload);
        yield put(getListCandidatesByTrainingSuccess(response.data));
    } catch (errors) {
        yield put(getListCandidatesByTrainingFailure(errors));
    }
}

/**
 * TODO: Get training summary
 */

function* getTrainingSummary({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingInProgressSummary, payload);
        yield put(getTrainingSummarySuccess(response.data));
    } catch (errors) {
        yield put(getTrainingSummaryFailure(errors));
    }
}

/**
 * TODO: Get training result course summary
 */

function* getTrainingResultCourseSummary({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingResultCourseSummary, payload);
        yield put(getTrainingResultCourseSummarySuccess(response.data));
    } catch (errors) {
        yield put(getTrainingResultCourseSummaryFailure(errors));
    }
}

/**
 * TODO: Get training summary candidate list
 */

function* getTrainingSummaryCandidateList({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingResourceStatistic, payload);
        yield put(getTrainingSummaryCandidateListSuccess(response.data));
    } catch (errors) {
        yield put(getTrainingSummaryCandidateListFailure(errors));
    }
}

/**
 * TODO: Get candidate training detail stats
 */

function* getCandidateTrainingDetailStats({ payload }) {
    try {
        const response = yield call(TrainingServices.getCandidateTrainingDetailStats, payload);
        yield put(getCandidateTrainingDetailStatsSuccess(response.data));
    } catch (errors) {
        yield put(getCandidateTrainingDetailStatsFailure(errors));
    }
}

/**
 * TODO: Start training
 */

function* startTraining({ payload }) {
    try {
        const response = yield call(TrainingServices.startTraining, payload);
        yield put(startTrainingSuccess(response.data));
    } catch (errors) {
        yield put(startTrainingFailure(errors));
    }
}

/**
 * TODO: Stop training
 */

function* stopTraining({ payload }) {
    try {
        const response = yield call(TrainingServices.stopTraining, payload);
        yield put(stopTrainingSuccess(response.data));
    } catch (errors) {
        yield put(stopTrainingFailure(errors));
    }
}

/**
 * TODO: Rebuild training
 */

function* rebuildTraining({ payload }) {
    try {
        const response = yield call(TrainingServices.rebuildTraining, payload);
        yield put(rebuildTrainingSuccess(response.data));
    } catch (errors) {
        yield put(rebuildTrainingFailure(errors));
    }
}

/**
 * TODO: Delete trainings
 */

function* deleteTrainings({ payload }) {
    try {
        const response = yield call(TrainingServices.deleteTrainings, payload);
        yield put(deleteTrainingsSuccess(response));
    } catch (errors) {
        yield put(deleteTrainingsFailure(errors));
    }
}

/**
 * TODO: Get training details
 */

function* getTrainingDetails({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingDetails, payload);
        yield put(getTrainingDetailsSuccess(transformTrainingDetails(response.data)));
    } catch (errors) {
        yield put(getTrainingDetailsFailure(errors));
    }
}

/**
 * TODO: Update training
 */

function* updateTraining({ payload }) {
    try {
        const response = yield call(TrainingServices.updateTraining, payload);
        yield put(updateTrainingSuccess(response.data));
    } catch (errors) {
        yield put(updateTrainingFailure(errors));
    }
}

/**
 * TODO: Get training clone details
 */

function* getTrainingCloneDetails({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingCloneDetails, payload);
        yield put(getTrainingCloneDetailsSuccess(transformTrainingDetails(response.data)));
    } catch (errors) {
        yield put(getTrainingCloneDetailsFailure(errors));
    }
}

/**
 * TODO: Get training summary departments
 */

function* getTrainingSummaryDepartments({ payload }) {
    try {
        const response = yield call(TrainingServices.getTrainingSummaryDepartments, payload);
        yield put(getTrainingSummaryDepartmentsSuccess(response.data));
    } catch (errors) {
        yield put(getTrainingSummaryDepartmentsFailure(errors));
    }
}

/**
 * TODO: Watchers
 */

function* watchTrainingSaga() {
    yield takeLatest(types.GET_LIST_COURSE_REQUEST, getListCourseRequestHandler);
    yield takeLatest(types.CREATE_TRAINING_REQUEST, createTrainingRequestHandler);
    yield takeLatest(types.GET_LIST_TRAININGS_REQUEST, getListTrainingRequestHandler);
    yield takeLatest(
        types.GET_LIST_TRAININGS_EXPAND_ROW_REQUEST,
        getListTrainingExpandRowRequestHandler
    );
    yield takeLatest(
        types.GET_LIST_CANDIDATES_BY_TRAINING_REQUEST,
        getListCandidatesByTrainingRequestHandler
    );
    yield takeLatest(types.GET_TRAINING_SUMMARY_REQUEST, getTrainingSummary);
    yield takeLatest(
        types.GET_TRAINING_RESULT_COURSE_SUMMARY_REQUEST,
        getTrainingResultCourseSummary
    );
    yield takeLatest(
        types.GET_TRAINING_SUMMARY_CANDIDATE_LIST_REQUEST,
        getTrainingSummaryCandidateList
    );
    yield takeLatest(
        types.GET_CANDIDATE_TRAINING_DETAIL_STATS_REQUEST,
        getCandidateTrainingDetailStats
    );
    yield takeLatest(types.START_TRAINING_REQUEST, startTraining);
    yield takeLatest(types.STOP_TRAINING_REQUEST, stopTraining);
    yield takeLatest(types.REBUILD_TRAINING_REQUEST, rebuildTraining);
    yield takeLatest(types.DELETE_TRAININGS_REQUEST, deleteTrainings);
    yield takeLatest(types.GET_TRAINING_DETAILS_REQUEST, getTrainingDetails);
    yield takeLatest(types.UPDATE_TRAINING_REQUEST, updateTraining);
    yield takeLatest(types.GET_TRAINING_CLONE_DETAILS_REQUEST, getTrainingCloneDetails);
    yield takeLatest(types.GET_TRAINING_SUMMARY_DEPARTMENTS_REQUEST, getTrainingSummaryDepartments);
}

export default function* trainingSaga() {
    yield fork(watchTrainingSaga);
}
