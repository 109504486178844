import types from './actionTypes';

/**
 * TODO: Get list course
 */

const getListCourse = (payload) => ({
    type: types.GET_LIST_COURSE_REQUEST,
    payload
});

const getListCourseSuccess = (payload) => ({
    type: types.GET_LIST_COURSE_SUCCESS,
    payload
});

const getListCourseFailure = (payload) => ({
    type: types.GET_LIST_COURSE_FAILURE,
    payload
});

/**
 * TODO: Create training
 */

const createTraining = (payload) => ({
    type: types.CREATE_TRAINING_REQUEST,
    payload
});

const createTrainingSuccess = (payload) => ({
    type: types.CREATE_TRAINING_SUCCESS,
    payload
});

const createTrainingFailure = (payload) => ({
    type: types.CREATE_TRAINING_FAILURE,
    payload
});

const createTrainingReset = () => ({
    type: types.CREATE_TRAINING_RESET
});

/**
 * TODO: Get list trainings
 */

const getListTrainings = (payload, permissions) => ({
    type: types.GET_LIST_TRAININGS_REQUEST,
    payload,
    permissions
});

const getListTrainingsSuccess = (payload) => ({
    type: types.GET_LIST_TRAININGS_SUCCESS,
    payload
});

const getListTrainingsFailure = (payload) => ({
    type: types.GET_LIST_TRAININGS_FAILURE,
    payload
});

/**
 * TODO: Get list trainings expand row
 */

const getListTrainingsExpandRow = (payload) => ({
    type: types.GET_LIST_TRAININGS_EXPAND_ROW_REQUEST,
    payload
});

const getListTrainingsExpandRowSuccess = (payload) => ({
    type: types.GET_LIST_TRAININGS_EXPAND_ROW_SUCCESS,
    payload
});

const getListTrainingsExpandRowFailure = (payload) => ({
    type: types.GET_LIST_TRAININGS_EXPAND_ROW_FAILURE,
    payload
});

/**
 * TODO: Get list candidates by training
 */

const getListCandidatesByTraining = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_TRAINING_REQUEST,
    payload
});

const getListCandidatesByTrainingSuccess = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_TRAINING_SUCCESS,
    payload
});

const getListCandidatesByTrainingFailure = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_TRAINING_FAILURE,
    payload
});

/**
 * TODO: Get training summary
 */

const getTrainingSummary = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_REQUEST,
    payload
});

const getTrainingSummarySuccess = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_SUCCESS,
    payload
});

const getTrainingSummaryFailure = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_FAILURE,
    payload
});

/**
 * TODO: Get training result course summary
 */

const getTrainingResultCourseSummary = (payload) => ({
    type: types.GET_TRAINING_RESULT_COURSE_SUMMARY_REQUEST,
    payload
});

const getTrainingResultCourseSummarySuccess = (payload) => ({
    type: types.GET_TRAINING_RESULT_COURSE_SUMMARY_SUCCESS,
    payload
});

const getTrainingResultCourseSummaryFailure = (payload) => ({
    type: types.GET_TRAINING_RESULT_COURSE_SUMMARY_FAILURE,
    payload
});

/**
 * TODO: Get training summary candidate list
 */

const getTrainingSummaryCandidateList = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_CANDIDATE_LIST_REQUEST,
    payload
});

const getTrainingSummaryCandidateListSuccess = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_CANDIDATE_LIST_SUCCESS,
    payload
});

const getTrainingSummaryCandidateListFailure = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_CANDIDATE_LIST_FAILURE,
    payload
});

/**
 * TODO: Get candidate training detail stats
 */

const getCandidateTrainingDetailStats = (payload) => ({
    type: types.GET_CANDIDATE_TRAINING_DETAIL_STATS_REQUEST,
    payload
});

const getCandidateTrainingDetailStatsSuccess = (payload) => ({
    type: types.GET_CANDIDATE_TRAINING_DETAIL_STATS_SUCCESS,
    payload
});

const getCandidateTrainingDetailStatsFailure = (payload) => ({
    type: types.GET_CANDIDATE_TRAINING_DETAIL_STATS_FAILURE,
    payload
});

/**
 * TODO: start training
 */

const startTraining = (payload) => ({
    type: types.START_TRAINING_REQUEST,
    payload
});

const startTrainingSuccess = (payload) => ({
    type: types.START_TRAINING_SUCCESS,
    payload
});

const startTrainingFailure = (payload) => ({
    type: types.START_TRAINING_FAILURE,
    payload
});

/**
 * TODO: stop training
 */

const stopTraining = (payload) => ({
    type: types.STOP_TRAINING_REQUEST,
    payload
});

const stopTrainingSuccess = (payload) => ({
    type: types.STOP_TRAINING_SUCCESS,
    payload
});

const stopTrainingFailure = (payload) => ({
    type: types.STOP_TRAINING_FAILURE,
    payload
});

/**
 * TODO: Rebuild training
 */

const rebuildTraining = (payload) => ({
    type: types.REBUILD_TRAINING_REQUEST,
    payload
});

const rebuildTrainingSuccess = (payload) => ({
    type: types.REBUILD_TRAINING_SUCCESS,
    payload
});

const rebuildTrainingFailure = (payload) => ({
    type: types.REBUILD_TRAINING_FAILURE,
    payload
});

/**
 * TODO: Delete trainings
 */

const deleteTrainings = (payload) => ({
    type: types.DELETE_TRAININGS_REQUEST,
    payload
});

const deleteTrainingsSuccess = (payload) => ({
    type: types.DELETE_TRAININGS_SUCCESS,
    payload
});

const deleteTrainingsFailure = (payload) => ({
    type: types.DELETE_TRAININGS_FAILURE,
    payload
});

/**
 * TODO: Get training details
 */

const getTrainingDetails = (payload) => ({
    type: types.GET_TRAINING_DETAILS_REQUEST,
    payload
});

const getTrainingDetailsSuccess = (payload) => ({
    type: types.GET_TRAINING_DETAILS_SUCCESS,
    payload
});

const getTrainingDetailsFailure = (payload) => ({
    type: types.GET_TRAINING_DETAILS_FAILURE,
    payload
});

const getTrainingDetailsReset = () => ({
    type: types.GET_TRAINING_DETAILS_RESET
});

/**
 * TODO: Update training
 */

const updateTraining = (payload) => ({
    type: types.UPDATE_TRAINING_REQUEST,
    payload
});

const updateTrainingSuccess = (payload) => ({
    type: types.UPDATE_TRAINING_SUCCESS,
    payload
});

const updateTrainingFailure = (payload) => ({
    type: types.UPDATE_TRAINING_FAILURE,
    payload
});

const updateTrainingReset = () => ({
    type: types.UPDATE_TRAINING_RESET
});

/**
 * TODO: Get training clone details
 */

const getTrainingCloneDetails = (payload) => ({
    type: types.GET_TRAINING_CLONE_DETAILS_REQUEST,
    payload
});

const getTrainingCloneDetailsSuccess = (payload) => ({
    type: types.GET_TRAINING_CLONE_DETAILS_SUCCESS,
    payload
});

const getTrainingCloneDetailsFailure = (payload) => ({
    type: types.GET_TRAINING_CLONE_DETAILS_FAILURE,
    payload
});

const getTrainingCloneDetailsReset = () => ({
    type: types.GET_TRAINING_CLONE_DETAILS_RESET
});

/**
 * TODO: Get training summary departments
 */

const getTrainingSummaryDepartments = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_DEPARTMENTS_REQUEST,
    payload
});

const getTrainingSummaryDepartmentsSuccess = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_DEPARTMENTS_SUCCESS,
    payload
});

const getTrainingSummaryDepartmentsFailure = (payload) => ({
    type: types.GET_TRAINING_SUMMARY_DEPARTMENTS_FAILURE,
    payload
});

export {
    // * Get list course
    getListCourse,
    getListCourseSuccess,
    getListCourseFailure,

    // * Create training
    createTraining,
    createTrainingSuccess,
    createTrainingFailure,
    createTrainingReset,

    // * List Training
    getListTrainings,
    getListTrainingsSuccess,
    getListTrainingsFailure,

    // * List Training Expand Row
    getListTrainingsExpandRow,
    getListTrainingsExpandRowSuccess,
    getListTrainingsExpandRowFailure,

    // * Get list candidates by training
    getListCandidatesByTraining,
    getListCandidatesByTrainingSuccess,
    getListCandidatesByTrainingFailure,

    // * Get Training Summary
    getTrainingSummary,
    getTrainingSummarySuccess,
    getTrainingSummaryFailure,

    // * Get Training Result Course Summary
    getTrainingResultCourseSummary,
    getTrainingResultCourseSummarySuccess,
    getTrainingResultCourseSummaryFailure,

    // * Get Training Summary Candidate List
    getTrainingSummaryCandidateList,
    getTrainingSummaryCandidateListSuccess,
    getTrainingSummaryCandidateListFailure,

    //* Get candidate training detail stats
    getCandidateTrainingDetailStats,
    getCandidateTrainingDetailStatsSuccess,
    getCandidateTrainingDetailStatsFailure,

    // * Start training
    startTraining,
    startTrainingSuccess,
    startTrainingFailure,

    // * stop training
    stopTraining,
    stopTrainingSuccess,
    stopTrainingFailure,

    // * rebuild training
    rebuildTraining,
    rebuildTrainingSuccess,
    rebuildTrainingFailure,

    // * delete trainings
    deleteTrainings,
    deleteTrainingsSuccess,
    deleteTrainingsFailure,

    // * Get training details
    getTrainingDetails,
    getTrainingDetailsSuccess,
    getTrainingDetailsFailure,
    getTrainingDetailsReset,

    // * Update training
    updateTraining,
    updateTrainingSuccess,
    updateTrainingFailure,
    updateTrainingReset,

    // * Get training clone details
    getTrainingCloneDetails,
    getTrainingCloneDetailsSuccess,
    getTrainingCloneDetailsFailure,
    getTrainingCloneDetailsReset,

    // * Get training summary departments
    getTrainingSummaryDepartments,
    getTrainingSummaryDepartmentsSuccess,
    getTrainingSummaryDepartmentsFailure
};
