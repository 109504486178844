import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import AuthRoutes from 'routes/AuthRoutes';
import ProtectedRoutes from 'routes/ProtectedRoutes';
import NotFound from './404';

import { useAuthSlice } from 'services/auth';
import { useACLSlice } from 'services/acl';
import { useUserSlice } from 'services/user';
import { useORGSlice } from 'services/organization';
import { useCommonSlice } from 'services/common';
import { useAssessmentSlice } from 'services/assessment';
import { useTrainingSlice } from 'services/training';
import { useSimulationSlice } from 'services/simulation';
import { useCandidateSlice } from 'services/candidate';
import { useDashboardSlice } from 'services/dashboard';
import { useExportSlice } from 'services/exports';
import { useStatisticsPhishingSlice } from 'services/statistics/phishing';
import { useAssessmentStatisticsSlice } from 'services/statistics/assessment';
import { useTrainingStatisticsSlice } from 'services/statistics/training';
import { useSummaryStatisticsSlice } from 'services/statistics/summary';
import { useSurveySlice } from 'services/survey';

const App = () => {
    useAuthSlice();
    useACLSlice();
    useUserSlice();
    useORGSlice();
    useCommonSlice();
    useAssessmentSlice();
    useTrainingSlice();
    useSimulationSlice();
    useCandidateSlice();
    useDashboardSlice();
    useExportSlice();
    useSummaryStatisticsSlice();
    useAssessmentStatisticsSlice();
    useTrainingStatisticsSlice();
    useStatisticsPhishingSlice();
    useSurveySlice();

    // ? State
    const { isAuthenticated } = useAuth();

    return (
        <>
            {isAuthenticated !== null && (
                <BrowserRouter>
                    <Routes>
                        {/* Protected route */}
                        <Route
                            path='/*'
                            element={
                                isAuthenticated ? (
                                    <ProtectedRoutes />
                                ) : (
                                    <Navigate to='/auth/login' />
                                )
                            }
                        />

                        {/* Authenticated route */}
                        <Route
                            path='/auth/*'
                            element={
                                !isAuthenticated ? <AuthRoutes /> : <Navigate to='/dashboard' />
                            }
                        />

                        <Route
                            path='/'
                            element={
                                isAuthenticated ? (
                                    <Navigate to='/dashboard' />
                                ) : (
                                    <Navigate to='/auth/login' />
                                )
                            }
                        />

                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            )}
        </>
    );
};

export default App;
