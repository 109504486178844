// const { REACT_APP_DOMAIN } = process.env;
/**
 * Creates an object composed of the own and inherited enumerable property paths of object
 * that are not omitted
 */
const omit = (obj, props) => {
    obj = { ...obj };
    // props = [props];
    props.forEach((prop) => delete obj[prop]);
    return obj;
};

/**
 * Gets the value at path of object.
 * If the resolved value is undefined, the defaultValue is returned in its place.
 */
const get = (obj, path, defValue) => {
    // If path is not defined or it has false value
    if (!path) return undefined;
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    // Find value
    const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result;
};

/**
 *
 * @param {*} actionTypes
 * @returns *_REQUEST / *_SUCCESS /  *_FAILURE actions
 */
const filterActionTypes = (actionTypes) => {
    return actionTypes.map((action) => {
        const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
        if (!matches) return null;
        const [, requestName] = matches;
        return requestName;
    });
};

// ? Check if the object is empty
const isEmptyObject = (obj) => {
    if (obj === null || obj === undefined) return true;

    const values = Object.values(obj);
    return (
        (Object.keys(obj).length === 0 && obj.constructor === Object) ||
        values.every((x) => x === null || x === undefined)
    );
};

// ? Get the key names of an object
const getKeyNames = (object = {}) => {
    return Object.keys(object);
};

const removeUndefinedFieldsFromObject = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
    return obj;
};

const addObjects = (obj1, obj2) => {
    const result = {};

    // Loop through the keys in obj1 and add the values from obj2 if the key exists in obj2
    for (const key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
            result[key] = obj1[key] + (key in obj2 ? obj2[key] : 0);
        }
    }

    // Loop through the keys in obj2 and add any missing keys to the result with their values
    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key) && !(key in obj1)) {
            result[key] = obj2[key];
        }
    }

    return result;
};

const cleanBlankAttributes = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([, v]) => v !== undefined && v !== null && v !== '')
    );
};

const getUrlParams = (params, paramKeys) => {
    let obj = {};
    paramKeys.map((key) => {
        if (params.get(key)) obj[key] = params.get(key);
    });
    return cleanBlankAttributes(obj);
};

const findIndexByValue = (obj, value) => {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
        if (obj[keys[i]] === value) {
            return i;
        }
    }

    return -1; // Value not found
};

// ? Compare 2 objects
const compareObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!compareObjects(val1, val2)) {
                return false;
            }
        } else if (val1 !== val2) {
            return false;
        }
    }

    return true;
};

export default {
    isEmptyObject,
    get,
    omit,
    filterActionTypes,
    getKeyNames,
    removeUndefinedFieldsFromObject,
    addObjects,
    cleanBlankAttributes,
    getUrlParams,
    findIndexByValue,
    compareObjects
};
