import httpClient from 'api';
import ACL_CONSTANTS from 'api/constants/acl';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Staff List
 * @param {Object} data
 * @param {String} data.page
 * @param {String} data.pageSize
 * @returns {Promise}
 */

const getStaffList = catchAsync(async (params) => {
    const response = await httpClient.get(ACL_CONSTANTS.GET_STAFF_LIST, { params });
    return response;
});

/**
 * TODO: Staff List By Role
 * @param {Object} data
 * @param {String} data.page
 * @param {String} data.pageSize
 * @returns {Promise}
 */

const getStaffListByRole = catchAsync(async ({ code, ...rest }) => {
    const response = await httpClient.get(`${ACL_CONSTANTS.GET_STAFF_LIST_BY_ROLE}/${code}`, {
        params: rest
    });
    return response;
});

/**
 * TODO: Delete staffs from role
 * @param {String} roleCode
 * @param {Array} codesOfStaff
 */

const deleteStaffsFromRole = catchAsync(async ({ roleCode, codes }) => {
    const response = await httpClient.delete(
        `${ACL_CONSTANTS.DELETE_STAFFS_FROM_ROLE}/${roleCode}`,
        {
            data: { codes }
        }
    );
    return response;
});

/**
 * TODO: Ban Staff
 * @param {Object} code - uuid of the staff
 * @returns {Promise}
 */

const putBanStaff = catchAsync(async ({ code = '', reason = '' }) => {
    const response = await httpClient.put(`${ACL_CONSTANTS.PUT_BAN_STAFF}/${code}`, { reason });
    return response;
});

/**
 * TODO: Role List
 * @param {Object} data
 * @param {String} data.page
 * @param {String} data.pageSize
 * @returns {Promise}
 */

const getRoleList = catchAsync(async (params) => {
    const response = await httpClient.get(ACL_CONSTANTS.GET_ROLE_LIST, { params });
    return response;
});

/**
 * TODO: Permissions List
 */

const getPermissionsList = catchAsync(async () => {
    const response = await httpClient.get(ACL_CONSTANTS.GET_PERMISSIONS_LIST);
    return response;
});

/**
 * TODO: Add role
 */

const addRole = catchAsync(async (data) => {
    const response = await httpClient.post(ACL_CONSTANTS.ADD_ROLE, data);
    return response;
});

/**
 * TODO: Get role by id
 */

const getRoleById = catchAsync(async (id) => {
    const response = await httpClient.get(`${ACL_CONSTANTS.GET_ROLE_BY_ID}/${id}`);
    return response;
});

/**
 * TODO: Update role
 */

const updateRole = catchAsync(async (id, data) => {
    const response = await httpClient.put(`${ACL_CONSTANTS.UPDATE_ROLE}/${id}`, data);
    return response;
});

/**
 * TODO: Delete roles
 * @param {Array} roles
 */

const deleteRoles = catchAsync(async (roles) => {
    const response = await httpClient.delete(`${ACL_CONSTANTS.DELETE_ROLES}`, {
        data: roles
    });
    return response;
});

/**
 * TODO: Add staff
 */

const addStaff = catchAsync(async (data) => {
    const response = await httpClient.post(ACL_CONSTANTS.ADD_STAFF, data);
    return response;
});

/**
 * TODO: Get staff by id
 */

const getStaffById = catchAsync(async (id) => {
    const response = await httpClient.get(`${ACL_CONSTANTS.GET_STAFF_BY_ID}/${id}`);
    return response;
});

/**
 * TODO: Update staff
 */

const updateStaff = catchAsync(async (id, data) => {
    const response = await httpClient.put(`${ACL_CONSTANTS.UPDATE_STAFF}/${id}`, data);
    return response;
});

/**
 * TODO: Reset staff password
 */

const resetStaffPassword = catchAsync(async (staffCode, data) => {
    const response = await httpClient.post(
        `${ACL_CONSTANTS.RESET_STAFF_PASSWORD}/${staffCode}`,
        data
    );
    return response;
});

/**
 * Interface auth services
 */

const AclServices = {
    getStaffList,
    getStaffListByRole,
    putBanStaff,
    getRoleList,
    getPermissionsList,
    addRole,
    getRoleById,
    updateRole,
    deleteRoles,
    deleteStaffsFromRole,
    addStaff,
    getStaffById,
    updateStaff,
    resetStaffPassword
};

export default AclServices;
