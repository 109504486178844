import { checkItemStatus } from 'transformer/common';
import numberUtils from 'utils/number-utils';
import dateUtils from 'utils/date-utils';
import stringUtils from 'utils/string-utils';

import {
    deleteStatuses,
    startStatuses,
    stopStatuses,
    cloneStatuses,
    rebuildStatus
} from 'common/constants/assessment';

import {
    transformCandidateDataInOverviewStep,
    transformCandidateDataBeforeSendToServer
} from 'transformer/candidate';

/**
 * TODO: Transform training data to show in the review page
 */

const transformTrainingDataToReview = (data) => {
    const { departments, unselectedCandidates, startDate, endDate, methodSelection, candidates } =
        data;
    // ? Get total candidates

    const candidateTransformedData = transformCandidateDataInOverviewStep(
        candidates,
        unselectedCandidates,
        departments,
        methodSelection
    );

    const { totalCandidates, totalCandidatesNotPaidYet, totalCandidatesPaid } =
        candidateTransformedData;

    // ? Get start date
    const startDateFormatted = dateUtils.formatTableDateTime(startDate);

    // ? Get end date
    const endDateFormatted = dateUtils.formatTableDateTime(endDate);
    return {
        ...data,
        startTime: startDateFormatted,
        endTime: endDateFormatted,
        totalCandidates,
        totalCandidatesNotPaidYet,
        totalCandidatesPaid
    };
};

/**
 * TODO: Transform training data before send to server
 */

const transformTrainingDataBeforeSendToServer = (data) => {
    const {
        trainingTitle,
        startDate,
        endDate,
        learnMandatoryRoute,
        departments,
        candidates,
        unselectedCandidates,
        methodSelection,
        courses = []
    } = data;

    const {
        departmentKeys,
        candidates: candidateEmails,
        unselectedCandidates: unselectedCandidateEmails
    } = transformCandidateDataBeforeSendToServer(
        candidates,
        unselectedCandidates,
        departments,
        methodSelection
    );

    const nowDateTime = new Date(Date.now());
    const tempLaunchDate =
        startDate < nowDateTime.toISOString() ? nowDateTime.toISOString() : startDate;

    // ? Format courses
    let tempCourses = courses.map((course) => {
        return {
            ...course,
            modules: course?.modules.map((module) => {
                return module.slug;
            })
        };
    });

    return {
        title: trainingTitle,
        startDate: dateUtils.formatDateTimeParam(tempLaunchDate),
        endDate: dateUtils.formatDateTimeParam(endDate),
        learnMandatoryRoute,
        startOn: dateUtils.formatDateTimeParam(tempLaunchDate),
        timezone: dateUtils.getCurrentTimezone(),
        courses: tempCourses,
        departments: departmentKeys,
        candidateExcludes: unselectedCandidateEmails,
        candidates: candidateEmails
    };
};

const transformTrainingList = (list = [], permissions = {}) => {
    if (list.length === 0) {
        return;
    }

    return list.map((item) => {
        let status = checkItemStatus(item.status);
        return {
            ...item,
            status,
            title: item.title,
            credit: numberUtils.formatNumberWithCommas(item.credit),
            duration: item?.duration || 0,
            startDate: item.startDate,
            endDate: item.endDate,
            isDelete: permissions?.['sat.training.theory.delete']
                ? deleteStatuses.includes(status)
                : false,
            isStart: permissions?.['sat.training.theory.start']
                ? startStatuses.includes(status)
                : false,
            isStop: permissions?.['sat.training.theory.stop']
                ? stopStatuses.includes(status)
                : false,
            isRebuild:
                rebuildStatus.includes(status) && dateUtils.isAfterCurrentTime(item.startDate),
            isEdit: permissions?.['sat.training.theory.update']
                ? status == 'draft' ||
                  (status == 'ready' && dateUtils.isMoreThanMinutes(item.startDate))
                : false,
            isClone: permissions?.['sat.training.theory.clone']
                ? cloneStatuses.includes(status)
                : false
        };
    });
};

const transformTrainingListExpandRowData = (data) => {
    return {
        courseDetail: data?.items,
        title: stringUtils.htmlDecode(data.title),
        duration: data?.courseDuration || 0
    };
};

/**
 * TODO: Transform training summary  data
 */

const transformSummaryData = (data) => {
    return {
        totalCandidates: numberUtils.formatNumberWithCommas(data.totalCandidates),
        chartsData: [
            {
                colors: ['#97D79A', '#F5F5F5'],
                data: [
                    {
                        id: 'training.completed',
                        value: data.details?.percentCompleted,
                        count: data.details?.totalCompleted
                    },
                    {
                        id: 'training.inCompleted',
                        value: 100 - data.details?.percentCompleted
                    }
                ],
                title: 'completed'
            },
            {
                colors: ['#FFD650', '#F5F5F5'],
                data: [
                    {
                        id: 'training.inprogress',
                        value: data.details?.percentInProgress,
                        count: data.details?.totalInProgress
                    },
                    {
                        id: 'training.notInProgress',
                        value: 100 - data.details?.percentInProgress
                    }
                ],
                title: 'incomplete'
            },
            {
                colors: ['#FF7D5C', '#F5F5F5'],
                data: [
                    {
                        id: 'training.notEnroll',
                        value: data.details?.percentNotEnroll,
                        count: data.details?.totalNotEnroll
                    },
                    {
                        id: 'training.enroll',
                        value: 100 - data.details?.percentNotEnroll
                    }
                ],
                title: 'notYetEnrolled'
            }
        ]
    };
};

const transformDetailModulesData = (modules) => {
    return modules.map((module, idx) => {
        const formattedID = numberUtils.formatIDWithTwoDigits(idx + 1);
        return {
            no: formattedID,
            title: module.title,
            complete: numberUtils.formatFloatingPercent(module.percentComplete),
            quiz: `${module.totalQuizRead}/${module.totalQuizzes}`,
            lecture: `${module.totalSlideRead}/${module.totalSlides}`
        };
    });
};

/**
 * TODO: Transform training details data
 */

const transformTrainingDetails = (data) => {
    // ! Format candidates
    let candidateExcludes = data?.candidateExcludes || [];

    // ! Format departments
    let departments = data?.departments || [];
    departments = departments.map((department) => {
        return {
            ...department,
            tempTotalCandidate:
                department?.totalCandidateExcludes + department?.totalCandidateAssigned,
            totalCandidate: department?.totalCandidateAssigned,

            totalCandidateNotPaidYet: 0,
            totalCandidatePaid: department?.totalCandidatePaid,
            departmentKey: department?.departmentKey?.toLowerCase() || '',
            departmentName: department?.departmentName?.toLowerCase() || ''
        };
    });

    // ! Data courses
    let courses = data?.courses || [];

    courses =
        courses.map((course) => {
            return {
                ...course,
                code: course?.courseSlug,
                modules: course?.modules || []
            };
        }) || [];

    const courseDetails = courses?.length > 0 ? courses[0] : {};

    return {
        status: data?.status,
        scheduleStep: {
            trainingTitle: data?.title,
            startDate: data?.startDate,
            endDate: data?.endDate
        },
        candidateStep: {
            departments,
            unselectedCandidates: candidateExcludes,
            candidates: data?.candidates || []
        },
        courseStep: {
            courses,
            courseDetails
        },
        learnMandatoryRoute: data?.learnMandatoryRoute
    };
};

export {
    transformTrainingDataToReview,
    transformTrainingDataBeforeSendToServer,
    transformTrainingList,
    transformTrainingListExpandRowData,
    transformSummaryData,
    transformDetailModulesData,
    transformTrainingDetails
};
