import { checkItemStatus } from 'transformer/common';
import dateUtils from 'utils/date-utils';
import numberUtils from 'utils/number-utils';
import {
    deleteStatuses,
    startStatuses,
    stopStatuses,
    cloneStatuses,
    rebuildStatus
} from 'common/constants/assessment';
import objectUtils from 'utils/object-utils';

const transformSurveyList = (list = [], permissions = {}) => {
    if (list.length === 0) {
        return;
    }

    return list.map((item) => {
        let status = checkItemStatus(item.status);
        return {
            ...item,
            title: item.details.title,
            credit: numberUtils.formatNumberWithCommas(item.credit),
            startDate: item.startDate,
            endDate: item.endDate,
            status: status,
            isDelete: permissions['sat.survey.delete'] ? deleteStatuses.includes(status) : false,
            isStart: permissions['sat.survey.start'] ? startStatuses.includes(status) : false,
            isStop: permissions['sat.survey.stop'] ? stopStatuses.includes(status) : false,
            isRebuild:
                permissions['sat.survey.rebuild'] && dateUtils.isAfterCurrentTime(item.startDate)
                    ? rebuildStatus.includes(status)
                    : false,
            isClone: permissions['sat.survey.index'] ? cloneStatuses.includes(status) : false,
            isEdit: permissions?.['sat.survey.index']
                ? status == 'draft' ||
                  (status == 'ready' && dateUtils.isMoreThanMinutes(item.startDate))
                : false
        };
    });
};

// ? Transform survey to show in overview
const transformSurveyOverview = (survey = {}) => {
    if (objectUtils.isEmptyObject(survey)) return;

    const { scheduleStep, candidateStep } = survey;
    const departments = candidateStep?.departments || [];
    const unselectedCandidates = candidateStep?.unselectedCandidates || [];

    // ? Get start date
    const startDateFormatted = dateUtils.formatTableDateTime(scheduleStep?.startDate);

    // ? Get end date
    const endDateFormatted = dateUtils.formatTableDateTime(scheduleStep?.endDate);

    // ? Get total candidates
    let totalCandidates = 0;
    let totalCandidatesNotPaidYet = 0;
    let totalCandidatesPaid = 0;

    // ? Get total candidates
    totalCandidates =
        departments.reduce((total, department) => total + department.tempTotalCandidate, 0) -
            unselectedCandidates?.length || 0;

    // ? Get total candidates paid
    const totalCandidatesPaidUnselected =
        unselectedCandidates?.filter((candidate) => {
            return candidate?.isPaid;
        })?.length || 0;

    totalCandidatesPaid =
        departments.reduce((total, department) => {
            return total + department.totalCandidatePaid;
        }, 0) - totalCandidatesPaidUnselected;

    // ? Get total candidates not paid yet
    totalCandidatesNotPaidYet = totalCandidates - totalCandidatesPaid;

    return {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        totalCandidatesNotPaidYet,
        totalCandidatesPaid,
        totalCandidates
    };
};

// ? Transform survey before send to API
const transformSurveyBeforeSend = (survey = {}) => {
    if (objectUtils.isEmptyObject(survey)) return;

    const { scheduleStep, candidateStep, surveyStep } = survey;

    // ? Get all departmentKeys of departments
    const departmentKeys = candidateStep?.departments.map((department) => department.departmentKey);

    // ? Format candidate excludes
    let candidatesTransformer = [];

    if (candidateStep?.unselectedCandidates.length > 0) {
        candidatesTransformer = candidateStep?.unselectedCandidates.map((candidate) => {
            return candidate?.email;
        });
    }

    return {
        title: scheduleStep?.surveyTitle,
        startDate: dateUtils.formatDateTimeParam(scheduleStep?.startDate),
        endDate: dateUtils.formatDateTimeParam(scheduleStep?.endDate),
        timezone: dateUtils.getCurrentTimezone(),
        departments: departmentKeys,
        candidateExcludes: candidatesTransformer,
        templateType: surveyStep?.surveyMode,
        templateCodes: surveyStep?.surveyCodes,
        locales: surveyStep?.locales || []
    };
};

// ? Transform survey details
const transformSurveyDetails = (data = {}) => {
    // ! Format candidates
    let candidateExcludes = data?.candidateExcludes || [];

    // ! Format departments
    let departments = data?.departments || [];
    departments = departments.map((department) => {
        return {
            ...department,
            tempTotalCandidate:
                department?.totalCandidateExcludes + department?.totalCandidateAssigned,
            totalCandidate: department?.totalCandidateAssigned,
            totalCandidateNotPaidYet: 0,
            totalCandidatePaid: department?.totalCandidatePaid,
            departmentKey: department?.departmentKey?.toLowerCase() || '',
            departmentName: department?.departmentName?.toLowerCase() || ''
        };
    });

    // ! Format survey codes
    let surveyCodes = data?.templates?.map((template) => template.slug);

    return {
        scheduleStep: {
            startDate: data?.startDate,
            endDate: data?.endDate,
            surveyTitle: data?.title
        },
        candidateStep: {
            departments,
            candidates: data?.candidates,
            unselectedCandidates: candidateExcludes
        },
        surveyStep: {
            surveyMode: 'template',
            surveyCodes,
            surveyCodesData: data?.templates,
            locales: data?.locales
        },
        status: data?.status
    };
};

export {
    transformSurveyList,
    transformSurveyOverview,
    transformSurveyBeforeSend,
    transformSurveyDetails
};
