import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import EXPORT_CONSTANTS from 'api/constants/exports';
import dateUtils from 'utils/date-utils';
import objectUtils from 'utils/object-utils';

const createRequestURL = (path, code, endpoint) =>
    `${path}/${endpoint}`.replace(':assessmentCode', code);

/**
 * TODO: Export assessment participants
 */

const exportAssessmentParticipantsPDF = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_PDF_EXPORT,
        assessmentCode,
        'candidates'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportAssessmentParticipantsSheet = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_SHEET_EXPORT,
        assessmentCode,
        'candidates'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export assessment questions
 */

const exportAssessmentQuestionsPDF = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_PDF_EXPORT,
        assessmentCode,
        'questions'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportAssessmentQuestionsSheet = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_SHEET_EXPORT,
        assessmentCode,
        'questions'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export assessment in-progress
 */

const exportAssessmentInprogressPDF = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_PDF_EXPORT,
        assessmentCode,
        'statistic'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportAssessmentInprogressSheet = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_SHEET_EXPORT,
        assessmentCode,
        'statistic'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export assessment result
 */

const exportAssessmentResultPDF = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_PDF_EXPORT,
        assessmentCode,
        'summary'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            candidates: objectUtils.omit(params.candidates, ['page', 'pageSize']),
            questions: objectUtils.omit(params.questions, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportAssessmentResultSheet = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_SHEET_EXPORT,
        assessmentCode,
        'summary'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            candidates: objectUtils.omit(params.candidates, ['page', 'pageSize']),
            questions: objectUtils.omit(params.questions, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export assessment individual result
 */

const exportAssessmentIndividualPDF = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_PDF_EXPORT,
        assessmentCode,
        'candidate/results'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportAssessmentIndividualSheet = catchAsync(async ({ params, assessmentCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.ASSESSMENT_SHEET_EXPORT,
        assessmentCode,
        'candidate/results'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const AssessmentServices = {
    exportAssessmentParticipantsPDF,
    exportAssessmentParticipantsSheet,
    exportAssessmentQuestionsPDF,
    exportAssessmentQuestionsSheet,
    exportAssessmentInprogressPDF,
    exportAssessmentInprogressSheet,
    exportAssessmentResultPDF,
    exportAssessmentResultSheet,
    exportAssessmentIndividualPDF,
    exportAssessmentIndividualSheet
};

export default AssessmentServices;
