const EXPORT_CONSTANTS = {
    ASSESSMENT_PDF_EXPORT: '/assessments/:assessmentCode/export/pdf',
    ASSESSMENT_SHEET_EXPORT: '/assessments/:assessmentCode/export/sheet',
    TRAINING_PDF_EXPORT: '/trainings/:trainingCode/export/pdf',
    TRAINING_SHEET_EXPORT: '/trainings/:trainingCode/export/sheet',
    PHISHING_PDF_EXPORT: '/phishing/:phishingCode/export/pdf',
    PHISHING_SHEET_EXPORT: '/phishing/:phishingCode/export/sheet',
    PARTICIPANT_SHEET_EXPORT: '/user/candidate/sheet/export',
    EXPORT_REPORT_PDF: 'report/other/export/report',
    EXPORT_REPORT_AS_RAW: 'report/other/candidates/raw/export',
    GET_EXPORT_TOKEN: 'report/other/export/token',
    SURVEY_SHEET_EXPORT: 'surveys/:surveyCode/export'
};

export default EXPORT_CONSTANTS;
