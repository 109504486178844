import initState from './initialState';
import actionTypes from './actionTypes';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSESSMENT_REPORT_SUMMARY_SUCCESS:
            return reducerHandler.getAssessmentSummary(state, action);
        case actionTypes.GET_ASSESSMENT_REPORT_TOTAL_SUCCESS:
            return reducerHandler.getAssessmentTotal(state, action);
        case actionTypes.ASSESSMENT_BY_DEPARTMENT_SUCCESS:
            return reducerHandler.getAssessmentByDepartment(state, action);
        case actionTypes.NOT_YET_ASSIGNED_TO_ASSESSMENT_SUCCESS:
            return reducerHandler.getParticipantNotYetAssigned(state, action);
        default:
            return state;
    }
};

export default reducer;
