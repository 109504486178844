import types from './actionTypes';

// * Get list of departments
const getDepartmentList = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_REQUEST,
    payload
});

const getDeparmentListSuccess = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_SUCCESS,
    payload
});

const getDeparmentListFailure = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_FAILURE,
    payload
});

// * Get list of departments for sms phishing
const getDepartmentListForSmsPhishing = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_REQUEST,
    payload
});

const getDeparmentListForSmsPhishingSuccess = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_SUCCESS,
    payload
});

const getDeparmentListForSmsPhishingFailure = (payload) => ({
    type: types.GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_FAILURE,
    payload
});

// * Toggle breadcrumbs
const toggleBreadcrumbs = (payload) => ({
    type: types.TOGGLE_BREADCRUMBS,
    payload
});

const toggleBreadcrumbsSuccess = (payload) => ({
    type: types.TOGGLE_BREADCRUMBS_SUCCESS,
    payload
});

const toggleBreadcrumbsFailure = (payload) => ({
    type: types.TOGGLE_BREADCRUMBS_FAILURE,
    payload
});

export {
    // ? Get list of departments
    getDepartmentList,
    getDeparmentListSuccess,
    getDeparmentListFailure,

    // ? Get list of departments for sms phishing
    getDepartmentListForSmsPhishing,
    getDeparmentListForSmsPhishingSuccess,
    getDeparmentListForSmsPhishingFailure,

    // ? Toggle breadcrumbs
    toggleBreadcrumbs,
    toggleBreadcrumbsSuccess,
    toggleBreadcrumbsFailure
};
