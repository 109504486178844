import { store } from 'index';
import reducer from './reducer';
import saga from './saga';

const key = 'statisticsPhishing';

export const useStatisticsPhishingSlice = () => {
    store.injectReducer(key, reducer);
    store.injectSaga(key, saga);
};
