import { combineReducers } from 'redux';
import { loadingReducer } from './loading/reducer';
import { errorReducer } from './error/reducer';

const rootReducer = () => {
    return {};
};
const staticReducers = {
    root: rootReducer,
    loading: loadingReducer,
    error: errorReducer
};
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducers = {}) => {
    // Initially we don't have any injectedReducers, so returning identity function to avoid the error
    const rootReducer = combineReducers({
        ...staticReducers,
        ...injectedReducers
    });
    return rootReducer;
};

export default createReducer;
