import { transformCandidatesList } from 'transformer/candidate';

/**
 * TODO: Transform Candidates data
 */

const getListCandidates = (state, payload) => {
    return {
        ...state,
        deleteCandidatesSuccessMessage: '',
        listCandidates: {
            data: transformCandidatesList(payload.candidates),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Get list candidates for sms campaign
 */

const getListCandidatesForSmsCampaign = (state, payload) => {
    return {
        ...state,
        listCandidatesForSmsCampaign: {
            data: transformCandidatesList(payload.candidates),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: delete candidates
 */

const deleteCandidates = (state) => {
    return {
        ...state,
        deleteCandidatesSuccessMessage: 'deleteSuccess'
    };
};

/**
 * TODO: delete candidates
 */

const resetDeleteFailedError = (state) => {
    return {
        ...state
    };
};

/**
 * TODO: Add candidates
 */

const addCandidatesReducerHandler = (state) => {
    return {
        ...state,
        isAddCandidatesSuccess: true
    };
};

const addCandidatesResetReducerHandler = (state) => {
    return {
        ...state,
        isAddCandidatesSuccess: false
    };
};

/**
 * TODO: Import candidates
 */

const importCandidatesReducerHandler = (state) => {
    return {
        ...state,
        isImportCandidatesSuccess: true
    };
};

const importCandidatesResetReducerHandler = (state) => {
    return {
        ...state,
        isImportCandidatesSuccess: false
    };
};

/**
 * TODO: Update candidate
 */

const updateCandidateReducerHandler = (state) => {
    return {
        ...state,
        isUpdateCandidateSuccess: true
    };
};

const updateCandidateResetReducerHandler = (state) => {
    return {
        ...state,
        isUpdateCandidateSuccess: false
    };
};

/**
 * TODO: Check valid candidates
 */

const checkValidCandidatesReducerHandler = (state, payload) => {
    return {
        ...state,
        validCandidates: payload?.candidates || []
    };
};

const checkValidCandidatesResetReducerHandler = (state) => {
    return {
        ...state,
        validCandidates: []
    };
};

export default {
    getListCandidates,
    getListCandidatesForSmsCampaign,
    deleteCandidates,
    resetDeleteFailedError,
    addCandidatesReducerHandler,
    addCandidatesResetReducerHandler,
    importCandidatesReducerHandler,
    importCandidatesResetReducerHandler,
    updateCandidateReducerHandler,
    updateCandidateResetReducerHandler,
    checkValidCandidatesReducerHandler,
    checkValidCandidatesResetReducerHandler
};
