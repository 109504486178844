import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_CONTRACT_LIST_SUCCESS:
            return reducerHandler.getContractListHandler(state, action);
        case types.GET_CONTRACT_DETAIL_SUCCESS:
            return reducerHandler.getContractDetailHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
