const TRAINING_CONSTANTS = {
    GET_LIST_COURSE: 'trainings/course/list',
    CREATE_TRAINING: 'trainings/create',
    GET_LIST_TRAINING: 'trainings',
    GET_TRAINING_DETAILS: 'trainings',
    UPDATE_TRAINING: 'trainings',
    GET_TRAINING_CLONE_DETAILS: 'trainings',
    SUMMARY_DEPARTMENTS: 'department/statistics'
};

export default TRAINING_CONSTANTS;
