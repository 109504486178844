import loadable from '@loadable/component';

const UserSettingsLayout = loadable(() => import('pages/user/settings'));

const userRouteItems = [
    {
        value: 'user/settings',
        key: 'sat.user',
        component: UserSettingsLayout
    }
];

export default userRouteItems;
