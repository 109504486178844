const actionTypes = {
    // * Export phishing targets
    EXPORT_PHISHING_TARGETS_PDF_REQUEST: 'EXPORT_PHISHING_TARGETS_PDF_REQUEST',
    EXPORT_PHISHING_TARGETS_PDF_SUCCESS: 'EXPORT_PHISHING_TARGETS_PDF_SUCCESS',
    EXPORT_PHISHING_TARGETS_PDF_FAILURE: 'EXPORT_PHISHING_TARGETS_PDF_FAILURE',

    EXPORT_PHISHING_TARGETS_SHEET_REQUEST: 'EXPORT_PHISHING_TARGETS_SHEET_REQUEST',
    EXPORT_PHISHING_TARGETS_SHEET_SUCCESS: 'EXPORT_PHISHING_TARGETS_SHEET_SUCCESS',
    EXPORT_PHISHING_TARGETS_SHEET_FAILURE: 'EXPORT_PHISHING_TARGETS_SHEET_FAILURE',

    // * Export phishing result
    EXPORT_PHISHING_RESULT_PDF_REQUEST: 'EXPORT_PHISHING_RESULT_PDF_REQUEST',
    EXPORT_PHISHING_RESULT_PDF_SUCCESS: 'EXPORT_PHISHING_RESULT_PDF_SUCCESS',
    EXPORT_PHISHING_RESULT_PDF_FAILURE: 'EXPORT_PHISHING_RESULT_PDF_FAILURE',

    EXPORT_PHISHING_RESULT_SHEET_REQUEST: 'EXPORT_PHISHING_RESULT_SHEET_REQUEST',
    EXPORT_PHISHING_RESULT_SHEET_SUCCESS: 'EXPORT_PHISHING_RESULT_SHEET_SUCCESS',
    EXPORT_PHISHING_RESULT_SHEET_FAILURE: 'EXPORT_PHISHING_RESULT_SHEET_FAILURE'
};

export default actionTypes;
