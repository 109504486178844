import React from 'react';
import { Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

const AuthLayout = loadable(() => import('layouts/AuthLayout'));
const LoginPage = loadable(() => import('pages/auth/Login'));
const RegisterPage = loadable(() => import('pages/auth/Register'));
const ForgotPassword = loadable(() => import('pages/auth/ForgotPassword'));
const ResetPassword = loadable(() => import('pages/auth/ResetPassword'));
const Verify2FA = loadable(() => import('pages/auth/Verify2FA'));
const NotFound = loadable(() => import('pages/404'));

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path='/*' element={<AuthLayout />}>
                <Route index path='login' element={<LoginPage />} />
                <Route path='register' element={<RegisterPage />} />
                <Route path='verify-otp' element={<Verify2FA />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='reset-password/*' element={<ResetPassword />} />
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    );
};

export default AuthRoutes;
