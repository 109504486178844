import numberUtils from 'utils/number-utils';
import stringUtils from 'utils/string-utils';

const transformDataWithID = (
    data,
    idKey = 'id',
    currentPage = 1,
    pageSize = 10,
    totalRecord = 0,
    sortDirection = ''
) => {
    let transformedData = [];

    for (let i = 0; i < data.length; i++) {
        let id = i + pageSize * (currentPage - 1) + 1;

        if (sortDirection === 'asc') {
            id = totalRecord - ((currentPage - 1) * pageSize + i);
        }
        let item = data[i];
        let formattedID = numberUtils.formatIDWithTwoDigits(id);
        let decodedDepartments = stringUtils.htmlDecode(item?.departments);
        let assessment = numberUtils.formatFloatingScore(item?.assessment);
        let training = numberUtils.formatFloatingScore(item?.training);
        let phishing = numberUtils.formatFloatingScore(item?.phishing);
        let totalScore = numberUtils.formatFloatingScore(item?.totalScore);

        transformedData.push({
            ...item,
            assessment,
            training,
            phishing,
            totalScore,
            departments: decodedDepartments,
            [idKey]: formattedID
        });
    }

    return transformedData;
};

const transformAssessmentByDepartment = (data, idKey = 'id', currentPage = 1, pageSize = 10) => {
    return data.map((item, key) => {
        let index = key + 1;
        let formattedID = numberUtils.formatIDWithTwoDigits(index + pageSize * (currentPage - 1));
        let department = stringUtils.htmlDecode(item.department);
        return {
            [idKey]: formattedID,
            department,
            numberOfAccounts: item.numberOfAccounts,
            passedPercent: item.passedPercent + '%'
        };
    });
};

const transformNotYetAssignedData = (data, idKey = 'id', currentPage = 1, pageSize = 10) => {
    return data.map((item, key) => {
        let index = key + 1;
        let formattedID = numberUtils.formatIDWithTwoDigits(index + pageSize * (currentPage - 1));
        let department = stringUtils.htmlDecode(item.department);
        return {
            [idKey]: formattedID,
            department: department,
            fullName: item.fullName,
            email: item.email
        };
    });
};

const transformTrainingByDepartment = (data, idKey = 'id', currentPage = 1, pageSize = 10) => {
    return data.map((item, key) => {
        let index = key + 1;
        let formattedID = numberUtils.formatIDWithTwoDigits(index + pageSize * (currentPage - 1));
        let department = stringUtils.htmlDecode(item.department);
        return {
            [idKey]: formattedID,
            department,
            numberOfAccounts: item.numberOfAccounts,
            completedPercent: item.completedPercent
        };
    });
};

const transformTablePagination = (meta, totalCountTxt = 'totalDepartment') => {
    return {
        ...meta,
        totalCountTxt
    };
};

const transformDepartmentsList = (list = []) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item) => {
        return {
            name: stringUtils.htmlDecode(item.departmentName),
            key: stringUtils.htmlDecode(item.departmentKey),
            checked: false
        };
    });
};

export {
    transformDataWithID,
    transformTablePagination,
    transformAssessmentByDepartment,
    transformDepartmentsList,
    transformNotYetAssignedData,
    transformTrainingByDepartment
};
