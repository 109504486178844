import services from 'api/statistics/training';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import actions from './actions';
import actionTypes from './actionTypes';

/**
 * TODO: Get training total
 */
function* getTrainingTotalHandler({ payload }) {
    try {
        const response = yield call(services.getTrainingTotal, payload);
        yield put(actions.getTrainingTotalSuccess(response.data));
    } catch (error) {
        yield put(actions.getTrainingTotalFailure(error));
    }
}

/**
 * TODO: Get training summary
 */
function* getTrainingSummaryHandler({ payload }) {
    try {
        const response = yield call(services.getTrainingSummary, payload);
        yield put(actions.getTrainingSummarySuccess(response.data));
    } catch (error) {
        yield put(actions.getTrainingSummaryFailure(error));
    }
}

/**
 * TODO: Get Training by department
 */
function* getTrainingByDepartmentHandler({ payload }) {
    try {
        const response = yield call(services.getTrainingByDepartment, payload);
        yield put(actions.getTrainingByDepartmentSuccess(response.data));
    } catch (error) {
        yield put(actions.getTrainingByDepartmentFailure(error));
    }
}

/**
 * TODO: Not yet assigned
 */
function* getParticipantNotYetAssignedHandler({ payload }) {
    try {
        const response = yield call(services.getParticipantNotYetAssignToTraining, payload);
        yield put(actions.getParticipantNotYetAssignedToTrainingSuccess(response.data));
    } catch (error) {
        yield put(actions.getParticipantNotYetAssignedToTrainingFailure(error));
    }
}

function* watchSummarySaga() {
    yield takeLatest(actionTypes.GET_TRAINING_REPORT_SUMMARY_REQUEST, getTrainingSummaryHandler);
    yield takeLatest(actionTypes.GET_TRAINING_REPORT_TOTAL_REQUEST, getTrainingTotalHandler);
    yield takeLatest(actionTypes.TRAINING_BY_DEPARTMENT_REQUEST, getTrainingByDepartmentHandler);
    yield takeLatest(
        actionTypes.NOT_YET_ASSIGNED_TO_TRAINING_REQUEST,
        getParticipantNotYetAssignedHandler
    );
}

export default function* summaryReportSaga() {
    yield fork(watchSummarySaga);
}
