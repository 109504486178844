import httpClient from 'api';
import ASSESSMENT_CONSTANTS from 'api/constants/assessment';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get list assessment
 */

const getListAssessment = catchAsync(async (params) => {
    const response = await httpClient.get(ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT, {
        params
    });
    return response;
});

/**
 * TODO: Get list questions
 */

const getListQuestions = catchAsync(async (data) => {
    const response = await httpClient.get(ASSESSMENT_CONSTANTS.GET_LIST_QUESTION, {
        params: data
    });
    return response;
});

/**
 * TODO: Get list templates
 */

const getListTemplates = catchAsync(async (data) => {
    const response = await httpClient.get(ASSESSMENT_CONSTANTS.GET_LIST_TEMPLATES, {
        params: data
    });
    return response;
});

/**
 * TODO: Create assessment
 */

const createAssessment = catchAsync(async (data) => {
    const response = await httpClient.post(ASSESSMENT_CONSTANTS.CREATE_ASSESSMENT, data);
    return response;
});

/**
 * TODO: Edit assessment
 */

const editAssessment = catchAsync(async ({ data, assessmentCode }) => {
    const response = await httpClient.put(
        `${ASSESSMENT_CONSTANTS.EDIT_ASSESSMENT}/${assessmentCode}`,
        data
    );
    return response;
});

/**
 * TODO: Get assessment details
 */

const getAssessmentDetails = catchAsync(async (assessmentCode) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_ASSESSMENT_DETAILS}/${assessmentCode}/edit`
    );
    return response;
});

/**
 * TODO: Get assessment clone details
 */

const getAssessmentCloneDetails = catchAsync(async (assessmentCode) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_ASSESSMENT_CLONE_DETAILS}/${assessmentCode}/clone`
    );
    return response;
});

/**
 * TODO: Get list questions by template ids
 */

const getListQuestionsByTemplateIds = catchAsync(async (data) => {
    const response = await httpClient.get(ASSESSMENT_CONSTANTS.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS, {
        params: data
    });
    return response;
});

/**
 * TODO: Get list exam tags
 */

const getListExamTags = catchAsync(async (data) => {
    const response = await httpClient.get(ASSESSMENT_CONSTANTS.GET_LIST_EXAM_TAGS, {
        params: data
    });
    return response;
});

/**
 * TODO: Get list candidate by assessment
 */

const getListCandidateByAssessment = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/candidates`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get list question by assessment
 */

const getListQuestionByAssessment = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/questions`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get Assessment's Summary Participants
 */

const getAssessmentSummaryParticipants = catchAsync(async ({ assessmentCode = '' }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/summary/participants`
    );
    return response;
});

/**
 * TODO: Get Assessment's Summary Statistic
 */

const getAssessmentSummaryStatistic = catchAsync(async ({ assessmentCode = '' }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/summary/statistic`
    );
    return response;
});

/**
 * TODO: Get Assessment's Summary Questions
 */

const getAssessmentSummaryQuestions = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/summary/questions/results`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get Assessment's Summary Candidates
 */

const getAssessmentSummaryCandidates = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/summary/candidates`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get Assessment's Candidate Detail
 */

const getAssessmentCandidateDetail = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/detail/candidate`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get Assessment's Individual Candidate Result
 */

const getAssessmentIndividualCandidateResult = catchAsync(
    async ({ assessmentCode = '', ...rest }) => {
        const response = await httpClient.get(
            `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/detail/candidate/results`,
            {
                params: rest
            }
        );
        return response;
    }
);

/**
 * TODO: Get Assessment's Detail Question's Result
 */

const getAssessmentDetailQuestionResult = catchAsync(
    async ({ assessmentCode = '', questionCode = '', ...rest }) => {
        const response = await httpClient.get(
            `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}/detail/${questionCode}/candidate`,
            {
                params: rest
            }
        );
        return response;
    }
);

/**
 * TODO: Start Assessment
 */

const putStartAssessment = catchAsync(async ({ assessmentCode = '' }) => {
    const response = await httpClient.put(
        `${ASSESSMENT_CONSTANTS.START_ASSESSMENT}/${assessmentCode}`
    );
    return response;
});

/**
 * TODO: Stop Assessment
 */

const putStopAssessment = catchAsync(async ({ assessmentCode = '' }) => {
    const response = await httpClient.put(
        `${ASSESSMENT_CONSTANTS.STOP_ASSESSMENT}/${assessmentCode}`
    );
    return response;
});

/**
 * TODO: Rebuild Assessment
 */

const putRebuildAssessment = catchAsync(async ({ assessmentCode = '' }) => {
    const response = await httpClient.put(
        `${ASSESSMENT_CONSTANTS.REBUILD_ASSESSMENT}/${assessmentCode}`
    );
    return response;
});

/**
 * TODO: Delete Assessments
 */

const deleteAssessments = catchAsync(async (params) => {
    const response = await httpClient.delete(`${ASSESSMENT_CONSTANTS.DELETE_ASSESSMENTS}`, {
        params
    });
    return response;
});

/**
 * TODO: Get language supported
 */

const getLanguageSupported = catchAsync(async () => {
    const response = await httpClient.get(`${ASSESSMENT_CONSTANTS.GET_LANGUAGE_SUPPORTED}`);
    return response;
});

/**
 * TODO: Get summary departments
 */

const getAssessmentSummaryDepartments = catchAsync(async ({ assessmentCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${ASSESSMENT_CONSTANTS.GET_LIST_ASSESSMENT}/${assessmentCode}${ASSESSMENT_CONSTANTS.GET_SUMMARY_DEPARTMENTS}`,
        {
            params: rest
        }
    );
    return response;
});

const AssessmentServices = {
    getListAssessment,
    getListQuestions,
    getListTemplates,
    createAssessment,
    editAssessment,
    getAssessmentDetails,
    getAssessmentCloneDetails,
    getListQuestionsByTemplateIds,
    getListExamTags,
    getListCandidateByAssessment,
    getListQuestionByAssessment,
    getAssessmentSummaryParticipants,
    getAssessmentSummaryStatistic,
    getAssessmentSummaryQuestions,
    getAssessmentSummaryCandidates,
    getAssessmentCandidateDetail,
    getAssessmentIndividualCandidateResult,
    getAssessmentDetailQuestionResult,
    putStartAssessment,
    putStopAssessment,
    putRebuildAssessment,
    deleteAssessments,
    getLanguageSupported,
    getAssessmentSummaryDepartments
};

export default AssessmentServices;
