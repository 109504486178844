import httpClient from 'api';
import CANDIDATE_CONSTANTS from 'api/constants/candidate';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get list candidate
 */

const getListCandidate = catchAsync(async (params) => {
    const response = await httpClient.get(CANDIDATE_CONSTANTS.GET_LIST_CANDIDATE, {
        params
    });
    return response;
});

/**
 * TODO: Get list candidate for sms campaign
 */

const getListCandidateForSmsCampaign = catchAsync(async (params) => {
    const response = await httpClient.get(CANDIDATE_CONSTANTS.GET_LIST_CANDIDATE_FOR_SMS_CAMPAIGN, {
        params
    });
    return response;
});

/**
 * TODO: Delete candidates
 */

const deleteCandidates = catchAsync(async (params) => {
    const response = await httpClient.delete(`${CANDIDATE_CONSTANTS.DELETE_CANDIDATE}`, {
        data: params
    });
    return response;
});

/**
 * TODO: Add candidates
 */

const addCandidates = catchAsync(async (requestBody) => {
    const response = await httpClient.post(`${CANDIDATE_CONSTANTS.ADD_CANDIDATES}`, requestBody);
    return response;
});

/**
 * TODO: Import candidates
 */

const importCandidates = catchAsync(async (formData) => {
    const response = await httpClient.post(`${CANDIDATE_CONSTANTS.IMPORT_CANDIDATES}`, formData);
    return response;
});

/**
 * TODO: Update candidates
 */

const updateCandidate = catchAsync(async (data) => {
    // ? Remove email field from data
    const { email, ...rest } = data;

    const response = await httpClient.put(`${CANDIDATE_CONSTANTS.UPDATE_CANDIDATE}/${email}`, rest);
    return response;
});

/**
 * TODO: Check valid candidates
 */

const checkValidCandidates = catchAsync(async (data) => {
    const response = await httpClient.post(`${CANDIDATE_CONSTANTS.CHECK_VALID_CANDIDATES}`, data);
    return response;
});

const CandidateServices = {
    getListCandidate,
    getListCandidateForSmsCampaign,
    deleteCandidates,
    addCandidates,
    importCandidates,
    updateCandidate,
    checkValidCandidates
};

export default CandidateServices;
