import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_STAFF_LIST_SUCCESS:
            return reducerHandler.getStaffListHandler(state, action);
        case types.DELETE_STAFFS_FROM_ROLE_SUCCESS:
            return reducerHandler.deleteStaffsFromRoleHandler(state, action);
        case types.GET_STAFF_LIST_BY_ROLE_SUCCESS:
            return reducerHandler.getStaffListByRoleHandler(state, action);
        case types.PUT_BAN_STAFF_SUCCESS:
            return reducerHandler.banStaffHandler(state, action);
        case types.GET_PERMISSIONS_LIST_SUCCESS:
            return reducerHandler.getPermissionsListHandler(state, action);
        case types.GET_ROLE_LIST_SUCCESS:
            return reducerHandler.getRoleListHandler(state, action);
        case types.ADD_ROLE_SUCCESS:
            return reducerHandler.addRoleHandler(state, action);
        case types.ADD_ROLE_RESET:
            return reducerHandler.resetAddRoleHandler(state, action);
        case types.GET_ROLE_BY_ID_SUCCESS:
            return reducerHandler.getRoleByIdHandler(state, action);
        case types.GET_ROLE_BY_ID_RESET:
            return reducerHandler.resetGetRoleByIdHandler(state, action);
        case types.UPDATE_ROLE_SUCCESS:
            return reducerHandler.updateRoleHandler(state, action);
        case types.UPDATE_ROLE_RESET:
            return reducerHandler.resetUpdateRoleHandler(state, action);
        case types.DELETE_ROLES_SUCCESS:
            return reducerHandler.deleteRolesHandler(state, action);
        case types.ADD_STAFF_SUCCESS:
            return reducerHandler.addStaffHandler(state, action);
        case types.ADD_STAFF_RESET:
            return reducerHandler.resetAddStaffHandler(state, action);
        case types.GET_STAFF_BY_ID_SUCCESS:
            return reducerHandler.getStaffByIdHandler(state, action);
        case types.UPDATE_STAFF_SUCCESS:
            return reducerHandler.updateStaffHandler(state, action);
        case types.UPDATE_STAFF_RESET:
            return reducerHandler.resetUpdateStaffHandler(state, action);
        case types.RESET_STAFF_PASSWORD_SUCCESS:
            return reducerHandler.resetStaffPasswordHandler(state, action);
        case types.RESET_STAFF_PASSWORD_RESET:
            return reducerHandler.resetResetStaffPasswordHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
