import AuthServices from 'api/auth';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import authActionTypes from './actionTypes';
import {
    loginSuccess,
    loginFailure,
    registerSuccess,
    registerFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    forgotPasswordVerifyCodeSuccess,
    forgotPasswordVerifyCodeFailure,
    forgotPasswordResendSuccess,
    forgotPasswordResendFailure,
    logoutSuccess,
    logoutFailure,
    verifyOTPSuccess,
    verifyOTPFailure,
    resendOTPSuccess,
    resendOTPFailure
} from './actions';

/**
 * TODO: Login request handler
 */

function* loginRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.login, payload);
        yield put(loginSuccess(response.data));
    } catch (errors) {
        yield put(loginFailure(errors));
    }
}

/**
 * TODO: Register request handler
 */

function* registerRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.register, payload);
        yield put(registerSuccess(response.data));
    } catch (errors) {
        yield put(registerFailure(errors));
    }
}

/**
 * TODO: Forgot password request handler
 */

function* forgotPasswordRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.forgotPassword, payload);
        yield put(forgotPasswordSuccess(response.data));
    } catch (errors) {
        yield put(forgotPasswordFailure(errors));
    }
}

/**
 * TODO: Forgot password resend request handler
 */

function* forgotPasswordResendRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.forgotPasswordResend, payload);
        yield put(forgotPasswordResendSuccess(response.data));
    } catch (errors) {
        yield put(forgotPasswordResendFailure(errors));
    }
}

/**
 * TODO: Forgot password verify code request handler
 */

function* forgotPasswordVerifyCodeRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.forgotPasswordVerifyCode, payload);
        yield put(forgotPasswordVerifyCodeSuccess(response.data));
    } catch (errors) {
        yield put(forgotPasswordVerifyCodeFailure(errors));
    }
}

/**
 * TODO: Reset password request handler
 */

function* resetPasswordRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.resetPassword, payload);
        yield put(resetPasswordSuccess(response.data));
    } catch (errors) {
        yield put(resetPasswordFailure(errors));
    }
}

/**
 * TODO: Logout request handler
 */

function* logoutRequestHandler() {
    try {
        yield call(AuthServices.logout);
        yield put(logoutSuccess());
    } catch (errors) {
        yield put(logoutFailure(errors));
    }
}

/**
 * TODO: Verify OTP request handler
 */

function* verifyOTPRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.verifyOTP, payload);
        yield put(verifyOTPSuccess(response.data));
    } catch (errors) {
        yield put(verifyOTPFailure(errors));
    }
}

/**
 * TODO: Resend OTP request handler
 */

function* resendOTPRequestHandler({ payload }) {
    try {
        const response = yield call(AuthServices.resendOTP, payload);
        yield put(resendOTPSuccess(response.data));
    } catch (errors) {
        yield put(resendOTPFailure(errors));
    }
}

function* watchAuthSaga() {
    yield takeLatest(authActionTypes.LOGIN_REQUEST, loginRequestHandler);
    yield takeLatest(authActionTypes.REGISTER_REQUEST, registerRequestHandler);
    yield takeLatest(authActionTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordRequestHandler);
    yield takeLatest(
        authActionTypes.FORGOT_PASSWORD_RESEND_REQUEST,
        forgotPasswordResendRequestHandler
    );
    yield takeLatest(
        authActionTypes.FORGOT_PASSWORD_VERIFY_CODE_REQUEST,
        forgotPasswordVerifyCodeRequestHandler
    );
    yield takeLatest(authActionTypes.RESET_PASSWORD_REQUEST, resetPasswordRequestHandler);
    yield takeLatest(authActionTypes.LOGOUT_REQUEST, logoutRequestHandler);
    yield takeLatest(authActionTypes.VERIFY_OTP_REQUEST, verifyOTPRequestHandler);
    yield takeLatest(authActionTypes.RESEND_OTP_REQUEST, resendOTPRequestHandler);
}

export default function* authSaga() {
    yield fork(watchAuthSaga);
}
