import Cookies from 'js-cookie';
import httpClient from 'api';
import EXPORT_REPORT_CONSTANTS from 'api/constants/exports';
import { catchAsync } from 'utils/error-utils';
import dateUtils from 'utils/date-utils';

const lang = Cookies.get('i18next') || 'en';
const timezone = dateUtils.getCurrentTimezone();

/**
 * TODO: Export Report
 */

const exportReportPDF = catchAsync(async (payload) => {
    payload.append('timezone', timezone);
    const response = await httpClient.post(EXPORT_REPORT_CONSTANTS.EXPORT_REPORT_PDF, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
            lang
        }
    });
    return response;
});

/**
 * TODO: Export report as raw
 */

const exportReportAsRaw = catchAsync(async (payload) => {
    const response = await httpClient.post(
        EXPORT_REPORT_CONSTANTS.EXPORT_REPORT_AS_RAW,
        { ...payload, timezone },
        {
            headers: {
                lang
            }
        }
    );
    return response;
});

/**
 * TODO: Get export token
 */

const getExportToken = catchAsync(async () => {
    const response = await httpClient.get(EXPORT_REPORT_CONSTANTS.GET_EXPORT_TOKEN);
    return response;
});

export default {
    exportReportPDF,
    exportReportAsRaw,
    getExportToken
};
