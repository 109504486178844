import CalendarIcon from 'assets/icon/CalendarIcon';
import QuizIcon from 'assets/icon/QuizIcon';
import SolutionIcon from 'assets/icon/SolutionIcon';
import SmileIcon from 'assets/icon/SmileIcon';

const rawCountries = [
    {
        name: 'Vietnam',
        shortName: 'vn',
        dialCode: '84'
    },
    {
        name: 'Singapore',
        shortName: 'sg',
        dialCode: '65'
    },
    {
        name: 'Thailand',
        shortName: 'th',
        dialCode: '66'
    },
    {
        name: 'Malaysia',
        shortName: 'my',
        dialCode: '60'
    }
];

const supportLanguageList = [
    {
        label: 'Vietnamese',
        key: 'vi'
    },
    {
        label: 'English (United States)',
        key: 'en'
    },
    {
        label: 'Thai',
        key: 'th'
    }
];

const pwdRequirementList = [
    {
        label: 'minimum8characters',
        isValid: false
    },
    {
        label: 'atLeast1Special',
        isValid: false
    },
    {
        label: 'atLeast1Lowercase',
        isValid: false
    },
    {
        label: 'atLeast1Uppercase',
        isValid: false
    },
    {
        label: 'atLeast1Number',
        isValid: false
    }
];
const KEYBOARD_KEY = {
    left: 'ArrowLeft',
    right: 'ArrowRight',
    backspace: 'Backspace'
};

export const CANDIDATE_CUSTOMIZATION_METHODS = {
    MANUAL: 'manual',
    IMPORT: 'import'
};

export const CANDIDATE_CUSTOMIZATION_LAYOUTS = {
    METHOD_SELECTION: 'methodSelection',
    MANUAL: 'manual',
    IMPORT: 'import'
};

export const HORIZONTAL_STEPPER_ICONS = [CalendarIcon, SolutionIcon, QuizIcon, SmileIcon];

export { rawCountries, supportLanguageList, pwdRequirementList, KEYBOARD_KEY };
