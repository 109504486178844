import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ? Get list survey
        case types.GET_LIST_SURVEY_SUCCESS:
            return reducerHandler.getListSurveyReducerHandler(state, action.payload);
        case types.START_SURVEY_SUCCESS:
            return reducerHandler.startSurvey(state, action.payload);
        case types.STOP_SURVEY_SUCCESS:
            return reducerHandler.stopSurvey(state, action.payload);
        case types.REBUILD_SURVEY_SUCCESS:
            return reducerHandler.rebuildSurvey(state, action.payload);
        case types.DELETE_SURVEYS_SUCCESS:
            return reducerHandler.deleteSurveys(state, action.payload);
        case types.GET_LIST_SURVEY_TEMPLATES_SUCCESS:
            return reducerHandler.getListSurveyTemplatesReducerHandler(state, action.payload);
        case types.GET_LIST_CANDIDATES_BY_SURVEY_SUCCESS:
            return reducerHandler.getListCandidatesBySurvey(state, action.payload);
        case types.GET_LIST_QUESTIONS_BY_SURVEY_SUCCESS:
            return reducerHandler.getListQuestionsBySurvey(state, action.payload);
        case types.CREATE_SURVEY_SUCCESS:
            return reducerHandler.createSurvey(state, action.payload);
        case types.CREATE_SURVEY_RESET:
            return reducerHandler.createSurveyReset(state, action.payload);
        case types.GET_SURVEY_DETAILS_SUCCESS:
            return reducerHandler.getSurveyDetailsReducerHandler(state, action.payload);
        case types.GET_SURVEY_DETAILS_RESET:
            return reducerHandler.getSurveyDetailsResetReducerHandler(state);
        case types.EDIT_SURVEY_SUCCESS:
            return reducerHandler.editSurvey(state, action.payload);
        case types.EDIT_SURVEY_RESET:
            return reducerHandler.editSurveyReset(state, action.payload);

        default:
            return state;
    }
};

export default reducer;
