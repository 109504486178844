const formatNumberWithCommas = (number) => {
    if (number === undefined || number === null) return;
    return number.toLocaleString('en-US');
};

const formatNumberCeiling = (number, step = 100) => {
    return Math.ceil(number / step) * step;
};

const formatIDWithTwoDigits = (id) => {
    if (id === undefined || id === null || id === '') return;
    return id < 10 && id !== 0 ? '0' + id.toString() : id.toString();
};

const formatFloatingScore = (score) => {
    return score > 0 && score < 10 ? score.toFixed(1) : score;
};

const formatFloatingPercent = (percent) => {
    if (isInteger(percent) || (percent == 0 && percent == 100)) return percent;
    return percent.toFixed(1);
};

const formatTablePageInput = (input) => {
    return input.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
};

// ? Check character is numeric
const isNumeric = (char) => {
    return !isNaN(char) && !isNaN(parseFloat(char));
};

const randomFunctionWithArrayAndTotalElement = (array, totalElements) => {
    if (totalElements === undefined || totalElements === null) return;

    if (totalElements === 1) return [1];

    let scaledRandomValues = array.map((value) => Math.floor(Math.random() * value) + 1);

    let currentTotal = scaledRandomValues.reduce((acc, val) => acc + val, 0);
    let remainingElements = totalElements - currentTotal;

    while (remainingElements !== 0) {
        const index = Math.floor(Math.random() * scaledRandomValues.length);
        const newValue = scaledRandomValues[index] + (remainingElements > 0 ? 1 : -1);

        if (newValue >= 1 && newValue <= array[index]) {
            scaledRandomValues[index] = newValue;
            remainingElements -= remainingElements > 0 ? 1 : -1;
        }
    }

    return scaledRandomValues;
};

const isInteger = (n) => {
    return n === +n && n === (n | 0);
};

const formattedPercent = (value, total) => {
    const percentValue = (value / total) * 100;
    return isInteger(percentValue) ? percentValue : parseFloat(percentValue.toFixed(2));
};

const isArrayHasMoreThanOneNonZeroValue = (arr = []) => {
    let nonZeroItemCounter = 0;
    arr.forEach((item) => {
        if (item.value > 0) {
            nonZeroItemCounter += 1;
        }
    });
    if (arr.length == 2 && nonZeroItemCounter == 1) {
        return false;
    }
    return nonZeroItemCounter > 1;
};
export default {
    formatNumberWithCommas,
    formatNumberCeiling,
    formatIDWithTwoDigits,
    formatFloatingScore,
    formatTablePageInput,
    isNumeric,
    randomFunctionWithArrayAndTotalElement,
    isInteger,
    formattedPercent,
    formatFloatingPercent,
    isArrayHasMoreThanOneNonZeroValue
};
