import { call, takeLatest, put, fork } from 'redux-saga/effects';

import services from 'api/acl';
import types from './actionTypes';
import {
    getStaffListSuccess,
    getStaffListFailure,
    getPermissionsListSuccess,
    getPermissionsListFailure,
    addRoleSuccess,
    addRoleFailure,
    getRoleByIdSuccess,
    getRoleByIdFailure,
    updateRoleSuccess,
    updateRoleFailure,
    putBanStaffSuccess,
    putBanStaffFailure,
    getRoleListSuccess,
    getRoleListFailure,
    addStaffSuccess,
    addStaffFailure,
    getStaffListByRoleSuccess,
    getStaffListByRoleFailure,
    deleteRolesSuccess,
    deleteRolesFailure,
    deleteStaffsFromRoleSuccess,
    deleteStaffsFromRolesFailure,
    getStaffByIdSuccess,
    getStaffByIdFailure,
    updateStaffSuccess,
    updateStaffFailure,
    resetStaffPasswordSuccess,
    resetStaffPasswordFailure
} from './actions';

// ~ Transformer
import { transformedPermissionsData } from 'transformer/permission';
import { transformedStaffDetails } from 'transformer/staff';

/**
 * TODO:  staff list request handler
 */

function* getStaffListRequestHandler({ payload }) {
    try {
        const response = yield call(services.getStaffList, payload);
        yield put(getStaffListSuccess(response.data));
    } catch (errors) {
        yield put(getStaffListFailure(errors));
    }
}

/**
 * TODO:  staff list by role request handler
 */

function* getStaffListByRoleRequestHandler({ payload }) {
    try {
        const response = yield call(services.getStaffListByRole, payload);
        yield put(getStaffListByRoleSuccess(response.data));
    } catch (errors) {
        yield put(getStaffListByRoleFailure(errors));
    }
}

/**
 * TODO:  Delete staffs from role request handler
 */

function* deleteStaffsFromRoleRequestHandler({ payload }) {
    try {
        const response = yield call(services.deleteStaffsFromRole, payload);
        yield put(deleteStaffsFromRoleSuccess(response.data));
    } catch (errors) {
        yield put(deleteStaffsFromRolesFailure(errors));
    }
}

/**
 * TODO:  ban staff request handler
 */

function* putBanStaffRequestHandler({ payload }) {
    try {
        const response = yield call(services.putBanStaff, payload);
        yield put(putBanStaffSuccess(response.data));
    } catch (errors) {
        yield put(putBanStaffFailure(errors));
    }
}

/**
 * TODO:  role list request handler
 */

function* getRoleListRequestHandler({ payload }) {
    try {
        const response = yield call(services.getRoleList, payload);
        yield put(getRoleListSuccess(response.data));
    } catch (errors) {
        yield put(getRoleListFailure(errors));
    }
}

/**
 * TODO: permission list request handler
 */

function* getPermissionsListRequestHandler({ payload }) {
    try {
        const response = yield call(services.getPermissionsList, payload);
        const dataTransformed = transformedPermissionsData(response.data?.permissions);
        yield put(getPermissionsListSuccess(dataTransformed));
    } catch (errors) {
        yield put(getPermissionsListFailure(errors));
    }
}

/**
 * TODO: add role request handler
 */

function* addRoleRequestHandler({ payload }) {
    try {
        const response = yield call(services.addRole, payload);
        yield put(addRoleSuccess(response.data));
    } catch (errors) {
        yield put(addRoleFailure(errors));
    }
}

/**
 * TODO: get role by id request handler
 */

function* getRoleByIdRequestHandler({ payload }) {
    try {
        const response = yield call(services.getRoleById, payload);
        yield put(getRoleByIdSuccess(response.data));
    } catch (errors) {
        yield put(getRoleByIdFailure(errors));
    }
}

/**
 * TODO: update role request handler
 */

function* updateRoleRequestHandler({ payload }) {
    try {
        const requestBody = {
            name: payload.name,
            permissions: payload.permissions
        };

        const response = yield call(services.updateRole, payload.id, requestBody);
        yield put(updateRoleSuccess(response.data));
    } catch (errors) {
        yield put(updateRoleFailure(errors));
    }
}

/**
 * TODO:  Delete roles request handler
 */

function* deleteRolesRequestHandler({ payload }) {
    try {
        const response = yield call(services.deleteRoles, payload);
        yield put(deleteRolesSuccess(response.data));
    } catch (errors) {
        yield put(deleteRolesFailure(errors));
    }
}
/*
 * TODO: add staff request handler
 */

function* addStaffRequestHandler({ payload }) {
    try {
        const response = yield call(services.addStaff, payload);
        yield put(addStaffSuccess(response.data));
    } catch (errors) {
        yield put(addStaffFailure(errors));
    }
}

/**
 * TODO: get staff by id request handler
 */

function* getStaffByIdRequestHandler({ payload }) {
    try {
        const response = yield call(services.getStaffById, payload);
        yield put(getStaffByIdSuccess(transformedStaffDetails(response.data)));
    } catch (errors) {
        yield put(getStaffByIdFailure(errors));
    }
}

/**
 * TODO: update staff request handler
 */

function* updateStaffRequestHandler({ payload }) {
    try {
        const id = payload.id;
        delete payload.id;
        const response = yield call(services.updateStaff, id, payload);
        yield put(updateStaffSuccess(response.data));
    } catch (errors) {
        yield put(updateStaffFailure(errors));
    }
}

/**
 * TODO: reset staff password request handler
 */

function* resetStaffPasswordRequestHandler({ payload }) {
    try {
        const staffCode = payload.staffCode;
        delete payload.staffCode;

        const response = yield call(services.resetStaffPassword, staffCode, payload);
        yield put(resetStaffPasswordSuccess(response.data));
    } catch (errors) {
        yield put(resetStaffPasswordFailure(errors));
    }
}

function* watchAclSaga() {
    yield takeLatest(types.GET_STAFF_LIST_REQUEST, getStaffListRequestHandler);
    yield takeLatest(types.GET_STAFF_LIST_BY_ROLE_REQUEST, getStaffListByRoleRequestHandler);
    yield takeLatest(types.DELETE_STAFFS_FROM_ROLE_REQUEST, deleteStaffsFromRoleRequestHandler);
    yield takeLatest(types.PUT_BAN_STAFF_REQUEST, putBanStaffRequestHandler);
    yield takeLatest(types.GET_ROLE_LIST_REQUEST, getRoleListRequestHandler);
    yield takeLatest(types.GET_PERMISSIONS_LIST_REQUEST, getPermissionsListRequestHandler);
    yield takeLatest(types.ADD_ROLE_REQUEST, addRoleRequestHandler);
    yield takeLatest(types.GET_ROLE_BY_ID_REQUEST, getRoleByIdRequestHandler);
    yield takeLatest(types.UPDATE_ROLE_REQUEST, updateRoleRequestHandler);
    yield takeLatest(types.DELETE_ROLES_REQUEST, deleteRolesRequestHandler);
    yield takeLatest(types.ADD_STAFF_REQUEST, addStaffRequestHandler);
    yield takeLatest(types.GET_STAFF_BY_ID_REQUEST, getStaffByIdRequestHandler);
    yield takeLatest(types.UPDATE_STAFF_REQUEST, updateStaffRequestHandler);
    yield takeLatest(types.RESET_STAFF_PASSWORD_REQUEST, resetStaffPasswordRequestHandler);
}

export default function* aclSaga() {
    yield fork(watchAclSaga);
}
