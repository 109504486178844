import {
    securityAwarenessScoreDataTransformer,
    transformSARByDepartment
} from 'transformer/statistics/summary';
import { formatDataForPhishingResult } from 'transformer/statistics/phishing';
import { transformTablePagination } from 'transformer/statistics/department-general';

/**
 * TODO: Get total candidates and departments
 */

const getTotalCandidatesAndDepartments = (state, { payload }) => {
    return {
        ...state,
        totalCandidates: payload.totalCandidates,
        totalDepartments: payload.totalDepartments
    };
};

/**
 * TODO: Get security awareness score
 */

const getSecurityAwarenessScore = (state, { payload }) => {
    return {
        ...state,
        securityAwarenessTotalScore: payload.total,
        securityAwarenessScoreData: securityAwarenessScoreDataTransformer(payload.detail)
    };
};

const getSummaryPhishingResult = (state, { payload }) => {
    return {
        ...state,
        phishingResult: formatDataForPhishingResult({
            totalCampaigns: payload.totalCampaigns,
            totalTarget: payload.totalTarget,
            ...payload.detail
        })
    };
};

/**
 * TODO: Get SAR By Department
 */

const getSARByDepartment = (state, { payload = {} }) => {
    return {
        ...state,
        securityAwarenessRatingByDepartment: {
            reportKey: payload?.reportKey,
            data: transformSARByDepartment(
                payload?.groups,
                'topList',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage,
                payload?.meta.totalRecord,
                payload?.meta.sortDirection
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

/**
 * TODO: Get SAR Of Participant
 */

const getSAROfParticipant = (state, { payload = {} }) => {
    return {
        ...state,
        detailedSecurityAwarenessResults: {
            reportKey: payload?.reportKey,
            data: transformSARByDepartment(
                payload?.employees,
                'id',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

export default {
    getTotalCandidatesAndDepartments,
    getSecurityAwarenessScore,
    getSummaryPhishingResult,
    getSARByDepartment,
    getSAROfParticipant
};
