import { fork } from 'redux-saga/effects';
import watchExportAssessmentSaga from './assessment/saga';
import watchExportTrainingSaga from './training/saga';
import watchExportPhishingSaga from './phishing/saga';
import watchExportParticipantSaga from './participant/saga';
import watchStatisticsExportSaga from './statistics/saga';
import watchExportSurveySaga from './survey/saga';

export default function* exportSaga() {
    yield fork(watchExportAssessmentSaga);
    yield fork(watchExportTrainingSaga);
    yield fork(watchExportPhishingSaga);
    yield fork(watchExportParticipantSaga);
    yield fork(watchStatisticsExportSaga);
    yield fork(watchExportSurveySaga);
}
