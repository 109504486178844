import React from 'react';
import ReactDOM from 'react-dom/client';

import i18n from 'i18n';
import { I18nextProvider } from 'react-i18next';
import { ProSidebarProvider } from 'react-pro-sidebar';

import App from 'pages/App';

// * Store
import { Provider } from 'react-redux';
import configureAppStore from './store/index';

// * MUI
import { ThemeProvider } from '@mui/material';
import theme from 'themes';

// * Styling
import './styles/main.scss';
import { AppContextProvider } from 'context';

// Create redux store with history
const initialState = {};
export const store = configureAppStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <AppContextProvider>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme()}>
                    <ProSidebarProvider>
                        <App />
                    </ProSidebarProvider>
                </ThemeProvider>
            </I18nextProvider>
        </AppContextProvider>
    </Provider>
);
