// ~ Icons
import CalendarIcon from 'assets/icon/CalendarIcon';
import QuizIcon from 'assets/icon/QuizIcon';
import SolutionIcon from 'assets/icon/SolutionIcon';
import SmileIcon from 'assets/icon/SmileIcon';

export const SIMULATION_TYPES = {
    EMAIL: 'email',
    SMS: 'sms',
    OTT: 'ott'
};

// ? Steps in the simulation
const CREATE_SIMULATION_STEPS = {
    SETTINGS: 'settings',
    WEBSITE_SETTINGS: 'websiteSettings',
    CAMPAIGN_INFO: 'campaignInformation',
    REVIEW: 'review'
};

// ? Steps in the simulation with data
const CREATE_SIMULATION_STEPS_WITH_DATA = [
    {
        label: 'websiteSettings',
        isPermissionStep: true,
        labelIcon: SolutionIcon
    },
    {
        label: 'campaignInformation',
        isPermissionStep: true,
        labelIcon: QuizIcon
    },

    {
        label: 'review',
        isPermissionStep: true,
        labelIcon: SmileIcon
    }
];

const CREATE_EMAIL_SIMULATION_STEPS = {
    EMAIL_SETTINGS: 'email-settings',
    ...CREATE_SIMULATION_STEPS
};

const CREATE_EMAIL_SIMULATION_STEPS_WITH_DATA = [
    {
        label: 'emailContentSettings',
        isPermissionStep: false,
        labelIcon: CalendarIcon
    },
    ...CREATE_SIMULATION_STEPS_WITH_DATA
];

const CREATE_SMS_SIMULATION_STEPS = {
    SMS_SETTINGS: 'sms-settings',
    WEBSITE_SETTINGS: 'website-settings',
    CAMPAIGN_INFO: 'campaign-info',
    REVIEW: 'review'
};

const CREATE_SMS_SIMULATION_STEPS_WITH_DATA = [
    {
        label: 'smsContentSettings',
        isPermissionStep: false,
        labelIcon: CalendarIcon
    },
    {
        label: 'websiteSettings',
        isPermissionStep: true,
        labelIcon: SolutionIcon
    },
    {
        label: 'campaignInformation',
        isPermissionStep: true,
        labelIcon: QuizIcon
    },

    {
        label: 'review',
        isPermissionStep: true,
        labelIcon: SmileIcon
    }
];

const CREATE_OTT_SIMULATION_STEPS = {
    OTT_SETTINGS: 'ott-settings',
    WEBSITE_SETTINGS: 'website-settings',
    CAMPAIGN_INFO: 'campaign-info',
    REVIEW: 'review'
};

const CREATE_OTT_SIMULATION_STEPS_WITH_DATA = [
    {
        label: 'ottContentSettings',
        isPermissionStep: false,
        labelIcon: CalendarIcon
    },
    {
        label: 'websiteSettings',
        isPermissionStep: true,
        labelIcon: SolutionIcon
    },
    {
        label: 'campaignInformation',
        isPermissionStep: true,
        labelIcon: QuizIcon
    },
    {
        label: 'review',
        isPermissionStep: true,
        labelIcon: SmileIcon
    }
];

// ? Section in the website settings
const WEBSITE_SETTINGS_SECTION = {
    NOTIFICATION: 0,
    DOMAIN: 1,
    CLONE_WEBSITE: 2,
    BUILD_WEBSITE: 3
};

// ? Section in campaign info
const CAMPAIGN_INFO_SECTION = {
    CAMPAIGN_NAME: 0,
    CAMPAIGN_TIME: 1
};

// ? Email content requirements
const EMAIL_CONTENT_REQUIREMENTS = [
    {
        label: 'instruction.mustHave',
        isValid: false,
        isDynamicKey: true,
        key: '{{.URL}}',
        reason: 'urlReason'
    }
];

// ? Email content optionals
const EMAIL_CONTENT_OPTIONALS = [
    {
        isValid: false,
        key: '{{.FullName}}',
        reason: 'fullNameReason'
    },
    {
        isValid: false,
        key: '{{.Position}}',
        reason: 'positionReason'
    }
];

// ? SMS content requirements
const SMS_CONTENT_REQUIREMENTS = [
    {
        label: 'instruction.maximum',
        isValid: false,
        isDynamicKey: true,
        key: '255',
        reason: ''
    },
    {
        label: 'instruction.unsignedContent',
        isValid: false,
        isDynamicKey: false,
        reason: ''
    },
    {
        label: 'instruction.mustHave',
        isValid: false,
        isDynamicKey: true,
        key: '{{.URL}}',
        reason: 'urlReason'
    }
];

// ? SMS content optionals
const SMS_CONTENT_OPTIONALS = [
    {
        isValid: false,
        key: '{{.FullName}}',
        reason: 'fullNameReason'
    },
    {
        isValid: false,
        key: '{{.Position}}',
        reason: 'positionReason'
    }
];

// ? OTT content requirements
const OTT_CONTENT_REQUIREMENTS = [
    {
        label: 'instruction.maximum',
        isValid: false,
        isDynamicKey: true,
        key: '1000',
        reason: ''
    },
    {
        label: 'instruction.mustHave',
        isValid: false,
        isDynamicKey: true,
        key: '{{.URL}}',
        reason: 'urlReason'
    }
];

// ? OTT content optionals
const OTT_CONTENT_OPTIONALS = [
    {
        isValid: false,
        key: '{{.FullName}}',
        reason: 'fullNameReason'
    },
    {
        isValid: false,
        key: '{{.Position}}',
        reason: 'positionReason'
    }
];

export {
    CREATE_SIMULATION_STEPS,
    CREATE_SIMULATION_STEPS_WITH_DATA,
    CREATE_EMAIL_SIMULATION_STEPS,
    CREATE_EMAIL_SIMULATION_STEPS_WITH_DATA,
    CREATE_SMS_SIMULATION_STEPS,
    CREATE_SMS_SIMULATION_STEPS_WITH_DATA,
    WEBSITE_SETTINGS_SECTION,
    CAMPAIGN_INFO_SECTION,
    CREATE_OTT_SIMULATION_STEPS,
    CREATE_OTT_SIMULATION_STEPS_WITH_DATA,
    SMS_CONTENT_REQUIREMENTS,
    SMS_CONTENT_OPTIONALS,
    OTT_CONTENT_REQUIREMENTS,
    OTT_CONTENT_OPTIONALS,
    EMAIL_CONTENT_REQUIREMENTS,
    EMAIL_CONTENT_OPTIONALS
};
