const actionTypes = {
    // * Export training participants
    EXPORT_TRAINING_PARTICIPANTS_PDF_REQUEST: 'EXPORT_TRAINING_PARTICIPANTS_PDF_REQUEST',
    EXPORT_TRAINING_PARTICIPANTS_PDF_SUCCESS: 'EXPORT_TRAINING_PARTICIPANTS_PDF_SUCCESS',
    EXPORT_TRAINING_PARTICIPANTS_PDF_FAILURE: 'EXPORT_TRAINING_PARTICIPANTS_PDF_FAILURE',

    EXPORT_TRAINING_PARTICIPANTS_SHEET_REQUEST: 'EXPORT_TRAINING_PARTICIPANTS_SHEET_REQUEST',
    EXPORT_TRAINING_PARTICIPANTS_SHEET_SUCCESS: 'EXPORT_TRAINING_PARTICIPANTS_SHEET_SUCCESS',
    EXPORT_TRAINING_PARTICIPANTS_SHEET_FAILURE: 'EXPORT_TRAINING_PARTICIPANTS_SHEET_FAILURE',

    // * Export training in-progress
    EXPORT_TRAINING_INPROGRESS_PDF_REQUEST: 'EXPORT_TRAINING_INPROGRESS_PDF_REQUEST',
    EXPORT_TRAINING_INPROGRESS_PDF_SUCCESS: 'EXPORT_TRAINING_INPROGRESS_PDF_SUCCESS',
    EXPORT_TRAINING_INPROGRESS_PDF_FAILURE: 'EXPORT_TRAINING_INPROGRESS_PDF_FAILURE',

    EXPORT_TRAINING_INPROGRESS_SHEET_REQUEST: 'EXPORT_TRAINING_INPROGRESS_SHEET_REQUEST',
    EXPORT_TRAINING_INPROGRESS_SHEET_SUCCESS: 'EXPORT_TRAINING_INPROGRESS_SHEET_SUCCESS',
    EXPORT_TRAINING_INPROGRESS_SHEET_FAILURE: 'EXPORT_TRAINING_INPROGRESS_SHEET_FAILURE',

    // * Export training result
    EXPORT_TRAINING_RESULT_PDF_REQUEST: 'EXPORT_TRAINING_RESULT_PDF_REQUEST',
    EXPORT_TRAINING_RESULT_PDF_SUCCESS: 'EXPORT_TRAINING_RESULT_PDF_SUCCESS',
    EXPORT_TRAINING_RESULT_PDF_FAILURE: 'EXPORT_TRAINING_RESULT_PDF_FAILURE',

    EXPORT_TRAINING_RESULT_SHEET_REQUEST: 'EXPORT_TRAINING_RESULT_SHEET_REQUEST',
    EXPORT_TRAINING_RESULT_SHEET_SUCCESS: 'EXPORT_TRAINING_RESULT_SHEET_SUCCESS',
    EXPORT_TRAINING_RESULT_SHEET_FAILURE: 'EXPORT_TRAINING_RESULT_SHEET_FAILURE',

    // * Export training individual result
    EXPORT_TRAINING_INDIVIDUAL_PDF_REQUEST: 'EXPORT_TRAINING_INDIVIDUAL_PDF_REQUEST',
    EXPORT_TRAINING_INDIVIDUAL_PDF_SUCCESS: 'EXPORT_TRAINING_INDIVIDUAL_PDF_SUCCESS',
    EXPORT_TRAINING_INDIVIDUAL_PDF_FAILURE: 'EXPORT_TRAINING_INDIVIDUAL_PDF_FAILURE',

    EXPORT_TRAINING_INDIVIDUAL_SHEET_REQUEST: 'EXPORT_TRAINING_INDIVIDUAL_SHEET_REQUEST',
    EXPORT_TRAINING_INDIVIDUAL_SHEET_SUCCESS: 'EXPORT_TRAINING_INDIVIDUAL_SHEET_SUCCESS',
    EXPORT_TRAINING_INDIVIDUAL_SHEET_FAILURE: 'EXPORT_TRAINING_INDIVIDUAL_SHEET_FAILURE'
};

export default actionTypes;
