import objectUtils from 'utils/object-utils';
import phoneUtils from 'utils/phone-utils';

const transformUserProfileData = (data) => {
    const { user } = data;
    const { dialCode, phoneNumber } = phoneUtils.getDialCodeAndPhoneNumberFromInput(
        user.phoneNumber
    );
    return {
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
        dialCode,
        phoneNumber
    };
};

const transformCreditSummary = (data) => {
    if (objectUtils.isEmptyObject(data)) {
        return {};
    }
    const progressPercent = (
        (data.creditBalance.spentCredit / data.creditBalance.totalCredit) *
        100
    ).toFixed(2);
    const creditBalance = data.creditBalance;
    const isIncreaseUsage = data.spendingThisMonth.usedCreditGap >= 0;
    const spendingThisMonth = {
        ...data.spendingThisMonth,
        usedCreditGap: Math.abs(data.spendingThisMonth.usedCreditGap),
        isIncreaseUsage
    };
    return {
        creditBalance,
        spendingThisMonth,
        progressPercent: progressPercent
    };
};

export { transformUserProfileData, transformCreditSummary };
