const initialState = {
    userData: null,
    isUpdateUserProfileSuccess: false,
    isChangePasswordSuccess: false,
    isEnable2FASuccess: false,
    isDisable2FASuccess: false,
    userSocketToken: '',
    creditSummary: {},
    creditHistory: {
        data: [],
        pagination: {}
    }
};

export default initialState;
