const initialState = {
    listCandidates: {
        data: [],
        pagination: {}
    },
    listCandidatesForSmsCampaign: {
        data: [],
        pagination: {}
    },

    deleteCandidatesSuccessMessage: '',
    isAddCandidatesSuccess: false,
    isImportCandidatesSuccess: false,
    isUpdateCandidateSuccess: false,
    validCandidates: []
};

export default initialState;
