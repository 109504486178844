import actionTypes from './actionTypes';

// ? Get assessment result
const getAssessmentSummary = (payload) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_SUMMARY_REQUEST,
    payload
});

const getAssessmentSummarySuccess = (payload) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_SUMMARY_SUCCESS,
    payload
});

const getAssessmentSummaryFailure = (errors) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_SUMMARY_FAILURE,
    payload: { errors }
});

// ? Get assessment total
const getAssessmentTotal = (payload) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_TOTAL_REQUEST,
    payload
});

const getAssessmentTotalSuccess = (payload) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_TOTAL_SUCCESS,
    payload
});

const getAssessmentTotalFailure = (errors) => ({
    type: actionTypes.GET_ASSESSMENT_REPORT_TOTAL_FAILURE,
    payload: { errors }
});

// ? Request
const getAssessmentByDepartmentRequest = (data) => ({
    type: actionTypes.ASSESSMENT_BY_DEPARTMENT_REQUEST,
    payload: data
});

// ? Success
const getAssessmentByDepartmentSuccess = (data) => ({
    type: actionTypes.ASSESSMENT_BY_DEPARTMENT_SUCCESS,
    payload: data
});

// ? Failure
const getAssessmentByDepartmentFailure = (errors) => ({
    type: actionTypes.ASSESSMENT_BY_DEPARTMENT_FAILURE,
    payload: errors
});

// ? Request
const getParticipantNotYetAssignedToAssessmentRequest = (data) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_ASSESSMENT_REQUEST,
    payload: data
});

// ? Success
const getParticipantNotYetAssignedToAssessmentSuccess = (data) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_ASSESSMENT_SUCCESS,
    payload: data
});

// ? Failure
const getParticipantNotYetAssignedToAssessmentFailure = (errors) => ({
    type: actionTypes.NOT_YET_ASSIGNED_TO_ASSESSMENT_FAILURE,
    payload: errors
});

export default {
    getAssessmentSummary,
    getAssessmentSummarySuccess,
    getAssessmentSummaryFailure,
    getAssessmentTotal,
    getAssessmentTotalSuccess,
    getAssessmentTotalFailure,
    getAssessmentByDepartmentRequest,
    getAssessmentByDepartmentSuccess,
    getAssessmentByDepartmentFailure,
    getParticipantNotYetAssignedToAssessmentRequest,
    getParticipantNotYetAssignedToAssessmentSuccess,
    getParticipantNotYetAssignedToAssessmentFailure
};
