const surveyTypes = {
    // ? Get list survey
    GET_LIST_SURVEY_REQUEST: 'GET_LIST_SURVEY_REQUEST',
    GET_LIST_SURVEY_SUCCESS: 'GET_LIST_SURVEY_SUCCESS',
    GET_LIST_SURVEY_FAILURE: 'GET_LIST_SURVEY_FAILURE',

    // * Start Survey
    START_SURVEY_REQUEST: 'START_SURVEY_REQUEST',
    START_SURVEY_SUCCESS: 'START_SURVEY_SUCCESS',
    START_SURVEY_FAILURE: 'START_SURVEY_FAILURE',

    // * Stop Survey
    STOP_SURVEY_REQUEST: 'STOP_SURVEY_REQUEST',
    STOP_SURVEY_SUCCESS: 'STOP_SURVEY_SUCCESS',
    STOP_SURVEY_FAILURE: 'STOP_SURVEY_FAILURE',

    // * Rebuild Survey
    REBUILD_SURVEY_REQUEST: 'REBUILD_SURVEY_REQUEST',
    REBUILD_SURVEY_SUCCESS: 'REBUILD_SURVEY_SUCCESS',
    REBUILD_SURVEY_FAILURE: 'REBUILD_SURVEY_FAILURE',

    // * Delete Surveys
    DELETE_SURVEYS_REQUEST: 'DELETE_SURVEYS_REQUEST',
    DELETE_SURVEYS_SUCCESS: 'DELETE_SURVEYS_SUCCESS',
    DELETE_SURVEYS_FAILURE: 'DELETE_SURVEYS_FAILURE',

    // * List survey templates
    GET_LIST_SURVEY_TEMPLATES_REQUEST: 'GET_LIST_SURVEY_TEMPLATES_REQUEST',
    GET_LIST_SURVEY_TEMPLATES_SUCCESS: 'GET_LIST_SURVEY_TEMPLATES_SUCCESS',
    GET_LIST_SURVEY_TEMPLATES_FAILURE: 'GET_LIST_SURVEY_TEMPLATES_FAILURE',

    // * Get list candidates by survey
    GET_LIST_CANDIDATES_BY_SURVEY_REQUEST: 'GET_LIST_CANDIDATES_BY_SURVEY_REQUEST',
    GET_LIST_CANDIDATES_BY_SURVEY_SUCCESS: 'GET_LIST_CANDIDATES_BY_SURVEY_SUCCESS',
    GET_LIST_CANDIDATES_BY_SURVEY_FAILURE: 'GET_LIST_CANDIDATES_BY_SURVEY_FAILURE',

    // * Get list questions by survey
    GET_LIST_QUESTIONS_BY_SURVEY_REQUEST: 'GET_LIST_QUESTIONS_BY_SURVEY_REQUEST',
    GET_LIST_QUESTIONS_BY_SURVEY_SUCCESS: 'GET_LIST_QUESTIONS_BY_SURVEY_SUCCESS',
    GET_LIST_QUESTIONS_BY_SURVEY_FAILURE: 'GET_LIST_QUESTIONS_BY_SURVEY_FAILURE',

    // * Create survey
    CREATE_SURVEY_REQUEST: 'CREATE_SURVEY_REQUEST',
    CREATE_SURVEY_SUCCESS: 'CREATE_SURVEY_SUCCESS',
    CREATE_SURVEY_FAILURE: 'CREATE_SURVEY_FAILURE',
    CREATE_SURVEY_RESET: 'CREATE_SURVEY_RESET',

    // * Get survey details
    GET_SURVEY_DETAILS_REQUEST: 'GET_SURVEY_DETAILS_REQUEST',
    GET_SURVEY_DETAILS_SUCCESS: 'GET_SURVEY_DETAILS_SUCCESS',
    GET_SURVEY_DETAILS_FAILURE: 'GET_SURVEY_DETAILS_FAILURE',
    GET_SURVEY_DETAILS_RESET: 'GET_SURVEY_DETAILS_RESET',

    // * Edit survey
    EDIT_SURVEY_REQUEST: 'EDIT_SURVEY_REQUEST',
    EDIT_SURVEY_SUCCESS: 'EDIT_SURVEY_SUCCESS',
    EDIT_SURVEY_FAILURE: 'EDIT_SURVEY_FAILURE',
    EDIT_SURVEY_RESET: 'EDIT_SURVEY_RESET'
};

export default surveyTypes;
