const initialState = {
    totalCandidates: 0,
    totalDepartments: 0,
    securityAwarenessTotalScore: 0,
    securityAwarenessScoreData: [],
    phishingResult: {},
    securityAwarenessRatingByDepartment: {
        data: [],
        pagination: {}
    },
    detailedSecurityAwarenessResults: {
        data: [],
        pagination: {}
    }
};

export default initialState;
