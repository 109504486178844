const initState = {
    isSendTestEmailSuccess: false,
    sendTestEmailError: {},
    phishingEmailTemplatesData: {},
    phishingListNotificationsData: {},

    listPhishingCampaigns: {
        data: [],
        pagination: {}
    },
    listTargets: {
        data: [],
        pagination: {}
    },
    scamEmailContent: {},
    scamWebContent: {},
    viewPhishingNotification: {},
    phishingSummary: {},
    phishingTimelineResult: [],
    phishingResultCandidateList: {
        data: [],
        pagination: {}
    },

    stopCampaignSuccessMessage: '',
    deleteCampaignsSuccessMessage: '',
    isCreatePhishingCampaignSuccess: false,
    isCreateSmsPhishingCampaignSuccess: false,
    listBookmarksData: {},
    isImportPhishingSiteSuccess: false,
    importPhishingSiteData: {},
    listSmsBrandsData: [],
    isSendTestSmsSuccess: false,
    listSmsTemplatesData: {},
    previewSiteData: {},
    listCategoryData: {},
    clonePhishingDetailsData: {},
    isSendTestOttSuccess: false,
    isCreateOttPhishingCampaignSuccess: false,
    templatesOttPhishingData: {},
    summaryDepartments: {
        data: [],
        pagination: {}
    }
};

export default initState;
