import types from './actionTypes';

/**
 * TODO: Export phishing targets
 */

export const exportPhishingTargetsPDF = (payload, permissions) => ({
    type: types.EXPORT_PHISHING_TARGETS_PDF_REQUEST,
    payload,
    permissions
});

export const exportPhishingTargetsPDFSuccess = (payload) => ({
    type: types.EXPORT_PHISHING_TARGETS_PDF_SUCCESS,
    payload
});

export const exportPhishingTargetsPDFFailure = (payload) => ({
    type: types.EXPORT_PHISHING_TARGETS_PDF_FAILURE,
    payload
});

export const exportPhishingTargetsSheet = (payload, permissions) => ({
    type: types.EXPORT_PHISHING_TARGETS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportPhishingTargetsSheetSuccess = (payload) => ({
    type: types.EXPORT_PHISHING_TARGETS_SHEET_SUCCESS,
    payload
});

export const exportPhishingTargetsSheetFailure = (payload) => ({
    type: types.EXPORT_PHISHING_TARGETS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export phishing result
 */

export const exportPhishingResultPDF = (payload, permissions) => ({
    type: types.EXPORT_PHISHING_RESULT_PDF_REQUEST,
    payload,
    permissions
});

export const exportPhishingResultPDFSuccess = (payload) => ({
    type: types.EXPORT_PHISHING_RESULT_PDF_SUCCESS,
    payload
});

export const exportPhishingResultPDFFailure = (payload) => ({
    type: types.EXPORT_PHISHING_RESULT_PDF_FAILURE,
    payload
});

export const exportPhishingResultSheet = (payload, permissions) => ({
    type: types.EXPORT_PHISHING_RESULT_SHEET_REQUEST,
    payload,
    permissions
});

export const exportPhishingResultSheetSuccess = (payload) => ({
    type: types.EXPORT_PHISHING_RESULT_SHEET_SUCCESS,
    payload
});

export const exportPhishingResultSheetFailure = (payload) => ({
    type: types.EXPORT_PHISHING_RESULT_SHEET_FAILURE,
    payload
});
