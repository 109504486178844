import httpClient from 'api';
import SURVEY_CONSTANTS from 'api/constants/survey';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get list survey
 */

const getListSurvey = catchAsync(async (params) => {
    const response = await httpClient.get(SURVEY_CONSTANTS.LIST_SURVEY, {
        params
    });
    return response;
});

/**
 * TODO: Start Survey
 */

const putStartSurvey = catchAsync(async ({ surveyCode = '' }) => {
    const endpoint = SURVEY_CONSTANTS.START_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.put(endpoint);
    return response;
});

/**
 * TODO: Stop Survey
 */

const putStopSurvey = catchAsync(async ({ surveyCode = '' }) => {
    const endpoint = SURVEY_CONSTANTS.STOP_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.put(endpoint);
    return response;
});

/**
 * TODO: Rebuild Survey
 */

const putRebuildSurvey = catchAsync(async ({ surveyCode = '' }) => {
    const endpoint = SURVEY_CONSTANTS.REBUILD_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.put(endpoint);
    return response;
});

/**
 * TODO: Delete Surveys
 */

const deleteSurveys = catchAsync(async (params) => {
    const response = await httpClient.delete(`${SURVEY_CONSTANTS.DELETE_SURVEYS}`, {
        params
    });
    return response;
});

/**
 * TODO: List survey templates
 */

const getListSurveyTemplates = catchAsync(async (params) => {
    const response = await httpClient.get(SURVEY_CONSTANTS.LIST_SURVEY_TEMPLATES, {
        params
    });
    return response;
});

/**
 * TODO: Get list candidate by survey
 */

const getListCandidateBySurvey = catchAsync(async ({ surveyCode = '', ...rest }) => {
    const endpoint = SURVEY_CONSTANTS.GET_CANDIDATES_BY_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.get(endpoint, { params: rest });
    return response;
});

/**
 * TODO: Get list question by survey
 */

const getListQuestionBySurvey = catchAsync(async ({ surveyCode = '', ...rest }) => {
    const endpoint = SURVEY_CONSTANTS.GET_QUESTIONS_BY_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.get(endpoint, { params: rest });
    return response;
});

/**
 * TODO: Create survey
 */

const createSurvey = catchAsync(async (data) => {
    const response = await httpClient.post(SURVEY_CONSTANTS.CREATE_SURVEY, data);
    return response;
});

/**
 * TODO: Get survey details
 */

const getSurveyDetails = catchAsync(async (surveyCode = '') => {
    const endpoint = SURVEY_CONSTANTS.GET_SURVEY_DETAILS.replace(':surveyCode', surveyCode);
    const response = await httpClient.get(endpoint);
    return response;
});

/**
 * TODO: Edit survey
 */

const editSurvey = catchAsync(async ({ data, surveyCode }) => {
    const endpoint = SURVEY_CONSTANTS.EDIT_SURVEY.replace(':surveyCode', surveyCode);
    const response = await httpClient.put(endpoint, data);
    return response;
});

const SurveyServices = {
    getListSurvey,
    putStartSurvey,
    putStopSurvey,
    putRebuildSurvey,
    deleteSurveys,
    getListSurveyTemplates,
    getListCandidateBySurvey,
    getListQuestionBySurvey,
    createSurvey,
    getSurveyDetails,
    editSurvey
};

export default SurveyServices;
