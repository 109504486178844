import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';
import CandidateServices from 'api/candidate';
import {
    getListCandidatesSuccess,
    getListCandidatesFailure,
    getListCandidatesForSmsCampaignSuccess,
    getListCandidatesForSmsCampaignFailure,
    deleteCandidatesSuccess,
    deleteCandidatesFailure,
    addCandidatesSuccess,
    addCandidatesFailure,
    importCandidatesSuccess,
    importCandidatesFailure,
    updateCandidateSuccess,
    updateCandidateFailure,
    checkValidCandidatesSuccess,
    checkValidCandidatesFailure
} from './actions';

import { transformInvalidCandidatesErrors } from 'transformer/candidate';

/**
 * TODO: Get list candidates request handler
 */

function* getListCandidatesRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.getListCandidate, payload);
        yield put(getListCandidatesSuccess(response.data));
    } catch (errors) {
        yield put(getListCandidatesFailure(errors));
    }
}

/**
 * TODO: Get list candidates for sms campaign request handler
 */

function* getListCandidatesForSmsCampaignRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.getListCandidateForSmsCampaign, payload);
        yield put(getListCandidatesForSmsCampaignSuccess(response.data));
    } catch (errors) {
        yield put(getListCandidatesForSmsCampaignFailure(errors));
    }
}

/**
 * TODO: Delete candidates
 */

function* deleteCandidatesRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.deleteCandidates, payload);
        yield put(deleteCandidatesSuccess(response));
    } catch (errors) {
        yield put(deleteCandidatesFailure(errors));
    }
}

/**
 * TODO: Add candidates
 */

function* addCandidatesRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.addCandidates, payload);
        yield put(addCandidatesSuccess(response));
    } catch (errors) {
        yield put(addCandidatesFailure(errors));
    }
}

/**
 * TODO: Import candidates
 */

function* importCandidatesRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.importCandidates, payload);
        yield put(importCandidatesSuccess(response));
    } catch (errors) {
        yield put(importCandidatesFailure(errors));
    }
}

/**
 * TODO: Update candidate
 */

function* updateCandidateRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.updateCandidate, payload);
        yield put(updateCandidateSuccess(response));
    } catch (errors) {
        yield put(updateCandidateFailure(errors));
    }
}

/**
 * TODO: Check valid candidates
 */

function* checkValidCandidatesRequestHandler({ payload }) {
    try {
        const response = yield call(CandidateServices.checkValidCandidates, payload);
        yield put(checkValidCandidatesSuccess(response?.data));
    } catch (errors) {
        const errorsTransformer = payload?.candidates
            ? transformInvalidCandidatesErrors(errors, payload.candidates)
            : errors;

        yield put(checkValidCandidatesFailure(errorsTransformer));
    }
}

/**
 * TODO: Watchers
 */

function* watchCandidateSaga() {
    yield takeLatest(types.GET_LIST_CANDIDATES_REQUEST, getListCandidatesRequestHandler);
    yield takeLatest(
        types.GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_REQUEST,
        getListCandidatesForSmsCampaignRequestHandler
    );
    yield takeLatest(types.DELETE_CANDIDATES_REQUEST, deleteCandidatesRequestHandler);
    yield takeLatest(types.ADD_CANDIDATES_REQUEST, addCandidatesRequestHandler);
    yield takeLatest(types.IMPORT_CANDIDATES_REQUEST, importCandidatesRequestHandler);
    yield takeLatest(types.UPDATE_CANDIDATE_REQUEST, updateCandidateRequestHandler);
    yield takeLatest(types.CHECK_VALID_CANDIDATES_REQUEST, checkValidCandidatesRequestHandler);
}

export default function* candidateSaga() {
    yield fork(watchCandidateSaga);
}
