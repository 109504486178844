import { store } from 'index';
import reducer from './reducer';
import saga from './saga';

const key = 'training-statistics';

export const useTrainingStatisticsSlice = () => {
    store.injectReducer(key, reducer);
    store.injectSaga(key, saga);
};
