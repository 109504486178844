const AUTH_CONSTANTS = {
    LOGIN: '/auth/login/email',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/password/reset',
    FORGOT_PASSWORD_RESEND: '/auth/password/reset/resend',
    FORGOT_PASSWORD_VERIFY_CODE: '/auth/password/reset/verify',
    RESET_PASSWORD: '/auth/password/reset/complete',
    LOGOUT: '/auth/logout',
    VERIFY_OTP: '/auth/2fa/verify',
    RESEND_OTP: '/auth/2fa/resend'
};

export default AUTH_CONSTANTS;
