const PHISHING_STATISTICS_CONSTANTS = {
    GET_STATISTICS_PHISHING_SUMMARY: '/report/phishing/summary',
    GET_STATISTICS_PHISHING_RATING_BY_MONTHS: '/report/dashboard/phishing/rating',
    GET_STATISTICS_PHISHING_RESULT: '/report/phishing/result/summary',
    GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT: '/report/departments/phishing/rating',
    GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE: '/report/scenarios/clicked/top',
    GET_STATISTICS_PHISHING_SUMMARY_TOTAL: '/report/phishing/summary/total',
    GET_STATISTICS_DETAILS_PHISHING_RESULT: '/report/scenarios/detail',
    GET_STATISTICS_LIST_TARGETS_UNPHISHED: '/report/employees/unphished'
};

export default PHISHING_STATISTICS_CONSTANTS;
