const commonActionTypes = {
    // ? Get department list
    GET_DEPARTMENT_LIST_REQUEST: 'GET_DEPARTMENT_LIST_REQUEST',
    GET_DEPARTMENT_LIST_SUCCESS: 'GET_DEPARTMENT_LIST_SUCCESS',
    GET_DEPARTMENT_LIST_FAILURE: 'GET_DEPARTMENT_LIST_FAILURE',

    // ? Get department list for sms phishing
    GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_REQUEST: 'GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_REQUEST',
    GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_SUCCESS: 'GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_SUCCESS',
    GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_FAILURE: 'GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_FAILURE',

    // ? Toggle breadcrumbs
    TOGGLE_BREADCRUMBS: 'TOGGLE_BREADCRUMBS',
    TOGGLE_BREADCRUMBS_SUCCESS: 'TOGGLE_BREADCRUMBS_SUCCESS',
    TOGGLE_BREADCRUMBS_FAILURE: 'TOGGLE_BREADCRUMBS_FAILURE'
};

export default commonActionTypes;
