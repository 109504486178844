import UserServices from 'api/user';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import {
    getUserProfileSuccess,
    getUserProfileFailure,
    updateUserProfileSuccess,
    updateUserProfileFailure,
    getUserTokenSuccess,
    getUserTokenFailure,
    changePasswordSuccess,
    changePasswordFailure,
    enable2FASuccess,
    enable2FAFailure,
    disable2FASuccess,
    disable2FAFailure,
    getCreditSummarySuccess,
    getCreditSummaryFailure,
    getCreditHistorySuccess,
    getCreditHistoryFailure
} from './actions';

// ~ Transformers
import { transformUserProfileData } from 'transformer/user';

/**
 * TODO: Get user profile request handler
 */

function* getUserProfileRequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.getUserProfile, payload);
        yield put(getUserProfileSuccess(transformUserProfileData(response.data)));
    } catch (errors) {
        yield put(getUserProfileFailure(errors));
    }
}

/**
 * TODO: Update user profile request handler
 */

function* updateUserProfileRequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.updateUserProfile, payload);
        yield put(updateUserProfileSuccess(transformUserProfileData(response.data)));
    } catch (errors) {
        yield put(updateUserProfileFailure(errors));
    }
}

/**
 * TODO: Change password request handler
 */

function* changePasswordRequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.changePassword, payload);
        yield put(changePasswordSuccess(response.data));
    } catch (errors) {
        yield put(changePasswordFailure(errors));
    }
}

/**
 * TODO: Enable 2FA request handler
 */

function* enable2FARequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.enable2FA, payload);
        yield put(enable2FASuccess(response.data));
    } catch (errors) {
        yield put(enable2FAFailure(errors));
    }
}

/**
 * TODO: Disable 2FA request handler
 */

function* disable2FARequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.disable2FA, payload);
        yield put(disable2FASuccess(response.data));
    } catch (errors) {
        yield put(disable2FAFailure(errors));
    }
}

/**
 * TODO: Get user token request handler
 */

function* getUserTokenRequestHandler() {
    try {
        const response = yield call(UserServices.getUserToken);
        yield put(getUserTokenSuccess(response.data));
    } catch (errors) {
        yield put(getUserTokenFailure(errors));
    }
}

/**
 * TODO: Get credit summary request handler
 */

function* getCreditSummaryRequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.getCreditSummary, payload);
        yield put(getCreditSummarySuccess(response.data));
    } catch (errors) {
        yield put(getCreditSummaryFailure(errors));
    }
}

/**
 * TODO: Get credit history request handler
 */

function* getCreditHistoryRequestHandler({ payload }) {
    try {
        const response = yield call(UserServices.getCreditHistory, payload);
        yield put(getCreditHistorySuccess(response.data));
    } catch (errors) {
        yield put(getCreditHistoryFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

function* watchUserSaga() {
    yield takeLatest(actionTypes.GET_USER_PROFILE_REQUEST, getUserProfileRequestHandler);
    yield takeLatest(actionTypes.UPDATE_USER_PROFILE_REQUEST, updateUserProfileRequestHandler);
    yield takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequestHandler);
    yield takeLatest(actionTypes.ENABLE_2FA_REQUEST, enable2FARequestHandler);
    yield takeLatest(actionTypes.DISABLE_2FA_REQUEST, disable2FARequestHandler);
    yield takeLatest(actionTypes.GET_USER_TOKEN_REQUEST, getUserTokenRequestHandler);
    yield takeLatest(actionTypes.GET_CREDIT_SUMMARY_REQUEST, getCreditSummaryRequestHandler);
    yield takeLatest(actionTypes.GET_CREDIT_HISTORY_REQUEST, getCreditHistoryRequestHandler);
}

export default function* userSaga() {
    yield fork(watchUserSaga);
}
