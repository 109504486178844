import { useState } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
import Cookies from 'js-cookie';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const domain = process.env.REACT_APP_REPORT_DOMAIN;

    useIsomorphicLayoutEffect(() => {
        const token = Cookies.get(process.env.REACT_APP_BASE_TOKEN_KEY);
        setIsAuthenticated(!!token);
    }, []);

    const loginHandler = (token) => {
        Cookies.set(process.env.REACT_APP_BASE_TOKEN_KEY, token, {
            domain
        });
        Cookies.set(process.env.REACT_APP_BASE_TOKEN_TYPE_KEY, 'Bearer', {
            domain
        });

        setIsAuthenticated(true);
    };

    const logoutHandler = () => {
        Cookies.set(process.env.REACT_APP_BASE_TOKEN_KEY, '', {
            domain
        });
        Cookies.set(process.env.REACT_APP_BASE_TOKEN_TYPE_KEY, 'Bearer', {
            domain
        });
        window.localStorage.clear();
        setIsAuthenticated(false);
    };

    return { isAuthenticated, loginHandler, logoutHandler };
};

export default useAuth;
