import DOMPurify from 'dompurify';
import i18next from 'i18next';

const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
    first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('');

const htmlDecode = (input, isDompurify = true) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    if (txt.value) {
        if (isDompurify) {
            const config = {
                ALLOWED_TAGS: [
                    'html',
                    'meta',
                    'title',
                    'head',
                    'body',
                    'div',
                    'p',
                    'h1',
                    'h2',
                    'h3',
                    'h4',
                    'h5',
                    'h6',
                    'a',
                    'img',
                    'style',
                    'table',
                    'thead',
                    'tbody',
                    'tr',
                    'th',
                    'td',
                    'ul',
                    'ol',
                    'li',
                    'br',
                    'hr',
                    'span',
                    'strong',
                    'em',
                    'b',
                    'i',
                    'u',
                    'button',
                    'a'
                ],
                ALLOWED_ATTR: ['href', 'style', 'class', 'src']
            };

            return DOMPurify.sanitize(txt.value, config);
        } else {
            return txt.value;
        }
    }

    return '';
};

// ? Convert camelCase to string with spaces
const camelCaseToSpaceCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

// ? Check If String Should Be Plural Noun Or Not
const pluralNounCheck = (dataLength = 0, str = '', suffix = 's') => {
    if (i18next.language === 'en') {
        // ! Lowercase the string
        if (str === null || str === '') return;
        // str = str.toLowerCase();
        return dataLength > 1 ? `${str}${suffix}` : str;
    } else {
        return str;
    }
};

// ? Convert string to boolean
const stringToBoolean = (str) => {
    switch (str.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
            return false;
        default:
            return null;
    }
};

// ? Render an ellipsis ("...") to represent the clipped text
const ellipsisString = (str = '', clipPosition = 12) => {
    if (str === null || str === '') return;
    if (str.length < clipPosition) return str;
    return `${str.slice(0, clipPosition - 1)}...`;
};

const htmlEscape = (str) => {
    return str
        .replace(/&/g, '&amp;')
        .replace(/'/g, '&apos;')
        .replace(/"/g, '&quot;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;');
};

// ? Split protocols from url
const splitProtocol = (url) => {
    if (url && url.length > 0) {
        const protocol = url.split('://')[0];
        const urlWithoutProtocol = url.split('://')[1];
        return { protocol, urlWithoutProtocol };
    } else {
        return { protocol: '', urlWithoutProtocol: '' };
    }
};

// ? String to camel case
const formatStringToCamelCase = (input = '') => {
    if (input && input.length > 0) {
        const words = input.split(' ');
        const formattedWords = words.map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        });
        return formattedWords.join('');
    }

    return '';
};

const hasNumber = (myString) => {
    return /\d/.test(myString);
};

const convertErrorValue = (
    err,
    translatedKey,
    replaceDate = '',
    replaceMin = '',
    replaceMax = '',
    isDefaultError = false
) => {
    if (!isDefaultError) {
        return err
            .replace(/{{key}}/g, translatedKey)
            .replace(/:date/g, replaceDate)
            .replace(/:min/g, replaceMin)
            .replace(/:max/g, replaceMax);
    }

    return i18next.t(`${err}`);
};

const toLowerCase = (str) => {
    return str.toLowerCase();
};

const getSlugFromPathname = () => {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const slug = parts[parts.length - 2];
    return slug;
};

const truncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + '...';
};

export default {
    pluralNounCheck,
    capitalizeFirstLetter,
    htmlDecode,
    camelCaseToSpaceCase,
    stringToBoolean,
    ellipsisString,
    htmlEscape,
    splitProtocol,
    formatStringToCamelCase,
    hasNumber,
    convertErrorValue,
    toLowerCase,
    getSlugFromPathname,
    truncateString
};
