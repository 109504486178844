import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_LIST_ASSESSMENTS_SUCCESS:
            return reducerHandler.getListAssessments(state, action.payload);
        case types.GET_LIST_QUESTIONS_SUCCESS:
            return reducerHandler.getListQuestionsReducerHandler(state, action.payload);
        // ~ Get list templates
        case types.GET_LIST_TEMPLATES_SUCCESS:
            return reducerHandler.getListTemplatesReducerHandler(state, action.payload);
        // ~ Create assessment
        case types.CREATE_ASSESSMENT_SUCCESS:
            return reducerHandler.createAssessmentReducerHandler(state, action.payload);
        case types.CREATE_ASSESSMENT_RESET:
            return reducerHandler.createAssessmentResetReducerHandler(state);

        // ~ Edit assessment
        case types.EDIT_ASSESSMENT_SUCCESS:
            return reducerHandler.editAssessmentReducerHandler(state, action.payload);

        case types.EDIT_ASSESSMENT_RESET:
            return reducerHandler.editAssessmentResetReducerHandler(state);
        // ~ Get assessment details
        case types.GET_ASSESSMENT_DETAILS_SUCCESS:
            return reducerHandler.getAssessmentDetailsReducerHandler(state, action.payload);
        case types.GET_ASSESSMENT_DETAILS_RESET:
            return reducerHandler.getAssessmentDetailsResetReducerHandler(state);

        // ~ Get assessment clone details
        case types.GET_ASSESSMENT_CLONE_DETAILS_SUCCESS:
            return reducerHandler.getAssessmentCloneDetailsReducerHandler(state, action.payload);
        case types.GET_ASSESSMENT_CLONE_DETAILS_RESET:
            return reducerHandler.getAssessmentCloneDetailsResetReducerHandler(state);
        // ~ Get list questions by template ids
        case types.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_SUCCESS:
            return reducerHandler.getListQuestionsByTemplateIdsReducerHandler(
                state,
                action.payload
            );
        // ~ Get list exam tags
        case types.GET_LIST_EXAM_TAGS_SUCCESS:
            return reducerHandler.getListExamTagsReducerHandler(state, action.payload);
        // ~ Summary
        case types.GET_LIST_CANDIDATES_BY_ASSESSMENT_SUCCESS:
            return reducerHandler.getListCandidatesByAssessment(state, action.payload);
        case types.GET_LIST_QUESTIONS_BY_ASSESSMENT_SUCCESS:
            return reducerHandler.getListQuestionsByAssessment(state, action.payload);
        case types.GET_ASSESSMENT_SUMMARY_PARTICIPANTS_SUCCESS:
            return reducerHandler.getAssessmentSummaryParticipants(state, action.payload);
        case types.GET_ASSESSMENT_SUMMARY_STATISTIC_SUCCESS:
            return reducerHandler.getAssessmentSummaryStatistic(state, action.payload);
        case types.GET_ASSESSMENT_SUMMARY_QUESTION_LIST_SUCCESS:
            return reducerHandler.getAssessmentSummaryQuestionList(state, action.payload);
        case types.GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_SUCCESS:
            return reducerHandler.getAssessmentSummaryCandidateList(state, action.payload);
        case types.GET_ASSESSMENT_CANDIDATE_DETAIL_SUCCESS:
            return reducerHandler.getAssessmentCandidateDetail(state, action.payload);
        case types.GET_ASSESSMENT_CANDIDATE_RESULT_SUCCESS:
            return reducerHandler.getAssessmentCandidateResult(state, action.payload);

        // ~ Get assessment detail question result
        case types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_SUCCESS:
            return reducerHandler.getDetailQuestionResult(state, action.payload);

        case types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_RESET:
            return reducerHandler.getDetailQuestionResultReset(state);

        // ~ Start assessment success
        case types.START_ASSESSMENT_SUCCESS:
            return reducerHandler.startAssessment(state, action.payload);
        case types.STOP_ASSESSMENT_SUCCESS:
            return reducerHandler.stopAssessment(state, action.payload);
        case types.REBUILD_ASSESSMENT_SUCCESS:
            return reducerHandler.rebuildAssessment(state, action.payload);
        case types.DELETE_ASSESSMENTS_SUCCESS:
            return reducerHandler.deleteAssessments(state, action.payload);
        case types.GET_LANGUAGE_SUPPORTED_SUCCESS:
            return reducerHandler.getLanguageSupported(state, action.payload);

        // ~ Get assessment summary departments
        case types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_SUCCESS:
            return reducerHandler.getAssessmentSummaryDepartments(state, action.payload);

        case types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_RESET:
            return reducerHandler.getAssessmentSummaryDepartmentsReset(state);

        default:
            return state;
    }
};

export default reducer;
