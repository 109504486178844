import dateUtils from 'utils/date-utils';
import objectUtils from 'utils/object-utils';
import numberUtils from 'utils/number-utils';
import i18next from 'i18next';

// ? Security Awareness Score Data Transformer
const securityAwarenessScoreDataTransformer = (data) => {
    const transformedData = data.map((item) => {
        return {
            label: `${i18next.t(`common:common.${item.label}`)}: ${item.score}`,
            score: item.score,
            maxScore: 10
        };
    });
    return transformedData;
};

// ? Phishing rating by months data transformer
const phishingRatingByMonthsDataTransformer = (data) => {
    let details = [];

    const dataTransformed = data.map((singleData) => {
        details.push({
            data: [
                {
                    value: singleData.detail.totalClicked,
                    id: 'Clicked'
                },
                {
                    value: singleData.detail.totalSent > 0 ? singleData.detail.totalSent : 1,
                    id: 'Sent',
                    isNoData: singleData.detail.totalSent === 0
                }
            ],
            colors:
                singleData.detail.totalSent === 0 ? ['#0096A7', '#ECEEF0'] : ['#0096A7', '#4CCFE1']
        });

        return {
            x: dateUtils.convertNumberToMonth(singleData.month),
            y: singleData.detail.rating,
            sent: singleData.detail.totalSent,
            clicked: singleData.detail.totalClicked
        };
    });

    return {
        items: [
            {
                id: 'Phishing',
                data: dataTransformed
            }
        ],
        details
    };
};

// ? Phishing summary data transformer
const phishingSummaryDataTransformer = (data) => {
    let dataTransformer = {
        accountPhishing: data.accountPhishing,
        totalSent: data.totalSent,
        totalCampaigns: data.totalCampaigns
    };

    const {
        openedPercent,
        clickedPercent,
        submittedPercent,
        totalOpened,
        totalClicked,
        totalSubmitted
    } = data.detail;

    const clickedPercentOnChart = clickedPercent - submittedPercent;
    const openedPercentOnChart = openedPercent - clickedPercentOnChart - submittedPercent;
    const sentPercentOnChart =
        100 - openedPercentOnChart - clickedPercentOnChart - submittedPercent;

    dataTransformer.details = [
        {
            id: 'Phishing',
            data: [
                {
                    x: 'sent',
                    y: sentPercentOnChart,
                    realityPercent: '100%',
                    total: data.totalSent
                },
                {
                    x: 'opened',
                    y: openedPercentOnChart,
                    realityPercent: `${openedPercent}%`,
                    total: totalOpened
                },
                {
                    x: 'clicked',
                    y: clickedPercentOnChart,

                    realityPercent: `${clickedPercent}%`,
                    total: totalClicked
                },
                {
                    x: 'submitted',
                    y: submittedPercent,

                    realityPercent: `${submittedPercent}%`,
                    total: totalSubmitted
                }
            ]
        }
    ];

    // ? Check if all percentageCalculated in details is 0
    let isAllPercentageCalculatedZero = true;

    dataTransformer?.details.forEach((item) => {
        if (item?.realityPercent !== 0) {
            isAllPercentageCalculatedZero = false;
        }
    });

    if (isAllPercentageCalculatedZero) {
        dataTransformer?.details.push({
            id: '',
            color: '#CDD5DF',
            value: 1,
            realityPercent: 100
        });
    }

    return dataTransformer;
};

// ? assessment and training total data transformer
const totalDataTransformer = (data = {}) => {
    if (objectUtils.isEmptyObject(data)) return;
    const { totalRunning, totalReady, totalClosed, totalFailed, totalDraft, totalCreated } = data;

    let percentRunning = 0;
    let percentReady = 0;
    let percentClosed = 0;
    let percentFailed = 0;
    let percentDraft = 0;

    if (totalCreated !== 0) {
        percentRunning = (totalRunning / totalCreated) * 100;
        totalCreated;
        percentReady = (totalReady / totalCreated) * 100;
        percentClosed = (totalClosed / totalCreated) * 100;
        percentFailed = (totalFailed / totalCreated) * 100;
        percentDraft = 100 - (percentRunning + percentReady + percentClosed + percentFailed);
    }

    const details = [
        {
            id: 'ready',
            value: totalReady,
            percentageCalculated: percentReady,
            color: '#68A7EF',
            order: 0
        },
        {
            id: 'running',
            value: totalRunning,
            percentageCalculated: percentRunning,
            color: '#97D79A',
            order: 2
        },
        {
            id: 'draft',
            value: totalDraft,
            percentageCalculated: percentDraft,
            color: '#FFD650',
            order: 4
        },
        {
            id: 'failed',
            value: totalFailed,
            percentageCalculated: percentFailed,
            color: '#FF7D5C',
            order: 1
        },
        {
            id: 'closed',
            value: totalClosed,
            percentageCalculated: percentClosed,
            color: '#CDD5DF',
            order: 3
        }
    ];

    return {
        totalItems: totalCreated,
        details
    };
};

// ? Assessment summary data transformer
const assessmentSummaryDataTransformer = (data) => {
    if (objectUtils.isEmptyObject(data) && data.details) return;
    const {
        totalCompleted,
        totalInProgress,
        inProgressPercent,
        totalUnenrolled,
        unenrolledPercent,
        totalPassed,
        passedPercent,
        totalFailed,
        failedPercent
    } = data.details;

    const details = [
        {
            id: 'passed',
            value: totalPassed,
            percentageCalculated: passedPercent,
            color: '#97D79A',
            order: 1
        },
        {
            id: 'failedAssessment',
            value: totalFailed,
            percentageCalculated: failedPercent,
            color: '#FF7D5C',
            order: 2
        },
        {
            id: 'in-progress',
            value: totalInProgress,
            percentageCalculated: inProgressPercent,
            color: '#FFD650',
            order: 0
        },
        {
            id: 'unenrolled',
            value: totalUnenrolled,
            percentageCalculated: unenrolledPercent,
            color: '#E0F7FA',
            order: 3
        }
    ];
    const statistics = [
        {
            id: 'passed',
            value: totalPassed,
            percentageCalculated: passedPercent,
            color: '#97D79A'
        },
        {
            id: 'failedAssessment',
            value: totalFailed,
            percentageCalculated: failedPercent,
            color: '#FF7D5C'
        }
    ];
    return {
        details,
        candidatesAssigned: data.assigned || 0,
        totalRunningAndClosedAssessment: data.total || 0,
        totalCompletedCandidates: totalCompleted || 0,
        statistics
    };
};

// ? Training summary data transformer
const trainingSummaryDataTransformer = (data) => {
    if (objectUtils.isEmptyObject(data) && data.details) return;
    const {
        totalCompleted,
        completedPercent,
        totalInComplete,
        inCompletePercent,
        totalUnenrolled,
        unEnrolledPercent,
        enrolledCompletedPercent,
        enrolledInCompletePercent
    } = data.details;

    const details = [
        {
            id: 'completed',
            value: totalCompleted,
            percentageCalculated: completedPercent,
            color: '#97D79A'
        },
        {
            id: 'incomplete',
            value: totalInComplete,
            percentageCalculated: inCompletePercent,
            color: '#FFD650'
        },
        {
            id: 'unenrolled',
            value: totalUnenrolled,
            percentageCalculated: unEnrolledPercent,
            color: '#E0F7FA'
        }
    ];

    const statistics = [
        {
            id: 'completed',
            value: totalCompleted,
            percentageCalculated: enrolledCompletedPercent,
            color: '#97D79A'
        },
        {
            id: 'incomplete',
            value: totalInComplete,
            percentageCalculated: enrolledInCompletePercent,
            color: '#FF7D5C'
        }
    ];

    return {
        details,
        candidatesAssigned: data.assigned || 0,
        totalRunningAndClosedAssessment: data.total || 0,
        totalEnrolledCandidates: data?.enrolled || 0,
        statistics
    };
};

const transformSARByDepartment = (
    data,
    idKey = 'id',
    currentPage = 1,
    pageSize = 10,
    totalRecord = 0,
    sortDirection = ''
) => {
    let transformedData = [];

    for (let i = 0; i < data.length; i++) {
        let id = i + pageSize * (currentPage - 1) + 1;

        if (sortDirection === 'asc') {
            id = totalRecord - ((currentPage - 1) * pageSize + i);
        }
        let item = data[i];
        let formattedID = numberUtils.formatIDWithTwoDigits(id);

        let assessment = numberUtils.formatFloatingScore(item?.assessment);
        let training = numberUtils.formatFloatingScore(item?.training);
        let phishing = numberUtils.formatFloatingScore(item?.phishing);
        let totalScore = numberUtils.formatFloatingScore(item?.totalScore);

        transformedData.push({
            ...item,
            assessment,
            training,
            phishing,
            totalScore,
            [idKey]: formattedID
        });
    }

    return transformedData;
};

export {
    securityAwarenessScoreDataTransformer,
    phishingRatingByMonthsDataTransformer,
    phishingSummaryDataTransformer,
    trainingSummaryDataTransformer,
    assessmentSummaryDataTransformer,
    totalDataTransformer,
    transformSARByDepartment
};
