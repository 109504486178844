import {
    trainingSummaryDataTransformer,
    totalDataTransformer
} from 'transformer/statistics/summary';
import {
    transformTablePagination,
    transformTrainingByDepartment,
    transformNotYetAssignedData
} from 'transformer/statistics/department-general';

/**
 * TODO: Get training summary
 */
const getTrainingSummary = (state, { payload }) => {
    return {
        ...state,
        trainingSummaryData: trainingSummaryDataTransformer(payload.training)
    };
};

/**
 * TODO: Get training total
 */
const getTrainingTotal = (state, { payload }) => {
    return {
        ...state,
        trainingTotalData: {
            trainings: totalDataTransformer(payload.trainings),
            candidates: payload.candidates
        }
    };
};

/**
 * TODO: Get Training By Department
 */

const getTrainingByDepartment = (state, { payload = {} }) => {
    return {
        ...state,
        trainingByDepartment: {
            reportKey: payload?.reportKey,
            data: transformTrainingByDepartment(
                payload?.groups,
                'id',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

const getParticipantNotYetAssigned = (state, { payload = {} }) => {
    // ? Check report key is exist in templateKeys
    return {
        ...state,
        participantNotYetAssignedTraining: {
            reportKey: payload?.reportKey,
            data: transformNotYetAssignedData(
                payload?.employees,
                'id',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: transformTablePagination(payload?.meta, 'totalDepartment')
        }
    };
};

export default {
    getTrainingSummary,
    getTrainingTotal,
    getTrainingByDepartment,
    getParticipantNotYetAssigned
};
