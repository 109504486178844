/**
 * TODO: Login reducer handler
 */

const loginReducerHandler = (state, { payload }) => {
    if (payload?.otp) {
        // ? Redirect to verify otp
        window.location.href = '/auth/verify-otp';
        return {
            ...state,
            isVerifyOTP: payload.otp
        };
    }
    // ? Redirect to dashboard page
    window.location.href = '/dashboard';
    return {
        ...state,
        isLoginSuccess: true,
        accessToken: payload?.accessToken
    };
};

/**
 * TODO: Register reducer handler
 */

const registerReducerHandler = (state) => {
    return {
        ...state,
        isRegisterSuccess: true
    };
};

/**
 * TODO: Forgot password reducer handler
 */
const forgotPasswordReducerHandler = (state) => {
    return {
        ...state,
        isForgotPasswordMailSent: true
    };
};

/**
 * TODO: Forgot password verify code reducer handler
 */
const forgotPasswordVerifyCodeReducerHandler = (state) => {
    return {
        ...state,
        isForgotPasswordCodeVerified: true
    };
};

/**
 * TODO: Reset password reducer handler
 */
const resetPasswordReducerHandler = (state) => {
    return {
        ...state,
        isResetPasswordSuccess: true
    };
};

/**
 * TODO: Logout reducer handler
 */

const logoutReducerHandler = (state) => {
    return {
        ...state,
        isLogoutSuccess: true
    };
};

/**
 * TODO: Verify OTP reducer handler
 */

const verifyOTPReducerHandler = (state, { payload }) => {
    // ? Redirect to dashboard page
    window.location.href = '/dashboard';
    return {
        ...state,
        isLoginSuccess: true,
        accessToken: payload?.accessToken
    };
};

/**
 * TODO: Resend OTP reducer handler
 */

const resendOTPReducerHandler = (state) => {
    return {
        ...state,
        isResendOTPSuccess: true
    };
};

export default {
    registerReducerHandler,
    loginReducerHandler,
    forgotPasswordReducerHandler,
    forgotPasswordVerifyCodeReducerHandler,
    resetPasswordReducerHandler,
    logoutReducerHandler,
    verifyOTPReducerHandler,
    resendOTPReducerHandler
};
