import ExportServices from 'api/exports/phishing';
import { call, takeLatest, put } from 'redux-saga/effects';
import types from './actionTypes';
import {
    exportPhishingTargetsPDFSuccess,
    exportPhishingTargetsPDFFailure,
    exportPhishingTargetsSheetSuccess,
    exportPhishingTargetsSheetFailure,
    exportPhishingResultPDFSuccess,
    exportPhishingResultPDFFailure,
    exportPhishingResultSheetSuccess,
    exportPhishingResultSheetFailure
} from './actions';

/**
 * TODO: Export phishing targets
 */

function* exportPhishingTargetsPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportPhishingTargetsPDF, payload);
        yield put(exportPhishingTargetsPDFSuccess(response));
    } catch (errors) {
        yield put(exportPhishingTargetsPDFFailure(errors));
    }
}

function* exportPhishingTargetsSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportPhishingTargetsSheet, payload);
        yield put(exportPhishingTargetsSheetSuccess(response));
    } catch (errors) {
        yield put(exportPhishingTargetsSheetFailure(errors));
    }
}

/**
 * TODO: Export phishing result
 */

function* exportPhishingResultPDFRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportPhishingResultPDF, payload);
        yield put(exportPhishingResultPDFSuccess(response));
    } catch (errors) {
        yield put(exportPhishingResultPDFFailure(errors));
    }
}

function* exportPhishingResultSheetRequestHandler({ payload }) {
    try {
        const response = yield call(ExportServices.exportPhishingResultSheet, payload);
        yield put(exportPhishingResultSheetSuccess(response));
    } catch (errors) {
        yield put(exportPhishingResultSheetFailure(errors));
    }
}

/**
 * TODO: Watchers or Observers
 */

export default function* watchExportPhishingSaga() {
    yield takeLatest(
        types.EXPORT_PHISHING_TARGETS_PDF_REQUEST,
        exportPhishingTargetsPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_PHISHING_TARGETS_SHEET_REQUEST,
        exportPhishingTargetsSheetRequestHandler
    );
    yield takeLatest(
        types.EXPORT_PHISHING_RESULT_PDF_REQUEST,
        exportPhishingResultPDFRequestHandler
    );
    yield takeLatest(
        types.EXPORT_PHISHING_RESULT_SHEET_REQUEST,
        exportPhishingResultSheetRequestHandler
    );
}
