import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

import commonEN from 'locales/en';
import commonVI from 'locales/vi';
import commonTH from 'locales/th';

// ? Permissions
import permissionEN from 'locales/permissions/en';
import permissionVI from 'locales/permissions/vi';
import permissionTH from 'locales/permissions/th';

// ? Auth
import authEN from 'locales/auth/en';
import authVI from 'locales/auth/vi';
import authTH from 'locales/auth/th';

// ? Role
import roleEN from 'locales/role/en';
import roleVI from 'locales/role/vi';
import roleTH from 'locales/role/th';

// ? Staff
import staffEN from 'locales/staff/en';
import staffVI from 'locales/staff/vi';
import staffTH from 'locales/staff/th';

// ? Credit history
import creditHistoryEN from 'locales/credit-history/en';
import creditHistoryVI from 'locales/credit-history/vi';
import creditHistoryTH from 'locales/credit-history/th';

// ? Candidate
import candidateEN from 'locales/candidate/en';
import candidateVI from 'locales/candidate/vi';
import candidateTH from 'locales/candidate/th';

// ? Assessment
import assessmentEN from 'locales/assessment/en';
import assessmentVI from 'locales/assessment/vi';
import assessmentTH from 'locales/assessment/th';

// ? Training
import trainingEN from 'locales/training/en';
import trainingVI from 'locales/training/vi';
import trainingTH from 'locales/training/th';

// ? Simulation
import simulationEN from 'locales/simulation/en';
import simulationVI from 'locales/simulation/vi';
import simulationTH from 'locales/simulation/th';

// ? Table
import tableEN from 'locales/table/en';
import tableVI from 'locales/table/vi';
import tableTH from 'locales/table/th';

// ? Errors
import errorEN from 'locales/errors/en';
import errorVI from 'locales/errors/vi';
import errorTH from 'locales/errors/th';

// ? Dashboard
import dashboardEN from 'locales/dashboard/en';
import dashboardVI from 'locales/dashboard/vi';
import dashboardTH from 'locales/dashboard/th';

// ? Statistics
import statisticsEN from 'locales/statistics/en';
import statisticsVI from 'locales/statistics/vi';
import statisticsTH from 'locales/statistics/th';

// ? Success
import successEN from 'locales/success/en';
import successVI from 'locales/success/vi';
import successTH from 'locales/success/th';

// ? Survey
import surveyEN from 'locales/survey/en';
import surveyVI from 'locales/survey/vi';
import surveyTH from 'locales/survey/th';

// The translation
const resources = {
    en: {
        common: commonEN,
        permission: permissionEN,
        errors: errorEN,
        auth: authEN,
        role: roleEN,
        staff: staffEN,
        table: tableEN,
        creditHistory: creditHistoryEN,
        candidate: candidateEN,
        assessment: assessmentEN,
        training: trainingEN,
        simulation: simulationEN,
        dashboard: dashboardEN,
        statistics: statisticsEN,
        success: successEN,
        survey: surveyEN
    },
    vi: {
        common: commonVI,
        permission: permissionVI,
        errors: errorVI,
        auth: authVI,
        role: roleVI,
        staff: staffVI,
        table: tableVI,
        creditHistory: creditHistoryVI,
        candidate: candidateVI,
        assessment: assessmentVI,
        training: trainingVI,
        simulation: simulationVI,
        dashboard: dashboardVI,
        statistics: statisticsVI,
        success: successVI,
        survey: surveyVI
    },
    th: {
        common: commonTH,
        permission: permissionTH,
        errors: errorTH,
        auth: authTH,
        role: roleTH,
        staff: staffTH,
        table: tableTH,
        creditHistory: creditHistoryTH,
        candidate: candidateTH,
        assessment: assessmentTH,
        training: trainingTH,
        simulation: simulationTH,
        dashboard: dashboardTH,
        statistics: statisticsTH,
        success: successTH,
        survey: surveyTH
    }
};

i18n.use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        whiteList: ['en', 'vi', 'th'],
        preload: ['en'],
        resources,
        fallbackLng: 'en',
        lng: Cookies.get('i18next') || 'en',
        debug: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        detection: {
            order: ['path', 'localStorage', 'htmlTag', 'cookie'],
            caches: ['localStorage', 'cookie'] // cache user language on
        }
    });

export default i18n;
