import httpClient from 'api';
import USER_CONSTANTS from 'api/constants/user';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get user profile
 */

const getUserProfile = catchAsync(async () => {
    const response = await httpClient.get(USER_CONSTANTS.GET_USER_PROFILE);
    return response;
});

/**
 * TODO: Update user profile
 */

const updateUserProfile = catchAsync(async (requestBody) => {
    const response = await httpClient.put(USER_CONSTANTS.UPDATE_USER_PROFILE, requestBody);
    return response;
});

/**
 * TODO: Change password
 */

const changePassword = catchAsync(async (params) => {
    const response = await httpClient.put(USER_CONSTANTS.CHANGE_PASSWORD, params);
    return response;
});

/**
 * TODO: Enable 2FA
 */

const enable2FA = catchAsync(async (params) => {
    const response = await httpClient.put(USER_CONSTANTS.ENABLE_2FA, params);
    return response;
});

/**
 * TODO: Disable 2FA
 */

const disable2FA = catchAsync(async (params) => {
    const response = await httpClient.put(USER_CONSTANTS.DISABLE_2FA, params);
    return response;
});

/**
 * TODO: Get user token
 */

const getUserToken = catchAsync(async () => {
    const response = await httpClient.get(USER_CONSTANTS.GET_USER_TOKEN);
    return response;
});

/**
 * TODO: Get credit summary
 */

const getCreditSummary = catchAsync(async () => {
    const response = await httpClient.get(USER_CONSTANTS.CREDIT_SUMMARY);
    return response;
});

/**
 * TODO: Get credit history
 */

const getCreditHistory = catchAsync(async (params) => {
    const response = await httpClient.get(USER_CONSTANTS.CREDIT_HISTORY, { params });
    return response;
});

const UserServices = {
    getUserProfile,
    updateUserProfile,
    getUserToken,
    changePassword,
    enable2FA,
    disable2FA,
    getCreditSummary,
    getCreditHistory
};

export default UserServices;
