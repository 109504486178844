import dashboardRouteItems from './dashboard';
import aclRouteItems from './acl';
import assessmentRouteItems from './assessment';
import trainingRouteItems from './training';
import simulationRouteItems from './simulation';
import candidateRouteItems from './candidate';
import userRouteItems from './user';
import statisticsRouteItems from './statistics';
import surveyRouteItems from './survey';

const routeItems = [
    ...dashboardRouteItems,
    ...aclRouteItems,
    ...assessmentRouteItems,
    ...trainingRouteItems,
    ...simulationRouteItems,
    ...candidateRouteItems,
    ...userRouteItems,
    ...statisticsRouteItems,
    ...surveyRouteItems
];

export default routeItems;
