const themeTypography = (theme) => ({
    fontFamily: theme?.customization?.fontFamily,
    fontWeight: 500,
    display1: {
        fontWeight: 900,
        fontSize: 24,
        color: theme?.colors?.secondary900,
        fontFamily: theme?.customization?.fontFamily
    },
    display2: {
        fontWeight: 700,
        fontSize: 24,
        color: theme?.colors?.secondary900,
        fontFamily: theme?.customization?.fontFamily
    },
    display3: {
        fontWeight: 700,
        fontSize: 20,
        color: theme?.colors?.secondary900,
        fontFamily: theme?.customization?.fontFamily
    },
    h1: {
        fontWeight: 600,
        fontSize: 20,
        fontFamily: theme?.customization?.fontFamily
    },
    h2: {
        fontWeight: 500,
        fontSize: 24,
        fontFamily: theme?.customization?.fontFamily
    },
    title: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        fontFamily: theme?.customization?.fontFamily
    },
    title1: {
        fontWeight: 900,
        fontSize: 24,
        color: theme?.colors?.secondary900,
        fontFamily: theme?.customization?.fontFamily
    },
    body1: {
        fontWeight: 400,
        fontSize: 16,
        fontFamily: theme?.customization?.fontFamily
    },
    body2: {
        fontWeight: 500,
        fontSize: 16,
        fontFamily: theme?.customization?.fontFamily
    },
    body3: {
        fontWeight: 600,
        fontSize: 12,
        fontFamily: theme?.customization?.fontFamily
    },
    body4: {
        fontWeight: 500,
        fontSize: 14,
        fontFamily: theme?.customization?.fontFamily
    },
    body5: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: theme?.customization?.fontFamily
    },
    body6: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: theme?.customization?.fontFamily
    },
    body7: {
        fontWeight: 500,
        fontSize: 12,
        fontFamily: theme?.customization?.fontFamily
    },
    body8: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '14px',
        fontFamily: theme?.customization?.fontFamily
    },
    body9: {
        fontWeight: 800,
        fontSize: 14,
        fontFamily: theme?.customization?.fontFamily
    },
    body10: {
        fontWeight: 800,
        fontSize: 16,
        fontFamily: theme?.customization?.fontFamily
    }
});

export default themeTypography;

// fontWeight: '800 !important'
// fontWeight={500}
// variant='body4'
// variant='body2'
// color: #1565C0
/// color='#163B65'
// color='#0D3E76'
