const SIMULATION_CONSTANTS = {
    SEND_TEST_EMAIL: 'phishing/emails/send-test',
    GET_PHISHING_EMAIL_TEMPLATES: 'phishing/settings/email/templates',
    GET_PHISHING_LIST_NOTIFICATIONS: 'phishing/settings/notifications',
    LIST_PHISHING_CAMPAIGN: 'phishing',
    DELETE_CAMPAIGNS: 'phishing/delete',
    CREATE_PHISHING_CAMPAIGN: 'phishing/emails/setup',
    GET_LIST_BOOKMARKS: 'phishing/settings/landing-pages',
    IMPORT_PHISHING_SITE: 'phishing/settings/sites/import',
    GET_LIST_SMS_BRANDS: 'phishing/settings/sms/brands',
    SEND_TEST_SMS: 'phishing/sms/send-test',
    GET_LIST_SMS_TEMPLATES: 'phishing/settings/sms/templates',
    CREATE_SMS_CAMPAIGN: 'phishing/sms/setup',
    PREVIEW_SITE: 'phishing/settings/landing-pages/find',
    GET_LIST_CATEGORY: 'phishing/settings/categories',
    GET_CLONE_PHISHING_DETAILS: 'phishing',
    SEND_TEST_OTT: 'phishing/ott/send-test',
    CREATE_OTT_CAMPAIGN: 'phishing/ott/setup',
    GET_TEMPLATES_OTT_PHISHING: 'phishing/settings/ott/templates',
    GET_PHISHING_SUMMARY_DEPARTMENTS: 'departments'
};

export default SIMULATION_CONSTANTS;
