import types from './actionTypes';

/**
 * TODO: Export training participants
 */

export const exportTrainingParticipantsPDF = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_PDF_REQUEST,
    payload,
    permissions
});

export const exportTrainingParticipantsPDFSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_PDF_SUCCESS,
    payload
});

export const exportTrainingParticipantsPDFFailure = (payload) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_PDF_FAILURE,
    payload
});

export const exportTrainingParticipantsSheet = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportTrainingParticipantsSheetSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_SHEET_SUCCESS,
    payload
});

export const exportTrainingParticipantsSheetFailure = (payload) => ({
    type: types.EXPORT_TRAINING_PARTICIPANTS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export training in-progress
 */

export const exportTrainingInprogressPDF = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_PDF_REQUEST,
    payload,
    permissions
});

export const exportTrainingInprogressPDFSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_PDF_SUCCESS,
    payload
});

export const exportTrainingInprogressPDFFailure = (payload) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_PDF_FAILURE,
    payload
});

export const exportTrainingInprogressSheet = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportTrainingInprogressSheetSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_SHEET_SUCCESS,
    payload
});

export const exportTrainingInprogressSheetFailure = (payload) => ({
    type: types.EXPORT_TRAINING_INPROGRESS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export training result
 */

export const exportTrainingResultPDF = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_RESULT_PDF_REQUEST,
    payload,
    permissions
});

export const exportTrainingResultPDFSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_RESULT_PDF_SUCCESS,
    payload
});

export const exportTrainingResultPDFFailure = (payload) => ({
    type: types.EXPORT_TRAINING_RESULT_PDF_FAILURE,
    payload
});

export const exportTrainingResultSheet = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_RESULT_SHEET_REQUEST,
    payload,
    permissions
});

export const exportTrainingResultSheetSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_RESULT_SHEET_SUCCESS,
    payload
});

export const exportTrainingResultSheetFailure = (payload) => ({
    type: types.EXPORT_TRAINING_RESULT_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export training individual result
 */

export const exportTrainingIndividualPDF = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_PDF_REQUEST,
    payload,
    permissions
});

export const exportTrainingIndividualPDFSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_PDF_SUCCESS,
    payload
});

export const exportTrainingIndividualPDFFailure = (payload) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_PDF_FAILURE,
    payload
});

export const exportTrainingIndividualSheet = (payload, permissions) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_SHEET_REQUEST,
    payload,
    permissions
});

export const exportTrainingIndividualSheetSuccess = (payload) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_SHEET_SUCCESS,
    payload
});

export const exportTrainingIndividualSheetFailure = (payload) => ({
    type: types.EXPORT_TRAINING_INDIVIDUAL_SHEET_FAILURE,
    payload
});
