const commonActionTypes = {
    // ? Get dashboard assessment
    GET_DASHBOARD_ASSESSMENT_REQUEST: 'GET_DASHBOARD_ASSESSMENT_REQUEST',
    GET_DASHBOARD_ASSESSMENT_SUCCESS: 'GET_DASHBOARD_ASSESSMENT_SUCCESS',
    GET_DASHBOARD_ASSESSMENT_FAILURE: 'GET_DASHBOARD_ASSESSMENT_FAILURE',

    // ? Get dashboard training
    GET_DASHBOARD_TRAINING_REQUEST: 'GET_DASHBOARD_TRAINING_REQUEST',
    GET_DASHBOARD_TRAINING_SUCCESS: 'GET_DASHBOARD_TRAINING_SUCCESS',
    GET_DASHBOARD_TRAINING_FAILURE: 'GET_DASHBOARD_TRAINING_FAILURE',

    // ? Get dashboard phishing
    GET_DASHBOARD_PHISHING_REQUEST: 'GET_DASHBOARD_PHISHING_REQUEST',
    GET_DASHBOARD_PHISHING_SUCCESS: 'GET_DASHBOARD_PHISHING_SUCCESS',
    GET_DASHBOARD_PHISHING_FAILURE: 'GET_DASHBOARD_PHISHING_FAILURE',

    // ? Get dashboard last activity
    GET_DASHBOARD_LAST_ACTIVITY_REQUEST: 'GET_DASHBOARD_LAST_ACTIVITY_REQUEST',
    GET_DASHBOARD_LAST_ACTIVITY_SUCCESS: 'GET_DASHBOARD_LAST_ACTIVITY_SUCCESS',
    GET_DASHBOARD_LAST_ACTIVITY_FAILURE: 'GET_DASHBOARD_LAST_ACTIVITY_FAILURE',

    // ? Get dashboard credit & participant
    GET_DASHBOARD_CREDIT_PARTICIPANT_REQUEST: 'GET_DASHBOARD_CREDIT_PARTICIPANT_REQUEST',
    GET_DASHBOARD_CREDIT_PARTICIPANT_SUCCESS: 'GET_DASHBOARD_CREDIT_PARTICIPANT_SUCCESS',
    GET_DASHBOARD_CREDIT_PARTICIPANT_FAILURE: 'GET_DASHBOARD_CREDIT_PARTICIPANT_FAILURE'
};

export default commonActionTypes;
