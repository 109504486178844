import types from './actionTypes';

/**
 * TODO: Export participant sheet
 */

export const exportParticipantSheet = (payload, permissions) => ({
    type: types.EXPORT_PARTICIPANT_SHEET_REQUEST,
    payload,
    permissions
});

export const exportParticipantSheetSuccess = (payload) => ({
    type: types.EXPORT_PARTICIPANT_SHEET_SUCCESS,
    payload
});

export const exportParticipantSheetFailure = (payload) => ({
    type: types.EXPORT_PARTICIPANT_SHEET_FAILURE,
    payload
});
