// ? Transform permission data
const transformedPermissionsData = (data = {}) => {
    // ? Get all key from data
    const keys = Object.keys(data);

    // ? For each key, format the data of that key from array of string to array of object with key and value
    keys.forEach((key) => {
        data[key] = data[key].map((item) => {
            return {
                key: item,
                value: null
            };
        });
    });

    return {
        permissionKeys: keys,
        data
    };
};

export { transformedPermissionsData };
