/**
 * TODO: Transform data is exporting successfully
 */

const dataExportingSuccess = (state) => {
    return {
        ...state,
        serverExportingSuccessMessage: 'yourDataIsExporting'
    };
};

/**
 * TODO: Transform data is exporting failed
 */

const dataExportingFailure = (state) => {
    return {
        ...state,
        serverExportingFailedMessage: 'exportPending'
    };
};

/**
 * TODO: Transform exported data sent successfully
 */

const exportedDataSentSuccess = (state) => {
    return {
        ...state,
        mailSentSuccessMessage: 'yourExportIsSent'
    };
};

/**
 * TODO: Clear export message
 */

const clearExportMessage = () => {
    return {
        serverExportingSuccessMessage: '',
        serverExportingFailedMessage: '',
        mailSentSuccessMessage: ''
    };
};

export default {
    dataExportingSuccess,
    dataExportingFailure,
    exportedDataSentSuccess,
    clearExportMessage
};
