const initialState = {
    trainingSummaryData: {},
    trainingTotalData: {},
    trainingByDepartment: {
        data: [],
        pagination: {},
        reportKey: ''
    },
    participantNotYetAssignedTraining: {
        data: [],
        pagination: {}
    }
};

export default initialState;
