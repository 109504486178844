import React from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

/**
 * Reusable Button.
 * @constructor
 * @param {any} props - The prop of the component.
 */

const BaseButton = ({
    variant = 'contained',
    children,
    type = 'button',
    styles = {},
    disabled = false,
    onClick = () => {},
    height = 40,
    ...props
}) => {
    const containedStyles = {
        color: '#fff',
        bgcolor: '#163B65',
        '&:hover': {
            bgcolor: '#1565C0',
            borderColor: '#1565C0' + '!important'
        }
    };

    const outlinedStyles = {
        color: '#163B65',
        bgcolor: '#fff',
        border: '1px solid #163B65',
        '&:hover': {
            color: '#2196F3',
            borderColor: '#1565C0'
        }
    };

    const textStyles = {
        bgcolor: 'transparent',
        color: '#163B65'
    };

    const disabledStyles = {
        opacity: '0.5'
    };

    return (
        <Button
            type={type}
            disabled={disabled}
            variant={variant}
            disableRipple
            sx={{
                height,
                '&.MuiButton-root': {
                    fontWeight: '600',
                    textTransform: 'unset',
                    boxShadow: 'none',
                    fontSize: '16px',
                    borderRadius: '5px'
                },
                '&.MuiButton-contained': containedStyles,
                '&.MuiButton-outlined': outlinedStyles,
                '&.MuiButton-text': textStyles,
                '&.Mui-disabled': disabledStyles,
                fontSize: '16px',
                ...styles
            }}
            onClick={onClick}
            {...props}
        >
            {children}
        </Button>
    );
};

BaseButton.propTypes = {
    variant: PropTypes.string,
    // children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.any,
    bgcolor: PropTypes.string,
    color: PropTypes.string,
    styles: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    height: PropTypes.number
};

export default BaseButton;
