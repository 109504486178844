import httpClient from 'api';

import PHISHING_STATISTICS_CONSTANTS from 'api/constants/statistics/phishing';

import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Statistics phishing summary
 */

const getStatisticsPhishingSummary = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_PHISHING_SUMMARY,
        {
            params
        }
    );
    return response;
});

/**
 * TODO: Statistics phishing rating by months
 */

const getStatisticsPhishingRatingByMonths = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_PHISHING_RATING_BY_MONTHS,
        {
            params
        }
    );
    return response;
});

/**
 * TODO: Statistics phishing result
 */

const getStatisticsPhishingResult = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_PHISHING_RESULT,
        {
            params
        }
    );

    return response;
});

/**
 * TODO: Statistics top phished by department
 */

const getStatisticsTopPhishedByDepartment = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT,
        {
            params
        }
    );

    return response;
});

/**
 * TODO: Statistics top clicked phishing template
 */

const getStatisticsTopClickedPhishingTemplate = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE,
        {
            params
        }
    );

    return response;
});

/**
 * TODO: Statistics phishing summary total
 */

const getStatisticsPhishingSummaryTotal = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_PHISHING_SUMMARY_TOTAL,
        {
            params
        }
    );

    return response;
});

/**
 * TODO: Statistics details phishing result
 */

const getStatisticsDetailsPhishingResult = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_DETAILS_PHISHING_RESULT,
        {
            params
        }
    );

    return response;
});

/**
 * TODO: Statistics list targets unphished
 */

const getStatisticsListTargetsUnphished = catchAsync(async (params) => {
    const response = await httpClient.get(
        PHISHING_STATISTICS_CONSTANTS.GET_STATISTICS_LIST_TARGETS_UNPHISHED,
        {
            params
        }
    );

    return response;
});

const PhishingStatisticsServices = {
    getStatisticsPhishingSummary,
    getStatisticsPhishingRatingByMonths,
    getStatisticsPhishingResult,
    getStatisticsTopPhishedByDepartment,
    getStatisticsTopClickedPhishingTemplate,
    getStatisticsPhishingSummaryTotal,
    getStatisticsDetailsPhishingResult,
    getStatisticsListTargetsUnphished
};

export default PhishingStatisticsServices;
