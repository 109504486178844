import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import createReducer from './rootReducer';
import rootSaga, { createSagaInjector } from './rootSaga';

const configureAppStore = (initialState = {}) => {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

    // sagaMiddleware
    const middlewares = [sagaMiddleware];

    const store = configureStore({
        reducer: createReducer(),
        preloadedState: initialState,
        devTools: process.env.REACT_APP_NODE_ENV !== 'production',
        middleware: [...middlewares]
    });

    // Add a dictionary to keep track of the registered async reducers
    store.injectedReducers = {};

    // Create an inject reducer function
    // This function adds the injected reducer, and creates a new combined reducer
    store.injectReducer = (key, injectedReducer) => {
        store.injectedReducers[key] = injectedReducer;
        store.replaceReducer(createReducer(store.injectedReducers));
    };

    // Add injectSaga method to our store
    store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./rootReducer', () => {
            const nextRootReducer = require('./rootReducer');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

export default configureAppStore;
