import i18next from 'i18next';

/**
 * TODO: Get list course
 */

import {
    transformDetailModulesData,
    transformSummaryData,
    transformTrainingListExpandRowData
} from 'transformer/training';

import { transformCandidatesListWithID } from 'transformer/candidate';
import { transformDeleteSuccessMessage } from 'transformer/assessment';
import stringUtils from 'utils/string-utils';

const getListCourseReducerHandler = (state, payload) => {
    return { ...state, listCourseData: payload };
};

/**
 * TODO: Create training
 */

const createTrainingReducerHandler = (state) => {
    return { ...state, isCreateTrainingSuccess: true };
};

const createTrainingResetReducerHandler = (state) => {
    return { ...state, isCreateTrainingSuccess: false };
};

/**
 * TODO: Transform Training List data
 */

const getListTraining = (state, payload) => {
    return {
        ...state,
        startTrainingSuccessMessage: '',
        stopTrainingSuccessMessage: '',
        rebuildTrainingSuccessMessage: '',
        deleteTrainingsSuccessMessage: '',
        listTrainings: {
            data: payload.trainings,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform Training List ExpandRow data
 */

const getListTrainingExpandRowData = (state, payload) => {
    return {
        ...state,
        listTrainingsExpandRow: transformTrainingListExpandRowData(payload)
    };
};

/**
 * TODO: Transform Candidates List By Training data
 */

const getListCandidatesByTraining = (state, payload) => {
    return {
        ...state,
        listCandidatesByTraining: {
            data: transformCandidatesListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage,
                payload?.meta.totalRecord,
                payload?.meta?.sortDirection
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform summary
 */

const getTrainingSummary = (state, payload) => {
    return {
        ...state,
        trainingSummary: transformSummaryData(payload)
    };
};
/**
 * TODO: Transform result course summary
 */

const getTrainingResultCourseSummary = (state, payload) => {
    return {
        ...state,
        resultCourseSummary: payload
    };
};

/**
 * TODO: Transform summary candidate list
 */

const getTrainingSummaryCandidateList = (state, payload) => {
    return {
        ...state,
        summaryCandidateList: {
            data: transformCandidatesListWithID(
                payload.candidates,
                'no',
                payload?.meta.currentPage,
                payload?.meta.recordPerPage
            ),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform candidate result detail stats
 */

const getCandidateResultDetailStats = (state, payload) => {
    if (payload.detail.length > 0)
        return {
            ...state,
            candidateTrainingDetailStats: {
                accessInfo: payload.accessInfo,
                percentComplete: payload.detail[0].percentComplete,
                modules: transformDetailModulesData(payload.detail[0].modules)
            }
        };
};

/**
 * TODO: start training
 */

const startTraining = (state) => {
    return {
        ...state,
        startTrainingSuccessMessage: i18next.t('success:training.startedSuccess')
    };
};

/**
 * TODO: stop training
 */

const stopTraining = (state) => {
    return {
        ...state,
        stopTrainingSuccessMessage: i18next.t('success:training.stoppedSuccess')
    };
};

/**
 * TODO: rebuild training
 */

const rebuildTraining = (state) => {
    return {
        ...state,
        rebuildTrainingSuccessMessage: i18next.t('success:training.rebuildSuccess')
    };
};

/**
 * TODO: delete trainings
 */

const deleteTrainings = (state, payload) => {
    const counter = transformDeleteSuccessMessage(payload.message);
    return {
        ...state,
        deleteTrainingsSuccessMessage: i18next.t('success:training.deleteSuccess', {
            key: +counter,
            label: stringUtils.pluralNounCheck(+counter, i18next.t('training:trainingLabel'), 's')
        })
    };
};

/**
 * TODO: Get training details
 */

const getTrainingDetails = (state, payload) => {
    return {
        ...state,
        trainingDetails: payload
    };
};

const getTrainingDetailsReset = (state) => {
    return {
        ...state,
        trainingDetails: {}
    };
};

/**
 * TODO: Update training
 */

const updateTraining = (state) => {
    return {
        ...state,
        isUpdateTrainingSuccess: true
    };
};

const updateTrainingReset = (state) => {
    return {
        ...state,
        isUpdateTrainingSuccess: false
    };
};

/**
 * TODO: Get training clone details
 */

const getTrainingCloneDetails = (state, payload) => {
    return {
        ...state,
        trainingCloneDetails: payload
    };
};

const getTrainingCloneDetailsReset = (state) => {
    return {
        ...state,
        trainingCloneDetails: {}
    };
};

/**
 * TODO: Get training summary departments
 */

const getTrainingSummaryDepartments = (state, payload) => {
    return {
        ...state,
        summaryDepartments: {
            data: payload.departments,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

export default {
    getListCourseReducerHandler,

    // ? Create training
    createTrainingReducerHandler,
    createTrainingResetReducerHandler,

    // ? List training
    getListTraining,
    getListTrainingExpandRowData,
    getListCandidatesByTraining,
    getTrainingSummary,
    getTrainingResultCourseSummary,
    getTrainingSummaryCandidateList,
    getCandidateResultDetailStats,
    startTraining,
    stopTraining,
    rebuildTraining,
    deleteTrainings,
    getTrainingDetails,
    getTrainingDetailsReset,
    updateTraining,
    updateTrainingReset,
    getTrainingCloneDetails,
    getTrainingCloneDetailsReset,
    getTrainingSummaryDepartments
};
