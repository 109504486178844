import {
    transformDashboardAssessmentData,
    transformDashboardPhishingData,
    transformDashboardTrainingData,
    transformDashboardLastActivityData,
    transformDashboardCreditData,
    transformDashboardParticipantData
} from 'transformer/dashboard';

/**
 * TODO: Transform dashboard assessment data
 */

const getDashboardAssessmentReducerHandler = (state, payload) => {
    return { ...state, assessment: transformDashboardAssessmentData(payload) };
};

/**
 * TODO: Transform dashboard training data
 */

const getDashboardTrainingReducerHandler = (state, payload) => {
    return { ...state, training: transformDashboardTrainingData(payload) };
};

/**
 * TODO: Transform dashboard phishing data
 */

const getDashboardPhishingHandler = (state, payload) => {
    return { ...state, phishing: transformDashboardPhishingData(payload) };
};

/**
 * TODO: Transform dashboard last activity data
 */

const getDashboardLastActivityHandler = (state, payload) => {
    return { ...state, lastActivity: transformDashboardLastActivityData(payload) };
};

/**
 * TODO: Transform dashboard credit participant data
 */

const getDashboardCreditParticipantHandler = (state, payload) => {
    return {
        ...state,
        credit: transformDashboardCreditData(payload?.credits),
        participant: transformDashboardParticipantData(payload?.participant)
    };
};

export default {
    getDashboardAssessmentReducerHandler,
    getDashboardTrainingReducerHandler,
    getDashboardPhishingHandler,
    getDashboardLastActivityHandler,
    getDashboardCreditParticipantHandler
};
