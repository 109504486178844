import CommonServices from 'api/common';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import types from './actionTypes';
import {
    getDeparmentListSuccess,
    getDeparmentListFailure,
    getDeparmentListForSmsPhishingSuccess,
    getDeparmentListForSmsPhishingFailure,
    toggleBreadcrumbsSuccess
} from './action';

import { transformDepartmentList } from 'transformer/common';

/**
 * TODO: Get department list request handler
 */

function* getDepartmentListRequestHandler({ payload }) {
    try {
        const response = yield call(CommonServices.getDepartmentList, payload);
        yield put(getDeparmentListSuccess(transformDepartmentList(response.data)));
    } catch (errors) {
        yield put(getDeparmentListFailure(errors));
    }
}

/**
 * TODO: Get department list for sms phishing request handler
 */

function* getDepartmentListForSmsPhishingRequestHandler({ payload }) {
    try {
        const response = yield call(CommonServices.getDepartmentListForSmsPhishing, payload);
        yield put(getDeparmentListForSmsPhishingSuccess(transformDepartmentList(response.data)));
    } catch (errors) {
        yield put(getDeparmentListForSmsPhishingFailure(errors));
    }
}

/**
 * TODO: Toggle breadcrumbs
 */

function* toggleBreadcrumbsRequestHandler({ payload }) {
    yield put(toggleBreadcrumbsSuccess(payload));
}

/**
 * TODO: Watchers or Observers
 */

function* watchCommonSaga() {
    yield takeLatest(types.GET_DEPARTMENT_LIST_REQUEST, getDepartmentListRequestHandler);
    yield takeLatest(
        types.GET_DEPARTMENT_LIST_FOR_SMS_PHISHING_REQUEST,
        getDepartmentListForSmsPhishingRequestHandler
    );

    yield takeLatest(types.TOGGLE_BREADCRUMBS, toggleBreadcrumbsRequestHandler);
}

export default function* commonSaga() {
    yield fork(watchCommonSaga);
}
