import stringUtils from 'utils/string-utils';

const checkItemStatus = (itemStatus = '') => {
    let status = itemStatus;
    if (itemStatus === 'new') status = 'ready';
    else if (itemStatus === 'running') status = 'running';
    else if (itemStatus === 'resolve') status = 'closed';
    return status;
};

const transformDepartmentList = ({ departments = [] }) => {
    const departmentTransformed = departments.map((department) => {
        return {
            ...department,
            departmentName: stringUtils.htmlDecode(department.departmentName).toLowerCase(),
            departmentKey: department?.departmentKey.toLowerCase() || '',
            tempTotalCandidate: department.totalCandidate
        };
    });

    return {
        departments: departmentTransformed
    };
};

export { transformDepartmentList, checkItemStatus };
