import httpClient from 'api';
import AUTH_CONSTANTS from 'api/constants/auth';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Login
 * @param {Object} data
 * @param {String} data.email
 * @param {String} data.password
 * @returns {Promise}
 */

const login = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.LOGIN, data);
    return response;
});

/**
 * TODO: Register
 * @param {Object} data
 * @param {String} data.displayName
 * @param {String} data.email
 * @param {String} data.password
 * @param {String} data.confirmPassword
 * @param {String} data.phoneNumber
 * @returns {Promise}
 */

const register = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.REGISTER, data);
    return response;
});

/**
 * TODO: Forgot password
 * @param {Object} data
 * @param {String} data.email
 * @returns {Promise}
 */

const forgotPassword = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.FORGOT_PASSWORD, data);
    return response;
});
/**
 * TODO: Forgot password resend
 * @param {Object} data
 * @param {String} data.email
 * @returns {Promise}
 */

const forgotPasswordResend = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.FORGOT_PASSWORD_RESEND, data);
    return response;
});

/**
 * TODO: Forgot password verify code
 * @param {Object} data
 * @param {String} data.code
 * @returns {Promise}
 */

const forgotPasswordVerifyCode = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.FORGOT_PASSWORD_VERIFY_CODE, data);
    return response;
});

/**
 * TODO: Reset password
 * @param {Object} data
 * @param {String} data.code
 * @param {String} data.newPassword
 * @param {String} data.confirmPassword
 * @returns {Promise}
 */

const resetPassword = catchAsync(async (data) => {
    const response = await httpClient.post(AUTH_CONSTANTS.RESET_PASSWORD, data);
    return response;
});

/**
 * TODO: Logout
 */

const logout = async () => {
    const response = await httpClient.post(AUTH_CONSTANTS.LOGOUT);
    return response;
};

/**
 * TODO: Verify 2FA OTP
 * @param {String} data.otp
 * @param {String} data.email
 * @returns {Promise}
 */

const verifyOTP = catchAsync(async (params) => {
    const response = await httpClient.post(AUTH_CONSTANTS.VERIFY_OTP, params);
    return response;
});

/**
 * TODO: Resend 2FA OTP
 * @param {String} data.email
 * @returns {Promise}
 */

const resendOTP = catchAsync(async (params) => {
    const response = await httpClient.post(AUTH_CONSTANTS.RESEND_OTP, params);
    return response;
});

/**
 * Interface auth services
 */

const AuthServices = {
    login,
    register,
    forgotPassword,
    forgotPasswordResend,
    forgotPasswordVerifyCode,
    resetPassword,
    logout,
    verifyOTP,
    resendOTP
};

export default AuthServices;
