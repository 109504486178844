import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_LIST_CANDIDATES_SUCCESS:
            return reducerHandler.getListCandidates(state, action.payload);
        case types.DELETE_CANDIDATES_SUCCESS:
            return reducerHandler.deleteCandidates(state, action.payload);
        case types.DELETE_CANDIDATES_RESET:
            return reducerHandler.resetDeleteFailedError(state);

        // ? Get list candidates for sms campaign
        case types.GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_SUCCESS:
            return reducerHandler.getListCandidatesForSmsCampaign(state, action.payload);

        // ? Add candidates
        case types.ADD_CANDIDATES_SUCCESS:
            return reducerHandler.addCandidatesReducerHandler(state, action.payload);
        case types.ADD_CANDIDATES_RESET:
            return reducerHandler.addCandidatesResetReducerHandler(state);

        // ? Import candidates
        case types.IMPORT_CANDIDATES_SUCCESS:
            return reducerHandler.importCandidatesReducerHandler(state, action.payload);
        case types.IMPORT_CANDIDATES_RESET:
            return reducerHandler.importCandidatesResetReducerHandler(state);

        // ? Update candidate
        case types.UPDATE_CANDIDATE_SUCCESS:
            return reducerHandler.updateCandidateReducerHandler(state, action.payload);
        case types.UPDATE_CANDIDATE_RESET:
            return reducerHandler.updateCandidateResetReducerHandler(state);

        // ? Check valid candidates
        case types.CHECK_VALID_CANDIDATES_SUCCESS:
            return reducerHandler.checkValidCandidatesReducerHandler(state, action.payload);

        case types.CHECK_VALID_CANDIDATES_RESET:
            return reducerHandler.checkValidCandidatesResetReducerHandler(state);

        default:
            return state;
    }
};

export default reducer;
