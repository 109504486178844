import types from './actionTypes';

/**
 * TODO: Get statistics phishing summary
 */

const getStatisticsPhishingSummary = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_REQUEST,
    payload
});

const getStatisticsPhishingSummarySuccess = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_SUCCESS,
    payload
});

const getStatisticsPhishingSummaryFailure = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_FAILURE,
    payload
});

/**
 * TODO: Get statistics phishing rating by months
 */

const getStatisticsPhishingRatingByMonths = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RATING_BY_MONTHS_REQUEST,
    payload
});

const getStatisticsPhishingRatingByMonthsSuccess = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RATING_BY_MONTHS_SUCCESS,
    payload
});

const getStatisticsPhishingRatingByMonthsFailure = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RATING_BY_MONTHS_FAILURE,
    payload
});

/**
 * TODO: Get statistics phishing result
 */

const getStatisticsPhishingResult = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RESULT_REQUEST,
    payload
});

const getStatisticsPhishingResultSuccess = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RESULT_SUCCESS,
    payload
});

const getStatisticsPhishingResultFailure = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_RESULT_FAILURE,
    payload
});

/**
 * TODO: Get statistics top phished by department
 */

const getStatisticsTopPhishedByDepartment = (payload) => ({
    type: types.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_REQUEST,
    payload
});

const getStatisticsTopPhishedByDepartmentSuccess = (payload) => ({
    type: types.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_SUCCESS,
    payload
});

const getStatisticsTopPhishedByDepartmentFailure = (payload) => ({
    type: types.GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_FAILURE,
    payload
});

/**
 * TODO: Get statistics top clicked phishing template
 */

const getStatisticsTopClickedPhishingTemplate = (payload) => ({
    type: types.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_REQUEST,
    payload
});

const getStatisticsTopClickedPhishingTemplateSuccess = (payload) => ({
    type: types.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_SUCCESS,
    payload
});

const getStatisticsTopClickedPhishingTemplateFailure = (payload) => ({
    type: types.GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_FAILURE,
    payload
});

/**
 * TODO: Get statistics phishing summary total
 */

const getStatisticsPhishingSummaryTotal = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_TOTAL_REQUEST,
    payload
});

const getStatisticsPhishingSummaryTotalSuccess = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_TOTAL_SUCCESS,
    payload
});

const getStatisticsPhishingSummaryTotalFailure = (payload) => ({
    type: types.GET_STATISTICS_PHISHING_SUMMARY_TOTAL_FAILURE,
    payload
});

/**
 * TODO: Get statistics details phishing result
 */

const getStatisticsDetailsPhishingResult = (payload) => ({
    type: types.GET_STATISTICS_DETAILS_PHISHING_RESULT_REQUEST,
    payload
});

const getStatisticsDetailsPhishingResultSuccess = (payload) => ({
    type: types.GET_STATISTICS_DETAILS_PHISHING_RESULT_SUCCESS,
    payload
});

const getStatisticsDetailsPhishingResultFailure = (payload) => ({
    type: types.GET_STATISTICS_DETAILS_PHISHING_RESULT_FAILURE,
    payload
});

/**
 * TODO: Get statistics list targets unphished
 */

const getStatisticsListTargetsUnphished = (payload) => ({
    type: types.GET_STATISTICS_LIST_TARGETS_UNPHISHED_REQUEST,
    payload
});

const getStatisticsListTargetsUnphishedSuccess = (payload) => ({
    type: types.GET_STATISTICS_LIST_TARGETS_UNPHISHED_SUCCESS,
    payload
});

const getStatisticsListTargetsUnphishedFailure = (payload) => ({
    type: types.GET_STATISTICS_LIST_TARGETS_UNPHISHED_FAILURE,
    payload
});

export {
    // ? Get statistics phishing summary
    getStatisticsPhishingSummary,
    getStatisticsPhishingSummarySuccess,
    getStatisticsPhishingSummaryFailure,

    // ? Get statistics phishing rating by months
    getStatisticsPhishingRatingByMonths,
    getStatisticsPhishingRatingByMonthsSuccess,
    getStatisticsPhishingRatingByMonthsFailure,

    // ? Get statistics phishing result
    getStatisticsPhishingResult,
    getStatisticsPhishingResultSuccess,
    getStatisticsPhishingResultFailure,

    // ? Get statistics top phished by department
    getStatisticsTopPhishedByDepartment,
    getStatisticsTopPhishedByDepartmentSuccess,
    getStatisticsTopPhishedByDepartmentFailure,

    // ? Get statistics top clicked phishing template
    getStatisticsTopClickedPhishingTemplate,
    getStatisticsTopClickedPhishingTemplateSuccess,
    getStatisticsTopClickedPhishingTemplateFailure,

    // ? Get statistics phishing summary total
    getStatisticsPhishingSummaryTotal,
    getStatisticsPhishingSummaryTotalSuccess,
    getStatisticsPhishingSummaryTotalFailure,

    // ? Get statistics details phishing result
    getStatisticsDetailsPhishingResult,
    getStatisticsDetailsPhishingResultSuccess,
    getStatisticsDetailsPhishingResultFailure,

    // ? Get statistics list targets unphished
    getStatisticsListTargetsUnphished,
    getStatisticsListTargetsUnphishedSuccess,
    getStatisticsListTargetsUnphishedFailure
};
