const initialState = {
    listAssessments: {
        data: [],
        pagination: {}
    },
    listCandidates: {
        data: [],
        pagination: {}
    },
    listCandidatesByAssessment: {
        data: [],
        pagination: {}
    },
    listQuestionsByAssessment: {
        data: [],
        pagination: {}
    },
    summaryParticipants: {
        candidatesAssigned: 0,
        details: [],
        assessmentDetail: {}
    },
    summaryStatistic: {
        totalQuestions: 0,
        details: []
    },
    listSummaryCandidates: {
        data: [],
        pagination: {}
    },
    listSummaryQuestion: {
        data: [],
        pagination: {}
    },
    listSummaryDepartments: {
        data: [],
        pagination: {}
    },
    candidateDetail: {
        data: [],
        pagination: {}
    },
    candidateResult: {
        details: [],
        status: '',
        score: 0
    },
    startAssessmentSuccessMessage: '',
    stopAssessmentSuccessMessage: '',
    rebuildAssessmentSuccessMessage: '',
    deleteAssessmentsSuccessMessage: '',
    detailQuestionResult: [],
    listQuestionsData: {},
    listTemplatesData: {},
    listQuestionsByTemplateIdsData: {},
    listExamTagsData: {},
    isCreateAssessmentSuccess: false,
    isEditAssessmentSuccess: false,
    languageSupported: [],
    assessmentDetailsData: {},
    assessmentCloneDetailsData: {}
};

export default initialState;
