const actionTypes = {
    // * Get list candidates
    GET_LIST_CANDIDATES_REQUEST: 'GET_LIST_CANDIDATES_REQUEST',
    GET_LIST_CANDIDATES_SUCCESS: 'GET_LIST_CANDIDATES_SUCCESS',
    GET_LIST_CANDIDATES_FAILURE: 'GET_LIST_CANDIDATES_FAILURE',

    // * Get list candidates for sms campaign
    GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_REQUEST: 'GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_REQUEST',
    GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_SUCCESS: 'GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_SUCCESS',
    GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_FAILURE: 'GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_FAILURE',

    // * Delete candidates
    DELETE_CANDIDATES_REQUEST: 'DELETE_CANDIDATES_REQUEST',
    DELETE_CANDIDATES_SUCCESS: 'DELETE_CANDIDATES_SUCCESS',
    DELETE_CANDIDATES_FAILURE: 'DELETE_CANDIDATES_FAILURE',
    DELETE_CANDIDATES_RESET: 'DELETE_CANDIDATES_RESET',

    // * Add candidates
    ADD_CANDIDATES_REQUEST: 'ADD_CANDIDATES_REQUEST',
    ADD_CANDIDATES_SUCCESS: 'ADD_CANDIDATES_SUCCESS',
    ADD_CANDIDATES_FAILURE: 'ADD_CANDIDATES_FAILURE',
    ADD_CANDIDATES_RESET: 'ADD_CANDIDATES_RESET',

    // * Import candidates
    IMPORT_CANDIDATES_REQUEST: 'IMPORT_CANDIDATES_REQUEST',
    IMPORT_CANDIDATES_SUCCESS: 'IMPORT_CANDIDATES_SUCCESS',
    IMPORT_CANDIDATES_FAILURE: 'IMPORT_CANDIDATES_FAILURE',
    IMPORT_CANDIDATES_RESET: 'IMPORT_CANDIDATES_RESET',

    // * Update candidate
    UPDATE_CANDIDATE_REQUEST: 'UPDATE_CANDIDATE_REQUEST',
    UPDATE_CANDIDATE_SUCCESS: 'UPDATE_CANDIDATE_SUCCESS',
    UPDATE_CANDIDATE_FAILURE: 'UPDATE_CANDIDATE_FAILURE',
    UPDATE_CANDIDATE_RESET: 'UPDATE_CANDIDATE_RESET',

    // * Check valid candidates
    CHECK_VALID_CANDIDATES_REQUEST: 'CHECK_VALID_CANDIDATES_REQUEST',
    CHECK_VALID_CANDIDATES_SUCCESS: 'CHECK_VALID_CANDIDATES_SUCCESS',
    CHECK_VALID_CANDIDATES_FAILURE: 'CHECK_VALID_CANDIDATES_FAILURE',
    CHECK_VALID_CANDIDATES_RESET: 'CHECK_VALID_CANDIDATES_RESET'
};

export default actionTypes;
