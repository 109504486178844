import { transformContractList } from 'transformer/organization';

/**
 * TODO: Transform Contract List Data
 */
const getContractListHandler = (state, { payload }) => {
    return {
        ...state,
        contracts: {
            data: transformContractList(payload.contracts),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform Contract Detail Data
 */
const getContractDetailHandler = (state, { payload }) => {
    return {
        ...state,
        contractDetail: payload
    };
};

export default {
    getContractListHandler,
    getContractDetailHandler
};
