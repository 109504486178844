import types from './actionTypes';

/**
 * TODO: Export survey participants
 */

export const exportSurveyParticipantsPDF = (payload, permissions) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_PDF_REQUEST,
    payload,
    permissions
});

export const exportSurveyParticipantsPDFSuccess = (payload) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_PDF_SUCCESS,
    payload
});

export const exportSurveyParticipantsPDFFailure = (payload) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_PDF_FAILURE,
    payload
});

export const exportSurveyParticipantsSheet = (payload, permissions) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportSurveyParticipantsSheetSuccess = (payload) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_SHEET_SUCCESS,
    payload
});

export const exportSurveyParticipantsSheetFailure = (payload) => ({
    type: types.EXPORT_SURVEY_PARTICIPANTS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export survey questions
 */

export const exportSurveyQuestionsPDF = (payload, permissions) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_PDF_REQUEST,
    payload,
    permissions
});

export const exportSurveyQuestionsPDFSuccess = (payload) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_PDF_SUCCESS,
    payload
});

export const exportSurveyQuestionsPDFFailure = (payload) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_PDF_FAILURE,
    payload
});

export const exportSurveyQuestionsSheet = (payload, permissions) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportSurveyQuestionsSheetSuccess = (payload) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_SHEET_SUCCESS,
    payload
});

export const exportSurveyQuestionsSheetFailure = (payload) => ({
    type: types.EXPORT_SURVEY_QUESTIONS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export survey result
 */
export const exportSurveyResultSheet = (payload, permissions) => ({
    type: types.EXPORT_SURVEY_RESULT_SHEET_REQUEST,
    payload,
    permissions
});

export const exportSurveyResultSheetSuccess = (payload) => ({
    type: types.EXPORT_SURVEY_RESULT_SHEET_SUCCESS,
    payload
});

export const exportSurveyResultSheetFailure = (payload) => ({
    type: types.EXPORT_SURVEY_RESULT_SHEET_FAILURE,
    payload
});
