import services from 'api/statistics/assessment';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import actions from './actions';
import actionTypes from './actionTypes';

/**
 * TODO: Get assessment total
 */
function* getAssessmentTotalHandler({ payload }) {
    try {
        const response = yield call(services.getAssessmentTotal, payload);
        yield put(actions.getAssessmentTotalSuccess(response.data));
    } catch (error) {
        yield put(actions.getAssessmentTotalFailure(error));
    }
}

/**
 * TODO: Get assessment summary
 */
function* getAssessmentSummaryHandler({ payload }) {
    try {
        const response = yield call(services.getAssessmentSummary, payload);
        yield put(actions.getAssessmentSummarySuccess(response.data));
    } catch (error) {
        yield put(actions.getAssessmentSummaryFailure(error));
    }
}

/**
 * TODO: Get Assessment by department
 */
function* getAssessmentByDepartmentHandler({ payload }) {
    try {
        const response = yield call(services.getAssessmentByDepartment, payload);
        yield put(actions.getAssessmentByDepartmentSuccess(response.data));
    } catch (error) {
        yield put(actions.getAssessmentByDepartmentFailure(error));
    }
}

/**
 * TODO: Not yet assigned
 */
function* getParticipantNotYetAssignedHandler({ payload }) {
    try {
        const response = yield call(services.getParticipantNotYetAssignToAssessment, payload);
        yield put(actions.getParticipantNotYetAssignedToAssessmentSuccess(response.data));
    } catch (error) {
        yield put(actions.getParticipantNotYetAssignedToAssessmentFailure(error));
    }
}

function* watchSummarySaga() {
    yield takeLatest(
        actionTypes.GET_ASSESSMENT_REPORT_SUMMARY_REQUEST,
        getAssessmentSummaryHandler
    );
    yield takeLatest(actionTypes.GET_ASSESSMENT_REPORT_TOTAL_REQUEST, getAssessmentTotalHandler);
    yield takeLatest(
        actionTypes.ASSESSMENT_BY_DEPARTMENT_REQUEST,
        getAssessmentByDepartmentHandler
    );
    yield takeLatest(
        actionTypes.NOT_YET_ASSIGNED_TO_ASSESSMENT_REQUEST,
        getParticipantNotYetAssignedHandler
    );
}

export default function* summaryReportSaga() {
    yield fork(watchSummarySaga);
}
