import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import EXPORT_CONSTANTS from 'api/constants/exports';
import objectUtils from 'utils/object-utils';
import dateUtils from 'utils/date-utils';

const exportParticipantsSheet = catchAsync(async ({ params }) => {
    const reqURL = EXPORT_CONSTANTS.PARTICIPANT_SHEET_EXPORT;
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const ParticipantServices = {
    exportParticipantsSheet
};

export default ParticipantServices;
