import dateUtils, { dayjs } from 'utils/date-utils';
import numberUtils from 'utils/number-utils';
import objectUtils from 'utils/object-utils';
import stringUtils from 'utils/string-utils';
import i18next from 'i18next';
import {
    CREATE_EMAIL_SIMULATION_STEPS,
    CREATE_SMS_SIMULATION_STEPS,
    CREATE_OTT_SIMULATION_STEPS
} from 'common/constants/simulation';

import { CANDIDATE_CUSTOMIZATION_METHODS } from 'common/constants';

/**
 * TODO: Transform phishing email template list
 */
const transformPhishingEmailTemplateList = (data) => {
    if (!data || data.length === 0) return [];

    return data.map((item) => {
        return {
            ...item,
            id: item?.id.toString() || '',
            isPreview: true,
            subject: stringUtils.htmlDecode(item?.subject) || '',
            bodyHtml: stringUtils.htmlDecode(item?.bodyHtml) || ''
        };
    });
};

const transformPhishingSmsTemplateList = (data) => {
    if (!data || data.length === 0) return [];

    return data.map((item) => {
        return {
            ...item,
            id: item?.id.toString() || ''
        };
    });
};

/**
 * TODO: Transform phishing campaigns list
 */

const transformPhishingCampaignsList = (list = [], permissions = {}) => {
    if (list.length === 0) {
        return;
    }

    return list.map((item) => {
        let status = item.status;
        return {
            ...item,
            credit: numberUtils.formatNumberWithCommas(item.credit),
            launchDate: dateUtils.formatTableDateTime(item.launchDate, i18next.language),
            terminateDate: dateUtils.formatTableDateTime(item.terminateDate, i18next.language),
            status,
            // isEdit: status === 'ready',
            isDelete: permissions?.['sat.simulation.phishing.delete'],
            isClone: status === 'ready' || status === 'closed' || status === 'running',
            // isStart: status === 'scheduled',
            isStop: permissions?.['sat.simulation.phishing.stop'] ? status === 'running' : false
        };
    });
};

/**
 * TODO: Transform target list with ID
 */

const transformTargetListWithID = (list, idKey = 'id', currentPage = 1, pageSize = 10) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item, key) => {
        let id = key + pageSize * (currentPage - 1) + 1;

        let formattedID = numberUtils.formatIDWithTwoDigits(id);

        return {
            ...item,
            [idKey]: formattedID
        };
    });
};

/**
 * TODO: Transform target data on overview step
 */

const transformTargetDataOnOverviewStep = (data = {}) => {
    const { departments, unselectedCandidateList, selectedCandidateList, mode } = data;

    let totalTarget = 0;
    let totalTargetIsPaid = 0;
    let totalTargetIsNotPaid = 0;

    // ! Total target

    if (mode === CANDIDATE_CUSTOMIZATION_METHODS.MANUAL) {
        totalTarget =
            departments.reduce((total, department) => total + department.tempTotalCandidate, 0) -
                unselectedCandidateList.length || 0;

        const totalTargetsPaidUnselected =
            unselectedCandidateList?.filter((candidate) => {
                return candidate?.isPaid;
            })?.length || 0;

        totalTargetIsPaid =
            departments.reduce((total, department) => {
                return total + department.totalCandidatePaid;
            }, 0) - totalTargetsPaidUnselected;

        // ? Get total candidates not paid yet
        totalTargetIsNotPaid = totalTarget - totalTargetIsPaid;
    } else if (mode === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        totalTarget = selectedCandidateList?.length - unselectedCandidateList?.length || 0;

        const selectedCandidates = selectedCandidateList.filter(
            (candidate) =>
                !unselectedCandidateList.some((unselected) => unselected.email === candidate.email)
        );

        totalTargetIsPaid =
            selectedCandidates?.filter((candidate) => candidate?.isPaid)?.length || 0;
        totalTargetIsNotPaid = totalTarget - totalTargetIsPaid;
    }

    return {
        totalTarget,
        totalTargetIsPaid,
        totalTargetIsNotPaid
    };
};

/**
 * TODO: Transform target data before creating simulation
 */

const transformTargetDataBeforeCreatingSimulation = (data = {}) => {
    const { departments, unselectedCandidateList, selectedCandidateList, mode } = data;
    let candidateExcludes = [];
    let departmentKeys = [];
    let candidates = [];

    if (mode === CANDIDATE_CUSTOMIZATION_METHODS.MANUAL) {
        departmentKeys = departments.map((department) => department.departmentKey);
        candidateExcludes = unselectedCandidateList.map((candidate) => candidate.email);
    } else if (mode === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT) {
        departmentKeys = [];
        const selectedCandidates = selectedCandidateList.filter(
            (candidate) =>
                !unselectedCandidateList.some((unselected) => unselected.email === candidate.email)
        );

        candidates = selectedCandidates.map((candidate) => candidate?.email);
    }

    return {
        departmentKeys,
        candidateExcludes,
        candidates
    };
};

/**
 * TODO: Transform simulation create data on overview step
 */

const transformSimulationCreateDataOnOverviewStep = (data) => {
    const { campaignInformationStepData, emailSettingsStepData } = data;

    const { totalTarget, totalTargetIsPaid, totalTargetIsNotPaid } =
        transformTargetDataOnOverviewStep(emailSettingsStepData);

    return {
        launchDate: dateUtils.formatTableDateTime(campaignInformationStepData?.launchDate),
        terminateDate: dateUtils.formatTableDateTime(campaignInformationStepData?.terminateDate),
        totalTarget,
        totalTargetIsPaid,
        totalTargetIsNotPaid,
        totalEmailPhishingPerDay: campaignInformationStepData?.totalEmailPhishingPerDay
    };
};

const transformSummaryData = (data = {}) => {
    return {
        // ? Campaign Detail
        resultDetail: {
            title: data.campaignName,
            launchDate: data.launchDate,
            terminateDate: data.terminateDate,
            lastActivity: data.lastActivity,
            targetPerDay: data.targetPerDay
        },
        // ? Statistics Data
        totalCandidates: data.summary.total,
        stats: {
            sent: {
                value: data.summary.sent,
                percent: numberUtils.formattedPercent(data.summary.sent, data.summary.total),
                color: '#1565C0'
            },
            opened: {
                value: data.summary.opened,
                percent: numberUtils.formattedPercent(data.summary.opened, data.summary.total),
                color: '#34B042'
            },
            clicked: {
                value: data.summary.clicked,
                percent: numberUtils.formattedPercent(data.summary.clicked, data.summary.total),
                color: '#FFC309'
            },
            submitted: {
                value: data.summary.submittedData,
                percent: numberUtils.formattedPercent(
                    data.summary.submittedData,
                    data.summary.total
                ),
                color: '#DD2C00'
            }
        },

        // ? Pop-up data
        actionsData: {
            emailTemplate: data.emailTemplate,
            webClone: data.webClone,
            viewNotification: data.viewNotification,
            campaignType: data?.campaignType
        }
    };
};

const convertTimelineData = (data) => {
    return data.map((item) => {
        return {
            formattedDate: dateUtils.formatDateTimeLineChart(
                dateUtils.formatChartAxisDateTime(item.date)
            ),
            opened: item.results.totalOpen,
            clicked: item.results.totalClicked,
            submitted: item.results.totalSubmitted,
            date: item.date
        };
    });
};

const transformTimelineResultData = (data = {}) => {
    if (objectUtils.isEmptyObject(data)) return;
    const { next, previous, results } = data;
    return {
        next,
        previous,
        previousDate: data?.previousDate,
        nextDate: data?.nextDate,
        result: convertTimelineData(results)
    };
};

const convertItemStatus = (itemStatus = '') => {
    let status = itemStatus;

    if (itemStatus === 'Scheduled') return 'scheduled';
    else if (itemStatus === 'Email Sent' || itemStatus === 'SMS Sent' || itemStatus === 'OTT Sent')
        status = 'sent';
    else if (itemStatus === 'Clicked Link') status = 'clicked';
    else if (
        itemStatus === 'Email Opened' ||
        itemStatus === 'SMS Opened' ||
        itemStatus === 'OTT Opened'
    )
        status = 'opened';
    else if (itemStatus === 'Submitted Data') status = 'submitted';
    else if (
        itemStatus === 'Error Sending Email' ||
        itemStatus === 'Error Sending SMS' ||
        itemStatus === 'Error Sending OTT'
    )
        status = 'sentError';
    return status;
};

const transformResultCandidateListWithID = (list, idKey = 'id', currentPage = 1, pageSize = 10) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item, key) => {
        let id = key + pageSize * (currentPage - 1) + 1;
        let formattedID = numberUtils.formatIDWithTwoDigits(id);

        let status = convertItemStatus(item.status);
        return {
            [idKey]: formattedID,
            fullName: item?.fullName || '',
            status,
            targetAddress: item?.targetAddress || '',
            department: item?.department || '',
            lastActivity: item?.behaviorTime,
            timelines: item?.timelines || {},
            email: item?.email || ''
        };
    });
};

/**
 * TODO: Transform simulation request body
 */

const transformSimulationRequestBody = (data) => {
    const { campaignInformationStepData, emailSettingsStepData, websiteSettingsStepData } = data;

    const mode = emailSettingsStepData?.mode;

    // ? Landing page data
    const landingPageData = websiteSettingsStepData?.landingPageData;
    const isSkipCloneWebsite = websiteSettingsStepData?.isSkipCloneWebsite;

    // ? Temp launch date
    let tempLaunchDate = null;
    let tempTerminateDate = null;

    if (campaignInformationStepData?.isStartImmediately) {
        tempLaunchDate = dateUtils.getCurrentTime();
    } else {
        tempLaunchDate = campaignInformationStepData?.launchDate;
    }

    tempTerminateDate = dayjs(tempLaunchDate).add(
        campaignInformationStepData?.campaginDuration,
        'day'
    );

    const { departmentKeys, candidateExcludes, candidates } =
        transformTargetDataBeforeCreatingSimulation(emailSettingsStepData);

    return {
        campaignName: campaignInformationStepData?.campaignTitle,
        isCustomEmailTemplate: emailSettingsStepData?.isCloneEmailTemplate ? 1 : 0,
        localeTemplate: emailSettingsStepData?.localeTemplate,
        emailHtml: emailSettingsStepData?.emailContent,
        sendingProfileFromEmail: emailSettingsStepData?.fromEmail,
        emailSubject: emailSettingsStepData?.subjectEmail,
        launchDate: dateUtils.formatDateTimeParam(tempLaunchDate),
        terminateDate: dateUtils.formatDateTimeParam(tempTerminateDate),
        phishingDomain: websiteSettingsStepData?.url,
        emailTemplateId: +emailSettingsStepData?.phishingEmailTemplateData?.id,
        candidateExcludes,
        launchingEmailPerDay: campaignInformationStepData?.totalEmailPhishingPerDay,
        departments: departmentKeys,
        notification: websiteSettingsStepData?.notificationId,
        timezone: dateUtils.getCurrentTimezone(),
        landingPageBody: !isSkipCloneWebsite ? landingPageData?.bodyHtml : null,
        importSite: !isSkipCloneWebsite ? landingPageData?.hostName : null,
        pageId: landingPageData?.id,
        startImmediately: campaignInformationStepData?.isStartImmediately,
        candidates: mode === CANDIDATE_CUSTOMIZATION_METHODS.IMPORT ? candidates : null
    };
};

/**
 * TODO: Transform simulation request body for sms campaign
 */

const transformSimulationRequestBodyForSMSCampaign = (data) => {
    const { campaignInformationStepData, smsSettingsStepData, websiteSettingsStepData } = data;

    // ? Get all departmentKeys from departments
    const departmentKeys = smsSettingsStepData?.departments.map(
        (department) => department.departmentKey
    );

    // ? Landing page data
    const landingPageData = websiteSettingsStepData?.landingPageData;
    const isSkipCloneWebsite = websiteSettingsStepData?.isSkipCloneWebsite;

    // ? Format candidates excludes
    const candidateExcludes = smsSettingsStepData?.unselectedCandidateList?.map((item) => {
        return item?.email;
    });

    // ? Temp launch date
    const nowDateTime = new Date(Date.now());
    const tempLaunchDate =
        campaignInformationStepData?.launchDate < nowDateTime.toISOString()
            ? nowDateTime.toISOString()
            : campaignInformationStepData?.launchDate;

    return {
        campaignName: campaignInformationStepData?.campaignTitle,
        isCustomSmsTemplate: smsSettingsStepData?.isCustomSmsTemplate ? 1 : 0,
        localeTemplate: smsSettingsStepData?.localeTemplate,
        smsContent: smsSettingsStepData?.smsContent,
        launchDate: dateUtils.formatDateTimeParam(tempLaunchDate),
        terminateDate: dateUtils.formatDateTimeParam(campaignInformationStepData?.terminateDate),
        launchingSmsPerDay: campaignInformationStepData?.totalEmailPhishingPerDay,
        departments: departmentKeys,
        notification: websiteSettingsStepData?.notificationId,
        timezone: dateUtils.getCurrentTimezone(),
        phishingDomain: websiteSettingsStepData?.url,
        importSite: !isSkipCloneWebsite ? landingPageData?.redirectUrl : null,
        landingPageBody: !isSkipCloneWebsite ? landingPageData?.bodyHtml : null,
        smsTemplateId: smsSettingsStepData?.phishingSmsTemplateData?.id
            ? +smsSettingsStepData?.phishingSmsTemplateData?.id
            : null,
        candidateExcludes,
        smsFrom: smsSettingsStepData?.smsFrom,
        pageId: landingPageData?.id
    };
};

/**
 * TODO: Transform simulation request body for ott campaign
 */

const transformSimulationRequestBodyForOTTCampaign = (data) => {
    const { campaignInformationStepData, ottSettingsStepData, websiteSettingsStepData } = data;
    // ? Get all departmentKeys from departments
    const departmentKeys = ottSettingsStepData?.departments.map(
        (department) => department.departmentKey
    );

    // ? Landing page data
    const landingPageData = websiteSettingsStepData?.landingPageData;
    const isSkipCloneWebsite = websiteSettingsStepData?.isSkipCloneWebsite;

    // ? Format candidates excludes
    const candidateExcludes = ottSettingsStepData?.unselectedCandidateList?.map((item) => {
        return item?.email;
    });

    // ? Temp launch date
    const nowDateTime = new Date(Date.now());
    const tempLaunchDate =
        campaignInformationStepData?.launchDate < nowDateTime.toISOString()
            ? nowDateTime.toISOString()
            : campaignInformationStepData?.launchDate;

    return {
        campaignName: campaignInformationStepData?.campaignTitle,
        isCustomOTTTemplate: ottSettingsStepData?.isCustomOTTTemplate ? 1 : 0,
        localeTemplate: ottSettingsStepData?.localeTemplate,
        ottContent: ottSettingsStepData?.ottContent,
        launchDate: dateUtils.formatDateTimeParam(tempLaunchDate),
        terminateDate: dateUtils.formatDateTimeParam(campaignInformationStepData?.terminateDate),
        launchingOttPerDay: campaignInformationStepData?.totalEmailPhishingPerDay,
        departments: departmentKeys,
        notification: websiteSettingsStepData?.notificationId,
        timezone: dateUtils.getCurrentTimezone(),
        phishingDomain: websiteSettingsStepData?.url,
        importSite: !isSkipCloneWebsite ? landingPageData?.redirectUrl : null,
        landingPageBody: !isSkipCloneWebsite ? landingPageData?.bodyHtml : null,
        // ottSettingsStepData: smsSettingsStepData?.phishingSmsTemplateData?.id
        //     ? +smsSettingsStepData?.phishingSmsTemplateData?.id
        //     : null,
        candidateExcludes,
        sender: ottSettingsStepData?.sender,
        provider: ottSettingsStepData?.provider,
        pageId: landingPageData?.id
    };
};

/**
 * TODO: Transform landing page when importing success
 */

const transformLandingPageData = (landingPageData) => {
    return {
        bodyHtml: landingPageData?.data,
        redirectUrl: landingPageData?.importSite,
        urlPhishing: landingPageData?.urlPreview,
        urlPreview: landingPageData?.urlPreview,
        hostName: landingPageData?.hostName,
        id: landingPageData?.id
    };
};

/**
 * TODO: Transform clone phishing data
 */

const transformClonePhishingData = (data) => {
    // ! Format candidates
    let candidateExcludes = data?.candidateExcludes || [];

    // ! Format departments
    let departments = data?.departments || [];
    departments = departments.map((department) => {
        return {
            ...department,
            tempTotalCandidate:
                department?.totalCandidateExcludes + department?.totalCandidateAssigned,
            totalCandidate: department?.totalCandidateAssigned,
            totalCandidateNotPaidYet: 0,
            totalCandidatePaid: department?.totalCandidateAssigned,
            departmentKey: department?.departmentKey?.toLowerCase() || '',
            departmentName: department?.departmentName?.toLowerCase() || ''
        };
    });

    // ! Format subdomain
    const subdomain = data?.phishingDomain?.split('.')[0] || '';

    // ! Temp data transformed
    let tempData = {
        currentStep:
            data?.campaignType === 'email'
                ? CREATE_EMAIL_SIMULATION_STEPS.EMAIL_SETTINGS
                : data?.campaignType === 'sms'
                ? CREATE_SMS_SIMULATION_STEPS.SMS_SETTINGS
                : data?.campaignType === 'ott'
                ? CREATE_OTT_SIMULATION_STEPS.OTT_SETTINGS
                : '',
        campaignType: data?.campaignType,
        emailSettingsStepData: {},
        websiteSettingsStepData: {
            subdomain,
            notificationId: data?.notification?.id,
            notificationData: data?.notification,
            url: data?.phishingDomain,
            isSkipCloneWebsite: data?.skipCloneWebiste,
            isChoosingBookmark: false,
            landingPageData: {
                urlPhishing: `https://${data?.phishingDomain}`,
                redirectUrl: data?.urlClone
            }
        },
        campaignInformationStepData: {
            campaignTitle: data?.campaignName,
            launchDate: data?.startDate,
            terminateDate: data?.endDate,
            totalEmailPhishingPerDay: 0,
            isStartImmediately: data?.startImmediately,
            campaginDuration: dayjs(data?.endDate).diff(dayjs(data?.startDate), 'day').toString()
        },
        smsSettingsStepData: {},
        ottSettingsStepData: {}
    };

    // ! Format emails settings step data
    if (data?.campaignType === 'email') {
        tempData.emailSettingsStepData = {
            departments,
            fromEmail: data?.sendingProfileFromEmail,
            subjectEmail: stringUtils.htmlDecode(data?.templateSubject),
            emailContent: stringUtils.htmlDecode(data?.templateContent || ''),
            selectedCandidateList: [],
            unselectedCandidateList: candidateExcludes,
            phishingEmailTemplateData: {
                id: `${data?.templateID}`,
                bodyHtml: stringUtils.htmlDecode(data?.templateContent || ''),
                subject: stringUtils.htmlDecode(data?.templateSubject),
                isPreview: true
            },
            isCloneEmailTemplate: false,
            localeTemplate: 'en',
            mode: CANDIDATE_CUSTOMIZATION_METHODS.MANUAL
        };

        delete tempData.smsSettingsStepData;
        delete tempData.ottSettingsStepData;
    } else if (data?.campaignType === 'sms') {
        tempData.smsSettingsStepData = {
            departments,
            selectedCandidateList: [],
            unselectedCandidateList: candidateExcludes,
            smsContent: stringUtils.htmlDecode(data?.templateContent),
            smsFrom: data?.sendingProfileFromEmail,
            smsTemplateData: {},
            isChooseSmsTemplate: false
        };

        delete tempData.emailSettingsStepData;
        delete tempData.ottSettingsStepData;
    } else if (data?.campaignType === 'ott') {
        tempData.ottSettingsStepData = {
            departments,
            selectedCandidateList: [],
            unselectedCandidateList: candidateExcludes,
            ottContent: stringUtils.htmlDecode(data?.templateContent),
            sender: data?.sendingProfileFromEmail,
            provider: data?.provider,
            ottTemplateData: {}
        };

        delete tempData.emailSettingsStepData;
        delete tempData.smsSettingsStepData;
    }

    return tempData;
};

export {
    transformPhishingEmailTemplateList,
    transformPhishingSmsTemplateList,
    transformPhishingCampaignsList,
    transformTargetListWithID,
    transformSimulationCreateDataOnOverviewStep,
    transformSummaryData,
    transformTimelineResultData,
    transformResultCandidateListWithID,
    transformSimulationRequestBody,
    transformSimulationRequestBodyForSMSCampaign,
    transformSimulationRequestBodyForOTTCampaign,
    transformLandingPageData,
    transformClonePhishingData,
    convertItemStatus
};
