// ? Get the dial code and phone number from the phone input
const getDialCodeAndPhoneNumberFromInput = (input = '') => {
    if (input.length > 0) {
        if (input.startsWith('+')) {
            const dialCode = input.substring(1, 3);
            const phoneNumber = input.substring(3);

            return { dialCode, phoneNumber };
        } else {
            return {
                dialCode: '',
                phoneNumber: input
            };
        }
    }

    return '';
};

export default { getDialCodeAndPhoneNumberFromInput };
