import httpClient from 'api';
import TRAINING_CONSTANTS from 'api/constants/training';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Get list course
 */

const getListCourse = catchAsync(async (params) => {
    const response = await httpClient.get(TRAINING_CONSTANTS.GET_LIST_COURSE, {
        params
    });
    return response;
});

/**
 * TODO: Create training
 */

const createTraining = catchAsync(async (requestBody) => {
    const response = await httpClient.post(TRAINING_CONSTANTS.CREATE_TRAINING, requestBody);

    return response;
});

/**
 * TODO: Get list training
 */

const getListTraining = catchAsync(async (params) => {
    const response = await httpClient.get(TRAINING_CONSTANTS.GET_LIST_TRAINING, {
        params
    });
    return response;
});

/**
 * TODO: Get list training expand row
 */

const getListTrainingExpandRow = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/expand`
    );
    return response;
});

/**
 * TODO: Get list candidate by training
 */

const getListCandidateByTraining = catchAsync(async ({ trainingCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/candidates`,
        {
            params: rest
        }
    );
    return response;
});

/**
 * TODO: Get Training In-progress Summary
 */

const getTrainingInProgressSummary = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/summary`
    );
    return response;
});

/**
 * TODO: Get Training Result Course Summary
 */

const getTrainingResultCourseSummary = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/result/course/summary`
    );
    return response;
});

/**
 * TODO: Get Training Resource Statistic
 */

const getTrainingResourceStatistic = catchAsync(async ({ trainingCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/resources/statistics`,
        { params: rest }
    );
    return response;
});

/**
 * TODO: Get Candidate Training Detail Statistic
 */

const getCandidateTrainingDetailStats = catchAsync(async ({ trainingCode = '', ...rest }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/detail/statistics`,
        { params: rest }
    );
    return response;
});

/**
 * TODO: Start Training
 */

const startTraining = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.put(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/start`
    );
    return response;
});

/**
 * TODO: Stop Training
 */

const stopTraining = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.put(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/stop`
    );
    return response;
});

/**
 * TODO: Rebuild Training
 */

const rebuildTraining = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.put(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/rebuild`
    );
    return response;
});

/**
 * TODO: Delete Trainings
 */

const deleteTrainings = catchAsync(async (params) => {
    const response = await httpClient.delete(`${TRAINING_CONSTANTS.GET_LIST_TRAINING}/delete`, {
        params
    });
    return response;
});

/**
 * TODO: Get training details
 */

const getTrainingDetails = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_TRAINING_DETAILS}/${trainingCode}/edit`
    );
    return response;
});

/**
 * TODO: Update training
 */

const updateTraining = catchAsync(async ({ data = {}, trainingCode = '' }) => {
    const response = await httpClient.put(
        `${TRAINING_CONSTANTS.GET_TRAINING_DETAILS}/${trainingCode}`,
        data
    );
    return response;
});

/**
 * TODO: Get training clone details
 */

const getTrainingCloneDetails = catchAsync(async ({ trainingCode = '' }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_TRAINING_CLONE_DETAILS}/${trainingCode}/clone`
    );
    return response;
});

/**
 * TODO: Get training summary departments
 */

const getTrainingSummaryDepartments = catchAsync(async ({ trainingCode, ...rest }) => {
    const response = await httpClient.get(
        `${TRAINING_CONSTANTS.GET_LIST_TRAINING}/${trainingCode}/${TRAINING_CONSTANTS.SUMMARY_DEPARTMENTS}`,
        { params: rest }
    );
    return response;
});

const TrainingServices = {
    getListCourse,
    createTraining,
    getListTraining,
    getListTrainingExpandRow,
    getListCandidateByTraining,
    getTrainingInProgressSummary,
    getTrainingResultCourseSummary,
    getTrainingResourceStatistic,
    getCandidateTrainingDetailStats,
    startTraining,
    stopTraining,
    rebuildTraining,
    deleteTrainings,
    getTrainingDetails,
    updateTraining,
    getTrainingCloneDetails,
    getTrainingSummaryDepartments
};

export default TrainingServices;
