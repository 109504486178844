import types from './actionTypes';

/**
 * TODO:  STAFF LIST action
 */
const getStaffList = (payload) => ({
    type: types.GET_STAFF_LIST_REQUEST,
    payload
});

const getStaffListSuccess = (payload) => ({
    type: types.GET_STAFF_LIST_SUCCESS,
    payload
});

const getStaffListFailure = (payload) => ({
    type: types.GET_STAFF_LIST_FAILURE,
    payload
});

const resetGetStaffListError = () => ({
    type: types.GET_STAFF_LIST_RESET
});

/**
 * TODO:  STAFF LIST BY ROLE action
 */
const getStaffListByRole = (payload) => ({
    type: types.GET_STAFF_LIST_BY_ROLE_REQUEST,
    payload
});

const getStaffListByRoleSuccess = (payload) => ({
    type: types.GET_STAFF_LIST_BY_ROLE_SUCCESS,
    payload
});

const getStaffListByRoleFailure = (payload) => ({
    type: types.GET_STAFF_LIST_BY_ROLE_FAILURE,
    payload
});

const resetGetStaffListByRoleError = () => ({
    type: types.GET_STAFF_LIST_BY_ROLE_RESET
});

/**
 * TODO: Delete Staffs From Role action
 */
const deleteStaffsFromRole = (payload) => ({
    type: types.DELETE_STAFFS_FROM_ROLE_REQUEST,
    payload
});

const deleteStaffsFromRoleSuccess = (payload) => ({
    type: types.DELETE_STAFFS_FROM_ROLE_SUCCESS,
    payload
});

const deleteStaffsFromRolesFailure = (payload) => ({
    type: types.DELETE_STAFFS_FROM_ROLE_FAILURE,
    payload
});

const resetDeleteStaffsFromRolesError = () => ({
    type: types.DELETE_STAFFS_FROM_ROLE_RESET
});

/**
 * TODO:  BAN STAFF action
 */
const putBanStaff = (payload) => ({
    type: types.PUT_BAN_STAFF_REQUEST,
    payload
});

const putBanStaffSuccess = (payload) => ({
    type: types.PUT_BAN_STAFF_SUCCESS,
    payload
});

const putBanStaffFailure = (payload) => ({
    type: types.PUT_BAN_STAFF_FAILURE,
    payload
});

const resetPutBanStaff = () => ({
    type: types.PUT_BAN_STAFF_RESET
});

/**
 * TODO:  ROLE LIST action
 */
const getRoleList = (payload) => ({
    type: types.GET_ROLE_LIST_REQUEST,
    payload
});

const getRoleListSuccess = (payload) => ({
    type: types.GET_ROLE_LIST_SUCCESS,
    payload
});

const getRoleListFailure = (payload) => ({
    type: types.GET_ROLE_LIST_FAILURE,
    payload
});

const resetGetRoleListError = () => ({
    type: types.GET_ROLE_LIST_RESET
});

/**
 * TODO: PERMISSIONS LIST action
 */

const getPermissionsList = (payload) => ({
    type: types.GET_PERMISSIONS_LIST_REQUEST,
    payload
});

const getPermissionsListSuccess = (payload) => ({
    type: types.GET_PERMISSIONS_LIST_SUCCESS,
    payload
});

const getPermissionsListFailure = (payload) => ({
    type: types.GET_PERMISSIONS_LIST_FAILURE,
    payload
});

const resetGetPermissionsListError = () => ({
    type: types.GET_PERMISSIONS_LIST_RESET
});

/**
 * TODO: ADD ROLE action
 */

const addRole = (payload) => ({
    type: types.ADD_ROLE_REQUEST,
    payload
});

const addRoleSuccess = (payload) => ({
    type: types.ADD_ROLE_SUCCESS,
    payload
});

const addRoleFailure = (payload) => ({
    type: types.ADD_ROLE_FAILURE,
    payload
});

const resetAddRoleError = () => ({
    type: types.ADD_ROLE_RESET
});

/**
 * TODO: Get Role By Id action
 */

const getRoleById = (payload) => ({
    type: types.GET_ROLE_BY_ID_REQUEST,
    payload
});

const getRoleByIdSuccess = (payload) => ({
    type: types.GET_ROLE_BY_ID_SUCCESS,
    payload
});

const getRoleByIdFailure = (payload) => ({
    type: types.GET_ROLE_BY_ID_FAILURE,
    payload
});

const resetGetRoleByIdError = () => ({
    type: types.GET_ROLE_BY_ID_RESET
});

/**
 * TODO: Update role
 */

const updateRole = (payload) => ({
    type: types.UPDATE_ROLE_REQUEST,
    payload
});

const updateRoleSuccess = (payload) => ({
    type: types.UPDATE_ROLE_SUCCESS,
    payload
});

const updateRoleFailure = (payload) => ({
    type: types.UPDATE_ROLE_FAILURE,
    payload
});

const resetUpdateRoleError = () => ({
    type: types.UPDATE_ROLE_RESET
});

/**
 * TODO: Delete roles
 */

const deleteRoles = (payload) => ({
    type: types.DELETE_ROLES_REQUEST,
    payload
});

const deleteRolesSuccess = (payload) => ({
    type: types.DELETE_ROLES_SUCCESS,
    payload
});

const deleteRolesFailure = (payload) => ({
    type: types.DELETE_ROLES_FAILURE,
    payload
});

const resetDeleteRolesError = () => ({
    type: types.DELETE_ROLES_RESET
});

/*
 * TODO: Add staff
 */

const addStaff = (payload) => ({
    type: types.ADD_STAFF_REQUEST,
    payload
});

const addStaffSuccess = (payload) => ({
    type: types.ADD_STAFF_SUCCESS,
    payload
});

const addStaffFailure = (payload) => ({
    type: types.ADD_STAFF_FAILURE,
    payload
});

const resetAddStaffError = () => ({
    type: types.ADD_STAFF_RESET
});

/**
 * TODO: Get staff by id
 */

const getStaffById = (payload) => ({
    type: types.GET_STAFF_BY_ID_REQUEST,
    payload
});

const getStaffByIdSuccess = (payload) => ({
    type: types.GET_STAFF_BY_ID_SUCCESS,
    payload
});

const getStaffByIdFailure = (payload) => ({
    type: types.GET_STAFF_BY_ID_FAILURE,
    payload
});

const resetGetStaffByIdError = () => ({
    type: types.GET_STAFF_BY_ID_RESET
});

/**
 * TODO: Update staff
 */

const updateStaff = (payload) => ({
    type: types.UPDATE_STAFF_REQUEST,
    payload
});

const updateStaffSuccess = (payload) => ({
    type: types.UPDATE_STAFF_SUCCESS,
    payload
});

const updateStaffFailure = (payload) => ({
    type: types.UPDATE_STAFF_FAILURE,
    payload
});

const resetUpdateStaffError = () => ({
    type: types.UPDATE_STAFF_RESET
});

/**
 * TODO: Reset staff password
 */

const resetStaffPassword = (payload) => ({
    type: types.RESET_STAFF_PASSWORD_REQUEST,
    payload
});

const resetStaffPasswordSuccess = (payload) => ({
    type: types.RESET_STAFF_PASSWORD_SUCCESS,
    payload
});

const resetStaffPasswordFailure = (payload) => ({
    type: types.RESET_STAFF_PASSWORD_FAILURE,
    payload
});

const resetResetStaffPasswordError = () => ({
    type: types.RESET_STAFF_PASSWORD_RESET
});

export {
    // ? Staff List
    getStaffList,
    getStaffListSuccess,
    getStaffListFailure,
    resetGetStaffListError,

    // ? Staff List By Role
    getStaffListByRole,
    getStaffListByRoleSuccess,
    getStaffListByRoleFailure,
    resetGetStaffListByRoleError,

    // ? Delete Staffs From Role
    deleteStaffsFromRole,
    deleteStaffsFromRoleSuccess,
    deleteStaffsFromRolesFailure,
    resetDeleteStaffsFromRolesError,

    // ? Ban Staff
    putBanStaff,
    putBanStaffFailure,
    putBanStaffSuccess,
    resetPutBanStaff,

    // ? Role List
    getRoleList,
    getRoleListSuccess,
    getRoleListFailure,
    resetGetRoleListError,

    // ? Permission
    getPermissionsList,
    getPermissionsListSuccess,
    getPermissionsListFailure,
    resetGetPermissionsListError,

    // ? Role
    addRole,
    addRoleSuccess,
    addRoleFailure,
    resetAddRoleError,

    // ? Get role by id
    getRoleById,
    getRoleByIdSuccess,
    getRoleByIdFailure,
    resetGetRoleByIdError,

    // ? Update role
    updateRole,
    updateRoleSuccess,
    updateRoleFailure,
    resetUpdateRoleError,

    // ? Delete Roles
    deleteRoles,
    deleteRolesSuccess,
    deleteRolesFailure,
    resetDeleteRolesError,

    // ? Add staff
    addStaff,
    addStaffSuccess,
    addStaffFailure,
    resetAddStaffError,

    // ? Get staff by id
    getStaffById,
    getStaffByIdSuccess,
    getStaffByIdFailure,
    resetGetStaffByIdError,

    // ? Update staff
    updateStaff,
    updateStaffSuccess,
    updateStaffFailure,
    resetUpdateStaffError,

    // ? Reset staff password
    resetStaffPassword,
    resetStaffPasswordSuccess,
    resetStaffPasswordFailure,
    resetResetStaffPasswordError
};
