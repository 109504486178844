import types from './actionTypes';

/**
 * TODO: Get list assessments
 */

export const getListAssessments = (payload, permissions) => ({
    type: types.GET_LIST_ASSESSMENTS_REQUEST,
    payload,
    permissions
});

export const getListAssessmentsSuccess = (payload) => ({
    type: types.GET_LIST_ASSESSMENTS_SUCCESS,
    payload
});

export const getListAssessmentsFailure = (payload) => ({
    type: types.GET_LIST_ASSESSMENTS_FAILURE,
    payload
});

/**
 * TODO: Get list questions
 */

export const getListQuestions = (payload) => ({
    type: types.GET_LIST_QUESTIONS_REQUEST,
    payload
});

export const getListQuestionsSuccess = (payload) => ({
    type: types.GET_LIST_QUESTIONS_SUCCESS,
    payload
});

export const getListQuestionsFailure = (payload) => ({
    type: types.GET_LIST_QUESTIONS_FAILURE,
    payload
});

/**
 * TODO: Get list templates
 */

export const getListTemplates = (payload) => ({
    type: types.GET_LIST_TEMPLATES_REQUEST,
    payload
});

export const getListTemplatesSuccess = (payload) => ({
    type: types.GET_LIST_TEMPLATES_SUCCESS,
    payload
});

export const getListTemplatesFailure = (payload) => ({
    type: types.GET_LIST_TEMPLATES_FAILURE,
    payload
});

/**
 * TODO: Create assessment
 */

export const createAssessment = (payload) => ({
    type: types.CREATE_ASSESSMENT_REQUEST,
    payload
});

export const createAssessmentSuccess = (payload) => ({
    type: types.CREATE_ASSESSMENT_SUCCESS,
    payload
});

export const createAssessmentFailure = (payload) => ({
    type: types.CREATE_ASSESSMENT_FAILURE,
    payload
});

export const createAssessmentReset = () => ({
    type: types.CREATE_ASSESSMENT_RESET
});

/**
 * TODO: Get assessment details
 */
export const getAssessmentDetails = (payload) => ({
    type: types.GET_ASSESSMENT_DETAILS_REQUEST,
    payload
});

export const getAssessmentDetailsSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_DETAILS_SUCCESS,
    payload
});

export const getAssessmentDetailsFailure = (payload) => ({
    type: types.GET_ASSESSMENT_DETAILS_FAILURE,
    payload
});

export const getAssessmentDetailsReset = () => ({
    type: types.GET_ASSESSMENT_DETAILS_RESET
});

/**
 * TODO: Get assessment clone details
 */

export const getAssessmentCloneDetails = (payload) => ({
    type: types.GET_ASSESSMENT_CLONE_DETAILS_REQUEST,
    payload
});

export const getAssessmentCloneDetailsSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_CLONE_DETAILS_SUCCESS,
    payload
});

export const getAssessmentCloneDetailsFailure = (payload) => ({
    type: types.GET_ASSESSMENT_CLONE_DETAILS_FAILURE,
    payload
});

export const getAssessmentCloneDetailsReset = () => ({
    type: types.GET_ASSESSMENT_CLONE_DETAILS_RESET
});

/**
 * TODO: Edit assessment
 */

export const editAssessment = (payload) => ({
    type: types.EDIT_ASSESSMENT_REQUEST,
    payload
});

export const editAssessmentSuccess = (payload) => ({
    type: types.EDIT_ASSESSMENT_SUCCESS,
    payload
});

export const editAssessmentFailure = (payload) => ({
    type: types.EDIT_ASSESSMENT_FAILURE,
    payload
});

export const editAssessmentReset = () => ({
    type: types.EDIT_ASSESSMENT_RESET
});

/**
 * TODO: Get list questions by template ids
 */

export const getListQuestionsByTemplateIds = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_REQUEST,
    payload
});

export const getListQuestionsByTemplateIdsSuccess = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_SUCCESS,
    payload
});

export const getListQuestionsByTemplateIdsFailure = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_TEMPLATE_IDS_FAILURE,
    payload
});

/**
 * TODO: Get list exam tags
 */

export const getListExamTags = (payload) => ({
    type: types.GET_LIST_EXAM_TAGS_REQUEST,
    payload
});

export const getListExamTagsSuccess = (payload) => ({
    type: types.GET_LIST_EXAM_TAGS_SUCCESS,
    payload
});

export const getListExamTagsFailure = (payload) => ({
    type: types.GET_LIST_EXAM_TAGS_FAILURE,
    payload
});

/**
 * TODO: Get list candidates by assessment
 */

export const getListCandidatesByAssessment = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_ASSESSMENT_REQUEST,
    payload
});

export const getListCandidatesByAssessmentSuccess = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_ASSESSMENT_SUCCESS,
    payload
});

export const getListCandidatesByAssessmentFailure = (payload) => ({
    type: types.GET_LIST_CANDIDATES_BY_ASSESSMENT_FAILURE,
    payload
});

/**
 * TODO: Get list questions by assessment
 */

export const getListQuestionsByAssessment = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_ASSESSMENT_REQUEST,
    payload
});

export const getListQuestionsByAssessmentSuccess = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_ASSESSMENT_SUCCESS,
    payload
});

export const getListQuestionsByAssessmentFailure = (payload) => ({
    type: types.GET_LIST_QUESTIONS_BY_ASSESSMENT_FAILURE,
    payload
});

/**
 * TODO: Get assessment summary participants
 */

export const getAssessmentSummaryParticipants = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_PARTICIPANTS_REQUEST,
    payload
});

export const getAssessmentSummaryParticipantsSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_PARTICIPANTS_SUCCESS,
    payload
});

export const getAssessmentSummaryParticipantsFailure = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_PARTICIPANTS_FAILURE,
    payload
});

/**
 * TODO: Get assessment summary statistics
 */

export const getAssessmentSummaryStatistic = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_STATISTIC_REQUEST,
    payload
});

export const getAssessmentSummaryStatisticSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_STATISTIC_SUCCESS,
    payload
});

export const getAssessmentSummaryStatisticFailure = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_STATISTIC_FAILURE,
    payload
});

/**
 * TODO: Get assessment summary question list
 */

export const getAssessmentSummaryQuestionList = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_QUESTION_LIST_REQUEST,
    payload
});

export const getAssessmentSummaryQuestionListSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_QUESTION_LIST_SUCCESS,
    payload
});

export const getAssessmentSummaryQuestionListFailure = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_QUESTION_LIST_FAILURE,
    payload
});

/**
 * TODO: Get assessment summary candidate list
 */

export const getAssessmentSummaryCandidateList = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_REQUEST,
    payload
});

export const getAssessmentSummaryCandidateListSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_SUCCESS,
    payload
});

export const getAssessmentSummaryCandidateListFailure = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_CANDIDATE_LIST_FAILURE,
    payload
});

/**
 * TODO: Get assessment candidate detail
 */

export const getAssessmentCandidateDetail = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_DETAIL_REQUEST,
    payload
});

export const getAssessmentCandidateDetailSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_DETAIL_SUCCESS,
    payload
});

export const getAssessmentCandidateDetailFailure = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_DETAIL_FAILURE,
    payload
});

/**
 * TODO: Get assessment candidate result
 */

export const getAssessmentCandidateResult = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_RESULT_REQUEST,
    payload
});

export const getAssessmentCandidateResultSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_RESULT_SUCCESS,
    payload
});

export const getAssessmentCandidateResultFailure = (payload) => ({
    type: types.GET_ASSESSMENT_CANDIDATE_RESULT_FAILURE,
    payload
});

/**
 * TODO: Get assessment detail question result
 */

export const getAssessmentDetailQuestionResult = (payload) => ({
    type: types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_REQUEST,
    payload
});

export const getAssessmentDetailQuestionResultSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_SUCCESS,
    payload
});

export const getAssessmentDetailQuestionResultFailure = (payload) => ({
    type: types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_FAILURE,
    payload
});

export const getAssessmentDetailQuestionResultReset = () => ({
    type: types.GET_ASSESSMENT_DETAIL_QUESTION_RESULT_RESET
});

/**
 * TODO: start assessment
 */

export const startAssessment = (payload) => ({
    type: types.START_ASSESSMENT_REQUEST,
    payload
});

export const startAssessmentSuccess = (payload) => ({
    type: types.START_ASSESSMENT_SUCCESS,
    payload
});

export const startAssessmentFailure = (payload) => ({
    type: types.START_ASSESSMENT_FAILURE,
    payload
});

/**
 * TODO: stop assessment
 */

export const stopAssessment = (payload) => ({
    type: types.STOP_ASSESSMENT_REQUEST,
    payload
});

export const stopAssessmentSuccess = (payload) => ({
    type: types.STOP_ASSESSMENT_SUCCESS,
    payload
});

export const stopAssessmentFailure = (payload) => ({
    type: types.STOP_ASSESSMENT_FAILURE,
    payload
});

/**
 * TODO: Rebuild assessment
 */

export const rebuildAssessment = (payload) => ({
    type: types.REBUILD_ASSESSMENT_REQUEST,
    payload
});

export const rebuildAssessmentSuccess = (payload) => ({
    type: types.REBUILD_ASSESSMENT_SUCCESS,
    payload
});

export const rebuildAssessmentFailure = (payload) => ({
    type: types.REBUILD_ASSESSMENT_FAILURE,
    payload
});

/**
 * TODO: Delete assessments
 */

export const deleteAssessments = (payload) => ({
    type: types.DELETE_ASSESSMENTS_REQUEST,
    payload
});

export const deleteAssessmentsSuccess = (payload) => ({
    type: types.DELETE_ASSESSMENTS_SUCCESS,
    payload
});

export const deleteAssessmentsFailure = (payload) => ({
    type: types.DELETE_ASSESSMENTS_FAILURE,
    payload
});

/**
 * TODO: Get language supported
 */

export const getLanguageSupported = (payload) => ({
    type: types.GET_LANGUAGE_SUPPORTED_REQUEST,
    payload
});

export const getLanguageSupportedSuccess = (payload) => ({
    type: types.GET_LANGUAGE_SUPPORTED_SUCCESS,
    payload
});

export const getLanguageSupportedFailure = (payload) => ({
    type: types.GET_LANGUAGE_SUPPORTED_FAILURE,
    payload
});

/**
 * TODO: Get assessment summary departments
 */

export const getAssessmentSummaryDepartments = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_REQUEST,
    payload
});

export const getAssessmentSummaryDepartmentsSuccess = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_SUCCESS,
    payload
});

export const getAssessmentSummaryDepartmentsFailure = (payload) => ({
    type: types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_FAILURE,
    payload
});

export const getAssessmentSummaryDepartmentsReset = () => ({
    type: types.GET_ASSESSMENT_SUMMARY_DEPARTMENTS_RESET
});
