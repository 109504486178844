const initialState = {
    assessment: {},
    training: {},
    phishing: {},
    lastActivity: [],
    credit: {},
    participant: {}
};

export default initialState;
