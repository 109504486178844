const CREATE_ASSESSMENT_STEPS = {
    SCHEDULE: 'schedule',
    CANDIDATE: 'candidates',
    CHALLENGE: 'challenge',
    REVIEW: 'review'
};

const CREATE_ASSESSMENT_STEPS_WITH_DATA = [
    {
        label: 'schedule'
    },
    {
        label: 'candidates'
    },
    {
        label: 'challenge'
    },

    {
        label: 'review'
    }
];

const CHALLENGE_ASSESSMENT_STEP_INDEX = {
    GENERAL: 0,
    CHALLENGE_MODE: 1,
    CHALLENGE_DETAIL: 2
};

const deleteStatuses = ['ready', 'closed', 'draft', 'failed'];
const startStatuses = ['ready'];
const stopStatuses = ['running'];
const cloneStatuses = ['ready', 'closed', 'running'];
const rebuildStatus = ['failed'];

export {
    CREATE_ASSESSMENT_STEPS,
    CREATE_ASSESSMENT_STEPS_WITH_DATA,
    CHALLENGE_ASSESSMENT_STEP_INDEX,
    deleteStatuses,
    startStatuses,
    stopStatuses,
    cloneStatuses,
    rebuildStatus
};
