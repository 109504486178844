import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ? Send test email
        case types.SEND_TEST_EMAIL_SUCCESS:
            return reducerHandler.sendTestEmailReducerHandler(state, action.payload);
        case types.SEND_TEST_EMAIL_FAILURE:
            return reducerHandler.sendTestEmailFailureReducerHandler(state, action.payload);
        case types.SEND_TEST_EMAIL_RESET:
            return reducerHandler.sendTestEmailResetReducerHandler(state);

        // ? Get phishing email templates
        case types.GET_PHISHING_EMAIL_TEMPLATES_SUCCESS:
            return reducerHandler.getPhishingEmailTemplatesReducerHandler(state, action.payload);

        // ? Get phishing list notifications
        case types.GET_PHISHING_LIST_NOTIFICATIONS_SUCCESS:
            return reducerHandler.getPhishingListNotificationsReducerHandler(state, action.payload);

        // ? List of phishing
        case types.GET_LIST_PHISHING_CAMPAIGNS_SUCCESS:
            return reducerHandler.getListPhishingCampaigns(state, action.payload);

        // ? Get list targets
        case types.GET_LIST_TARGETS_SUCCESS:
            return reducerHandler.getListTargets(state, action.payload);

        // ? Get scam email content
        case types.GET_SCAM_EMAIL_CONTENT_SUCCESS:
            return reducerHandler.getScamEmailContent(state, action.payload);

        // ? Get scam web content
        case types.GET_SCAM_WEB_CONTENT_SUCCESS:
            return reducerHandler.getScamWebContent(state, action.payload);
        case types.RESET_SCAM_WEB_CONTENT:
            return reducerHandler.resetScamWebContent(state);

        // ? Get view phishing notification
        case types.GET_VIEW_PHISHING_NOTIFICATION_SUCCESS:
            return reducerHandler.getViewPhishingNotification(state, action.payload);

        // ? Get phishing summary
        case types.GET_PHISHING_SUMMARY_SUCCESS:
            return reducerHandler.getPhishingSummary(state, action.payload);

        // ? Get phishing timeline
        case types.GET_PHISHING_TIMELINE_RESULT_SUCCESS:
            return reducerHandler.getPhishingTimelineResult(state, action.payload);

        // ? Get phishing result candidate list
        case types.GET_PHISHING_RESULT_CANDIDATE_LIST_SUCCESS:
            return reducerHandler.getPhishingResultCandidateList(state, action.payload);

        // ? Stop campaign
        case types.STOP_CAMPAIGN_SUCCESS:
            return reducerHandler.stopCampaign(state, action.payload);

        // ? Delete campaigns
        case types.DELETE_CAMPAIGNS_SUCCESS:
            return reducerHandler.deleteCampaigns(state, action.payload);

        // ? Create phishing campaign
        case types.CREATE_PHISHING_CAMPAIGN_SUCCESS:
            return reducerHandler.createPhishingCampaignReducerHandler(state, action.payload);

        case types.CREATE_PHISHING_CAMPAIGN_RESET:
            return reducerHandler.createPhishingCampaignResetReducerHandler(state);

        // ? Get list bookmarks
        case types.GET_LIST_BOOKMARKS_SUCCESS:
            return reducerHandler.getListBookmarks(state, action.payload);

        // ? Import phishing site
        case types.IMPORT_PHISHING_SITE_SUCCESS:
            return reducerHandler.importPhishingSiteReducerHandler(state, action.payload);

        case types.IMPORT_PHISHING_SITE_FAILURE:
            return reducerHandler.importPhishingSiteFailureReducerHandler(state, action.payload);

        case types.IMPORT_PHISHING_SITE_RESET:
            return reducerHandler.importPhishingSiteResetReducerHandler(state);

        // ? Get list sms brands
        case types.GET_LIST_SMS_BRANDS_SUCCESS:
            return reducerHandler.getListSmsBrandsReducerHandler(state, action.payload);

        // ? Send test sms
        case types.SEND_TEST_SMS_SUCCESS:
            return reducerHandler.sendTestSmsReducerHandler(state, action.payload);

        case types.SEND_TEST_SMS_RESET:
            return reducerHandler.sendTestSmsResetReducerHandler(state);

        case types.SEND_TEST_SMS_FAILURE:
            return reducerHandler.sendTestSmsFailureReducerHandler(state, action.payload);

        // ? Get list sms templates
        case types.GET_LIST_SMS_TEMPLATES_SUCCESS:
            return reducerHandler.getListSmsTemplatesReducerHandler(state, action.payload);

        case types.GET_LIST_SMS_TEMPLATES_FAILURE:
            return reducerHandler.getListSmsTemplatesFailureReducerHandler(state, action.payload);

        // ? Create sms phishing campaign
        case types.CREATE_SMS_CAMPAIGN_SUCCESS:
            return reducerHandler.createSmsPhishingCampaignReducerHandler(state, action.payload);

        case types.CREATE_SMS_CAMPAIGN_RESET:
            return reducerHandler.createSmsPhishingCampaignResetReducerHandler(
                state,
                action.payload
            );

        // ? Preview site
        case types.PREVIEW_SITE_SUCCESS:
            return reducerHandler.previewSiteReducerHandler(state, action.payload);

        case types.PREVIEW_SITE_RESET:
            return reducerHandler.previewSiteResetReducerHandler(state);

        // ? Get list category
        case types.GET_LIST_CATEGORY_SUCCESS:
            return reducerHandler.getListCategoryReducerHandler(state, action.payload);

        case types.GET_LIST_CATEGORY_FAILURE:
            return reducerHandler.getListCategoryFailureReducerHandler(state);

        // ? Get clone phishing details
        case types.GET_CLONE_PHISHING_DETAILS_SUCCESS:
            return reducerHandler.getClonePhishingDetailsReducerHandler(state, action.payload);

        case types.GET_CLONE_PHISHING_DETAILS_FAILURE:
            return reducerHandler.getClonePhishingDetailsFailureReducerHandler(state);

        // ? Send test ott
        case types.SEND_TEST_OTT_SUCCESS:
            return reducerHandler.sendTestOttReducerHandler(state, action.payload);

        case types.SEND_TEST_OTT_RESET:
            return reducerHandler.sendTestOttResetReducerHandler(state);

        // ? Create ott campaign
        case types.CREATE_OTT_CAMPAIGN_SUCCESS:
            return reducerHandler.createOttCampaignReducerHandler(state, action.payload);

        case types.CREATE_OTT_CAMPAIGN_RESET:
            return reducerHandler.createOttCampaignResetReducerHandler(state);

        // ? Get templates ott phishing
        case types.GET_TEMPLATES_OTT_PHISHING_SUCCESS:
            return reducerHandler.getTemplatesOttPhishingReducerHandler(state, action.payload);

        case types.GET_TEMPLATES_OTT_PHISHING_FAILURE:
            return reducerHandler.getTemplatesOttPhishingFailureReducerHandler(state);

        // ? Get phishing summary departments
        case types.GET_PHISHING_SUMMARY_DEPARTMENTS_SUCCESS:
            return reducerHandler.getPhishingSummaryDepartments(state, action.payload);

        default:
            return state;
    }
};

export default reducer;
