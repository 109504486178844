const SURVEY_CONSTANTS = {
    LIST_SURVEY: 'surveys',
    START_SURVEY: 'surveys/:surveyCode/start',
    STOP_SURVEY: 'surveys/:surveyCode/stop',
    REBUILD_SURVEY: 'surveys/:surveyCode/rebuild',
    LIST_SURVEY_TEMPLATES: 'surveys/templates',
    DELETE_SURVEYS: 'surveys/delete',
    GET_CANDIDATES_BY_SURVEY: 'surveys/:surveyCode/candidates',
    GET_QUESTIONS_BY_SURVEY: 'surveys/:surveyCode/questions',
    CREATE_SURVEY: 'surveys/create',
    GET_SURVEY_DETAILS: 'surveys/:surveyCode/detail',
    EDIT_SURVEY: 'surveys/:surveyCode/edit'
};

export default SURVEY_CONSTANTS;
