import loadable from '@loadable/component';

const SimulationPage = loadable(() => import('pages/simulation'));
const PhishingResult = loadable(() => import('pages/simulation/PhishingResult'));
const CreateEmailSimulation = loadable(() => import('pages/simulation/create-email'));
const CreateSmsSimulation = loadable(() => import('pages/simulation/create-sms'));
const CreateOttSimulation = loadable(() => import('pages/simulation/create-ott'));
const CloneSimulation = loadable(() => import('pages/simulation/clone'));

const simulationRouteItems = [
    {
        value: 'simulation',
        key: 'sat.simulation.phishing.index',
        component: SimulationPage
    },
    {
        value: 'simulation/create-email',
        key: 'sat.simulation.phishing_email.create',
        component: CreateEmailSimulation
    },
    {
        value: 'simulation/:id/result',
        key: 'sat.simulation.phishing.view_result',
        component: PhishingResult
    },
    {
        value: 'simulation/create-sms',
        key: 'sat.simulation.phishing_sms.create',
        component: CreateSmsSimulation
    },
    {
        value: 'simulation/clone/:id',
        key: 'sat.simulation.phishing.clone',
        component: CloneSimulation
    },
    {
        value: 'simulation/create-ott',
        key: 'sat.simulation.phishing_ott.create',
        component: CreateOttSimulation
    }
];

export default simulationRouteItems;
