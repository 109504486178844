const transformedStaffList = (list = [], isUpdate = true) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item) => {
        return {
            displayName: `${item.displayName}#?${item.email}`,
            email: item.email,
            roles: item.roles,
            isAdmin: item.isAdmin,
            activeStatus: item.activeStatus,
            phoneNumber: item.phoneNumber || '',
            createdAt: item.createdAt,
            lastLogin: item.lastLogin || null,
            code: item.code,
            isEdit: !item.isAdmin && isUpdate,
            isBlock: !item.isAdmin && item.activeStatus !== 'banned' && isUpdate
        };
    });
};

const transformedRoleList = (list = []) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item) => {
        return {
            name: item.name,
            createdAt: item.createdAt,
            numberOfStaffs: item.numberOfStaffs,
            code: item.slug,
            isEdit: true,
            isDelete: true
        };
    });
};

const transformedStaffDetails = (data) => {
    if (!data) {
        return;
    }
    return {
        ...data,

        // ? Get dial code from phone number with splice

        dialCode: data.phoneNumber ? data.phoneNumber.slice(1, 3) : '84',
        phoneNumber: data.phoneNumber ? data.phoneNumber.slice(3) : ''
    };
};

export { transformedStaffList, transformedRoleList, transformedStaffDetails };
