import objectUtils from 'utils/object-utils';
import dateUtils from 'utils/date-utils';

const transformDashboardAssessmentData = (assessment = {}) => {
    if (objectUtils.isEmptyObject(assessment)) return;
    const {
        totalRunning,
        totalReady,
        totalClosed,
        totalFailed,
        totalDraft,
        totalAssessment,
        totalAssessmentOfMonth,
        totalAssessmentPreviousMonth
    } = assessment;

    const totalStatus = totalRunning + totalReady + totalClosed + totalFailed + totalDraft;

    let percentRunning = 0;
    let percentReady = 0;
    let percentClosed = 0;
    let percentFailed = 0;
    let percentDraft = 0;

    if (totalStatus !== 0) {
        percentRunning = (totalRunning / totalStatus) * 100;
        percentReady = (totalReady / totalStatus) * 100;
        percentClosed = (totalClosed / totalStatus) * 100;
        percentFailed = (totalFailed / totalStatus) * 100;
        percentDraft = 100 - (percentRunning + percentReady + percentClosed + percentFailed);
    }

    const details = [
        {
            id: 'ready',
            value: totalReady,
            percentageCalculated: percentReady,
            color: '#68A7EF'
        },
        {
            id: 'running',
            value: totalRunning,
            percentageCalculated: percentRunning,
            color: '#97D79A'
        },
        {
            id: 'draft',
            value: totalDraft,
            percentageCalculated: percentDraft,
            color: '#FFD650'
        },
        {
            id: 'failed',
            value: totalFailed,
            percentageCalculated: percentFailed,
            color: '#FF7D5C'
        },
        {
            id: 'closed',
            value: totalClosed,
            percentageCalculated: percentClosed,
            color: '#CDD5DF'
        }
    ];

    // ? Calculate whether the assessments are created increasing or not
    const createdGap = totalAssessmentOfMonth - totalAssessmentPreviousMonth;

    return {
        totalItems: totalAssessment,
        details,
        totalOfMonth: totalAssessmentOfMonth,
        createdGap: Math.abs(createdGap),
        isIncrease: createdGap > 0
    };
};

const transformDashboardTrainingData = (training = {}) => {
    if (objectUtils.isEmptyObject(training)) return;
    const {
        totalRunning,
        totalReady,
        totalClosed,
        totalFailed,
        totalDraft,
        totalTraining,
        totalTrainingOfMonth,
        totalTrainingPreviousMonth
    } = training;

    const totalStatus = totalRunning + totalReady + totalClosed + totalFailed + totalDraft;

    let percentRunning = 0;
    let percentReady = 0;
    let percentClosed = 0;
    let percentFailed = 0;
    let percentDraft = 0;

    if (totalStatus !== 0) {
        percentRunning = (totalRunning / totalStatus) * 100;
        percentReady = (totalReady / totalStatus) * 100;
        percentClosed = (totalClosed / totalStatus) * 100;
        percentFailed = (totalFailed / totalStatus) * 100;
        percentDraft = 100 - (percentRunning + percentReady + percentClosed + percentFailed);
    }

    const details = [
        {
            id: 'ready',
            value: totalReady,
            percentageCalculated: percentReady,
            color: '#68A7EF'
        },
        {
            id: 'running',
            value: totalRunning,
            percentageCalculated: percentRunning,
            color: '#97D79A'
        },
        {
            id: 'draft',
            value: totalDraft,
            percentageCalculated: percentDraft,
            color: '#FFD650'
        },
        {
            id: 'failed',
            value: totalFailed,
            percentageCalculated: percentFailed,
            color: '#FF7D5C'
        },
        {
            id: 'closed',
            value: totalClosed,
            percentageCalculated: percentClosed,
            color: '#CDD5DF'
        }
    ];

    // ? Calculate whether the trainings are created increasing or not
    const createdGap = totalTrainingOfMonth - totalTrainingPreviousMonth;

    return {
        totalItems: totalTraining,
        details,
        totalOfMonth: totalTrainingOfMonth,
        createdGap: Math.abs(createdGap),
        isIncrease: createdGap > 0
    };
};

const transformDashboardPhishingData = (phishing = {}) => {
    if (objectUtils.isEmptyObject(phishing)) return;
    const {
        totalInProgress,
        totalQueued,
        totalClosed,
        totalPhishing,
        totalPhishingOfMonth,
        totalPhishingPreviousMonth
    } = phishing;

    const totalStatus = totalInProgress + totalQueued + totalClosed;

    let percentRunning = 0;
    let percentQueued = 0;
    let percentClosed = 0;

    if (totalStatus !== 0) {
        percentRunning = (totalInProgress / totalStatus) * 100;
        percentQueued = (totalQueued / totalStatus) * 100;
        percentClosed = 100 - (percentRunning + percentQueued);
    }

    const details = [
        {
            id: 'running',
            value: totalInProgress,
            percentageCalculated: percentRunning,
            color: '#97D79A'
        },
        {
            id: 'queued',
            value: totalQueued,
            percentageCalculated: percentQueued,
            color: '#A2E1F0'
        },
        {
            id: 'closed',
            value: totalClosed,
            percentageCalculated: percentClosed,
            color: '#CDD5DF'
        }
    ];

    // ? Calculate whether the trainings are created increasing or not
    const createdGap = totalPhishingOfMonth - totalPhishingPreviousMonth;

    return {
        totalItems: totalPhishing,
        details,
        totalOfMonth: totalPhishingOfMonth,
        createdGap: Math.abs(createdGap),
        isIncrease: createdGap > 0
    };
};

const transformDashboardLastActivityData = (data) => {
    return data.map((item) => {
        return {
            formattedDate: dateUtils.formatDateTimeLineChart(
                dateUtils.formatChartAxisDateTime(item.date)
            ),
            assessment: item.results.assessment,
            training: item.results.training,
            phishing: item.results.phishing,
            date: item.date
        };
    });
};

const transformDashboardCreditData = (data = {}) => {
    if (objectUtils.isEmptyObject(data)) return;
    const { spent, remaining } = data;

    const total = spent + remaining;

    let percentSpent = 0;
    let percentRemaining = 0;

    if (total !== 0) {
        percentSpent = (spent / total) * 100;
        percentRemaining = 100 - percentSpent;
    }

    const details = [
        {
            id: 'spent',
            value: spent,
            percentageCalculated: percentSpent,
            color: '#97D79A'
        },
        {
            id: 'remaining',
            value: remaining,
            percentageCalculated: percentRemaining,
            color: '#CDD5DF'
        }
    ];

    return {
        totalItems: total || 0,
        details
    };
};

const transformDashboardParticipantData = (data = {}) => {
    if (objectUtils.isEmptyObject(data)) return;

    const { assigned, unAssigned } = data;

    const total = assigned + unAssigned;

    let percentAssigned = 0;
    let percentUnAssigned = 0;

    if (total !== 0) {
        percentAssigned = (assigned / total) * 100;
        percentUnAssigned = 100 - percentAssigned;
    }

    const details = [
        {
            id: 'assigned',
            value: assigned,
            percentageCalculated: percentAssigned,
            color: '#68A7EF'
        },
        {
            id: 'unassigned',
            value: unAssigned,
            percentageCalculated: percentUnAssigned,
            color: '#CDD5DF'
        }
    ];

    return {
        totalItems: total || 0,
        details
    };
};

export {
    transformDashboardAssessmentData,
    transformDashboardTrainingData,
    transformDashboardPhishingData,
    transformDashboardLastActivityData,
    transformDashboardCreditData,
    transformDashboardParticipantData
};
