const USER_CONSTANTS = {
    GET_USER_PROFILE: '/user/profile',
    UPDATE_USER_PROFILE: '/user/profile/edit',
    GET_USER_TOKEN: '/user/token',
    CHANGE_PASSWORD: '/auth/password/edit',
    ENABLE_2FA: '/auth/2fa/enable',
    DISABLE_2FA: '/auth/2fa/disable',
    CREDIT_SUMMARY: '/user/transaction/credit/summary',
    CREDIT_HISTORY: '/user/transaction/credit/history'
};

export default USER_CONSTANTS;
