import types from './actionTypes';

/**
 * TODO: Get user profile
 */
const getUserProfile = () => ({
    type: types.GET_USER_PROFILE_REQUEST
});

const getUserProfileSuccess = (payload) => ({
    type: types.GET_USER_PROFILE_SUCCESS,
    payload
});

const getUserProfileFailure = (payload) => ({
    type: types.GET_USER_PROFILE_FAILURE,
    payload
});

/**
 * TODO: Update user profile
 */

const updateUserProfile = (payload) => ({
    type: types.UPDATE_USER_PROFILE_REQUEST,
    payload
});

const updateUserProfileSuccess = (payload) => ({
    type: types.UPDATE_USER_PROFILE_SUCCESS,
    payload
});

const updateUserProfileFailure = (payload) => ({
    type: types.UPDATE_USER_PROFILE_FAILURE,
    payload
});

const updateUserProfileReset = () => ({
    type: types.UPDATE_USER_PROFILE_RESET
});

const changePassword = (payload) => ({
    type: types.CHANGE_PASSWORD_REQUEST,
    payload
});

const changePasswordSuccess = (payload) => ({
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload
});

const changePasswordFailure = (payload) => ({
    type: types.CHANGE_PASSWORD_FAILURE,
    payload
});

const resetChangePasswordError = () => ({
    type: types.CHANGE_PASSWORD_RESET
});

const enable2FA = (payload) => ({
    type: types.ENABLE_2FA_REQUEST,
    payload
});

const enable2FASuccess = (payload) => ({
    type: types.ENABLE_2FA_SUCCESS,
    payload
});

const enable2FAFailure = (payload) => ({
    type: types.ENABLE_2FA_FAILURE,
    payload
});

const resetEnable2FAError = () => ({
    type: types.ENABLE_2FA_RESET
});

const disable2FA = (payload) => ({
    type: types.DISABLE_2FA_REQUEST,
    payload
});

const disable2FASuccess = (payload) => ({
    type: types.DISABLE_2FA_SUCCESS,
    payload
});

const disable2FAFailure = (payload) => ({
    type: types.DISABLE_2FA_FAILURE,
    payload
});

const resetDisable2FAError = () => ({
    type: types.DISABLE_2FA_RESET
});

/**
 * TODO: Get user token
 */

const getUserToken = () => ({
    type: types.GET_USER_TOKEN_REQUEST
});

const getUserTokenSuccess = (payload) => ({
    type: types.GET_USER_TOKEN_SUCCESS,
    payload
});

const getUserTokenFailure = (payload) => ({
    type: types.GET_USER_TOKEN_FAILURE,
    payload
});

/**
 * TODO: Get credit summary
 */

const getCreditSummary = () => ({
    type: types.GET_CREDIT_SUMMARY_REQUEST
});

const getCreditSummarySuccess = (payload) => ({
    type: types.GET_CREDIT_SUMMARY_SUCCESS,
    payload
});

const getCreditSummaryFailure = (payload) => ({
    type: types.GET_CREDIT_SUMMARY_FAILURE,
    payload
});
/**
 * TODO: Get credit history
 */

const getCreditHistory = (payload) => ({
    type: types.GET_CREDIT_HISTORY_REQUEST,
    payload
});

const getCreditHistorySuccess = (payload) => ({
    type: types.GET_CREDIT_HISTORY_SUCCESS,
    payload
});

const getCreditHistoryFailure = (payload) => ({
    type: types.GET_CREDIT_HISTORY_FAILURE,
    payload
});

export {
    // ? Get user profile
    getUserProfile,
    getUserProfileSuccess,
    getUserProfileFailure,

    // ? Update user profile
    updateUserProfile,
    updateUserProfileSuccess,
    updateUserProfileFailure,
    updateUserProfileReset,

    // ? Update password
    changePassword,
    changePasswordSuccess,
    changePasswordFailure,
    resetChangePasswordError,

    // ? Enable 2FA
    enable2FA,
    enable2FASuccess,
    enable2FAFailure,
    resetEnable2FAError,

    // ? Disable 2FA
    disable2FA,
    disable2FASuccess,
    disable2FAFailure,
    resetDisable2FAError,

    // ? Get user token
    getUserToken,
    getUserTokenSuccess,
    getUserTokenFailure,

    // ? Get credit summary
    getCreditSummary,
    getCreditSummarySuccess,
    getCreditSummaryFailure,

    // ? Get credit history
    getCreditHistory,
    getCreditHistorySuccess,
    getCreditHistoryFailure
};
