import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import EXPORT_CONSTANTS from 'api/constants/exports';
import dateUtils from 'utils/date-utils';
import objectUtils from 'utils/object-utils';

const createRequestURL = (path, code, endpoint) =>
    `${path}/${endpoint}`.replace(':surveyCode', code);

/**
 * TODO: Export survey participants
 */

const exportSurveyParticipantsPDF = catchAsync(async ({ params, surveyCode }) => {
    const reqURL = createRequestURL(EXPORT_CONSTANTS.SURVEY_PDF_EXPORT, surveyCode, 'candidates');
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportSurveyParticipantsSheet = catchAsync(async ({ params, surveyCode }) => {
    const reqURL = createRequestURL(EXPORT_CONSTANTS.SURVEY_SHEET_EXPORT, surveyCode, 'candidates');
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export survey questions
 */

const exportSurveyQuestionsPDF = catchAsync(async ({ params, surveyCode }) => {
    const reqURL = createRequestURL(EXPORT_CONSTANTS.SURVEY_PDF_EXPORT, surveyCode, 'questions');
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportSurveyQuestionsSheet = catchAsync(async ({ params, surveyCode }) => {
    const reqURL = createRequestURL(EXPORT_CONSTANTS.SURVEY_SHEET_EXPORT, surveyCode, 'questions');
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export survey result
 */

const exportSurveyResultSheet = catchAsync(async ({ surveyCode }) => {
    const endpoint = EXPORT_CONSTANTS.SURVEY_SHEET_EXPORT.replace(':surveyCode', surveyCode);
    const response = await httpClient.post(endpoint, {
        timezone: dateUtils.getCurrentTimezone()
    });
    return response;
});

const SurveyServices = {
    exportSurveyParticipantsPDF,
    exportSurveyParticipantsSheet,
    exportSurveyQuestionsPDF,
    exportSurveyQuestionsSheet,
    exportSurveyResultSheet
};

export default SurveyServices;
