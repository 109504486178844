const transformContractList = (list = []) => {
    if (list.length === 0) {
        return;
    }
    return list.map((item) => {
        return {
            contractName: item.contractName,
            contractId: item.slug,
            createdAt: item.createdAt,
            expiredDate: item.expiredDate
        };
    });
};

export { transformContractList };
