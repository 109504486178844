import httpClient from 'api';
import COMMON_CONSTANTS from 'api/constants/common';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Department List
 */

const getDepartmentList = catchAsync(async (params) => {
    const response = await httpClient.get(COMMON_CONSTANTS.GET_DEPARTMENT_LIST, { params });
    return response;
});

/**
 * TODO: Department List For SMS Phishing
 */

const getDepartmentListForSmsPhishing = catchAsync(async (params) => {
    const response = await httpClient.get(COMMON_CONSTANTS.GET_DEPARTMENT_FOR_SMS_PHISHING, {
        params
    });
    return response;
});

const CommonServices = {
    getDepartmentList,
    getDepartmentListForSmsPhishing
};

export default CommonServices;
