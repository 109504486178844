import { transformedRoleList, transformedStaffList } from 'transformer/staff';

/**
 * TODO: Transform Staff List Data
 */

const getStaffListHandler = (state, { payload }) => {
    return {
        ...state,
        bannedStaffSuccessMessage: '',
        staffs: {
            data: transformedStaffList(payload.staffs),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Transform Staff List By Role Data
 */

const getStaffListByRoleHandler = (state, { payload }) => {
    return {
        ...state,
        deletedStaffsFromRoleSuccessMessage: '',
        staffsByRole: {
            data: transformedStaffList(payload.staffs, false),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Update Message Deleted Staffs Successfully
 */

const deleteStaffsFromRoleHandler = (state) => {
    return {
        ...state,
        deletedStaffsFromRoleSuccessMessage: 'role.deleteStaffSuccess'
    };
};

/**
 * TODO: Update Message Banned Staff Successfully
 */

const banStaffHandler = (state) => {
    return {
        ...state,
        bannedStaffSuccessMessage: 'staff.bannedSuccess'
    };
};

/**
 * TODO: Transform Role List Data
 */

const getRoleListHandler = (state, { payload }) => {
    return {
        ...state,
        deletedRolesSuccessMessage: '',
        roles: {
            data: transformedRoleList(payload.roles),
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

/**
 * TODO: Permissions list reducer handler
 */

const getPermissionsListHandler = (state, { payload }) => {
    return {
        ...state,
        permissions: payload
    };
};

/**
 * TODO: Add role reducer handler
 */

const addRoleHandler = (state) => {
    return {
        ...state,
        isAddRoleSuccess: true
    };
};

/**
 * TODO: Reset add role reducer handler
 */

const resetAddRoleHandler = (state) => {
    return {
        ...state,
        isAddRoleSuccess: false
    };
};

/**
 * TODO: Get role by id reducer handler
 */

const getRoleByIdHandler = (state, { payload }) => {
    return {
        ...state,
        roleData: payload
    };
};

/**
 * TODO: Reset get role by id reducer handler
 */

const resetGetRoleByIdHandler = (state) => {
    return {
        ...state,
        roleData: {}
    };
};

/**
 * TODO: Update role reducer handler
 */

const updateRoleHandler = (state) => {
    return {
        ...state,
        isUpdateRoleSuccess: true
    };
};

/**
 * TODO: Reset update role reducer handler
 */

const resetUpdateRoleHandler = (state) => {
    return {
        ...state,
        isUpdateRoleSuccess: false
    };
};

/**
 * TODO: Update Message Deleted Staffs Successfully
 */

const deleteRolesHandler = (state) => {
    return {
        ...state,
        deletedRolesSuccessMessage: 'role.deleteSuccess'
    };
};

/*
 * TODO: Add staff reducer handler
 */

const addStaffHandler = (state) => {
    return {
        ...state,
        isAddStaffSuccess: true
    };
};

/**
 * TODO: Reset add staff reducer handler
 */

const resetAddStaffHandler = (state) => {
    return {
        ...state,
        isAddStaffSuccess: false
    };
};

/**
 * TODO: Get staff by id reducer handler
 */

const getStaffByIdHandler = (state, { payload }) => {
    return {
        ...state,
        staffData: payload
    };
};

/**
 * TODO: Update staff reducer handler
 */

const updateStaffHandler = (state) => {
    return {
        ...state,
        isUpdateStaffSuccess: true
    };
};

/**
 * TODO: Reset update staff reducer handler
 */

const resetUpdateStaffHandler = (state) => {
    return {
        ...state,
        isUpdateStaffSuccess: false
    };
};

/**
 * TODO: Reset staff password reducer handler
 */

const resetStaffPasswordHandler = (state) => {
    return {
        ...state,
        isResetStaffPasswordSuccess: true
    };
};

/**
 * TODO: Reset staff password reducer handler
 */

const resetResetStaffPasswordHandler = (state) => {
    return {
        ...state,
        isResetStaffPasswordSuccess: false
    };
};

export default {
    getStaffListHandler,
    getStaffListByRoleHandler,
    deleteStaffsFromRoleHandler,
    banStaffHandler,
    getPermissionsListHandler,
    getRoleListHandler,
    addRoleHandler,
    resetAddRoleHandler,
    getRoleByIdHandler,
    resetGetRoleByIdHandler,
    updateRoleHandler,
    resetUpdateRoleHandler,
    deleteRolesHandler,
    addStaffHandler,
    resetAddStaffHandler,
    getStaffByIdHandler,
    updateStaffHandler,
    resetUpdateStaffHandler,
    resetStaffPasswordHandler,
    resetResetStaffPasswordHandler
};
