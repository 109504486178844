import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_USER_PROFILE_SUCCESS:
            return reducerHandler.getUserProfileReducerHandler(state, action.payload);

        // ? Update user profile
        case types.UPDATE_USER_PROFILE_SUCCESS:
            return reducerHandler.updateUserProfileReducerHandler(state, action.payload);
        case types.UPDATE_USER_PROFILE_RESET:
            return reducerHandler.updateUserProfileResetReducerHandler(state);
        case types.CHANGE_PASSWORD_SUCCESS:
            return reducerHandler.changePasswordReducerHandler(state);
        case types.CHANGE_PASSWORD_RESET:
            return reducerHandler.resetChangePasswordReducerHandler(state);
        case types.ENABLE_2FA_SUCCESS:
            return reducerHandler.enable2FAReducerHandler(state);
        case types.ENABLE_2FA_RESET:
            return reducerHandler.resetEnable2FAReducerHandler(state);
        case types.DISABLE_2FA_SUCCESS:
            return reducerHandler.disable2FAReducerHandler(state);
        case types.DISABLE_2FA_RESET:
            return reducerHandler.resetDisable2FAReducerHandler(state);
        // ? Get user token
        case types.GET_USER_TOKEN_SUCCESS:
            return reducerHandler.getUserTokenReducerHandler(state, action.payload);
        case types.GET_USER_TOKEN_FAILURE:
            return reducerHandler.getUserTokenFailureReducerHandler(state);
        case types.GET_CREDIT_SUMMARY_SUCCESS:
            return reducerHandler.getCreditSummary(state, action.payload);
        case types.GET_CREDIT_HISTORY_SUCCESS:
            return reducerHandler.getCreditHistory(state, action.payload);
        default:
            return state;
    }
};

export default reducer;
