const types = {
    // ? Get statistics phishing summary
    GET_STATISTICS_PHISHING_SUMMARY_REQUEST: 'GET_STATISTICS_PHISHING_SUMMARY_REQUEST',
    GET_STATISTICS_PHISHING_SUMMARY_SUCCESS: 'GET_STATISTICS_PHISHING_SUMMARY_SUCCESS',
    GET_STATISTICS_PHISHING_SUMMARY_FAILURE: 'GET_STATISTICS_PHISHING_SUMMARY_FAILURE',

    // ? Get statistics phishing rating by months
    GET_STATISTICS_PHISHING_RATING_BY_MONTHS_REQUEST:
        'GET_STATISTICS_PHISHING_RATING_BY_MONTHS_REQUEST',
    GET_STATISTICS_PHISHING_RATING_BY_MONTHS_SUCCESS:
        'GET_STATISTICS_PHISHING_RATING_BY_MONTHS_SUCCESS',
    GET_STATISTICS_PHISHING_RATING_BY_MONTHS_FAILURE:
        'GET_STATISTICS_PHISHING_RATING_BY_MONTHS_FAILURE',

    // ? Get statistics phishing result
    GET_STATISTICS_PHISHING_RESULT_REQUEST: 'GET_STATISTICS_PHISHING_RESULT_REQUEST',
    GET_STATISTICS_PHISHING_RESULT_SUCCESS: 'GET_STATISTICS_PHISHING_RESULT_SUCCESS',
    GET_STATISTICS_PHISHING_RESULT_FAILURE: 'GET_STATISTICS_PHISHING_RESULT_FAILURE',

    // ? Get statistics top phished by department
    GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_REQUEST:
        'GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_REQUEST',
    GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_SUCCESS:
        'GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_SUCCESS',
    GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_FAILURE:
        'GET_STATISTICS_TOP_PHISHED_BY_DEPARTMENT_FAILURE',

    // ? Get statistics top clicked phishing template
    GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_REQUEST:
        'GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_REQUEST',
    GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_SUCCESS:
        'GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_SUCCESS',
    GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_FAILURE:
        'GET_STATISTICS_TOP_CLICKED_PHISHING_TEMPLATE_FAILURE',

    // ? Get statistics phishing summary total
    GET_STATISTICS_PHISHING_SUMMARY_TOTAL_REQUEST: 'GET_STATISTICS_PHISHING_SUMMARY_TOTAL_REQUEST',
    GET_STATISTICS_PHISHING_SUMMARY_TOTAL_SUCCESS: 'GET_STATISTICS_PHISHING_SUMMARY_TOTAL_SUCCESS',
    GET_STATISTICS_PHISHING_SUMMARY_TOTAL_FAILURE: 'GET_STATISTICS_PHISHING_SUMMARY_TOTAL_FAILURE',

    // ? Get statistics details phishing result
    GET_STATISTICS_DETAILS_PHISHING_RESULT_REQUEST:
        'GET_STATISTICS_DETAILS_PHISHING_RESULT_REQUEST',
    GET_STATISTICS_DETAILS_PHISHING_RESULT_SUCCESS:
        'GET_STATISTICS_DETAILS_PHISHING_RESULT_SUCCESS',
    GET_STATISTICS_DETAILS_PHISHING_RESULT_FAILURE:
        'GET_STATISTICS_DETAILS_PHISHING_RESULT_FAILURE',

    // ? Get statistics list targets unphished
    GET_STATISTICS_LIST_TARGETS_UNPHISHED_REQUEST: 'GET_STATISTICS_LIST_TARGETS_UNPHISHED_REQUEST',
    GET_STATISTICS_LIST_TARGETS_UNPHISHED_SUCCESS: 'GET_STATISTICS_LIST_TARGETS_UNPHISHED_SUCCESS',
    GET_STATISTICS_LIST_TARGETS_UNPHISHED_FAILURE: 'GET_STATISTICS_LIST_TARGETS_UNPHISHED_FAILURE'
};

export default types;
