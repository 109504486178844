/**
 * TODO: Get user profile reducer handler
 */

import { transformCreditSummary } from 'transformer/user';

const getUserProfileReducerHandler = (state, payload) => {
    return { ...state, isEnable2FASuccess: false, isDisable2FASuccess: false, userData: payload };
};

/**
 * TODO: Update user profile reducer handler
 */

const updateUserProfileReducerHandler = (state, payload) => {
    return { ...state, isUpdateUserProfileSuccess: true, userData: payload };
};

const updateUserProfileResetReducerHandler = (state) => {
    return { ...state, isUpdateUserProfileSuccess: false };
};

/**
 * TODO: Change password reducer handler
 */
const changePasswordReducerHandler = (state) => {
    return {
        ...state,
        isChangePasswordSuccess: true
    };
};

const resetChangePasswordReducerHandler = (state) => {
    return { ...state, isChangePasswordSuccess: false };
};

/**
 * TODO: Enable 2FA reducer handler
 */
const enable2FAReducerHandler = (state) => {
    return {
        ...state,
        isEnable2FASuccess: true
    };
};

const resetEnable2FAReducerHandler = (state) => {
    return { ...state, isEnable2FASuccess: false };
};

/**
 * TODO: Disable 2FA reducer handler
 */
const disable2FAReducerHandler = (state) => {
    return {
        ...state,
        isDisable2FASuccess: true
    };
};

const resetDisable2FAReducerHandler = (state) => {
    return { ...state, isDisable2FASuccess: false };
};

/*
 * TODO: Get user token reducer handler
 */

const getUserTokenReducerHandler = (state, payload) => {
    return { ...state, userSocketToken: payload?.accessToken };
};

const getUserTokenFailureReducerHandler = (state) => {
    return { ...state, userSocketToken: '' };
};

/**
 * TODO: Transform credit summary data
 */

const getCreditSummary = (state, payload) => {
    return { ...state, creditSummary: transformCreditSummary(payload) };
};

/**
 * TODO: Transform credit history data
 */
const getCreditHistory = (state, payload) => {
    return {
        ...state,
        deleteCandidatesSuccessMessage: '',
        creditHistory: {
            data: payload.credits,
            pagination: { ...payload.meta, ...payload.links }
        }
    };
};

export default {
    getUserProfileReducerHandler,
    updateUserProfileReducerHandler,
    updateUserProfileResetReducerHandler,
    getUserTokenReducerHandler,
    getUserTokenFailureReducerHandler,
    changePasswordReducerHandler,
    resetChangePasswordReducerHandler,
    enable2FAReducerHandler,
    resetEnable2FAReducerHandler,
    disable2FAReducerHandler,
    resetDisable2FAReducerHandler,
    getCreditSummary,
    getCreditHistory
};
