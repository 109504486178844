import ExportServices from 'api/exports/statistics';
import arrayUtils from 'utils/array-utils';
import { call, put, takeLatest } from 'redux-saga/effects';
import exportActionTypes from './actionTypes';
import exportActions from './actions';

/**
 * TODO: Export Report PDF
 */

function* exportReportPDFHandler({ payload }) {
    try {
        let formData = arrayUtils.convertArrayToFormData(payload.templates);
        const response = yield call(ExportServices.exportReportPDF, formData);
        yield put(exportActions.exportReportPDFSuccess(response));
    } catch (error) {
        yield put(exportActions.exportReportPDFFailure(error));
    }
}

/**
 * TODO:  Export Report As Raw
 */

function* exportReportAsRawHandler() {
    try {
        const response = yield call(ExportServices.exportReportAsRaw);
        yield put(exportActions.exportReportAsRawSuccess(response));
    } catch (error) {
        yield put(exportActions.exportReportAsRawFailure(error));
    }
}

export default function* watchStatisticsExportSaga() {
    yield takeLatest(exportActionTypes.EXPORT_REPORT_PDF_REQUEST, exportReportPDFHandler);
    yield takeLatest(exportActionTypes.EXPORT_REPORT_AS_RAW_REQUEST, exportReportAsRawHandler);
}
