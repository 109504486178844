import httpClient from 'api';
import CONSTANTS from 'api/constants/statistics/assessment';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO:: Get assessment total
 */
const getAssessmentTotal = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.ASSESSMENT_TOTAL, { params });
    return response;
});

/**
 * TODO:: Get assessment summary
 */
const getAssessmentSummary = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.ASSESSMENT_SUMMARY, { params });
    return response;
});
/**
 * TODO: Assessment By Department
 */

const getAssessmentByDepartment = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.ASSESSMENT_BY_DEPARTMENT, { params });
    return response;
});

/**
 * TODO: Not yet assign to assessment
 */

const getParticipantNotYetAssignToAssessment = catchAsync(async (params) => {
    const response = await httpClient.get(CONSTANTS.NOT_YET_ASSIGN_TO_ASSESSMENT, { params });
    return response;
});

export default {
    getAssessmentSummary,
    getAssessmentTotal,
    getAssessmentByDepartment,
    getParticipantNotYetAssignToAssessment
};
