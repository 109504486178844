const ACL_CONSTANTS = {
    GET_STAFF_LIST: '/acl/staffs',
    GET_STAFF_LIST_BY_ROLE: '/acl/staffs/role',
    DELETE_STAFFS_FROM_ROLE: '/acl/staffs/role',
    PUT_BAN_STAFF: 'acl/staffs/ban',
    GET_ROLE_LIST: '/acl/roles',
    DELETE_ROLES: '/acl/roles',
    GET_PERMISSIONS_LIST: '/acl/permissions',
    ADD_ROLE: '/acl/roles/create',
    GET_ROLE_BY_ID: '/acl/roles',
    UPDATE_ROLE: '/acl/roles',
    ADD_STAFF: '/acl/staffs/create',
    GET_STAFF_BY_ID: '/acl/staffs',
    UPDATE_STAFF: '/acl/staffs',
    RESET_STAFF_PASSWORD: '/acl/staffs/reset/password'
};

export default ACL_CONSTANTS;
