import { createSelector } from '@reduxjs/toolkit';
import { createErrorSelector } from 'store/error/selector';
import { createLoadingSelector } from 'store/loading/selector';
import initState from './initialState';
import types from './actionTypes';

const userState = (state) => state.user || initState;

// ? Get user profile data
const loadingUserProfileSelector = createLoadingSelector([types.GET_USER_PROFILE_REQUEST]);
const userProfileData = createSelector(userState, (user) => user.userData);

const userPermissionsData = createSelector(
    userState,
    ({ userData }) => userData?.permissions || {}
);

const userUnitPriceData = createSelector(userState, ({ userData }) => userData?.unit || 0);

const userCreditData = createSelector(userState, ({ userData }) => userData?.credit || 0);

const isExportingSelector = createSelector(
    userState,
    ({ userData }) => userData?.isExporting || false
);

// ? Is update user profile success
const isUpdateUserProfileSuccessSelector = createSelector(
    userState,
    (user) => user.isUpdateUserProfileSuccess
);

const updateUserProfileErrorSelector = createErrorSelector(
    [types.UPDATE_USER_PROFILE_FAILURE],
    userState
);

const isChangePasswordSuccessSelector = createSelector(
    userState,
    (user) => user.isChangePasswordSuccess
);

const changePasswordErrorSelector = createErrorSelector([types.CHANGE_PASSWORD_FAILURE], userState);

const isEnable2FASuccessSelector = createSelector(userState, (user) => user.isEnable2FASuccess);

const enable2FASuccessErrorSelector = createErrorSelector([types.ENABLE_2FA_REQUEST], userState);

const isDisable2FASuccessSelector = createSelector(userState, (user) => user.isDisable2FASuccess);

const disable2FASuccessErrorSelector = createErrorSelector([types.DISABLE_2FA_REQUEST], userState);

const userCodeForListenChannelSelector = createSelector(userState, (user) => user?.userData?.code);

const userSocketTokenData = createSelector(userState, (user) => user.userSocketToken);

const creditSummarySelector = createSelector(userState, (user) => user.creditSummary);
const creditSummaryLoadingSelector = createLoadingSelector([types.GET_CREDIT_SUMMARY_REQUEST]);

const creditHistorySelector = createSelector(userState, (user) => user.creditHistory);

const loadingCreditHistorySelector = createLoadingSelector([types.GET_CREDIT_HISTORY_REQUEST]);

const userLanguageSelector = createSelector(userState, (user) => user?.userData?.language);

export {
    loadingUserProfileSelector,
    userProfileData,
    userPermissionsData,
    userUnitPriceData,
    userCreditData,
    isUpdateUserProfileSuccessSelector,
    updateUserProfileErrorSelector,
    userSocketTokenData,
    isChangePasswordSuccessSelector,
    changePasswordErrorSelector,
    isEnable2FASuccessSelector,
    enable2FASuccessErrorSelector,
    isDisable2FASuccessSelector,
    disable2FASuccessErrorSelector,
    userCodeForListenChannelSelector,
    creditSummarySelector,
    creditHistorySelector,
    loadingCreditHistorySelector,
    creditSummaryLoadingSelector,
    isExportingSelector,
    userLanguageSelector
};
