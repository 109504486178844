import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * TODO
 * Get API_URL from .env file
 */
const {
    REACT_APP_BASE_API_DOMAIN,
    REACT_APP_BASE_PREFIX_API,
    REACT_APP_BASE_API_VERSION,
    REACT_APP_BASE_TOKEN_KEY,
    REACT_APP_BASE_TOKEN_TYPE_KEY
} = process.env;

const domain = process.env.REACT_APP_REPORT_DOMAIN;

/**
 * TODO
 * Config axios
 */
const configAxios = {
    baseURL: `${REACT_APP_BASE_API_DOMAIN}${REACT_APP_BASE_API_VERSION}${REACT_APP_BASE_PREFIX_API}`,
    timeout: 60000,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
};

/**
 * TODO
 * Create instance
 */
const instance = axios.create(configAxios);

/**
 * TODO
 * The request interceptor
 */

instance.interceptors.request.use(
    (config) => {
        config.headers.lang = Cookies.get('i18next') || 'en';
        const userToken = Cookies.get(REACT_APP_BASE_TOKEN_KEY) || '';
        const tokenType = Cookies.get(REACT_APP_BASE_TOKEN_TYPE_KEY) || 'Bearer';
        if (userToken) {
            config.headers.Authorization = `${tokenType} ${userToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

/**
 * TODO
 * The response interceptor
 */

instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response && error.response.status === 401) {
            Cookies.set(process.env.REACT_APP_BASE_TOKEN_KEY, '', {
                domain
            });
            Cookies.set(process.env.REACT_APP_BASE_TOKEN_TYPE_KEY, 'Bearer', {
                domain
            });

            // ? Direct to login page
            window.location.href = '/auth/login';
        }
        if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    }
);

export default instance;
