const trainingActionTypes = {
    // * Get list course
    GET_LIST_COURSE_REQUEST: 'GET_LIST_COURSE_REQUEST',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILURE: 'GET_LIST_COURSE_FAILURE',

    // * Create training
    CREATE_TRAINING_REQUEST: 'CREATE_TRAINING_REQUEST',
    CREATE_TRAINING_SUCCESS: 'CREATE_TRAINING_SUCCESS',
    CREATE_TRAINING_FAILURE: 'CREATE_TRAINING_FAILURE',
    CREATE_TRAINING_RESET: 'CREATE_TRAINING_RESET',

    // * Get list trainings
    GET_LIST_TRAININGS_REQUEST: 'GET_LIST_TRAININGS_REQUEST',
    GET_LIST_TRAININGS_SUCCESS: 'GET_LIST_TRAININGS_SUCCESS',
    GET_LIST_TRAININGS_FAILURE: 'GET_LIST_TRAININGS_FAILURE',

    // * Get list trainings expand row
    GET_LIST_TRAININGS_EXPAND_ROW_REQUEST: 'GET_LIST_TRAININGS_EXPAND_ROW_REQUEST',
    GET_LIST_TRAININGS_EXPAND_ROW_SUCCESS: 'GET_LIST_TRAININGS_EXPAND_ROW_SUCCESS',
    GET_LIST_TRAININGS_EXPAND_ROW_FAILURE: 'GET_LIST_TRAININGS_EXPAND_ROW_FAILURE',

    // * Get list candidates by training
    GET_LIST_CANDIDATES_BY_TRAINING_REQUEST: 'GET_LIST_CANDIDATES_BY_TRAINING_REQUEST',
    GET_LIST_CANDIDATES_BY_TRAINING_SUCCESS: 'GET_LIST_CANDIDATES_BY_TRAINING_SUCCESS',
    GET_LIST_CANDIDATES_BY_TRAINING_FAILURE: 'GET_LIST_CANDIDATES_BY_TRAINING_FAILURE',

    // * Get training summary
    GET_TRAINING_SUMMARY_REQUEST: 'GET_TRAINING_SUMMARY_REQUEST',
    GET_TRAINING_SUMMARY_SUCCESS: 'GET_TRAINING_SUMMARY_SUCCESS',
    GET_TRAINING_SUMMARY_FAILURE: 'GET_TRAINING_SUMMARY_FAILURE',

    // * Get training result course summary
    GET_TRAINING_RESULT_COURSE_SUMMARY_REQUEST: 'GET_TRAINING_RESULT_COURSE_SUMMARY_REQUEST',
    GET_TRAINING_RESULT_COURSE_SUMMARY_SUCCESS: 'GET_TRAINING_RESULT_COURSE_SUMMARY_SUCCESS',
    GET_TRAINING_RESULT_COURSE_SUMMARY_FAILURE: 'GET_TRAINING_RESULT_COURSE_SUMMARY_FAILURE',

    // * Get training summary candidate list
    GET_TRAINING_SUMMARY_CANDIDATE_LIST_REQUEST: 'GET_TRAINING_SUMMARY_CANDIDATE_LIST_REQUEST',
    GET_TRAINING_SUMMARY_CANDIDATE_LIST_SUCCESS: 'GET_TRAINING_SUMMARY_CANDIDATE_LIST_SUCCESS',
    GET_TRAINING_SUMMARY_CANDIDATE_LIST_FAILURE: 'GET_TRAINING_SUMMARY_CANDIDATE_LIST_FAILURE',

    // * Get candidate training detail
    GET_CANDIDATE_TRAINING_DETAIL_STATS_REQUEST: 'GET_CANDIDATE_TRAINING_DETAIL_STATS_REQUEST',
    GET_CANDIDATE_TRAINING_DETAIL_STATS_SUCCESS: 'GET_CANDIDATE_TRAINING_DETAIL_STATS_SUCCESS',
    GET_CANDIDATE_TRAINING_DETAIL_STATS_FAILURE: 'GET_CANDIDATE_TRAINING_DETAIL_FAILURE',

    // * Start Training
    START_TRAINING_REQUEST: 'START_TRAINING_REQUEST',
    START_TRAINING_SUCCESS: 'START_TRAINING_SUCCESS',
    START_TRAINING_FAILURE: 'START_TRAINING_FAILURE',

    // * Stop Training
    STOP_TRAINING_REQUEST: 'STOP_TRAINING_REQUEST',
    STOP_TRAINING_SUCCESS: 'STOP_TRAINING_SUCCESS',
    STOP_TRAINING_FAILURE: 'STOP_TRAINING_FAILURE',

    // * Rebuild Training
    REBUILD_TRAINING_REQUEST: 'REBUILD_TRAINING_REQUEST',
    REBUILD_TRAINING_SUCCESS: 'REBUILD_TRAINING_SUCCESS',
    REBUILD_TRAINING_FAILURE: 'REBUILD_TRAINING_FAILURE',

    // * Delete Trainings
    DELETE_TRAININGS_REQUEST: 'DELETE_TRAININGS_REQUEST',
    DELETE_TRAININGS_SUCCESS: 'DELETE_TRAININGS_SUCCESS',
    DELETE_TRAININGS_FAILURE: 'DELETE_TRAININGS_FAILURE',

    // * Get training details
    GET_TRAINING_DETAILS_REQUEST: 'GET_TRAINING_DETAILS_REQUEST',
    GET_TRAINING_DETAILS_SUCCESS: 'GET_TRAINING_DETAILS_SUCCESS',
    GET_TRAINING_DETAILS_FAILURE: 'GET_TRAINING_DETAILS_FAILURE',
    GET_TRAINING_DETAILS_RESET: 'GET_TRAINING_DETAILS_RESET',

    // * Update training
    UPDATE_TRAINING_REQUEST: 'UPDATE_TRAINING_REQUEST',
    UPDATE_TRAINING_SUCCESS: 'UPDATE_TRAINING_SUCCESS',
    UPDATE_TRAINING_FAILURE: 'UPDATE_TRAINING_FAILURE',
    UPDATE_TRAINING_RESET: 'UPDATE_TRAINING_RESET',

    // * Get training clone details
    GET_TRAINING_CLONE_DETAILS_REQUEST: 'GET_TRAINING_CLONE_DETAILS_REQUEST',
    GET_TRAINING_CLONE_DETAILS_SUCCESS: 'GET_TRAINING_CLONE_DETAILS_SUCCESS',
    GET_TRAINING_CLONE_DETAILS_FAILURE: 'GET_TRAINING_CLONE_DETAILS_FAILURE',
    GET_TRAINING_CLONE_DETAILS_RESET: 'GET_TRAINING_CLONE_DETAILS_RESET',

    // * Get training summary departments
    GET_TRAINING_SUMMARY_DEPARTMENTS_REQUEST: 'GET_TRAINING_SUMMARY_DEPARTMENTS_REQUEST',
    GET_TRAINING_SUMMARY_DEPARTMENTS_SUCCESS: 'GET_TRAINING_SUMMARY_DEPARTMENTS_SUCCESS',
    GET_TRAINING_SUMMARY_DEPARTMENTS_FAILURE: 'GET_TRAINING_SUMMARY_DEPARTMENTS_FAILURE'
};

export default trainingActionTypes;
