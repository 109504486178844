const simulationTypes = {
    // ? Send test email
    SEND_TEST_EMAIL_REQUEST: 'SEND_TEST_EMAIL_REQUEST',
    SEND_TEST_EMAIL_SUCCESS: 'SEND_TEST_EMAIL_SUCCESS',
    SEND_TEST_EMAIL_FAILURE: 'SEND_TEST_EMAIL_FAILURE',
    SEND_TEST_EMAIL_RESET: 'SEND_TEST_EMAIL_RESET',

    // ? Get phishing email templates
    GET_PHISHING_EMAIL_TEMPLATES_REQUEST: 'GET_PHISHING_EMAIL_TEMPLATES_REQUEST',
    GET_PHISHING_EMAIL_TEMPLATES_SUCCESS: 'GET_PHISHING_EMAIL_TEMPLATES_SUCCESS',
    GET_PHISHING_EMAIL_TEMPLATES_FAILURE: 'GET_PHISHING_EMAIL_TEMPLATES_FAILURE',

    // ? Get phishing list notifications
    GET_PHISHING_LIST_NOTIFICATIONS_REQUEST: 'GET_PHISHING_LIST_NOTIFICATIONS_REQUEST',
    GET_PHISHING_LIST_NOTIFICATIONS_SUCCESS: 'GET_PHISHING_LIST_NOTIFICATIONS_SUCCESS',
    GET_PHISHING_LIST_NOTIFICATIONS_FAILURE: 'GET_PHISHING_LIST_NOTIFICATIONS_FAILURE',

    // * Get list phishing campaigns
    GET_LIST_PHISHING_CAMPAIGNS_REQUEST: 'GET_LIST_PHISHING_CAMPAIGNS_REQUEST',
    GET_LIST_PHISHING_CAMPAIGNS_SUCCESS: 'GET_LIST_PHISHING_CAMPAIGNS_SUCCESS',
    GET_LIST_PHISHING_CAMPAIGNS_FAILURE: 'GET_LIST_PHISHING_CAMPAIGNS_FAILURE',

    // * Get list targets
    GET_LIST_TARGETS_REQUEST: 'GET_LIST_TARGETS_REQUEST',
    GET_LIST_TARGETS_SUCCESS: 'GET_LIST_TARGETS_SUCCESS',
    GET_LIST_TARGETS_FAILURE: 'GET_LIST_TARGETS_FAILURE',

    // * Get scam email content
    GET_SCAM_EMAIL_CONTENT_REQUEST: 'GET_SCAM_EMAIL_CONTENT_REQUEST',
    GET_SCAM_EMAIL_CONTENT_SUCCESS: 'GET_SCAM_EMAIL_CONTENT_SUCCESS',
    GET_SCAM_EMAIL_CONTENT_FAILURE: 'GET_SCAM_EMAIL_CONTENT_FAILURE',

    // * Get scam web content
    GET_SCAM_WEB_CONTENT_REQUEST: 'GET_SCAM_WEB_CONTENT_REQUEST',
    GET_SCAM_WEB_CONTENT_SUCCESS: 'GET_SCAM_WEB_CONTENT_SUCCESS',
    GET_SCAM_WEB_CONTENT_FAILURE: 'GET_SCAM_WEB_CONTENT_FAILURE',
    RESET_SCAM_WEB_CONTENT: 'RESET_SCAM_WEB_CONTENT',

    // * Get view phishing notification
    GET_VIEW_PHISHING_NOTIFICATION_REQUEST: 'GET_VIEW_PHISHING_NOTIFICATION_REQUEST',
    GET_VIEW_PHISHING_NOTIFICATION_SUCCESS: 'GET_VIEW_PHISHING_NOTIFICATION_SUCCESS',
    GET_VIEW_PHISHING_NOTIFICATION_FAILURE: 'GET_VIEW_PHISHING_NOTIFICATION_FAILURE',

    // * Get phishing summary
    GET_PHISHING_SUMMARY_REQUEST: 'GET_PHISHING_SUMMARY_REQUEST',
    GET_PHISHING_SUMMARY_SUCCESS: 'GET_PHISHING_SUMMARY_SUCCESS',
    GET_PHISHING_SUMMARY_FAILURE: 'GET_PHISHING_SUMMARY_FAILURE',

    // * Get phishing timeline result
    GET_PHISHING_TIMELINE_RESULT_REQUEST: 'GET_PHISHING_TIMELINE_RESULT_REQUEST',
    GET_PHISHING_TIMELINE_RESULT_SUCCESS: 'GET_PHISHING_TIMELINE_RESULT_SUCCESS',
    GET_PHISHING_TIMELINE_RESULT_FAILURE: 'GET_PHISHING_TIMELINE_RESULT_FAILURE',

    // * Get phishing result candidate list
    GET_PHISHING_RESULT_CANDIDATE_LIST_REQUEST: 'GET_PHISHING_RESULT_CANDIDATE_LIST_REQUEST',
    GET_PHISHING_RESULT_CANDIDATE_LIST_SUCCESS: 'GET_PHISHING_RESULT_CANDIDATE_LIST_SUCCESS',
    GET_PHISHING_RESULT_CANDIDATE_LIST_FAILURE: 'GET_PHISHING_RESULT_CANDIDATE_LIST_FAILURE',

    // * Stop Campaign
    STOP_CAMPAIGN_REQUEST: 'STOP_CAMPAIGN_REQUEST',
    STOP_CAMPAIGN_SUCCESS: 'STOP_CAMPAIGN_SUCCESS',
    STOP_CAMPAIGN_FAILURE: 'STOP_CAMPAIGN_FAILURE',

    // * Delete Campaigns
    DELETE_CAMPAIGNS_REQUEST: 'DELETE_CAMPAIGNS_REQUEST',
    DELETE_CAMPAIGNS_SUCCESS: 'DELETE_CAMPAIGNS_SUCCESS',
    DELETE_CAMPAIGNS_FAILURE: 'DELETE_CAMPAIGNS_FAILURE',

    // * Create phishing campaign
    CREATE_PHISHING_CAMPAIGN_REQUEST: 'CREATE_PHISHING_CAMPAIGN_REQUEST',
    CREATE_PHISHING_CAMPAIGN_SUCCESS: 'CREATE_PHISHING_CAMPAIGN_SUCCESS',
    CREATE_PHISHING_CAMPAIGN_FAILURE: 'CREATE_PHISHING_CAMPAIGN_FAILURE',
    CREATE_PHISHING_CAMPAIGN_RESET: 'CREATE_PHISHING_CAMPAIGN_RESET',

    // * Get list bookmarks
    GET_LIST_BOOKMARKS_REQUEST: 'GET_LIST_BOOKMARKS_REQUEST',
    GET_LIST_BOOKMARKS_SUCCESS: 'GET_LIST_BOOKMARKS_SUCCESS',
    GET_LIST_BOOKMARKS_FAILURE: 'GET_LIST_BOOKMARKS_FAILURE',

    // * Import phishing site
    IMPORT_PHISHING_SITE_REQUEST: 'IMPORT_PHISHING_SITE_REQUEST',
    IMPORT_PHISHING_SITE_SUCCESS: 'IMPORT_PHISHING_SITE_SUCCESS',
    IMPORT_PHISHING_SITE_FAILURE: 'IMPORT_PHISHING_SITE_FAILURE',
    IMPORT_PHISHING_SITE_RESET: 'IMPORT_PHISHING_SITE_RESET',

    // * Get list sms brands
    GET_LIST_SMS_BRANDS_REQUEST: 'GET_LIST_SMS_BRANDS_REQUEST',
    GET_LIST_SMS_BRANDS_SUCCESS: 'GET_LIST_SMS_BRANDS_SUCCESS',
    GET_LIST_SMS_BRANDS_FAILURE: 'GET_LIST_SMS_BRANDS_FAILURE',

    // * Send test sms
    SEND_TEST_SMS_REQUEST: 'SEND_TEST_SMS_REQUEST',
    SEND_TEST_SMS_SUCCESS: 'SEND_TEST_SMS_SUCCESS',
    SEND_TEST_SMS_FAILURE: 'SEND_TEST_SMS_FAILURE',
    SEND_TEST_SMS_RESET: 'SEND_TEST_SMS_RESET',

    // * Get list sms templates
    GET_LIST_SMS_TEMPLATES_REQUEST: 'GET_LIST_SMS_TEMPLATES_REQUEST',
    GET_LIST_SMS_TEMPLATES_SUCCESS: 'GET_LIST_SMS_TEMPLATES_SUCCESS',
    GET_LIST_SMS_TEMPLATES_FAILURE: 'GET_LIST_SMS_TEMPLATES_FAILURE',

    // * Create sms campaign
    CREATE_SMS_CAMPAIGN_REQUEST: 'CREATE_SMS_CAMPAIGN_REQUEST',
    CREATE_SMS_CAMPAIGN_SUCCESS: 'CREATE_SMS_CAMPAIGN_SUCCESS',
    CREATE_SMS_CAMPAIGN_FAILURE: 'CREATE_SMS_CAMPAIGN_FAILURE',
    CREATE_SMS_CAMPAIGN_RESET: 'CREATE_SMS_CAMPAIGN_RESET',

    // * Preview site
    PREVIEW_SITE_REQUEST: 'PREVIEW_SITE_REQUEST',
    PREVIEW_SITE_SUCCESS: 'PREVIEW_SITE_SUCCESS',
    PREVIEW_SITE_FAILURE: 'PREVIEW_SITE_FAILURE',
    PREVIEW_SITE_RESET: 'PREVIEW_SITE_RESET',

    // * Get list category
    GET_LIST_CATEGORY_REQUEST: 'GET_LIST_CATEGORY_REQUEST',
    GET_LIST_CATEGORY_SUCCESS: 'GET_LIST_CATEGORY_SUCCESS',
    GET_LIST_CATEGORY_FAILURE: 'GET_LIST_CATEGORY_FAILURE',

    // * Get clone phishing details
    GET_CLONE_PHISHING_DETAILS_REQUEST: 'GET_CLONE_PHISHING_DETAILS_REQUEST',
    GET_CLONE_PHISHING_DETAILS_SUCCESS: 'GET_CLONE_PHISHING_DETAILS_SUCCESS',
    GET_CLONE_PHISHING_DETAILS_FAILURE: 'GET_CLONE_PHISHING_DETAILS_FAILURE',

    // * Send test ott
    SEND_TEST_OTT_REQUEST: 'SEND_TEST_OTT_REQUEST',
    SEND_TEST_OTT_SUCCESS: 'SEND_TEST_OTT_SUCCESS',
    SEND_TEST_OTT_FAILURE: 'SEND_TEST_OTT_FAILURE',
    SEND_TEST_OTT_RESET: 'SEND_TEST_OTT_RESET',

    // * Create ott campaign
    CREATE_OTT_CAMPAIGN_REQUEST: 'CREATE_OTT_CAMPAIGN_REQUEST',
    CREATE_OTT_CAMPAIGN_SUCCESS: 'CREATE_OTT_CAMPAIGN_SUCCESS',
    CREATE_OTT_CAMPAIGN_FAILURE: 'CREATE_OTT_CAMPAIGN_FAILURE',
    CREATE_OTT_CAMPAIGN_RESET: 'CREATE_OTT_CAMPAIGN_RESET',

    // * Get templates ott phishing
    GET_TEMPLATES_OTT_PHISHING_REQUEST: 'GET_TEMPLATES_OTT_PHISHING_REQUEST',
    GET_TEMPLATES_OTT_PHISHING_SUCCESS: 'GET_TEMPLATES_OTT_PHISHING_SUCCESS',
    GET_TEMPLATES_OTT_PHISHING_FAILURE: 'GET_TEMPLATES_OTT_PHISHING_FAILURE',

    // * Get phishing summary departments
    GET_PHISHING_SUMMARY_DEPARTMENTS_REQUEST: 'GET_PHISHING_SUMMARY_DEPARTMENTS_REQUEST',
    GET_PHISHING_SUMMARY_DEPARTMENTS_SUCCESS: 'GET_PHISHING_SUMMARY_DEPARTMENTS_SUCCESS',
    GET_PHISHING_SUMMARY_DEPARTMENTS_FAILURE: 'GET_PHISHING_SUMMARY_DEPARTMENTS_FAILURE'
};

export default simulationTypes;
