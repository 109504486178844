import httpClient from 'api';
import DASHBOARD_CONSTANTS from 'api/constants/dashboard';
import { catchAsync } from 'utils/error-utils';

/**
 * TODO: Dashboard assessment
 */

const getDashboardAssessment = catchAsync(async (params) => {
    const response = await httpClient.get(DASHBOARD_CONSTANTS.GET_DASHBOARD_ASSESSMENT, { params });
    return response;
});

/**
 * TODO: Dashboard training
 */

const getDashboardTraining = catchAsync(async (params) => {
    const response = await httpClient.get(DASHBOARD_CONSTANTS.GET_DASHBOARD_TRAINING, { params });
    return response;
});

/**
 * TODO: Dashboard phishing
 */

const getDashboardPhishing = catchAsync(async (params) => {
    const response = await httpClient.get(DASHBOARD_CONSTANTS.GET_DASHBOARD_PHISHING, { params });
    return response;
});

/**
 * TODO: Dashboard last activity
 */

const getDashboardLastActivity = catchAsync(async (params) => {
    const response = await httpClient.get(DASHBOARD_CONSTANTS.GET_DASHBOARD_LAST_ACTIVITY, {
        params
    });
    return response;
});

/**
 * TODO: Dashboard credit participant
 */

const getDashboardCreditAndParticipant = catchAsync(async (params) => {
    const response = await httpClient.get(DASHBOARD_CONSTANTS.GET_DASHBOARD_CREDIT_PARTICIPANT, {
        params
    });
    return response;
});

const DashboardServices = {
    getDashboardAssessment,
    getDashboardTraining,
    getDashboardPhishing,
    getDashboardLastActivity,
    getDashboardCreditAndParticipant
};

export default DashboardServices;
