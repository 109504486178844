import { catchAsync } from 'utils/error-utils';
import httpClient from '../index';
import EXPORT_CONSTANTS from 'api/constants/exports';
import dateUtils from 'utils/date-utils';
import objectUtils from 'utils/object-utils';

const createRequestURL = (path, code, endpoint) =>
    `${path}/${endpoint}`.replace(':trainingCode', code);

/**
 * TODO: Export training participants
 */

const exportTrainingParticipantsPDF = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_PDF_EXPORT,
        trainingCode,
        'popups/candidates'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportTrainingParticipantsSheet = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_SHEET_EXPORT,
        trainingCode,
        'popups/candidates'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export training in-progress
 */

const exportTrainingInprogressPDF = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_PDF_EXPORT,
        trainingCode,
        'popups/summary'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportTrainingInprogressSheet = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_SHEET_EXPORT,
        trainingCode,
        'popups/summary'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export training result
 */

const exportTrainingResultPDF = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_PDF_EXPORT,
        trainingCode,
        'summary/results'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportTrainingResultSheet = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_SHEET_EXPORT,
        trainingCode,
        'summary'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

/**
 * TODO: Export training individual result
 */

const exportTrainingIndividualPDF = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_PDF_EXPORT,
        trainingCode,
        'detail/statistics'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const exportTrainingIndividualSheet = catchAsync(async ({ params, trainingCode }) => {
    const reqURL = createRequestURL(
        EXPORT_CONSTANTS.TRAINING_SHEET_EXPORT,
        trainingCode,
        'detail/statistics'
    );
    const response = await httpClient.get(reqURL, {
        params: {
            ...objectUtils.omit(params, ['page', 'pageSize']),
            timezone: dateUtils.getCurrentTimezone()
        }
    });
    return response;
});

const TrainingServices = {
    exportTrainingParticipantsPDF,
    exportTrainingParticipantsSheet,
    exportTrainingInprogressPDF,
    exportTrainingInprogressSheet,
    exportTrainingResultPDF,
    exportTrainingResultSheet,
    exportTrainingIndividualPDF,
    exportTrainingIndividualSheet
};

export default TrainingServices;
