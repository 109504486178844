/**
 * TODO: Get department list reducer handler
 */

const getDepartmentListReducerHandler = (state, payload) => {
    return { ...state, departments: payload?.departments };
};

/**
 * TODO: Get department list for sms phishing reducer handler
 */

const getDepartmentListForSmsPhishingReducerHandler = (state, payload) => {
    return { ...state, departmentsForSmsPhishing: payload?.departments };
};

/**
 * TODO: Toggle breadcrumbs
 */

const toggleBreadcrumbsReducerHandler = (state, payload) => {
    return { ...state, isShowBreadcrumbs: payload };
};

export default {
    getDepartmentListReducerHandler,
    getDepartmentListForSmsPhishingReducerHandler,
    toggleBreadcrumbsReducerHandler
};
