import types from './actionTypes';

/**
 * TODO: Get list candidates
 */

const getListCandidates = (payload) => ({
    type: types.GET_LIST_CANDIDATES_REQUEST,
    payload
});

const getListCandidatesSuccess = (payload) => ({
    type: types.GET_LIST_CANDIDATES_SUCCESS,
    payload
});

const getListCandidatesFailure = (payload) => ({
    type: types.GET_LIST_CANDIDATES_FAILURE,
    payload
});

/**
 * TODO: Get list candidates for sms campaign
 */

const getListCandidatesForSmsCampaign = (payload) => ({
    type: types.GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_REQUEST,
    payload
});

const getListCandidatesForSmsCampaignSuccess = (payload) => ({
    type: types.GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_SUCCESS,
    payload
});

const getListCandidatesForSmsCampaignFailure = (payload) => ({
    type: types.GET_LIST_CANDIDATES_FOR_SMS_CAMPAIGN_FAILURE,
    payload
});

/**
 * TODO: Delete candidates
 */

const deleteCandidates = (payload) => ({
    type: types.DELETE_CANDIDATES_REQUEST,
    payload
});

const deleteCandidatesSuccess = (payload) => ({
    type: types.DELETE_CANDIDATES_SUCCESS,
    payload
});

const deleteCandidatesFailure = (payload) => ({
    type: types.DELETE_CANDIDATES_FAILURE,
    payload
});

const deleteCandidatesReset = (payload) => ({
    type: types.DELETE_CANDIDATES_RESET,
    payload
});

/**
 * TODO: Add candidates
 */

const addCandidates = (payload) => ({
    type: types.ADD_CANDIDATES_REQUEST,
    payload
});

const addCandidatesSuccess = (payload) => ({
    type: types.ADD_CANDIDATES_SUCCESS,
    payload
});

const addCandidatesFailure = (payload) => ({
    type: types.ADD_CANDIDATES_FAILURE,
    payload
});

const addCandidatesReset = () => ({
    type: types.ADD_CANDIDATES_RESET
});

/**
 * TODO: Import candidates
 */

const importCandidates = (payload) => ({
    type: types.IMPORT_CANDIDATES_REQUEST,
    payload
});

const importCandidatesSuccess = (payload) => ({
    type: types.IMPORT_CANDIDATES_SUCCESS,
    payload
});

const importCandidatesFailure = (payload) => ({
    type: types.IMPORT_CANDIDATES_FAILURE,
    payload
});

const importCandidatesReset = () => ({
    type: types.IMPORT_CANDIDATES_RESET
});

/**
 * TODO: Update candidate
 */

const updateCandidate = (payload) => {
    return {
        type: types.UPDATE_CANDIDATE_REQUEST,
        payload
    };
};

const updateCandidateSuccess = (payload) => ({
    type: types.UPDATE_CANDIDATE_SUCCESS,
    payload
});

const updateCandidateFailure = (payload) => ({
    type: types.UPDATE_CANDIDATE_FAILURE,
    payload
});

const updateCandidateReset = () => ({
    type: types.UPDATE_CANDIDATE_RESET
});

/**
 * TODO: Check valid candidates
 */

const checkValidCandidates = (payload) => ({
    type: types.CHECK_VALID_CANDIDATES_REQUEST,
    payload
});

const checkValidCandidatesSuccess = (payload) => ({
    type: types.CHECK_VALID_CANDIDATES_SUCCESS,
    payload
});

const checkValidCandidatesFailure = (payload) => ({
    type: types.CHECK_VALID_CANDIDATES_FAILURE,
    payload
});

const checkValidCandidatesReset = () => ({
    type: types.CHECK_VALID_CANDIDATES_RESET
});

export {
    // * Get list candidates
    getListCandidates,
    getListCandidatesSuccess,
    getListCandidatesFailure,

    // * Get list candidates for sms campaign
    getListCandidatesForSmsCampaign,
    getListCandidatesForSmsCampaignSuccess,
    getListCandidatesForSmsCampaignFailure,

    // * Delete assessments
    deleteCandidates,
    deleteCandidatesSuccess,
    deleteCandidatesFailure,
    deleteCandidatesReset,

    // * Add candidates
    addCandidates,
    addCandidatesSuccess,
    addCandidatesFailure,
    addCandidatesReset,

    // * Import candidates
    importCandidates,
    importCandidatesSuccess,
    importCandidatesFailure,
    importCandidatesReset,

    // * Update candidate
    updateCandidate,
    updateCandidateSuccess,
    updateCandidateFailure,
    updateCandidateReset,

    // * Check valid candidates
    checkValidCandidates,
    checkValidCandidatesSuccess,
    checkValidCandidatesFailure,
    checkValidCandidatesReset
};
