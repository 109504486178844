import exportActionTypes from './actionTypes';
import assessmentTypes from './assessment/actionTypes';
import trainingTypes from './training/actionTypes';
import phishingActionTypes from './phishing/actionTypes';
import participantActionTypes from './participant/actionTypes';
import statisticsActionTypes from './statistics/actionTypes';
import surveyActionTypes from './survey/actionTypes';

import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        // ? Server starting export success
        case assessmentTypes.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_QUESTIONS_PDF_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_QUESTIONS_SHEET_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_INPROGRESS_PDF_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_INPROGRESS_SHEET_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_RESULT_PDF_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_RESULT_SHEET_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_SUCCESS:
        case assessmentTypes.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_PARTICIPANTS_PDF_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_PARTICIPANTS_SHEET_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_INPROGRESS_PDF_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_INPROGRESS_SHEET_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_RESULT_PDF_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_RESULT_SHEET_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_INDIVIDUAL_PDF_SUCCESS:
        case trainingTypes.EXPORT_TRAINING_INDIVIDUAL_SHEET_SUCCESS:
        case phishingActionTypes.EXPORT_PHISHING_TARGETS_PDF_SUCCESS:
        case phishingActionTypes.EXPORT_PHISHING_TARGETS_SHEET_SUCCESS:
        case phishingActionTypes.EXPORT_PHISHING_RESULT_PDF_SUCCESS:
        case phishingActionTypes.EXPORT_PHISHING_RESULT_SHEET_SUCCESS:
        case participantActionTypes.EXPORT_PARTICIPANT_SHEET_SUCCESS:
        case statisticsActionTypes.EXPORT_REPORT_PDF_SUCCESS:
        case statisticsActionTypes.EXPORT_REPORT_AS_RAW_SUCCESS:
        case surveyActionTypes.EXPORT_SURVEY_RESULT_SHEET_SUCCESS:
            return reducerHandler.dataExportingSuccess(state);

        // ? Server pending or failed
        case assessmentTypes.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_QUESTIONS_PDF_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_QUESTIONS_SHEET_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_INPROGRESS_PDF_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_INPROGRESS_SHEET_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_RESULT_PDF_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_RESULT_SHEET_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_FAILURE:
        case assessmentTypes.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_FAILURE:
        case trainingTypes.EXPORT_TRAINING_PARTICIPANTS_PDF_FAILURE:
        case trainingTypes.EXPORT_TRAINING_PARTICIPANTS_SHEET_FAILURE:
        case trainingTypes.EXPORT_TRAINING_INPROGRESS_PDF_FAILURE:
        case trainingTypes.EXPORT_TRAINING_INPROGRESS_SHEET_FAILURE:
        case trainingTypes.EXPORT_TRAINING_RESULT_PDF_FAILURE:
        case trainingTypes.EXPORT_TRAINING_RESULT_SHEET_FAILURE:
        case trainingTypes.EXPORT_TRAINING_INDIVIDUAL_PDF_FAILURE:
        case trainingTypes.EXPORT_TRAINING_INDIVIDUAL_SHEET_FAILURE:
        case phishingActionTypes.EXPORT_PHISHING_TARGETS_PDF_FAILURE:
        case phishingActionTypes.EXPORT_PHISHING_TARGETS_SHEET_FAILURE:
        case phishingActionTypes.EXPORT_PHISHING_RESULT_PDF_FAILURE:
        case phishingActionTypes.EXPORT_PHISHING_RESULT_SHEET_FAILURE:
        case participantActionTypes.EXPORT_PARTICIPANT_SHEET_FAILURE:
        case statisticsActionTypes.EXPORT_REPORT_PDF_FAILURE:
        case statisticsActionTypes.EXPORT_REPORT_AS_RAW_FAILURE:
        case surveyActionTypes.EXPORT_SURVEY_RESULT_SHEET_FAILURE:
            return reducerHandler.dataExportingFailure(state);

        // ? Clear state
        case exportActionTypes.CLEAR_EXPORT_MESSAGE:
            return reducerHandler.clearExportMessage();
        default:
            return state;
    }
};

export default reducer;
