const CANDIDATE_CONSTANTS = {
    GET_LIST_CANDIDATE: 'user/candidate/list',
    GET_LIST_CANDIDATE_FOR_SMS_CAMPAIGN: 'user/candidate/sms',
    DELETE_CANDIDATE: 'user/candidate/delete',
    ADD_CANDIDATES: 'user/candidate/add',
    IMPORT_CANDIDATES: 'user/candidate/import',
    UPDATE_CANDIDATE: 'user/candidate/edit',
    CHECK_VALID_CANDIDATES: 'candidate/upload/check'
};

export default CANDIDATE_CONSTANTS;
