import loadable from '@loadable/component';

const Staff = loadable(() => import('pages/staff'));
const AddRolePage = loadable(() => import('pages/role/AddRole'));
const RoleDetailsPage = loadable(() => import('pages/role/Details'));
const AddStaff = loadable(() => import('pages/staff/AddStaff'));
const StaffDetailsPage = loadable(() => import('pages/staff/Details'));
const RoleList = loadable(() => import('pages/role'));
const StaffListByRole = loadable(() => import('pages/role/StaffListByRole'));
const ContractList = loadable(() => import('pages/contract'));
const ContractDetail = loadable(() => import('pages/contract/Detail'));
const CreditList = loadable(() => import('pages/user/credit'));

const aclRouteItems = [
    {
        value: 'organization/staff',
        key: 'sat.manager.staff.index',
        component: Staff
    },
    {
        value: 'organization/staff/add',
        key: 'sat.manager.staff.create',
        component: AddStaff
    },
    {
        value: 'organization/staff/edit/:id',
        key: 'sat.manager.staff.update',
        component: StaffDetailsPage
    },
    {
        value: 'organization/role',
        key: 'sat.manager.role.index',
        component: RoleList
    },
    {
        value: 'organization/role/add',
        key: 'sat.manager.role.create',
        component: AddRolePage
    },
    {
        value: 'organization/role/edit/:id',
        key: 'sat.manager.role.update',
        component: RoleDetailsPage
    },
    {
        value: 'organization/role/:id',
        key: 'sat.manager.role.index',
        component: StaffListByRole
    },
    {
        value: 'organization/contract',
        key: 'sat.organization.contract.info',
        component: ContractList
    },
    {
        value: 'organization/contract/:id',
        key: 'sat.organization.contract.info',
        component: ContractDetail
    },
    {
        value: 'organization/credit',
        key: 'sat.organization.credit.history',
        component: CreditList
    }
];

export default aclRouteItems;
