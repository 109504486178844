import loadable from '@loadable/component';

const SurveyList = loadable(() => import('pages/survey'));
const CreateSurvey = loadable(() => import('pages/survey/create'));
const EditSurvey = loadable(() => import('pages/survey/edit'));
const CloneSurvey = loadable(() => import('pages/survey/clone'));

const surveyRouteItems = [
    {
        value: 'survey',
        key: 'sat.survey.index',
        component: SurveyList
    },
    {
        value: 'survey/create',
        key: 'sat.survey.create',
        component: CreateSurvey
    },
    {
        value: 'survey/edit/:id',
        key: 'sat.survey.index',
        component: EditSurvey
    },
    {
        value: 'survey/clone/:id',
        key: 'sat.survey.index',
        component: CloneSurvey
    }
];

export default surveyRouteItems;
