import types from './actionTypes';
import initState from './initialState';
import reducerHandler from './reducerHandler';

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_LIST_COURSE_SUCCESS:
            return reducerHandler.getListCourseReducerHandler(state, action.payload);

        /**
         * TODO: Create training
         */
        case types.CREATE_TRAINING_SUCCESS:
            return reducerHandler.createTrainingReducerHandler(state, action.payload);
        case types.CREATE_TRAINING_RESET:
            return reducerHandler.createTrainingResetReducerHandler(state);
        case types.GET_LIST_TRAININGS_SUCCESS:
            return reducerHandler.getListTraining(state, action.payload);
        case types.GET_LIST_TRAININGS_EXPAND_ROW_SUCCESS:
            return reducerHandler.getListTrainingExpandRowData(state, action.payload);
        // ~ Summary
        case types.GET_LIST_CANDIDATES_BY_TRAINING_SUCCESS:
            return reducerHandler.getListCandidatesByTraining(state, action.payload);
        case types.GET_TRAINING_SUMMARY_SUCCESS:
            return reducerHandler.getTrainingSummary(state, action.payload);
        case types.GET_TRAINING_RESULT_COURSE_SUMMARY_SUCCESS:
            return reducerHandler.getTrainingResultCourseSummary(state, action.payload);
        case types.GET_TRAINING_SUMMARY_CANDIDATE_LIST_SUCCESS:
            return reducerHandler.getTrainingSummaryCandidateList(state, action.payload);
        case types.GET_CANDIDATE_TRAINING_DETAIL_STATS_SUCCESS:
            return reducerHandler.getCandidateResultDetailStats(state, action.payload);
        case types.START_TRAINING_SUCCESS:
            return reducerHandler.startTraining(state, action.payload);

        case types.STOP_TRAINING_SUCCESS:
            return reducerHandler.stopTraining(state, action.payload);
        case types.REBUILD_TRAINING_SUCCESS:
            return reducerHandler.rebuildTraining(state, action.payload);
        case types.DELETE_TRAININGS_SUCCESS:
            return reducerHandler.deleteTrainings(state, action.payload);
        case types.GET_TRAINING_DETAILS_SUCCESS:
            return reducerHandler.getTrainingDetails(state, action.payload);
        case types.GET_TRAINING_DETAILS_RESET:
            return reducerHandler.getTrainingDetailsReset(state);

        // ~ Update training
        case types.UPDATE_TRAINING_SUCCESS:
            return reducerHandler.updateTraining(state, action.payload);

        case types.UPDATE_TRAINING_RESET:
            return reducerHandler.updateTrainingReset(state);

        // ~ Get training clone details
        case types.GET_TRAINING_CLONE_DETAILS_SUCCESS:
            return reducerHandler.getTrainingCloneDetails(state, action.payload);
        case types.GET_TRAINING_CLONE_DETAILS_RESET:
            return reducerHandler.getTrainingCloneDetailsReset(state);

        // ~ Get training summary departments
        case types.GET_TRAINING_SUMMARY_DEPARTMENTS_SUCCESS:
            return reducerHandler.getTrainingSummaryDepartments(state, action.payload);
        default:
            return state;
    }
};

export default reducer;
