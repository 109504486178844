import services from 'api/statistics/summary';
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import actions from './actions';
import actionTypes from './actionTypes';

/**
 * TODO: Get total candidates and departments
 */
function* getTotalCandidatesAndDepartmentsHandler() {
    try {
        const response = yield call(services.getTotalCandidatesAndDepartments);
        yield put(actions.getTotalCandidatesAndDepartmentsSuccess(response.data));
    } catch (error) {
        yield put(actions.getTotalCandidatesAndDepartmentsFailure(error));
    }
}

/**
 * TODO: Get security awareness score
 */
function* getSecurityAwarenessScoreHandler() {
    try {
        const response = yield call(services.getSecurityAwarenessScore);
        yield put(actions.getSecurityAwarenessScoreSuccess(response.data));
    } catch (error) {
        yield put(actions.getSecurityAwarenessScoreFailure(error));
    }
}

/**
 * TODO: Get phishing result
 */
function* getPhishingResult() {
    try {
        const response = yield call(services.getPhishingResult);
        yield put(actions.getPhishingResultSuccess(response.data));
    } catch (error) {
        yield put(actions.getPhishingResultFailure(error));
    }
}

/**
 * TODO: Get SA Rating By Department
 */
function* getSARByDepartment({ payload }) {
    try {
        const response = yield call(services.getSecurityAwarenessRatingByDepartment, payload);
        yield put(actions.getSecurityAwarenessRatingByDepartmentSuccess(response.data));
    } catch (error) {
        yield put(actions.getSecurityAwarenessRatingByDepartmentFailure(error));
    }
}

/**
 * TODO: Get SA Result of Each Participant
 */
function* getSAResultOfParticipant({ payload }) {
    try {
        const response = yield call(services.getSAResultOfParticipant, payload);
        yield put(actions.getSecurityAwarenessResultsOfParticipantSuccess(response.data));
    } catch (error) {
        yield put(actions.getSecurityAwarenessResultsOfParticipantFailure(error));
    }
}

function* watchSummarySaga() {
    yield takeLatest(
        actionTypes.GET_TOTAL_CANDIDATES_AND_DEPARTMENTS_REQUEST,
        getTotalCandidatesAndDepartmentsHandler
    );

    yield takeLatest(
        actionTypes.GET_SECURITY_AWARENESS_SCORE_REQUEST,
        getSecurityAwarenessScoreHandler
    );
    yield takeLatest(actionTypes.GET_PHISHING_RESULT_REQUEST, getPhishingResult);
    yield takeLatest(
        actionTypes.SECURITY_AWARENESS_RATING_BY_DEPARTMENT_REQUEST,
        getSARByDepartment
    );
    yield takeLatest(
        actionTypes.DETAILED_SECURITY_AWARENESS_RESULTS_REQUEST,
        getSAResultOfParticipant
    );
}

export default function* summaryStatisticsSaga() {
    yield fork(watchSummarySaga);
}
