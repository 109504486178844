import types from './actionTypes';

/**
 * TODO: Export assessment participants
 */

export const exportAssessmentParticipantsPDF = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_REQUEST,
    payload,
    permissions
});

export const exportAssessmentParticipantsPDFSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_SUCCESS,
    payload
});

export const exportAssessmentParticipantsPDFFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_PDF_FAILURE,
    payload
});

export const exportAssessmentParticipantsSheet = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportAssessmentParticipantsSheetSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_SUCCESS,
    payload
});

export const exportAssessmentParticipantsSheetFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_PARTICIPANTS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export assessment questions
 */

export const exportAssessmentQuestionsPDF = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_PDF_REQUEST,
    payload,
    permissions
});

export const exportAssessmentQuestionsPDFSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_PDF_SUCCESS,
    payload
});

export const exportAssessmentQuestionsPDFFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_PDF_FAILURE,
    payload
});

export const exportAssessmentQuestionsSheet = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportAssessmentQuestionsSheetSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_SHEET_SUCCESS,
    payload
});

export const exportAssessmentQuestionsSheetFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_QUESTIONS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export assessment in-progress
 */

export const exportAssessmentInprogressPDF = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_PDF_REQUEST,
    payload,
    permissions
});

export const exportAssessmentInprogressPDFSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_PDF_SUCCESS,
    payload
});

export const exportAssessmentInprogressPDFFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_PDF_FAILURE,
    payload
});

export const exportAssessmentInprogressSheet = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_SHEET_REQUEST,
    payload,
    permissions
});

export const exportAssessmentInprogressSheetSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_SHEET_SUCCESS,
    payload
});

export const exportAssessmentInprogressSheetFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INPROGRESS_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export assessment result
 */

export const exportAssessmentResultPDF = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_PDF_REQUEST,
    payload,
    permissions
});

export const exportAssessmentResultPDFSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_PDF_SUCCESS,
    payload
});

export const exportAssessmentResultPDFFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_PDF_FAILURE,
    payload
});

export const exportAssessmentResultSheet = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_SHEET_REQUEST,
    payload,
    permissions
});

export const exportAssessmentResultSheetSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_SHEET_SUCCESS,
    payload
});

export const exportAssessmentResultSheetFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_RESULT_SHEET_FAILURE,
    payload
});

/**
 * TODO: Export assessment individual result
 */

export const exportAssessmentIndividualPDF = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_REQUEST,
    payload,
    permissions
});

export const exportAssessmentIndividualPDFSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_SUCCESS,
    payload
});

export const exportAssessmentIndividualPDFFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_PDF_FAILURE,
    payload
});

export const exportAssessmentIndividualSheet = (payload, permissions) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_REQUEST,
    payload,
    permissions
});

export const exportAssessmentIndividualSheetSuccess = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_SUCCESS,
    payload
});

export const exportAssessmentIndividualSheetFailure = (payload) => ({
    type: types.EXPORT_ASSESSMENT_INDIVIDUAL_SHEET_FAILURE,
    payload
});
